import React, {useState} from "react";
import {useSelector} from "react-redux";
import StartupsModal from "./StartupsModal";
import {Trans, useTranslation} from "react-i18next";
import CardPlaceholder from "../CardPlaceholder";
import StartupCard from "components/cards/StartupCard";

import "./StartupTab.scss";
import useTranslationForGame from "../../../../../../../hooks/useTranslationForGame";

export default function StartupTab({onToggleModal, slideFrom}) {
    const {t} = useTranslation('pages/common/board/conception_modal', {keyPrefix: 'components.startup_tab'});
    const tfg = useTranslationForGame(t);

    const isTournament = useSelector(state => state.game.isTournament);
    const showBoardForTeam = useSelector(state => state.session.showBoardForTeam);
    const startup = useSelector(state => state.teams[showBoardForTeam].startup);

    const [showStartupsModal, setShowStartupsModal] = useState(false);
    const handleCloseFoundersModal = () => {
        setShowStartupsModal(false);
        onToggleModal(false);
    }
    const handleShowFoundersModal = () => {
        setShowStartupsModal(true);
        onToggleModal(true);
    };

    return (
        <>
            <div className="conception-modal-startup-tab">
                <div className={"w-100 animate__animated animate__faster " + slideFrom}>
                    <h4>
                        <Trans t={t} i18nKey={tfg('texts.title', {}, true)}
                               components={{1: <span className='text-warning'/>}}/>
                    </h4>

                    {isTournament && (<p>{tfg('texts.description')}</p>)}
                </div>

                <div className={"w-100 animate__animated animate__faster pb-4 " + slideFrom}>
                    <div className="w-100 d-flex justify-content-center mt-4">
                        <div className="text-center h-100">
                            {(!startup) && (<CardPlaceholder color="info" onClick={() => handleShowFoundersModal()}/>)}

                            {startup && (<StartupCard startup={startup} selectable={false}/>)}

                            <button className={"btn btn-info text-white " + (!startup ? 'pulsating' : '')}
                                    onClick={() => handleShowFoundersModal()}>
                                {startup ? t('buttons.change') : t('buttons.select')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {showStartupsModal && (<StartupsModal onClose={handleCloseFoundersModal}/>)}
        </>
    );
}