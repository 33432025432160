import {createSlice} from '@reduxjs/toolkit'
import {GAME_PHASES, GAME_STEPS, GAMES, PLAYER_ACCESS_CONTROL} from "../../config/CONSTANTS";
import CardUtil from "../../utils/CardUtil";
import GameUtil from "../../utils/GameUtil";

const initialState = {
    gameInfo: {
        id: null,
        version: 'v1.0.0',
        name: null,
        game: GAMES.SMGX,
        startAt: null,
        openUntil: null,
        gameMaster: null,
        owner: null,
        gamePack: {
            id: null,
            packVersion: null,
            contentVersion: null,
        },
        client: {
            name: null,
            contactName: null,
            contactEmail: null,
        },
        callUrl: null,
        gameUrl: null,
        gameMasterUrl: null,
    },
    tournamentInfo: {
        canRequestCertificate: false,
        needSetup: false,
    },

    customization: {},

    ranking: {},
    teamCount: 0,
    currentStep: GAME_STEPS.AWAITING,
    currentPhase: GAME_PHASES.PHASE_1,
    players: [],
    events: [],

    playersCounter: {
        devices: 0,
        localPlayers: 0,
        total: 0
    },

    isFinished: false,
    isPaused: false,
    canJoinTeam: true,
    canHireCards: false,
    isMarketOpen: false,
    hasRevealedWinners: false,
    playerAccessControl: PLAYER_ACCESS_CONTROL.ALL,
    allowCertificateRequest: true,
    maxPlayerCount: null,

    usePreSelectedStartups: false,
    preSelectedStartups: false,

    isPocket: false,
    isNationalSession: false,
    isOwnSession: true,

    isTemporary: false,
    isDemonstration: false,
    isTournament: false,
};

function getPlayerCount(players) {
    const counter = {
        devices: players.length,
        localPlayers: 0,
        total: 0
    };

    players.forEach(player => {
        counter.localPlayers += player?.localPlayers?.length ?? 0;
    });

    counter.total = counter.localPlayers + counter.devices;

    return counter;
}


export const GameSlice = createSlice({
    name: 'game',
    initialState,
    reducers: {
        setGameInfo(state, {payload}) {
            if (payload.game)
                GameUtil.setGameType(payload.game);

            state.gameInfo = {
                ...state.gameInfo,
                ...payload
            };
        },

        setCurrentStep(state, {payload}) {
            state.currentPhase = CardUtil.getGamePhaseForStep(payload);
            state.currentStep = payload;
            state.isMarketOpen = payload > GAME_STEPS.PITCH_1 && payload < GAME_STEPS.END_GAME;
            state.isFinished = payload >= GAME_STEPS.END_GAME
        },

        setTeamCount(state, {payload}) {
            state.teamCount = payload;
        },
        setIsPaused(state, {payload}) {
            state.isPaused = payload;
        },
        setCanJoinTeam(state, {payload}) {
            state.canJoinTeam = payload;
        },
        setPlayerAccessControl(state, {payload}) {
            state.playerAccessControl = payload;
        },
        setAllowCertificateRequest(state, {payload}) {
            state.allowCertificateRequest = payload;
        },
        setUsePreSelectedStartups(state, {payload}) {
            state.usePreSelectedStartups = payload;
        },
        setPreSelectedStartups(state, {payload}) {
            state.preSelectedStartups = payload;
        },
        setCanHireCards(state, {payload}) {
            state.canHireCards = payload;
        },
        setHasRevealedWinners(state, {payload}) {
            state.hasRevealedWinners = payload;
        },
        setRanking(state, {payload}) {
            state.ranking = payload;
        },
        updateRanking(state, {payload}) {
            const {team, data} = payload;
            if (state.ranking[team]) state.ranking[team] = {...state.ranking[team], ...data};
            else state.ranking[team] = data;

            let showedFor = 0;
            Object.values(state.ranking).forEach(rankingData => {
                if (rankingData.show)
                    showedFor++;
            });

            if (showedFor === state.teamCount)
                state.hasRevealedWinners = true;
        },

        // players
        addPlayers(state, {payload}) {
            if (Array.isArray(payload)) state.players.push(...payload);
            else state.players.push(payload);

            state.playersCounter = getPlayerCount(state.players);
        },
        removePlayers(state, {payload}) {
            if (Array.isArray(payload)) state.players = state.players.filter(p => !payload.includes(p.id));
            else state.players = state.players.filter(p => p.id !== payload.id);

            state.playersCounter = getPlayerCount(state.players);
        },
        removeAllPlayers(state, {payload}){
          state.players = [];
          state.playersCounter = 0;
        },
        updatePlayer(state, {payload}) {
            for (let i = 0; i < state.players.length; i++) {
                if (state.players[i].id === payload.id) {
                    state.players[i] = {...state.players[i], ...payload};
                    break;
                }
            }

            state.playersCounter = getPlayerCount(state.players);
        },
        setIsPocket(state, {payload}) {
            state.isPocket = payload;
        },
        setIsNationalSession(state, {payload}) {
            state.isNationalSession = payload;
        },
        setIsOwnSession(state, {payload}) {
            state.isOwnSession = payload;
        },
        setIsTemporary(state, {payload}) {
            state.isTemporary = payload;
        },
        setCustomization(state, {payload}) {
            state.customization = payload;
        },
        setMaxPlayerCount(state, {payload}) {
            state.maxPlayerCount = payload;
        },
        setIsDemonstration(state, {payload}) {
            state.isDemonstration = payload;
        },
        setIsTournament(state, {payload}) {
            state.isTournament = payload;
        },
        setTournamentInfo(state, {payload}) {
            state.tournamentInfo = {...state.tournamentInfo, ...payload};
        },
    }
});

export const {
    setGameInfo,
    setCurrentStep,
    setTeamCount,
    setIsPaused,
    setUsePreSelectedStartups,
    setPreSelectedStartups,
    setCanJoinTeam,
    setPlayerAccessControl,
    setAllowCertificateRequest,
    setCanHireCards,
    setHasRevealedWinners,
    setIsPocket,
    setIsNationalSession,
    setIsOwnSession,
    setIsTemporary,
    setCustomization,
    setMaxPlayerCount,
    setIsDemonstration,
    setIsTournament,

    setRanking,
    updateRanking,

    addPlayers,
    removePlayers,
    updatePlayer,
    removeAllPlayers,

    setTournamentInfo,
} = GameSlice.actions;
export default GameSlice.reducer;