import React from 'react';
import {Modal} from 'react-bootstrap';
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import StartupCard from "components/cards/StartupCard";

import "./StartupProfileModal.scss";
import FounderCard from "../../../../../../../components/cards/FounderCard";
import ValuePropositionCard, {
    VALUE_PROPOSITION_LAYOUT
} from "../../../../../../../components/cards/ValuePropositionCard";
import useTranslationForGame from "../../../../../../../hooks/useTranslationForGame";

export default function StartupProfileModal({onClose}) {
    const {t} = useTranslation('pages/common/board/startup', {keyPrefix: 'components.profile_modal'});
    const tfg = useTranslationForGame(t);

    const showBoardForTeam = useSelector(state => state.session.showBoardForTeam);
    const startup = useSelector(state => state.teams[showBoardForTeam].startup);
    const founders = useSelector(state => state.teams[showBoardForTeam].founders);
    const foundersIcons = useSelector(state => state.teams[showBoardForTeam].foundersIcons);
    const valuePropositions = useSelector(state => state.teams[showBoardForTeam].valuePropositions);

    return (
        <Modal show={true} onHide={onClose} className="modal-left fade startup-profile-modal" scrollable={true}>
            <Modal.Header closeButton className="bg-dark text-white">
                <Modal.Title>{tfg("texts.title")}</Modal.Title>
            </Modal.Header>

            <Modal.Body className="bg-dark">

                {!startup && !founders[0] && !founders[1] && !valuePropositions[0] && !valuePropositions[1] && (
                    <p className="text-center text-muted">{t("texts.empty")}</p>
                )}

                {startup && (
                    <div className="d-flex justify-content-center">
                        <StartupCard startup={startup} selectable={false}/>
                    </div>
                )}


                <div className="d-flex justify-content-center">
                    <div className="d-flex flex-column h-100">
                        {founders[0] && (
                            <FounderCard founder={founders[0]} icon={foundersIcons[0]} selectable={false}/>
                        )}
                        {valuePropositions[0] && (
                            <ValuePropositionCard valueProposition={valuePropositions[0]}
                                                  selectable={false}
                                                  layout={VALUE_PROPOSITION_LAYOUT.EXPANDABLE}/>
                        )}
                    </div>

                    <div className="d-flex flex-column h-100">
                        {founders[1] && (
                            <FounderCard founder={founders[1]} icon={foundersIcons[1]} selectable={false}/>
                        )}
                        {valuePropositions[1] && (
                            <ValuePropositionCard valueProposition={valuePropositions[1]}
                                                  selectable={false}
                                                  layout={VALUE_PROPOSITION_LAYOUT.EXPANDABLE}/>
                        )}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}
