import React, {memo, useMemo} from "react";
import {UNPREDICTABILITY_IMPACT} from "config/CONSTANTS";
import positiveIcon from "assets/img/board/ic_unpredict_positive.svg";
import negativeIcon from "assets/img/board/ic_unpredict_negative.svg";
import neutralIcon from "assets/img/board/ic_unpredict_neutral.svg";
import GameEventCardLayout from "../GameEventCardLayout/GameEventCardLayout";

import "./UnpredictabilityCard.scss";
import {useTranslation} from "react-i18next";

const UnpredictabilityCard = ({data}) => {
    const {t} = useTranslation('pages/common/board/game_events', {keyPrefix: 'components.unpredictability_card'});

    const icon = useMemo(() => {
        switch (data.impact) {
            case UNPREDICTABILITY_IMPACT.POSITIVE:
                return positiveIcon;
            case UNPREDICTABILITY_IMPACT.NEGATIVE:
                return negativeIcon;
            default:
                return neutralIcon;
        }
    }, [data]);

    const consequence = useMemo(() => {
        switch (data.impact) {
            case UNPREDICTABILITY_IMPACT.POSITIVE:
                return data.card.positiveConsequenceDescription;
            case UNPREDICTABILITY_IMPACT.NEGATIVE:
                return data.card.negativeConsequenceDescription;
            default:
                return data.card.neutralConsequenceDescription;
        }
    }, [data]);

    return (
        <div className="position-relative">
            <GameEventCardLayout title={t('texts.title')} color={'info'} leftContent={data.card.event}
                                 rightContent={<p dangerouslySetInnerHTML={{__html: consequence}}/>}/>

            <img alt="Unpredictability icon" src={icon}
                 className="unpredictability-card-icon animate__animated animate__faster animate__zoomIn"/>
        </div>
    );
}

export default memo(UnpredictabilityCard);