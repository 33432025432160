import React, {useEffect, useState} from "react";
import PlayerView from "./PlayerView";
import PlayerForm from "./PlayerForm";
import store from "../../../../redux/store";
import {GAME_STEPS} from "../../../../config/CONSTANTS";
import {useSelector} from "react-redux";

const VIEW_TYPES = {
    EDIT: 1,
    VIEW: 2
}

export default function PlayerItem({
                                       player,
                                       rootPlayerId,
                                       certificate,
                                       answeredNps = false,
                                       onRemoveNewLocalPlayer,
                                       requireCompany = true,
                                       requireCountry = true,
                                       canRemove = true,
                                       disableCertificateForm,
                                       hideCertificateInfo,
                                       disableNpsForm
                                   }) {


    const [view, setView] = useState(player.isTemporary ? VIEW_TYPES.EDIT : VIEW_TYPES.VIEW);
    const isGameMaster = useSelector(state => state.session.isGameMaster);

    const handleCloseForm = () => {
        if (player.isTemporary) {
            onRemoveNewLocalPlayer(player.id);
        } else {
            handleSetView(VIEW_TYPES.VIEW);
        }
    }

    const handleSetView = newView => {
        setView(newView);
    }

    useEffect(() => {

        if (
            !isGameMaster
            && player.isRoot
            && player.localPlayersCount === 0
            && (!certificate || Object.keys(certificate).length === 0)
            && store.getState().game.currentStep >= GAME_STEPS.ONBOARDING
        ) {
            setView(VIEW_TYPES.EDIT);
        }
    }, []);

    return (<div className="player-item">
        {view === VIEW_TYPES.VIEW && (
            <PlayerView player={player}
                        rootPlayerId={rootPlayerId}
                        certificate={certificate}
                        answeredNps={answeredNps}
                        canRemove={canRemove}
                        onEditClick={() => handleSetView(VIEW_TYPES.EDIT)}
                        disableCertificateForm={disableCertificateForm}
                        hideCertificateInfo={hideCertificateInfo}
                        disableNpsForm={disableNpsForm}/>
        )}

        {view === VIEW_TYPES.EDIT && (
            <PlayerForm player={player}
                        rootPlayerId={rootPlayerId}
                        certificate={certificate}
                        requireCompany={requireCompany}
                        requireCountry={requireCountry}
                        onClose={handleCloseForm}
                        disableCertificateForm={disableCertificateForm}/>
        )}

        <hr/>
    </div>)
}