import React, {memo, useMemo, useRef, useState} from "react";
import GameCardLayout from "components/cards/layouts/GameCardLayout";

import "./ValuePropositionCard.scss";
import {useTranslation} from "react-i18next";
import SimpleBar from "simplebar-react";
import useTranslationForGame from "../../hooks/useTranslationForGame";
import CardUtil from "../../utils/CardUtil";

export const VALUE_PROPOSITION_LAYOUT = {
    TITLE_ONLY: 1,
    FULL: 2,
    EXPANDABLE: 3,
}

const ValuePropositionCard = ({
                                  valueProposition,
                                  onSelect,
                                  selectable = false,
                                  previousSelected,
                                  highlightTitle = false,
                                  layout = VALUE_PROPOSITION_LAYOUT.FULL
                              }) => {

    const {t} = useTranslation('components/cards', {keyPrefix: 'value_proposition_card'});
    const tfg = useTranslationForGame(t);

    const handleClick = () => {
        if (selectable && onSelect) onSelect(valueProposition);
    };

    const detailsEl = useRef();
    const [showDetails, setShowDetails] = useState(false);
    const handleShowDetails = () => {
        setShowDetails(true);

        setTimeout(() => requestAnimationFrame(() => {
            detailsEl.current.scrollIntoView({behavior: 'smooth'})
        }), 200);
    };
    const handleHideDetails = () => setShowDetails(false);

    const cardClass = useMemo(() => selectable ? 'selectable' : '', [selectable]);
    const titleClass = useMemo(() => highlightTitle ? 'text-white' : '', [highlightTitle]);
    const detailsBtnClass = useMemo(() => !showDetails ? 'd-none' : '', [showDetails]);

    return (
        <GameCardLayout>
            <div className={"box border-info value-proposition-card bg-info text-left d-flex flex-column " + cardClass}
                 onClick={handleClick}>

                <div>
                    <div className="box-head text-dark bg-white text-uppercase mb-0 px-2 py-1">
                        {tfg('texts.title')}
                    </div>
                </div>

                {layout === VALUE_PROPOSITION_LAYOUT.TITLE_ONLY && (
                    <div
                        className="flex-fill value-content text-dark pt-1 d-flex justify-content-center align-items-center text-center">
                        <h6 className={"mb-0 font-weight-bold text-break " + titleClass}>
                            {valueProposition?.title || '???'}
                        </h6>
                    </div>
                )}

                {layout === VALUE_PROPOSITION_LAYOUT.FULL && (
                    <div className="flex-fill value-content text-dark">
                        <SimpleBar className="content-scrollable">
                            <h6 className="mb-0 font-weight-bold">{valueProposition?.title || '???'}</h6>

                            <p className="mb-0"
                               dangerouslySetInnerHTML={{__html: CardUtil.replaceLineBreaksWithBr(valueProposition.description || '')}}/>
                        </SimpleBar>
                    </div>
                )}

                {layout === VALUE_PROPOSITION_LAYOUT.EXPANDABLE && (
                    <>
                        <div className="flex-fill value-content">
                            {!showDetails && (
                                <div className="d-flex align-items-center justify-content-center text-center mt-2">
                                    <h6 className="font-weight-bold">{valueProposition.title}</h6>
                                </div>
                            )}

                            {showDetails && (
                                <h6 className="font-weight-bold">{valueProposition.title}</h6>
                            )}

                            <p ref={detailsEl}
                               className={"details " + detailsBtnClass}
                               dangerouslySetInnerHTML={{__html: CardUtil.replaceLineBreaksWithBr(valueProposition.description || '')}}/>
                        </div>

                        <div className="box-footer">
                            <button className="btn btn-link py-0 text-uppercase"
                                    onClick={showDetails ? handleHideDetails : handleShowDetails}>
                                {showDetails ? t('buttons.hide') : t('buttons.show')}
                            </button>
                        </div>
                    </>
                )}
            </div>
        </GameCardLayout>
    );
}

export default memo(ValuePropositionCard);