import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";

export default function StatusPitch3() {
    const {t} = useTranslation('pages/game_master/control_panel/step_status', {keyPrefix: 'components.status_pitch_3.texts'});
    const players = useSelector(state => state.game.players);

    const [allPlayersRequestedCertificate, setAllPlayersRequestedCertificate] = useState(false);

    useEffect(() => {
        for (let i = 0; i < players.length; i++) {
            if (players[i].isConnected && !players[i].certificatesRequested) {
                setAllPlayersRequestedCertificate(false);
                return;
            }
        }

        setAllPlayersRequestedCertificate(true);
    }, [players]);

    return (
        <div>
            <h6 className="font-weight-bold">{t('last_step_to_request_certificate')}</h6>

            {!allPlayersRequestedCertificate && (
                <h6 className="text-warning">
                    <FontAwesomeIcon icon={['fas', 'exclamation-circle']} className="mr-2"/>
                    {t('not_all_requested')}
                </h6>
            )}

            {allPlayersRequestedCertificate && (
                <h6 className="text-success">
                    <FontAwesomeIcon icon={['fas', 'check-circle']} className="mr-2"/>
                    {t('all_request')}
                </h6>
            )}
        </div>
    );
}