import React, {useState} from "react";

import "./Header.scss";
import logo from "assets/img/logos/svg/logo_only.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SessionPersistenceUtil, {GAME_MASTER_KEY} from "utils/SessionPersistenceUtil";
import GameLinksModal from "./components/GameLinksModal";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import JourneyMapModal from "../../../../common/JourneyMap/JourneyMapModal";
import AuthService from "../../../../../services/gameMaster/AuthService";

export default function Header() {
    const {t} = useTranslation('pages/game_master/control_panel/header');
    const gameInfo = useSelector(state => state.game.gameInfo);

    const [showLinksModal, setShowLinksModal] = useState(false);
    const handleShowLinksModal = () => setShowLinksModal(true);
    const handleCloseLinksModal = () => setShowLinksModal(false);

    const [showJourneyMapModal, setShowJourneyMapModal] = useState(false);
    const handleShowJourneyMapModal = () => setShowJourneyMapModal(true);
    const handleCloseJourneyMapModal = () => setShowJourneyMapModal(false);

    const handleLeaveGame = () => {
        const joinUrl = SessionPersistenceUtil.get(GAME_MASTER_KEY).gameUrl;

        AuthService.leave();
        window.location = joinUrl;
    };

    return (
        <>
            <div id="control-panel-header" className="w-100 d-flex justify-content-center">
                <div className="container-custom w-100 d-flex justify-content-between align-items-center px-5 py-2">
                    <div className="d-flex justify-content-center align-items-center py-1">
                        <img src={logo} alt="Mundi Game Experiences logo" className="mr-2"/>

                        <div>
                            <h4 className="m-0">{t('texts.page_title')}</h4>
                            <h6 className="mb-0">{gameInfo.name || ''}</h6>
                        </div>
                    </div>

                    <div>
                        <button type="button" className="btn btn-primary py-1 px-2 mr-2"
                                onClick={handleShowJourneyMapModal}>
                            <FontAwesomeIcon icon={['fas', 'map']} className="mr-2"/>
                            {t('buttons.journey_map')}
                        </button>

                        <button type="button" className="btn btn-primary py-1 px-2 mr-2" onClick={handleShowLinksModal}>
                            <FontAwesomeIcon icon={['fas', 'external-link-alt']} className="mr-2"/>
                            {t('buttons.game_links')}
                        </button>

                        <button type="button" className="btn btn-primary py-1 px-2" onClick={handleLeaveGame}>
                            <FontAwesomeIcon icon={['fas', 'sign-out-alt']} className="mr-2"/>
                            {t('buttons.leave')}
                        </button>
                    </div>
                </div>
            </div>

            {showLinksModal && (<GameLinksModal onClose={handleCloseLinksModal}/>)}
            {showJourneyMapModal && <JourneyMapModal onClose={handleCloseJourneyMapModal}/>}
        </>
    );
}