// ----------------------- ACCELERATION BONUSES -----------------------
import {SKILL_GENDER_GROUP} from "./SKILL_ICONS";

const GAME_PITCH_BONUS = 10;

export const DIVERSITY_GENDER_BONUS = {
    /** how many 'other' gender to have to give the bonus (i.e. Math.floor(count/AMOUNT) * BONUS) */
    [SKILL_GENDER_GROUP.OTHER]: {
        AMOUNT: 2,
        BONUS: 2
    },

    /** bonus related to the % of female skills (i.e. amount must be >= MIN && <= MAX */
    [SKILL_GENDER_GROUP.FEMALE]: {
        MIN: 40,
        MAX: 70,
        BONUS: 5
    },
}

/** bonus related to the % of the minority group */
export const DIVERSITY_RACE_BONUS = {
    25: 3,
    35: 4,
    50: 5,
};


// ----------------------- EXPORTS -----------------------
const ACCOUNTING_COMMON_CONFIG = Object.freeze({
    DIVERSITY_GENDER_BONUS,
    DIVERSITY_RACE_BONUS,
    GAME_PITCH_BONUS
});


export default ACCOUNTING_COMMON_CONFIG;