import React from "react";
import GameCardLayout from "components/cards/layouts/GameCardLayout";
import SimpleBar from "simplebar-react";

import {useTranslation} from "react-i18next";

export default function PitchCard({header, content, phase}) {
    const {t} = useTranslation('components/cards', {keyPrefix: 'pitch_card.texts'});

    const handleFormatContent = () => {
        return content.replaceAll('\n', '<br/>');
    }

    return (<GameCardLayout>
        <div className="d-flex pitch-card">
            <div className="box border-info w-100 bg-dark d-flex flex-column">
                <div>
                    <div className="box-head bg-info text-uppercase">{t('title', {step: phase})}</div>
                </div>

                <div className="value-content flex-fill w-100 d-flex flex-column text-white px-1 mb-3">
                    <SimpleBar className="content-scrollable">
                        <h5 className="mt-1 mb-0 text-center text-info">{header}</h5>
                        <p className="mx-2" dangerouslySetInnerHTML={{__html: handleFormatContent()}}/>
                    </SimpleBar>
                </div>
            </div>
        </div>
    </GameCardLayout>);
}