import React from "react";

import "./Header.scss";
import logo from "assets/img/logos/svg/without_tagline_3.svg";

export default function Header() {

    return (<>
            <div id="terms-header" className="w-100 d-flex justify-content-center bg-dark">
                <div className="w-100 d-flex justify-content-between align-items-center px-3 py-2">
                    <div className="d-flex justify-content-center align-items-center py-1">
                        <a href="https://startupmundi.com" target="_blank" referrerPolicy="no-referrer">
                            <img src={logo} alt="Mundi Game Experiences logo" className="mr-3"/>
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}