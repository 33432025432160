import React, {useEffect, useMemo, useRef, useState} from "react";

import "./ConceptionModal.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ValuePropositionTab from "./components/valueProposition/ValuePropositionTab";


import startupIcon from "assets/img/board/ic_startup_default.svg";
import founderIcon from "assets/img/board/skill_face.svg";
import valuePropositionIcon from "assets/img/board/ic_value_proposition_default.svg";
import FounderTab from "./components/founder/FounderTab";
import StartupTab from "./components/startup/StartupTab";
import {useSelector} from "react-redux";
import StepButton from "./components/StepButton";
import {Trans, useTranslation} from "react-i18next";
import {Portal} from "react-portal";
import useTranslationForGame from "../../../../../hooks/useTranslationForGame";
import DemoSessionService from "../../../../../services/common/DemoSessionService";
import TournamentService from "../../../../../services/common/TournamentService";
import GameUtil from "../../../../../utils/GameUtil";


const CONCEPTION_TABS = {
    STARTUP: 0,
    VALUE_PROPOSITIONS: 1,
    FOUNDERS: 2,
}

export default function ConceptionModal({onClose}) {
    const {t} = useTranslation('pages/common/board/conception_modal');
    const tfg = useTranslationForGame(t);

    const isTournament = useSelector(state => state.game.isTournament);
    const isDemonstration = useSelector(state => state.game.isDemonstration);
    const showBoardForTeam = useSelector(state => state.session.showBoardForTeam);
    const startupProfile = useSelector(state => state.teams[showBoardForTeam]);
    const usePreSelectedStartups = useSelector(state => state.game.usePreSelectedStartups);

    const isEsg = useMemo(GameUtil.isCurrentGameEsg, []);

    const containerRef = useRef();

    const [tabToShow, setTabToShow] = useState(-1);
    const [showingSubModal, setShowingSubModal] = useState(false);
    const [missingStartup, setMissingStartup] = useState(true);
    const [missingFounders, setMissingFounders] = useState(true);
    const [missingValuePropositions, setMissingValuePropositions] = useState(true);
    const [slideContentFrom, setSlideContentFrom] = useState('animate__slideInUp');
    const [allowAutoClose, setAllowAutoClose] = useState(null);

    const handleShowNextTab = () => {
        setSlideContentFrom('animate__slideInLeft');

        if (tabToShow === CONCEPTION_TABS.FOUNDERS)
            setTabToShow(CONCEPTION_TABS.STARTUP);
        else
            setTabToShow(tabToShow + 1);
    };

    const handleShowPreviousTab = () => {
        setSlideContentFrom('animate__slideInRight');

        if (tabToShow === CONCEPTION_TABS.STARTUP)
            setTabToShow(CONCEPTION_TABS.FOUNDERS);
        else
            setTabToShow(tabToShow - 1);
    };

    const handleSubModal = (isShowing) => {
        setShowingSubModal(isShowing);
    };

    const handleKeyDown = (event) => {
        if (event.keyCode === 27 && !showingSubModal)
            onClose();
    }

    useEffect(() => {
        if (usePreSelectedStartups) setTabToShow(1);
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        containerRef.current.focus();
    }, []);

    useEffect(() => {
        setSlideContentFrom('animate__slideInUp');

        const missingStartup = !startupProfile.startup;
        const missingFounders = !startupProfile.founders || !startupProfile.founders[0] || !startupProfile.founders[1];
        const missingVps = !startupProfile.valuePropositions || !startupProfile.valuePropositions[0] || !startupProfile.valuePropositions[1];
        let missingTotals = missingStartup + missingFounders + missingVps;

        let newTabToShow = tabToShow === -1 ? CONCEPTION_TABS.STARTUP : tabToShow;

        if (usePreSelectedStartups) {
            newTabToShow = CONCEPTION_TABS.VALUE_PROPOSITIONS;
        } else {
            if (missingStartup && missingTotals === 1)
                newTabToShow = CONCEPTION_TABS.STARTUP;

            if (missingVps && !missingStartup && (tabToShow === CONCEPTION_TABS.STARTUP || missingTotals === 1))
                newTabToShow = CONCEPTION_TABS.VALUE_PROPOSITIONS;

            if (missingFounders && !missingVps && (tabToShow === CONCEPTION_TABS.VALUE_PROPOSITIONS || missingTotals === 1))
                newTabToShow = CONCEPTION_TABS.FOUNDERS;
        }


        // this attribute will only be set when this function is ran for the first time
        if (allowAutoClose === null)
            setAllowAutoClose(missingTotals > 0);


        setTabToShow(newTabToShow);
        setMissingStartup(missingStartup);
        setMissingFounders(missingFounders);
        setMissingValuePropositions(missingVps);
    }, [startupProfile]);


    // when the team setup is completed automatically close the modal
    useEffect(() => {
        if (missingStartup || missingFounders || missingValuePropositions || !allowAutoClose)
            return;

        if (isDemonstration)
            DemoSessionService.advanceToQuestionStep();

        if (isTournament)
            TournamentService.touchTournamentState({hasFinishedConception: true});

        onClose();
    }, [allowAutoClose, isDemonstration, isTournament, missingStartup, missingFounders, missingValuePropositions]);


    return (
        <Portal>
            <div onKeyDown={handleKeyDown} tabIndex={-1} ref={containerRef}
                 className={"conception-modal py-3 px-4 show" + (showingSubModal ? 'blur' : '')}>

                <div className="container-custom">
                    <button type="button" className="btn btn-link float-right text-white btn-close"
                            onClick={onClose}>
                        <FontAwesomeIcon icon={['fas', 'times']} className={'fa-2x'}/>
                    </button>

                    <div className="w-100 animate__animated animate__faster animate__slideInDown mb-4">
                        <h4 className="mb-0">
                            {isTournament && isEsg && "É hora de definir o contexto onde irá atuar"}
                            {(!isTournament || !isEsg) && tfg('texts.title')}
                        </h4>

                        {isTournament && isEsg && (<p>
                            Escolha um Cenário, duas Metas Prioritárias e dois Responsáveis
                        </p>)}

                        {(!isTournament || !isEsg) && (<p>
                            {usePreSelectedStartups ? tfg('texts.subtitle_vp_only') : tfg('texts.subtitle_complete')}
                        </p>)}
                    </div>
                </div>

                <div className="container-custom">
                    <div
                        className="text-center step-buttons d-flex justify-content-center animate__animated animate__faster animate__slideInDown">
                        <div className="d-flex justify-content-around align-items-center">
                            {usePreSelectedStartups && (
                                <StepButton title={tfg('texts.value_propositions')} icon={valuePropositionIcon}
                                            isCurrent={false} clickable={false}
                                            isMissingData={missingValuePropositions}
                                            onClick={() => {
                                            }}/>
                            )}

                            {!usePreSelectedStartups && (
                                <>
                                    <div>
                                        <button type="button" className="btn btn-link py-0 text-white btn-navigate"
                                                onClick={handleShowPreviousTab}>
                                            <FontAwesomeIcon icon={['fas', 'angle-left']} className="fa-3x"/>
                                        </button>
                                    </div>

                                    <StepButton title={tfg('texts.startup')} icon={startupIcon}
                                                isCurrent={tabToShow === 0}
                                                isMissingData={missingStartup} onClick={() => setTabToShow(0)}/>

                                    <span className="step-divider"/>

                                    <StepButton title={tfg('texts.value_propositions')} icon={valuePropositionIcon}
                                                isCurrent={tabToShow === 1}
                                                isMissingData={missingValuePropositions}
                                                onClick={() => setTabToShow(1)}/>

                                    <span className="step-divider"/>

                                    <StepButton title={tfg('texts.founders')} icon={founderIcon}
                                                isCurrent={tabToShow === 2}
                                                isMissingData={missingFounders} onClick={() => setTabToShow(2)}/>

                                    <button type="button" className="btn btn-link py-0 text-white btn-navigate"
                                            onClick={handleShowNextTab}>
                                        <FontAwesomeIcon icon={['fas', 'angle-right']} className="fa-3x"/>
                                    </button>
                                </>
                            )}
                        </div>
                    </div>

                    {!missingValuePropositions && !missingStartup && !missingFounders && (
                        <div
                            className="w-100 d-flex justify-content-center text-success animate__animated animate__faster animate__slideInDown">
                            <div className="bg-dark text-center rounded px-3 py-1 mt-2">
                                <Trans t={t} i18nKey={(tfg('texts.all_set', {}, true))}/>
                            </div>
                        </div>
                    )}

                    <div className="tabs-container d-flex justify-content-center w-100 mt-3 text-center">
                        <div>
                            {tabToShow === CONCEPTION_TABS.STARTUP && (
                                <StartupTab slideFrom={slideContentFrom} onToggleModal={handleSubModal}/>)}

                            {tabToShow === CONCEPTION_TABS.VALUE_PROPOSITIONS && (
                                <ValuePropositionTab slideFrom={slideContentFrom} onToggleModal={handleSubModal}/>)}

                            {tabToShow === CONCEPTION_TABS.FOUNDERS && (
                                <FounderTab slideFrom={slideContentFrom} onToggleModal={handleSubModal}/>)}
                        </div>
                    </div>
                </div>
            </div>
        </Portal>
    );
}