import React from "react";
import {useTranslation} from "react-i18next";

export default function Exit() {
    const {t} = useTranslation('pages/player/exit');

    return (
        <div className="h-100 w-100 d-flex justify-content-center align-items-center flex-column bg-dark text-white">
            <h1 className="text-warning" style={{fontSize: '5rem'}}>{t('texts.message')}</h1>
            <h4>{t('texts.rejoin_info')}</h4>
        </div>
    );
}
