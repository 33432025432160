import React, {memo, useEffect, useRef, useState} from "react";

import "./TournamentPitchChallenge.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ChatMessage from "./components/ChatMessage";
import SimpleBar from "simplebar-react";
import Utils from "../../../../../../../utils/Utils";
import {useSelector} from "react-redux";
import TournamentService from "../../../../../../../services/common/TournamentService";
import ButtonSpinner from "../../../../../../../components/ButtonSpinner";
import ToastUtil from "../../../../../../../utils/ToastUtil";
import {
    getEsgPitch1Script,
    getEsgPitch2Script,
    getSmgxPitch1Script,
    getSmgxPitch2Script,
    getSmgxPitch3Script
} from "./services/TournamentPitchService";
import {GAME_STEPS} from "../../../../../../../config/CONSTANTS";
import TOURNAMENT_CONFIG from "../../../../../../../config/TOURNAMENT_CONFIG";
import GameUtil from "../../../../../../../utils/GameUtil";

const MAX_INPUT_LENGTH = 500;
const DELAY_BETWEEN_MESSAGES = 3; // seconds

function setDelayBetweenMessages(currentStep, messages) {
    let delay = currentStep === 0 ? 0 : 2;

    messages.forEach(message => {
        message.delay = delay;
        delay += DELAY_BETWEEN_MESSAGES;
    });
}

function transformMessagesToStore(messages) {
    return messages.map(message => {
        delete message.onShow;
        delete message.delay;

        return message;
    });
}

/**
 *
 * @param data Pitch step (i.e. GAME_STEPS.PITCH_#)
 * @returns {JSX.Element}
 * @constructor
 */
const TournamentPitchChallenge = ({data}) => {
    const scrollBarRef = useRef();
    const inputRef = useRef();

    const nickname = useSelector(state => state.session.user.nickname);
    const teamData = useSelector(state => state.teams[TOURNAMENT_CONFIG.TEAM]);

    const [messages, setMessages] = useState([]);
    const [pitchScript, setPitchScript] = useState([]);
    const [step, setStep] = useState(0);
    const [typingMessage, setTypingMessage] = useState('');
    const [isInputEnabled, setIsInputEnabled] = useState(false);
    const [isInputFocused, setIsInputFocused] = useState(false);
    const [isFinished, setIsFinished] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [highlightInput, setHighlightInput] = useState(false);
    const [pendingMessage, setPendingMessage] = useState(false);


    const handleScrollToEnd = () => {
        scrollBarRef.current.getScrollElement().scrollTop = scrollBarRef.current.getScrollElement().scrollHeight;
    }

    const handleEnableInput = () => {
        setIsInputEnabled(true);
        setHighlightInput(true);
    }

    const handleInputChange = (ev) => {
        setHighlightInput(false);
        setTypingMessage(ev.target.value);
    }

    const handleFormSubmit = (ev) => {
        ev.preventDefault();

        const newMessage = typingMessage.trim();

        if (!newMessage) {
            setTypingMessage(newMessage);
            return false;
        }

        setPendingMessage(newMessage);
        setTypingMessage('');
        setIsInputEnabled(false);
        setIsInputFocused(false);
    }

    const handleEditMessage = () => {
        setTypingMessage(pendingMessage);
        setPendingMessage(false);
        setIsInputEnabled(true);

        inputRef.current.click();
    }

    const handleStoreMessage = async () => {
        setIsLoading(true);

        const nextStep = step + 1;
        const nextStepMessages = structuredClone(pitchScript[nextStep]);

        const newMessage = {
            id: Utils.uuid(),
            message: pendingMessage,
            origin: 'right'
        };

        const dataToStore = {
            team: 1,
            pitch: data,
            data: {
                step: nextStep,
                messages: transformMessagesToStore([
                    ...messages,
                    newMessage,
                    ...nextStepMessages
                ])
            },
        };

        if (!await TournamentService.updatePitchData(dataToStore)) {
            ToastUtil.toastDanger('Algo inexperado aconteceu', 'Por favor, tente novamente');
            setIsLoading(false);

            return;
        }

        setDelayBetweenMessages(step, nextStepMessages);

        if (nextStep === pitchScript.length - 1) {
            // is last step
            // finish chat after last message
            nextStepMessages[nextStepMessages.length - 1].onShow = () => setIsFinished(true);
        } else {
            // has more stepsEsg after
            nextStepMessages[nextStepMessages.length - 1].onShow = handleEnableInput;
        }


        const newMessages = [
            ...messages,
            newMessage,
            ...nextStepMessages
        ];


        setTypingMessage('');
        setPendingMessage(false);
        setIsInputEnabled(false);
        setIsInputFocused(false);
        setIsLoading(false);
        setMessages(newMessages);
        setStep(nextStep);
    }

    const handleAdvanceGame = async () => {
        setIsLoading(true);

        if (!await TournamentService.advanceGame()) {
            setIsLoading(false);
            ToastUtil.toastDanger('Não foi possível avançar o jogo', 'Esta é a ultima etapa [por enquanto]');
        }
    }

    useEffect(() => handleScrollToEnd, [messages]);

    useEffect(() => {
        setTimeout(() => requestAnimationFrame(handleScrollToEnd));

        // we need to reset the width of the scroll container
        // without doing it, the width stays the same and can cause a visible overflow
        // couldn't find a proper solution for this using the scroll plugin API
        const resizeListener = function (ev) {
            if (scrollBarRef)
                scrollBarRef.current.placeholderEl.style.width = 'auto';
        }

        window.addEventListener('resize', resizeListener);

        return () => window.removeEventListener('resize', resizeListener);
    }, []);


    useEffect(() => {
        let pitchScript;

        if (GameUtil.isCurrentGameEsg()) {
            pitchScript = data === GAME_STEPS.PITCH_1
                ? getEsgPitch1Script(teamData, nickname)
                : getEsgPitch2Script(teamData, nickname);
        } else {
            if (data === GAME_STEPS.PITCH_1){
                pitchScript = getSmgxPitch1Script(teamData, nickname);
            }else if (data === GAME_STEPS.PITCH_2){
                pitchScript = getSmgxPitch2Script(teamData, nickname);
            }else{
                pitchScript = getSmgxPitch3Script(teamData, nickname);
            }
        }


        let newMessages = [];
        let stepToSet = 0;

        if (teamData.pitchData[data]?.messages?.length) {
            newMessages = structuredClone(teamData.pitchData[data]?.messages);
            stepToSet = teamData.pitchData[data].step;
        } else {
            newMessages = structuredClone(pitchScript[0]);
            setDelayBetweenMessages(step, newMessages);
        }


        newMessages[newMessages.length - 1].onShow = () => {
            if (stepToSet >= pitchScript.length - 1) {
                setIsFinished(true);
            } else {
                handleEnableInput();
            }

            setTimeout(() => requestAnimationFrame(handleScrollToEnd), 500);
        };


        setStep(stepToSet);
        setMessages(newMessages);
        setPitchScript(pitchScript);
    }, [teamData.pitchData]);

    return (
        <div
            className="tournament-pitch-challenge h-100 w-100 d-flex justify-content-center align-items-center flex-column">

            <div
                className={"chat-container h-100 w-100 rounded d-flex flex-column " + (isInputFocused ? 'input-focused' : '')}>
                <div
                    className="chat-header w-100 bg-dark text-light px-3 py-2 mb-1 shadow d-flex align-items-center rounded-top">
                    <div className="group-icon d-inline-flex justify-content-center align-items-center mr-2">
                        <FontAwesomeIcon icon={['fas', 'users']}/>
                    </div>

                    {GameUtil.isCurrentGameEsg() && (
                        <div>
                            <p className="mb-n1">Stakeholders</p>
                            <p className="text-muted mb-n1" style={{fontSize: "80%"}}>
                                CEO, CFO, COO
                            </p>
                        </div>
                    )}

                    {!GameUtil.isCurrentGameEsg() && (
                        <div>
                            <p className="mb-0">{teamData?.startup?.title}</p>
                        </div>
                    )}
                </div>

                <div className="chat-messages py-1 overflow-hidden flex-fill">
                    <SimpleBar ref={scrollBarRef}
                               className="content-scrollable w-100 px-3"
                               autoHide={false}>

                        {messages.map((message, index) => (<>
                            {index > 0 && messages[index - 1].origin !== message.origin && (
                                <span className="pb-1">&nbsp;</span>
                            )}

                            <ChatMessage key={message.id}
                                         message={message.message}
                                         delay={message.delay * 1000}
                                         from={message.from}
                                         origin={message.origin}
                                         onShow={message.onShow}
                                         onRender={handleScrollToEnd}/>
                        </>))}

                        {pendingMessage && (<>
                            <div className="rounded pt-3 pb-4 px-2 border-dark animate__animated animate__fadeIn"
                                 style={{background: "rgba(0,0,0,0.47)"}}>

                                <ChatMessage message={pendingMessage}
                                             origin={'right'}
                                             onRender={handleScrollToEnd}/>

                                <p className="text-center text-white  mb-2 mt-4 font-weight-light">
                                    [Esta é a sua resposta final?]
                                </p>

                                <div className="d-flex justify-content-center" style={{gap: 10}}>
                                    <ButtonSpinner className="btn btn-sm btn-success rounded"
                                                   onClick={handleStoreMessage}
                                                   showAnimation={isLoading}
                                                   disabled={isLoading}>
                                        Sim, confirmar resposta
                                    </ButtonSpinner>

                                    <button className="btn btn-sm btn-secondary text-dark rounded"
                                            onClick={handleEditMessage}
                                            disabled={isLoading}>

                                        Editar resposta
                                    </button>
                                </div>
                            </div>
                        </>)}
                    </SimpleBar>
                </div>

                {!isFinished && (
                    <form onSubmit={handleFormSubmit}
                          className="chat-input rounded-bottom bg-dark animate__animated animate__fadeInUp">

                        <div className="input-wrapper mx-2 mb-1 position-relative bg-dark">
                             <textarea name="message" required
                                       disabled={!isInputEnabled}
                                       ref={inputRef}
                                       rows={4}
                                       className={"form-control mt-1 position-absolute bottom " + (highlightInput ? 'pulsating-pitch-input' : '')}
                                       value={typingMessage}
                                       onFocus={() => setIsInputFocused(true)}
                                       onBlur={() => setIsInputFocused(false)}
                                       placeholder="Digite aqui a sua mensagem..."
                                       maxLength={MAX_INPUT_LENGTH}
                                       onChange={handleInputChange}></textarea>

                        </div>


                        <div className="d-flex justify-content-between align-items-center px-2 pb-1">
                            <p className="m-0 text-light" style={{fontSize: "75%"}}>
                                Envie uma mesagem por pergunta. {typingMessage.length}/{MAX_INPUT_LENGTH} caracteres.
                            </p>

                            <button type="submit" className="btn btn-sm btn-success n-0" disabled={!isInputEnabled}>
                                Enviar
                            </button>
                        </div>
                    </form>
                )}

                {isFinished && (
                    <div className="text-center bg-dark rounded-bottom py-3 animate__animated animate__fadeInUp">
                        <ButtonSpinner className="btn btn-success shadow rounded" showAnimation={isLoading}
                                       onClick={handleAdvanceGame}>
                            Avançar para a próxima etapa
                        </ButtonSpinner>
                    </div>)}
            </div>
        </div>
    );
}

export default memo(TournamentPitchChallenge);