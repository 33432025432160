import React from "react";

import {useSelector} from "react-redux";
import Utils from "utils/Utils";
import InfoPageLayout from "./InfoPageLayout/InfoPageLayout";
import {Trans, useTranslation} from "react-i18next";

export default function SessionNotStartedInfo() {
    const {t} = useTranslation('pages/common/session_status', {keyPrefix: 'components.session_not_started.texts'});
    const startAt = useSelector(state => state.game.gameInfo.startAt);

    return (
        <InfoPageLayout>
            {startAt && (
                <>
                    <h3 className="mb-3">{t('with_date.title')}</h3>
                    <h3 className="text-warning mb-3 font-weight-bold text-break">
                        {Utils.utcDateTimeToLocalDateStringWithoutSeconds(startAt, true)}
                    </h3>
                    <h3 className="mb-3"><Trans t={t} i18nKey={'with_date.message'}/></h3>
                </>
            )}

            {!startAt && (
                <>
                    <h3 className="mb-3">{t('without_date.title')}</h3>
                    <h3 className="text-warning mb-3 font-weight-bold">{t('without_date.subtitle')}</h3>
                    <h3 className="mb-3">{t('without_date.message')}</h3>
                </>
            )}
        </InfoPageLayout>
    );
}