import React, {useState} from "react";
import AdvanceButtons from "../components/AdvanceButtons";
import ToastUtil from "../../../../utils/ToastUtil";

export default function NicknameStep({data, onAdvance, onBack, isLastStep}) {
    const [nickname, setNickname] = useState(data ?? '');

    const buildData = (ignoreErrors = false) => {
        const data = {nickname: nickname.trim()};

        if (!ignoreErrors && (data.nickname.length < 4 || data.nickname > 20)) {
            setNickname(data.nickname)
            ToastUtil.toastDanger('Verifique os dados informados!');

            return false;
        }


        return {player: {nickname}};
    }

    const handleSubmit = ev => {
        ev.preventDefault();

        const data = buildData();

        if (data)
            onAdvance(data);
    }


    const handleBack = () => {
        onBack(buildData(true));
    }

    return (<form onSubmit={handleSubmit} className="animate__animated animate__fadeIn">
        <h4 className="mb-5 text-warning">
            Como esta é a sua primeira sessão, vamos configurar algumas detalhes
        </h4>


        <h5>Escolha um apelido!</h5>
        <p className="mb-0">
            Este apelido será a sua identidade pública.
            Ele será exibido em rankings e poderá ser visto por outros jogadores.
        </p>


        <p className="text-info">
            Dica: se você estiver jogando com mais pessoas, use o apelido para definir o nome da sua equipe!
        </p>


        <div className="form-group">
            <label className="required">Apelido</label>

            <input name="nickname"
                   style={{textAlign: 'center'}}
                   value={nickname}
                   className="form-control"
                   type="text"
                   minLength={3}
                   maxLength={20}
                   onChange={ev => setNickname(ev.target.value)}
                   required autoFocus/>

            <p className="form-text small">Deve ter entre 4 e 20 caracteres</p>
        </div>


        <AdvanceButtons onBack={handleBack} isLastStep={isLastStep} isFirstStep={true}/>
    </form>);
}