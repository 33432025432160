import React from 'react';
import LoggerService from "./services/common/LoggerService";

class GlobalErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {error: false};
    }

    /**
     * Catches errors not handled by any children
     *
     * @param error
     * @param errorInfo
     */
    componentDidCatch(error, errorInfo) {
        this.setState({error: true});
        LoggerService.error(error, {action: 'Global error boundary'});
    }

    render() {
        if (this.state.error) {
            return <div
                className="w-100 h-100 px-2 py-3 text-center d-flex justify-content-center align-items-center flex-column">
                <h3 className="mb-2 font-weight-bold">Something went wrong</h3>
                <h4>Please refresh the page and try again or contact us</h4>

                <p className="mt-3">code: 0x0000</p>
            </div>
        }

        return this.props.children;
    }
}

export default GlobalErrorBoundary;

