import React from "react";
import {Button, Modal} from "react-bootstrap";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

export default function ClientInfoModal({onClose}) {
    const {t} = useTranslation('pages/game_master/control_panel/game_info', {keyPrefix: 'components.client_info_modal'})
    const clientInfo = useSelector(state => state.game.gameInfo.client);

    return (
        <Modal show={true} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>{t('texts.title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="form-group">
                    <label>{t('texts.client')}</label>
                    <p className="m-0">{clientInfo.name || '-'}</p>
                </div>

                <div className="form-group">
                    <label>{t('texts.name')}</label>
                    <p className="m-0">{clientInfo.contactName || '-'}</p>
                </div>

                <div className="form-group">
                    <label>{t('texts.email')}</label>
                    <p className="m-0">{clientInfo.contactEmail || '-'}</p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>{t('buttons.close')}</Button>
            </Modal.Footer>
        </Modal>
    );
}