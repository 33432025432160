import {createSlice} from '@reduxjs/toolkit';
import Utils from "../../utils/Utils";

const initialState = {
    pages: {
        join: {
            bg_color: "#2D3A53"
        }
    },

    logos: {
        icon: "/img/defaultVisualPack/logo_only.svg",
        join_page_logo: "/img/defaultVisualPack/without_tagline.svg",
        join_page_secondary_logo: "/img/defaultVisualPack/default_2.svg",
        challenge_logo: "/img/defaultVisualPack/without_tagline_white.svg",
    }
};

export const ThemeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        setTheme(state, {payload}) {
            if (payload.logos) {
                state.logos = {...Utils.mergeObjectsDeep(state.logos, payload.logos)};

                if (payload.logos.join_page_logo)
                    state.logos.join_page_secondary_logo = payload.logos.join_page_logo;
            }

            if (payload.pages)
                state.pages = {...Utils.mergeObjectsDeep(state.pages, payload.pages)};
        },
    }
});

export const {
    setTheme
} = ThemeSlice.actions;

export default ThemeSlice.reducer;