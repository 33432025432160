import store from "redux/store";
import {
    setHasConnectionError,
    setHasFatalError,
    setHasLoadedGameData,
    setHasLoadedTeamData,
    setShowBoardForTeam,
    setTeam,
    setToken,
    setUser
} from "redux/slices/sessionSlice";

const SessionReduxService = {
    updateToken(token) {
        store.dispatch(setToken(token));
        return true;
    },

    updateUser(user) {
        store.dispatch(setUser(user));
        return true;
    },

    updateTeam(team) {
        store.dispatch(setTeam(team));
        return true;
    },

    updateHasLoadedData(hasLoadedData) {
        store.dispatch(setHasLoadedGameData(hasLoadedData));
        return true;
    },

    updateHasLoadedTeamData(hasLoadedData) {
        store.dispatch(setHasLoadedTeamData(hasLoadedData));
        return true;
    },

    updateHasConnectionError(hasError) {
        store.dispatch(setHasConnectionError(hasError));
        return true;
    },

    updateIsReconnectingError(hasError) {
        store.dispatch(setHasConnectionError(hasError));
        return true;
    },

    updateShowBoardForTeam(team) {
        store.dispatch(setShowBoardForTeam(team));
        return true;
    },

    updateHasFatalError(hasError) {
        store.dispatch(setHasFatalError(hasError));
    }
};

export default SessionReduxService;
