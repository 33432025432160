import React, {useMemo, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {GAME_STEPS} from "config/CONSTANTS";
import PlayerService from "../../../../services/common/PlayerService";
import ButtonSpinner from "../../../../components/ButtonSpinner";
import ToastUtil from "../../../../utils/ToastUtil";
import LoggerService from "../../../../services/common/LoggerService";
import SwalModalUtil from "../../../../utils/SwalModalUtil";

export default function PlayerView({
                                       player,
                                       rootPlayerId,
                                       certificate,
                                       answeredNps = false,
                                       canRemove = true,
                                       disableCertificateForm = false,
                                       disableNpsForm = false,
                                       hideCertificateInfo,
                                       onEditClick,
                                   }) {

    const {t} = useTranslation('pages/common/player_form', {keyPrefix: 'components.player_view'});

    const currentStep = useSelector(state => state.game.currentStep);
    const [isLoading, setIsLoading] = useState(false);

    const playerInfo = useMemo(() => {
        if (!certificate) return '';

        return [certificate?.name ?? '', certificate?.email ?? '', certificate?.company ?? '', certificate?.country ?? '']
            .filter(data => data)
            .join(' - ');
    }, [certificate]);

    const handleNpsClick = () => {
        const closePlayerFormButton = document.getElementsByClassName('btn-close-player-form');
        const openNpsModalButton = document.getElementsByClassName('nps-button');

        if (closePlayerFormButton.length && openNpsModalButton.length) {
            closePlayerFormButton[0].click();
            openNpsModalButton[0].click();
        }
    }

    const handleRemove = async () => {
        SwalModalUtil.confirmModal(t('confirms.remove_local_player.title'), t('confirms.remove_local_player.message'))
            .then(result => {
                if (!result) return;

                setIsLoading(true);

                PlayerService.removeLocalPlayer(rootPlayerId, player.id).then(result => {
                    if (result)
                        ToastUtil.toastSuccess(t('toasts.remove_success.title'), t('toasts.remove_success.message'));
                    else
                        ToastUtil.toastDanger(t('toasts.remove_error.title'), t('toasts.remove_error.message'));
                }).catch(err => {
                    LoggerService.error(err, {
                        action: '[PlayerView] - handleRemove()',
                        params: {player}
                    });

                    ToastUtil.toastDanger(t('toasts.remove_error.title'), t('toasts.remove_error.message'));
                    setIsLoading(false);
                });
            });
    }


    return (<div
        className="player-item d-flex flex-column-reverse  flex-md-row animate__animated animate__fadeIn animate__faster">

        <div className="flex-fill">
            <h6 className="mb-0"><FontAwesomeIcon icon={['fas',  'circle-user']} className="mr-1"/> {player.nickname}</h6>
            <p className="mb-1 text-muted">{playerInfo}</p>

            <div>
                {certificate && (
                    <div className="badge badge-pill border mr-2 rounded">{t('texts.certificate_requested')}</div>
                )}

                {answeredNps && (
                    <div className="badge badge-pill border rounded">{t('texts.nps_sent')}</div>
                )}
            </div>

            <div className="mt-2">
                {!disableCertificateForm && !certificate && (
                    <p className="font-weight-bold mb-0" style={{fontSize: '90%'}}>
                        <FontAwesomeIcon icon={['fas', 'exclamation-circle']} className="mr-1 text-warning"/>
                        {t('texts.request_certificate_info')}
                    </p>
                )}

                {disableCertificateForm && currentStep < GAME_STEPS.ONBOARDING && (
                    <p className="text-info mb-0">
                        <FontAwesomeIcon icon={['fas', 'exclamation-circle']} className="mr-1"/>
                        {t('texts.wait_game_to_start_info')}
                    </p>
                )}

                {disableCertificateForm && currentStep > GAME_STEPS.PITCH_3 && !certificate && (
                    <p className="text-muted mb-0">
                        <FontAwesomeIcon icon={['fas', 'exclamation-circle']} className="mr-1"/>
                        {t('texts.cannot_request_certificate')}
                    </p>
                )}

            </div>

            {!disableNpsForm && !answeredNps && !player.new && (
                <button type="button" className="btn btn-sm btn-link d-block p-0 mt-3 text-uppercase pulsating"
                        onClick={handleNpsClick}>
                    {t('buttons.rate_experience')}
                </button>
            )}
        </div>

        <div className="d-flex flex-md-column justify-content-md-center ml-md-2 mb-2 mb-md-0">
            <button type="button" className="btn btn-sm btn-primary py-0 px-1 mr-1 mr-md-0 mb-md-1 no-wrap"
                    disabled={isLoading}
                    onClick={onEditClick}>
                {t('buttons.edit')}
            </button>

            {canRemove && (
                <ButtonSpinner type="button" className="btn btn-sm btn-danger py-0 px-1 no-wrap" onClick={handleRemove}
                               showAnimation={isLoading}>
                    {t('buttons.remove')}
                </ButtonSpinner>
            )}
        </div>
    </div>);
}