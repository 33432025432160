import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    messages: [],
    hasDisplayedGoToBoardMessage: false,
};

export const MessagesSlice = createSlice({
    name: 'messages',
    initialState,
    reducers: {
        addMessage(state, {payload}) {
            state.messages.unshift({
                message: payload,
                time: new Date()
            });
        },

        setHasDisplayedGoToBoardMessage(state, {payload}) {
            state.hasDisplayedGoToBoardMessage = payload;
        }
    }
});

export const {
    addMessage,
    setHasDisplayedGoToBoardMessage
} = MessagesSlice.actions;
export default MessagesSlice.reducer;