import React, {useState} from "react";
import {Button, Modal} from "react-bootstrap";
import i18nUtil from "utils/i18nUtil";
import {useSelector} from "react-redux";
import ToastUtil from "utils/ToastUtil";
import SwalModalUtil from "utils/SwalModalUtil";
import GameService from "services/gameMaster/GameService";
import OverlayUtil from "utils/OverlayUtil";
import LoggerService from "services/common/LoggerService";
import {useTranslation} from "react-i18next";
import useTranslationForGame from "../../../../../../../hooks/useTranslationForGame";

export default function PitchBonusFormModal({onClose, step}) {
    const {t} = useTranslation('pages/game_master/control_panel/game_control', {keyPrefix: "components.pitch_bonus_buttons.components.pitch_bonus_modal"});
    const tfg = useTranslationForGame(t);

    const teamCount = useSelector(state => state.game.teamCount);
    const [sendToTeam, setSendToTeam] = useState(1);

    const handleSubmit = () => {
        if (!sendToTeam) {
            ToastUtil.toastInfo(t('toasts.invalid_team.title'), t('toasts.invalid_team.message'));
            return;
        }

        SwalModalUtil.confirmModal(t('confirms.send.title'), t('confirms.send.message', {team: sendToTeam})).then(result => {

            if (result) {
                OverlayUtil.toggleLoadingOverlay(true, t('overlays.sending'));

                const params = {team: sendToTeam, step};
                GameService.sendPitchBonus(params).then(result => {
                    if (result) {
                        ToastUtil.toastSuccess(t('toasts.send_pitch_bonus_success.title'), t('toasts.send_pitch_bonus_success.message'));
                        onClose();
                    } else {
                        ToastUtil.toastDanger(t('toasts.send_pitch_bonus_error.title'), t('toasts.send_pitch_bonus_error.message'));
                    }
                }).catch(error => {
                    console.error(error);
                    ToastUtil.toastDanger(t('toasts.send_pitch_bonus_error.title'), t('toasts.send_pitch_bonus_error.message'));

                    LoggerService.error(error, {
                        action: 'send pitch bonus - control panel',
                        params
                    });
                }).finally(() => {
                    OverlayUtil.toggleLoadingOverlay(false);
                });
            }
        });
    };

    return (
        <Modal show={true} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>{t('texts.modal_title', {step: i18nUtil.stepName(step)})}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="form-group">
                    <label>{tfg('inputs.team.label')}</label>

                    <select className="form-control" onChange={(event) => setSendToTeam(event.target.value)}
                            value={sendToTeam}>
                        <option value={1}>1</option>
                        {teamCount > 1 && (<option value={2}>2</option>)}
                        {teamCount > 2 && (<option value={3}>3</option>)}
                        {teamCount > 3 && (<option value={4}>4</option>)}
                    </select>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleSubmit}>{t('buttons.send')}</Button>
                <Button variant="secondary" onClick={onClose}>{t('buttons.close')}</Button>
            </Modal.Footer>
        </Modal>
    );
}