import React from 'react';
import {TERMS_ROUTES} from "../../../../navigation/ROUTES";

export default function PrivacyEn() {
    return <>
        <h4>Privacy Policy</h4>

        <p className="font-weight-bold mb-0">
            1. What Type of Information Does Startup Mundi Collect?
        </p>

        <p>
            There are several ways we may collect information from or about you, including through (A) information you
            provide
            to us directly; and (B) information that we automatically or passively collect.
        </p>

        <p>
            A. Information You Provide
            <br/>
            The personal information collected by Startup Mundi may include:
            <br/>
            Your name.
        </p>

        <p>
            B. Information We Automatically Collect

            <br/>
            Whenever you visit or interact with the site, we, as well as any third-party advertisers and/or service
            providers,
            may use cookies, web beacons, local shared objects (Flash Cookies), or other technologies to automatically
            or
            passively collect information about your online activity and website use information. For information about
            our use
            of cookies and other similar technologies through the site, you can read our <a target="_blank"
                                                                                            href={TERMS_ROUTES.COOKIES_POLICY}>Cookie
            Policy</a>.
        </p>

        <p className="mb-0">
            Please note that we automatically collect the following information about you:
        </p>

        <ul>
            <li>Website Use Information. We may automatically collect website use information when you visit our site.
                This may
                include information about your Internet service provider, your operating system, browser type, domain
                name,
                Internet protocol (IP) address, your access times, the website that referred you to us, the Web pages
                you
                request, and the date and time of those requests.
            </li>
            <li>Third Party Service Providers. We may use third party service providers to support our website. Some of
                these
                service providers may use technology such as cookies, web beacons, pixel tags, log files, or Flash
                cookies to
                receive, collect, and store information.
            </li>
            <li>Third Party Analytic Technologies. We may use third parties' analytics and tracking tools to better
                understand
                who is using the website, how people are using website, how to improve the effectiveness of the website,
                our
                services and related content, to help us or those third parties serve more targeted advertising to you
                across
                the Internet. These tools may use technology such as cookies, web beacons, pixel tags, log files, Flash
                cookies,
                HTML5 cookies, or other technologies to automatically collect and store certain information. They may
                also
                combine information they collect from your interaction with the website with information they collect
                from other
                sources. We do not have access to, or control over, these third parties' use of cookies or other
                tracking
                technologies.
            </li>
        </ul>


        <p className="font-weight-bold mb-0">
            2. How Does Startup Mundi Use Information About You?
        </p>

        <p className="mb-0">
            Startup Mundi may use the information we collect from and about you for a variety of business purposes,
            including
            to:
        </p>

        <ul>
            <li>Communicate with you about the game session and certificates.</li>
            <li>Provide you with information about products and services we offer (if you agree to receive such
                information).
            </li>
            <li>Address problems with the website or our business, and to protect the security or integrity of the Site
                and our
                business.
            </li>
        </ul>

        <p className="text-danger">
            We also use anonymous or aggregated information to allow us to audit site usage, improve our game process
            and to
            provide better user experience.
        </p>

        <p className="mb-0">
            We will have a lawful basis for processing your data if:
        </p>

        <ul>
            <li>We need to process your information in order to provide you with the products or service you have
                requested;
            </li>
            <li>You have consented to such processing;</li>
            <li>We have a legitimate interest for processing your data – e.g., for fraud prevention; network and
                information
                systems security; data analytics; enhancing, modifying or improving our services; identifying usage
                trends;
                and/or
            </li>
            <li>We are legally obliged to process it.</li>
        </ul>


        <p className="font-weight-bold mb-0">
            3. Does Startup Mundi Share Personal Information With Third Parties?
        </p>

        <p className="mb-0">
            We do not share your personal information, except in the limited circumstances described here. We may share
            your personal information with:
        </p>

        <ul>
            <li>Our Affiliates: We may share your information with our affiliates and subsidiaries bearing the Startup
                Mundi
                brand for business, operational, promotional and marketing purposes.
            </li>
            <li>Third Party Service Providers: We may share your information with third party service providers that
                provide
                business, professional or technical support functions for us, help us operate our business and the
                website, or
                administer activities on our behalf.
            </li>
            <li>School: If you access our content through an academic curriculum in your school or university, we may
                share
                personally-identifiable information about your progress, scores, results, logon and logoff times, and
                similar
                data with your school. We do not share this information with any outside entities other than your
                school.
            </li>
            <li>Legal Obligations; Safety: We may access and disclose your information to respond to subpoenas, judicial
                processes, or government requests and investigations, or in connection with an investigation on matters
                related
                to public safety, as permitted by law, or otherwise as required by law. We may disclose your information
                to
                protect the security of our site, servers, network systems, and databases. We also may disclose your
                information
                as necessary, if we believe that there has been a violation of our Terms of Use, any other legal
                document or
                contract related to our services, or the rights of any third party.
            </li>
            <li>Sale or Transfer of Business or Assets: We may sell or purchase assets during the normal course of our
                business.
                If another entity acquires us or any of our assets, information we have collected about you may be
                transferred
                to such entity. In addition, if any bankruptcy or reorganization proceeding is brought by or against us,
                such
                information may be considered an asset of ours and may be sold or transferred to third parties. Should
                such a
                sale or transfer occur, we will use reasonable efforts to try to require that the transferee use
                personal
                information provided through our services in a manner that is consistent with this Privacy Policy.
            </li>
            <li>Aggregate or Anonymous Non-Personal Information: We may also share aggregate, anonymous, or
                de-identified
                non-personal information with third parties for their marketing or analytics uses.
            </li>
        </ul>

        <p className="font-weight-bold mb-0">
            4. How Long is Personal Information Retained For?
        </p>

        <p>
            We will keep your personal data for as long as we need it for the purpose it is being processed for. For
            example, we
            will retain your information for as long as needed to provide you services; and after that, we will keep the
            personal information for a reasonable period of time, which enables us to handle or respond to any
            complaints,
            queries or concerns relating to your account.
        </p>

        <p className="font-weight-bold mb-0">
            5. How is Personal Information Secured?
        </p>

        <p>
            Startup Mundi maintains appropriate physical, technical and administrative security to help prevent the
            loss,
            misuse, unauthorized access, disclosure or modification of personal information.
            While we take these steps to protect your personal information as much as we reasonably can, no system or
            transmission of data over the Internet or any other public network, or any storage of data, can be
            guaranteed to be
            100% secure.
        </p>

        <p className="font-weight-bold mb-0">
            6. What are Your Rights and Choices?
        </p>

        <p className="mb-0">
            A. Your Rights
            <br/>
            We respect your privacy rights and provide you with reasonable access and rights to the personal data that
            you may
            have provided through your use of the website and services. You may update, amend, or delete your account
            information and/or preferences at any time by:
        </p>

        <ul>
            <li>Following the opt-out instructions in promotional emails we send you.</li>
            <li>Signing in to your Startup Mundi account, if you have one, to manage your preferences and to update your
                personal information.
            </li>
        </ul>

        <p>
            If you live in the European Economic Area (EEA), you may have additional rights under applicable data
            protection
            laws. If you wish to exercise those rights, please contact us using contact@startupmundi.com. At any time,
            you may
            object to the processing of your personal data, on legitimate grounds, except if otherwise permitted by
            applicable
            law. If you believe your right to privacy granted by applicable data protection laws has been infringed
            upon, please
            contact. You also have a right to lodge a complaint with data protection authorities.
            Please note that if you make any changes or update your account, these will be reflected in active user
            databases
            within a reasonable period of time; however, we may retain certain data when we have a legal obligation or
            lawful
            basis to do so.
            You may decline to share certain personal data with us, in which case we may not be able to provide to you
            some of
            the features and functionality of the website.
        </p>

        <p>
            B. Cookies, Tracking Options and California Do Not Track Disclosures
            <br/>
            For California Residents: Certain parts of our Site require cookies. You are free to set your browser or
            operating
            system settings to limit certain tracking or to decline cookies, but by doing so, you may not be able to use
            certain
            features on the Site or take full advantage of all of our offerings. Please refer to your Web browser's or
            operating
            system's website or "Help" section for more information on how to delete and/or disable your browser or
            operating
            system from receiving cookies or controlling your tracking preferences.
            Our system may not respond to Do Not Track requests or headers from some or all browsers. We may use cookies
            or
            other technologies to <span
            className="text-danger">store game entry access information and team's player preferences</span>.
        </p>

        <p>
            C. Your California Privacy Rights
            <br/>
            If you are a California resident, you may request information about our disclosure of personal information
            to third
            parties or affiliated companies for their direct marketing purposes. To make such a request, please contact
            us as
            set forth in contact@startupmundi.com e-mail. You must put the statement "Your California Privacy Rights" in
            the
            subject field. We are not responsible for notices that are not labeled or sent properly, or do not have
            complete
            information.
            Non-affiliated third parties are independent from Startup Mundi and if you wish to receive information about
            your
            disclosure choices or stop communications from such third parties, you will need to contact those
            non-affiliated
            third parties directly.
        </p>


        <p className="font-weight-bold mb-0">
            7. Does Startup Mundi Transfer Data Internationally?
        </p>

        <p>
            Startup Mundi is a global company and we, or our service providers, may process, transfer and store
            information about our users on servers located in various countries, including the United States, Brazil and
            the European Economic Area (EEA). As we are committed to protecting your information, we take steps to
            ensure that there are appropriate safeguards in place when we transfer this data. For data transferred from
            the EEA to Startup Mundi or its service providers outside the EEA, Startup Mundi relies on the EU Model
            Clauses (issued by the European Commission) to provide an adequate level of protection.

            <span className="text-danger">To request a copy of these Model EU Clauses, please contact us as provided below.</span>
        </p>

        <p className="font-weight-bold mb-0">
            8. Contact Us
        </p>

        <p>
            If you have any questions, comments, or concerns about our Privacy Policy, please contact us at:
        </p>

        <p>
            Startup Mundi
            <br/>
            Rua Professor do Lago Almeida, 140, cj 201
            <br/>
            Florianopolis, SC Brazil
            <br/>
            Email: contact@startupmundi.com
            <br/>
            Phone: +55 48 9 91079686
        </p>
    </>;
}


