import React, {useEffect, useMemo, useState} from "react";
import {Portal} from "react-portal";

import "./ConfirmExistingPlayerModal.scss";
import SwalModalUtil from "../../../../utils/SwalModalUtil";
import {PLAYER_ROUTES} from "../../../../navigation/ROUTES";
import {useNavigate} from "react-router-dom";
import AuthService from "../../../../services/player/AuthService";
import {Trans, useTranslation} from "react-i18next";
import OverlayUtil from "../../../../utils/OverlayUtil";

const TIMER_TIME = 15; // in seconds

export default function ConfirmExistingPlayerModal({nickname, team, onClose}) {
    const navigate = useNavigate();
    const {t} = useTranslation(['pages/player/join', 'common'], {keyPrefix:'components.confirm_existing_player_modal'});

    const [elapsedTime, setElapsedTime] = useState(TIMER_TIME);
    const [timerInterval, setTimerInterval] = useState(undefined);

    const handleConfirm = async () => {
        clearTimerInterval();

        await AuthService.commitPendingJoinAction();
        OverlayUtil.show();

        // await 250ms to prevent the screen from flashing white
        setTimeout(() => navigate(PLAYER_ROUTES.LOBBY), 250);
    }

    const handleIsAnotherPlayer = () => {
        clearTimerInterval();

        SwalModalUtil.infoModal(
            t('unique_info_modal.title'),
            t('unique_info_modal.message'),
            t('unique_info_modal.button')
        ).then(onClose);
    }

    const clearTimerInterval = () => {
        clearInterval(timerInterval);
    }

    const touchTimer = () => setElapsedTime(prev => prev - 1);

    useEffect(() => {
        if (elapsedTime <= 0) onClose();
    })

    useEffect(() => {
        setTimerInterval(setInterval(touchTimer, 1000));
        return clearTimerInterval;
    }, []);

    const elapsedTimeText = useMemo(() => {
        return Math.min(Math.max(elapsedTime, 0), TIMER_TIME);
    }, [elapsedTime]);

    return (<Portal>
        <div id="confirm-existing-player-modal" className="animate__animated animate__fadeIn">
            <div className="p-4 text-white d-flex flex-column justify-content-center align-items-center h-100 w-100">
                <div className="text-center">
                    <h2 className="animate__animated animate__faster animate__fadeInDown anim-step-1">
                        {t('greetings')}
                    </h2>

                    <h5 className="animate__animated animate__faster animate__fadeIn anim-step-2">
                        {t('greetings_2')}
                    </h5>

                    <hr className="border-light my-4 animate__animated animate__faster animate__fadeIn anim-step-3"/>

                    <h4 className="animate__animated animate__faster animate__fadeIn anim-step-3">
                        <Trans t={t} i18nKey={'player_info'}
                               values={{nickname}}
                               components={{span: <span className="text-warning font-weight-bold"/>}}/>

                        <span>&nbsp;</span>

                        {team && (
                            <Trans t={t} i18nKey={'from_team'}
                                   values={{team}}
                                   components={{span: <span className={"font-weight-bold text-team-" + team}/>}}/>
                        )}

                        {!team && t('from_no_team')}
                    </h4>
                </div>

                <div className="buttons gutters-1 mt-4 animate__animated animate__fadeInUp text-center anim-step-4">
                    <button type="button" className="btn btn-success mb-2" onClick={handleConfirm}>
                        {t('yes')}
                    </button>

                    <button type="button" className="btn btn-outline-danger mb-2" onClick={handleIsAnotherPlayer}>
                        {t('no')}
                    </button>

                    <h3 className="mt-3">{elapsedTimeText}</h3>
                </div>
            </div>
        </div>
    </Portal>);
}

