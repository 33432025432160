import {createSlice} from '@reduxjs/toolkit'
import {OVERLAY_THEME} from "../../config/CONSTANTS";

const initialState = {
    show: true,
    message: false,
    theme: OVERLAY_THEME.DARK,
    animate: false,
};

export const OverlaySlice = createSlice({
    name: 'overlay',
    initialState,
    reducers: {
        setOverlay(state, {payload}) {
            state.show = payload.show || false;
            state.message = payload.message || false;
            // theme is static for now
            state.theme =  OVERLAY_THEME.DARK;
            state.animate = payload.animate;
        },

        setShow(state, {payload}) {
            state.show = payload;
        },

        setMessage(state, {payload}) {
            state.message = payload;
        }
    }
});


export const {setShow, setMessage, setOverlay} = OverlaySlice.actions;
export default OverlaySlice.reducer;