import React, {useMemo} from "react";

import SwalModalUtil from "utils/SwalModalUtil";
import {useSelector} from "react-redux";
import {GAME_STEPS, GAME_STEPS_WITH_QUESTIONS} from "config/CONSTANTS";
import store from "redux/store";
import OverlayUtil from "utils/OverlayUtil";
import GameService from "services/gameMaster/GameService";
import ToastUtil from "utils/ToastUtil";
import i18nUtil from "utils/i18nUtil";
import LoggerService from "services/common/LoggerService";
import {useTranslation} from "react-i18next";
import GoToStepConfirmMessageUtil from "../utils/goToStepConfirmMessageUtil";
import GameControlService from "../services/GameControlService";
import Utils from "utils/Utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import GameUtil from "../../../../../../utils/GameUtil";

export default function AdvanceGameButtons() {
    const {t} = useTranslation(['pages/game_master/control_panel/game_control'], {keyPrefix: 'components.advance_game_buttons'});

    const team1 = useSelector(state => state.teams['1']);
    const team2 = useSelector(state => state.teams['2']);
    const team3 = useSelector(state => state.teams['3']);
    const team4 = useSelector(state => state.teams['4']);

    const teamCount = useSelector(state => state.game.teamCount);
    const currentGameStep = useSelector(state => state.game.currentStep);
    const isEsg = useMemo(() => GameUtil.isCurrentGameEsg(), []);

    const advanceButtonText = useMemo(() => {
        if (currentGameStep === GAME_STEPS.AWAITING)
            return t('buttons.start');

        if (currentGameStep === GAME_STEPS.END_GAME)
            return t('buttons.finished');

        if ((isEsg && currentGameStep === GAME_STEPS.PITCH_2) || currentGameStep === GAME_STEPS.PITCH_3)
            return t('buttons.end_game');

        return t('buttons.advance');
    }, [currentGameStep, isEsg]);

    const handleGoToNextStep = async () => {
        if (disableAdvanceButton) return;

        let nextStep = store.getState().game.currentStep + 1;

        // bypass to show the correct message because the ESG game is pocket only
        if (isEsg && nextStep === GAME_STEPS.SALES_MACHINE)
            nextStep = GAME_STEPS.END_GAME;

        const title = t('toasts.advance.title', {step: i18nUtil.stepName(nextStep)});
        const message = GoToStepConfirmMessageUtil.getMessage(nextStep);

        SwalModalUtil.confirmModal(title, message).then(async result => {
            if (result === true) {
                if (nextStep > GAME_STEPS.END_GAME) {
                    SwalModalUtil.infoModal(t('confirms.game_finished.title'), t('confirms.game_finished.message'));
                    return;
                }

                OverlayUtil.toggleLoadingOverlay(true, t('overlays.advancing'));

                if (GAME_STEPS_WITH_QUESTIONS.includes(nextStep)) {
                    const questions = GameControlService.getQuestionsForTeams(nextStep);

                    if (!await GameService.updateTeamsQuestions({step: nextStep, questions})) {
                        OverlayUtil.toggleLoadingOverlay(false);
                        ToastUtil.toastDanger(t('toasts.advance_error.title'), t('toasts.advance_error.message'));
                        return;
                    }
                }

                const params = {desiredStep: nextStep};
                GameService.advanceGame(params).then(result => {
                    if (result === true) ToastUtil.toastSuccess(t('toasts.advance_success.title'), t('toasts.advance_success.message'));
                    else ToastUtil.toastDanger(t('toasts.advance_error.title'), t('toasts.advance_error.message'));
                }).catch(reason => {
                    console.error('Could not advance the game');
                    console.error(reason);
                    ToastUtil.toastDanger(t('toasts.advance_error.title'), t('toasts.advance_error.message'));

                    LoggerService.error(reason, {
                        action: 'advance the game - control panel',
                        params
                    });
                }).finally(() => {
                    OverlayUtil.toggleLoadingOverlay(false);
                });
            }
        })
    };

    const handleEndGame = () => {
        if (currentGameStep !== GAME_STEPS.PITCH_2) return;

        SwalModalUtil.confirmModal(t('confirms.end_game.title'), t('confirms.end_game.message')).then(result => {
            if (result) {
                OverlayUtil.toggleLoadingOverlay(true, t('overlays.advancing'));

                const params = {desiredStep: GAME_STEPS.END_GAME};
                GameService.advanceGame(params).then(result => {
                    if (result === true) ToastUtil.toastSuccess(t('toasts.advance_success.title'), t('toasts.advance_success.message'));
                    else ToastUtil.toastDanger(t('toasts.advance_error.title'), t('toasts.advance_error.message'));
                }).catch(reason => {
                    console.error('Could not advance the game');
                    console.error(reason);
                    ToastUtil.toastDanger(t('toasts.advance_error.title'), t('toasts.advance_error.message'));

                    LoggerService.error(reason, {
                        action: 'advance the game - control panel',
                        params
                    });
                }).finally(() => {
                    OverlayUtil.toggleLoadingOverlay(false);
                });
            }
        });
    };

    const disableAdvanceButton = useMemo(() => {
        if (currentGameStep === GAME_STEPS.END_GAME)
            return true;

        if (currentGameStep === GAME_STEPS.CONCEPTION) {
            const teams = [team1, team2, team3, team4];

            for (let i = 0; i < teams.length && i < teamCount; i++) {
                const team = teams[i];

                if (!Utils.isset(team.startup)) return true;
                if (!Utils.isset(team.founders) || !Utils.isset(team.founders[0]) || !Utils.isset(team.founders[1])) return true;
                if (!Utils.isset(team.valuePropositions) || !Utils.isset(team.valuePropositions[0]) || !Utils.isset(team.valuePropositions[1])) return true;

                for (let j = 0; j < team.length; j++) {
                    if (i === j) continue;
                    if (team.startup === teams[j].startup) return true;
                }
            }
        }


        return false;
    }, [isEsg, currentGameStep, team1, team2, team3, team4]);

    return (<div className="mt-3">
        <button type="button" className="btn btn-primary" onClick={handleGoToNextStep} disabled={disableAdvanceButton}>
            {advanceButtonText}
        </button>

        {disableAdvanceButton && currentGameStep === GAME_STEPS.CONCEPTION && (
            <p className="mb-0 ml-2 d-inline text-danger font-weight-bold">
                <FontAwesomeIcon icon={['fas', 'exclamation-circle']}
                                 className="mr-1"/> {t('texts.conception_not_done')}
            </p>
        )}

        {currentGameStep === GAME_STEPS.PITCH_2 && !isEsg && (
            <button className="btn btn-link ml-2" onClick={handleEndGame}>{t('buttons.end_game_pocket')}</button>
        )}
    </div>);
}