import React, {useEffect, useState} from "react";
import {Tab, Tabs as Tbs} from "react-bootstrap";

import "./Tabs.scss";
import PhaseDetails from "./PhaseDetails";
import HiringDetails from "./HiringDetails";
import {useSelector} from "react-redux";
import {GAME_PHASES} from "config/CONSTANTS";
import {useTranslation} from "react-i18next";

const TABS = ['phase-1', 'phase-2', 'phase-3', 'skills', 'services'];

export default function Tabs() {
    const {t} = useTranslation('pages/common/board/accounting');
    const t2 = useTranslation('common').t;

    const showBoardForTeam = useSelector(state => state.session.showBoardForTeam);
    const currentPhase = useSelector(state => state.game.currentPhase);
    const isPocket = useSelector(state => state.game.isPocket);
    const skillsAccountingDetails = useSelector(state => state.teams[showBoardForTeam].accounting.skills);
    const servicesAccountingDetails = useSelector(state => state.teams[showBoardForTeam].accounting.services);

    const [key, setKey] = useState('phase-1');
    const [navClass, setNavClass] = useState('');

    useEffect(() => {
        setKey(isPocket ? TABS[1] : TABS[currentPhase - 1]);
    }, [currentPhase]);

    useEffect(() => {
        let navTabsClass = '';
        switch (key) {
            case 'phase-1':
                navTabsClass = 'border-danger';
                break;
            case 'phase-2':
                navTabsClass = 'border-warning';
                break;
            case 'phase-3':
                navTabsClass = 'border-info';
                break;
            case 'skills':
                navTabsClass = 'border-white';
                break;
            case 'services':
                navTabsClass = 'border-success';
                break;
        }

        setNavClass(navTabsClass);
    }, [key])

    return (
        <Tbs activeKey={key} onSelect={(k) => setKey(k)} className={"phases " + navClass}>
            <Tab eventKey="phase-1" title={t2('game_phases.phase_1')} tabClassName="border-danger text-white">
                <PhaseDetails phase={GAME_PHASES.PHASE_1} team={showBoardForTeam}/>
            </Tab>


            {currentPhase > GAME_PHASES.PHASE_1 && (
                <Tab eventKey="phase-2" title={t2('game_phases.phase_2')} tabClassName="border-warning text-white">
                    <PhaseDetails phase={GAME_PHASES.PHASE_2} team={showBoardForTeam}/>
                </Tab>
            )}

            {currentPhase > GAME_PHASES.PHASE_2 && !isPocket && (
                <Tab eventKey="phase-3" title={t2('game_phases.phase_3')} tabClassName="border-info text-white"
                     disabled={currentPhase < GAME_PHASES.PHASE_3}>
                    <PhaseDetails phase={GAME_PHASES.PHASE_3} team={showBoardForTeam}/>
                </Tab>
            )}


            {currentPhase > GAME_PHASES.PHASE_1 && (
                <Tab eventKey="skills" title={t('texts.skills')} tabClassName="border-light text-white">
                    <HiringDetails accountingDetails={skillsAccountingDetails}/>
                </Tab>
            )}


            {currentPhase > GAME_PHASES.PHASE_1 && (
                <Tab eventKey="services" title={t('texts.services')} tabClassName="border-success text-white">
                    <HiringDetails accountingDetails={servicesAccountingDetails}/>
                </Tab>
            )}
        </Tbs>
    );
}