import React from "react";
import ErrorPageLayout from "./ErroPageLayout/ErrorPageLayout";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

export default function UnrecoverableError() {
    const {t} = useTranslation('pages/common/errors', {keyPrefix: 'components.fatal_error'})
    const hasGamePackLoadError = useSelector(state => state.session.hasGamePackLoadError);

    return (
        <ErrorPageLayout>
            <h4>{hasGamePackLoadError ? t('game_pack_error.title') : t('texts.title')}</h4>
            <p>{hasGamePackLoadError ? t('game_pack_error.message') : t('texts.message')}</p>
        </ErrorPageLayout>
    );
}