import React, {useEffect, useMemo, useRef, useState} from 'react';

import ReactDOM from 'react-dom';
import Controls from './components/Controls';
import ReactPlayer from 'react-player/youtube'

import "./DemoVideoPlayer.scss";
import {useSelector} from "react-redux";
import {updateVideoPlayerState} from "../../redux/slices/demoSlice";
import store from "../../redux/store";
import DEMO_SESSION_CONFIG from "../../config/DEMO_SESSION_CONFIG";
import screenfull from "screenfull";

let mouseMoveEvData;

/**
 * Updates the player position on move
 * @param e
 */
function updatePlayerPosition(e) {
    e.preventDefault();

    let xPos = -(mouseMoveEvData.x - e.clientX) + mouseMoveEvData.pos.x;
    let yPos = -(mouseMoveEvData.y - e.clientY) + mouseMoveEvData.pos.y;

    checkPosition({x: xPos, y: yPos});
}

/**
 *  Adapter to NOT pass the event default param to 'checkPosition' fn
 */
function checkPositionDOMEventAdapter() {
    checkPosition();
}

/**
 * Ensures that the element is inside the viewport & automatically scales it
 *
 * @param position
 */
function checkPosition(position = store.getState().demo.videoPlayerState.position) {
    const sizeOffset = 20;
    const windowWidth = window.innerWidth;
    const windowWidthWithOffset = windowWidth - sizeOffset;

    let newDesiredWidth = windowWidthWithOffset / DEMO_SESSION_CONFIG.VIDEO_PLAYER.SCALE_FACTOR;

    function updateWidth(newWidth) {
        const newSizes = {
            width: newWidth,
            // 16x9 resolution
            height: newWidth * 9 / 16
        };

        store.dispatch(updateVideoPlayerState({size: newSizes}));
    }


    // ----------------------- adjust sizes -----------------------
    const minWidth = DEMO_SESSION_CONFIG.VIDEO_PLAYER.MIN_WIDTH;

    if (newDesiredWidth < minWidth) {
        newDesiredWidth = minWidth;
    }


    updateWidth(newDesiredWidth);
    const playerSize = store.getState().demo.videoPlayerState.size;


    // ----------------------- adjust position -----------------------
    let xPos = position.x;
    let yPos = position.y;

    if (xPos < 10)
        xPos = 10;

    if (xPos > window.innerWidth - playerSize.width - 10)
        xPos = window.innerWidth - playerSize.width - 10;

    if (yPos < 10)
        yPos = 10;

    if (yPos > window.innerHeight - playerSize.height - 10)
        yPos = window.innerHeight - playerSize.height - 10;

    store.dispatch(updateVideoPlayerState({
        position: {
            x: xPos,
            y: yPos
        }
    }));
}

function addEventListeners(e) {
    mouseMoveEvData = {x: e.clientX, y: e.clientY, pos: store.getState().demo.videoPlayerState.position};

    window.addEventListener('mousemove', updatePlayerPosition);
    document.addEventListener('mouseleave', removeEventListeners);
}

function removeEventListeners() {
    window.removeEventListener('mousemove', updatePlayerPosition);
    document.removeEventListener('mouseleave', removeEventListeners);
}


export default function DemoVideoPlayer() {
    const {
        enable,
        video,
        position,
        volume,
        isMuted,
        play,
        size,
        fullscreen,
        onEnd
    } = useSelector(state => state.demo.videoPlayerState);

    const [mouseHover, setMouseHover] = useState(false);
    const playerRef = useRef();

    const handleVideoEnded = () => {
        store.dispatch(updateVideoPlayerState({
            enable: false,
            initialized: false,
            play: false,
            fullscreen: false,
        }));

        if (onEnd)
            onEnd();
    }

    const style = useMemo(() => {
        return {
            width: `${size.width}px`,
            height: `${size.height}px`,
            zIndex: '9999',
            borderRadius: '4px',
            top: `${position.y}px`,
            left: `${position.x}px`,
            boxShadow: '0px 0px 41px -5px rgba(0,0,0,0.75)',
            transformOrigin: 'center',
        }
    }, [position, size]);

    const cardClass = useMemo(()=>enable ? 'animate__slideInRight' : 'animate__slideOutRight', [enable]);

    useEffect(() => {
        checkPosition();
        window.addEventListener('resize', checkPositionDOMEventAdapter);

        return () => {
            removeEventListeners();
            window.removeEventListener('resize', checkPositionDOMEventAdapter);
        };
    }, []);


    useEffect(() => {
        if (fullscreen && !screenfull.isFullscreen)
            screenfull.request(document.querySelector('#demo-video-player'))
        else if (!fullscreen && screenfull.isFullscreen)
            screenfull.exit();

    }, [fullscreen]);

    useEffect(() => {
        if (!enable)
            playerRef.current.seekTo(0);
    }, [enable]);


    return ReactDOM.createPortal(
        <div id="demo-video-player"
             className={"animate__animated animate__faster overflow-hidden position-fixed " + cardClass}
             style={style}
             onMouseOver={() => setMouseHover(true)}
             onMouseLeave={() => setMouseHover(false)}>

            <div id={"pop"} className="h-100 w-100 youtube-container">
                <ReactPlayer
                    ref={playerRef}
                    playing={play}
                    muted={isMuted}
                    volume={volume}
                    config={{
                        embedOptions: {
                            rel: 0,
                            modestbranding: 1,
                            disablekb: 1,
                            autoplay: 0,
                            controls: 0,
                            showInfo: 0,
                        },
                        playerVars: {
                            rel: 0,
                            modestbranding: 1,
                            disablekb: 1,
                            autoplay: 0,
                            controls: 0,
                            showInfo: 0,
                        }
                    }}
                    onEnded={handleVideoEnded}
                    controls={false}
                    height={"100%"}
                    width={"100%"}
                    url={video}/>
            </div>


            <Controls onMouseDown={addEventListeners}
                      onMouseUp={removeEventListeners}
                      onSkip={handleVideoEnded}
                      show={mouseHover}
                      movable={true}/>
        </div>,
        document.body
    );
}