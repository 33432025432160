import React from 'react';
import {TERMS_ROUTES} from "../../../../navigation/ROUTES";

export default function PrivacyPtBR() {
    return <>
        <h4>Política de Privacidade</h4>

        <p className="font-weight-bold mb-0">
            1. Que tipo de informação a Startup Mundi coleta?
        </p>

        <p>
            Existem várias maneiras pelas quais podemos coletar informações de você ou sobre você, inclusive por meio de
            (A)
            informações que você nos fornece diretamente; e (B) informações que coletamos automática ou passivamente.
        </p>

        <p>
            A. Informações que você fornece
            <br/>
            As informações pessoais coletadas pela Startup Mundi podem incluir:
            <br/>
            Seu nome.
        </p>

        <p>
            B. Informações que coletamos automaticamente
            <br/>
            Sempre que você visita ou interage com o site, nós, bem como quaisquer anunciantes e/ou provedores de
            serviços
            terceirizados, podemos usar cookies, web beacons, objetos compartilhados locais (Flash Cookies) ou outras
            tecnologias para coletar informações sobre sua atividade online e informações de uso do site. Para obter
            informações
            sobre nosso uso de cookies e outras tecnologias semelhantes através do site, você pode ler nossa <a
            target="_blank" href={TERMS_ROUTES.COOKIES_POLICY}>Política de Cookies</a>.
        </p>

        <p className="mb-0">
            Observe que coletamos automaticamente as seguintes informações sobre você:
        </p>

        <ul>
            <li>
                Informações de uso do site. Podemos coletar automaticamente informações de uso do site quando você
                visita nosso
                site. Isso pode incluir informações sobre seu provedor de serviços de Internet, seu sistema operacional,
                tipo de
                navegador, nome de domínio, endereço de protocolo de Internet (IP), seus horários de acesso, o site que
                o
                encaminhou para nós, as páginas da Web solicitadas e a data e hora desses pedidos.
            </li>

            <li>
                Prestadores de Serviços Terceirizados. Podemos usar provedores de serviços terceirizados para dar
                suporte ao
                nosso site. Alguns desses provedores de serviços podem usar tecnologias como cookies, web beacons, tags
                de
                pixel, arquivos de log ou cookies Flash para receber, coletar e armazenar informações.
            </li>

            <li>
                Tecnologias analíticas de terceiros. Podemos usar ferramentas de análise e rastreamento de terceiros
                para
                entender melhor quem está usando o site, como as pessoas estão usando o site, como melhorar a eficácia
                do site,
                nossos serviços e conteúdo relacionado, para nos ajudar ou a esses terceiros a servir mais direcionados
                publicidade para você na Internet. Essas ferramentas podem usar tecnologias como cookies, web beacons,
                pixel
                tags, arquivos de log, cookies Flash, cookies HTML5 ou outras tecnologias para coletar e armazenar
                automaticamente determinadas informações. Eles também podem combinar informações que coletam de sua
                interação
                com o site com informações que coletamos de outras fontes. Não temos acesso ou controle sobre o uso de
                cookies
                ou outras tecnologias de rastreamento por esses terceiros.
            </li>
        </ul>


        <p className="font-weight-bold mb-0">
            2. Como a Startup Mundi usa as informações sobre você?
        </p>

        <p className="mb-0">
            A Startup Mundi pode usar as informações que coletamos de você e sobre você para diversos fins comerciais,
            incluindo:
        </p>

        <ul>
            <li>
                Comunique-se com você sobre a sessão de jogo e certificados.
            </li>
            <li>
                Fornecer a você informações sobre produtos e serviços que oferecemos (se você concordar em receber tais
                informações).
            </li>
            <li>
                Resolver problemas com o site ou nossos negócios e proteger a segurança ou integridade do Site e nossos
                negócios.
            </li>
        </ul>

        <p className="text-danger">
            Também usamos informações anônimas ou agregadas para nos permitir auditar o uso do site, melhorar nosso
            processo de
            jogo e fornecer uma melhor experiência ao usuário.
        </p>

        <p className="mb-0">
            Teremos uma base legal para processar seus dados se:
        </p>

        <ul>
            <li>
                Precisamos processar suas informações para fornecer os produtos ou serviços que você solicitou;
            </li>
            <li>
                Você consentiu com tal processamento;
            </li>
            <li>
                Temos um interesse legítimo em processar seus dados – por exemplo, para prevenção de fraudes; segurança
                de redes
                e sistemas de informação; análise de dados; aprimorar, modificar ou melhorar nossos serviços;
                identificar
                tendências de uso; e/ou
            </li>
            <li>
                Somos legalmente obrigados a processá-lo.
            </li>
        </ul>


        <p className="font-weight-bold mb-0">
            3. A Startup Mundi compartilha informações pessoais com terceiros?
        </p>

        <p className="mb-0">
            Não compartilhamos suas informações pessoais, exceto nas circunstâncias limitadas descritas aqui.
            Podemos compartilhar suas informações pessoais com:
        </p>

        <ul>
            <li>Nossas Afiliadas: Podemos compartilhar suas informações com nossas afiliadas e subsidiárias com a marca
                Startup
                Mundi para fins comerciais, operacionais, promocionais e de marketing.
            </li>
            <li>Provedores de serviços terceirizados: Podemos compartilhar suas informações com provedores de serviços
                terceirizados que fornecem funções de suporte comercial, profissional ou técnico para nós, nos ajudam a
                operar
                nossos negócios e o site ou administram atividades em nosso nome.
            </li>
            <li>Escola: Se você acessar nosso conteúdo por meio de um currículo acadêmico em sua escola ou universidade,
                poderemos compartilhar informações de identificação pessoal sobre seu progresso, pontuações, resultados,
                horários de logon e logoff e dados semelhantes com sua escola. Não compartilhamos essas informações com
                nenhuma
                entidade externa que não seja sua escola.
            </li>
            <li>Obrigações legais; Segurança: Podemos acessar e divulgar suas informações para responder a intimações,
                processos
                judiciais ou solicitações e investigações governamentais, ou em conexão com uma investigação sobre
                assuntos
                relacionados à segurança pública, conforme permitido por lei ou conforme exigido por lei. Podemos
                divulgar suas
                informações para proteger a segurança de nosso site, servidores, sistemas de rede e bancos de dados.
                Também
                podemos divulgar suas informações conforme necessário, se acreditarmos que houve uma violação de nossos
                Termos
                de Uso, ou qualquer outro documento legal ou contrato relacionado aos nossos serviços ou os direitos de
                terceiros.
            </li>
            <li>Venda ou Transferência de Negócios ou Ativos: Podemos vender ou comprar ativos durante o curso normal de
                nossos
                negócios. Se outra entidade nos adquirir ou qualquer um de nossos ativos, as informações que coletamos
                sobre
                você podem ser transferidas para essa entidade. Além disso, se qualquer processo de falência ou
                reorganização
                for movido por ou contra nós, tais informações podem ser consideradas um ativo nosso e podem ser
                vendidas ou
                transferidas para terceiros. Caso ocorra tal venda ou transferência, envidaremos esforços razoáveis
                ​​para
                tentar exigir que o cessionário use as informações pessoais fornecidas por meio de nossos serviços de
                maneira
                consistente com esta Política de Privacidade.
            </li>
            <li>Informações não pessoais agregadas ou anônimas: Também podemos compartilhar informações não pessoais
                agregadas,
                anônimas ou desidentificadas com terceiros para seus usos de marketing ou análise.
            </li>
        </ul>

        <p className="font-weight-bold mb-0">
            4. Por quanto tempo as informações pessoais são retidas?
        </p>

        <p>
            Manteremos seus dados pessoais pelo tempo que precisarmos para a finalidade para a qual estão sendo
            processados. Por
            exemplo, reteremos suas informações pelo tempo necessário para fornecer serviços; e depois disso, manteremos
            as
            informações pessoais por um período de tempo razoável, o que nos permite lidar ou responder a quaisquer
            reclamações,
            dúvidas ou preocupações relacionadas à sua conta.
        </p>

        <p className="font-weight-bold mb-0">
            5. Como as informações pessoais são protegidas?
        </p>

        <p>
            A Startup Mundi mantém a segurança física, técnica e administrativa adequada para ajudar a prevenir a perda,
            uso
            indevido, acesso não autorizado, divulgação ou modificação de informações pessoais.
            Embora tomemos essas medidas para proteger suas informações pessoais o máximo que pudermos, nenhum sistema
            ou
            transmissão de dados pela Internet ou qualquer outra rede pública, ou qualquer armazenamento de dados, pode
            ser
            garantido como 100% seguro.
        </p>

        <p className="font-weight-bold mb-0">
            6. Quais são seus direitos e escolhas?
        </p>

        <p className="mb-0">
            A. Seus Direitos
            <br/>
            Respeitamos seus direitos de privacidade e fornecemos a você acesso e direitos razoáveis aos dados pessoais
            que
            você possa ter fornecido por meio do uso do site e dos serviços. Você pode atualizar, alterar ou excluir as
            informações e/ou preferências da sua conta a qualquer momento:
        </p>

        <ul>
            <li>Seguindo as instruções de exclusão nos e-mails promocionais que lhe enviamos.</li>
            <li>Fazer login na sua conta Startup Mundi, se você tiver uma, para gerenciar suas preferências e atualizar
                suas
                informações pessoais.
            </li>
        </ul>

        <p>
            Se você mora no Espaço Econômico Europeu (EEE), pode ter direitos adicionais sob as leis de proteção de
            dados
            aplicáveis. Se você deseja exercer esses direitos, entre em contato conosco usando contact@startupmundi.com.
            A
            qualquer momento, você pode se opor ao processamento de seus dados pessoais, por motivos legítimos, exceto
            se
            permitido de outra forma pela lei aplicável. Se você acredita que seu direito à privacidade concedido pelas
            leis de
            proteção de dados aplicáveis foi infringido, entre em contato. Você também tem o direito de apresentar uma
            reclamação às autoridades de proteção de dados.
            Observe que, se você fizer alterações ou atualizar sua conta, elas serão refletidas nos bancos de dados de
            usuários
            ativos dentro de um período de tempo razoável; no entanto, podemos reter determinados dados quando temos uma
            obrigação legal ou base legal para fazê-lo.
            Você pode se recusar a compartilhar certos dados pessoais conosco e, nesse caso, talvez não possamos
            fornecer a você
            alguns dos recursos e funcionalidades do site.
        </p>

        <p>
            B. Cookies, opções de rastreamento e divulgações de não rastreamento da Califórnia
            <br/>
            Para residentes da Califórnia: Certas partes do nosso Site requerem cookies. Você é livre para definir as
            configurações do seu navegador ou sistema operacional para limitar certos rastreamentos ou recusar cookies,
            mas ao
            fazer isso, você pode não conseguir usar certos recursos do Site ou aproveitar ao máximo todas as nossas
            ofertas.
            Consulte o site do seu navegador ou sistema operacional ou a seção "Ajuda" para obter mais informações sobre
            como
            excluir e/ou desativar seu navegador ou sistema operacional de receber cookies ou controlar suas
            preferências de
            rastreamento.
            Nosso sistema pode não responder a solicitações ou cabeçalhos Do Not Track de alguns ou de todos os
            navegadores.
            Podemos usar cookies ou outras tecnologias para <span className="text-danger">armazenar informações de acesso de entrada no jogo e as preferências
    dos jogadores da equipe</span>.
        </p>

        <p>
            C. Seus Direitos de Privacidade na Califórnia
            <br/>
            Se você for residente da Califórnia, poderá solicitar informações sobre nossa divulgação de informações
            pessoais a
            terceiros ou empresas afiliadas para fins de marketing direto. Para fazer tal solicitação, entre em contato
            conosco
            conforme estabelecido no e-mail contact@startupmundi.com. Você deve colocar a declaração "Your California
            Privacy
            Rights" no campo assunto. Não nos responsabilizamos por avisos que não estejam rotulados ou enviados
            adequadamente,
            ou que não contenham informações completas.
            Terceiros não afiliados são independentes da Startup Mundi e se você deseja receber informações sobre suas
            opções de
            divulgação ou interromper as comunicações de tais terceiros, você precisará entrar em contato diretamente
            com esses
            terceiros não afiliados.
        </p>


        <p className="font-weight-bold mb-0">
            7. A Startup Mundi transfere dados internacionalmente?
        </p>

        <p>
            A Startup Mundi é uma empresa global e nós, ou nossos provedores de serviços, podemos processar, transferir
            e
            armazenar informações sobre nossos usuários em servidores localizados em vários países, incluindo Estados
            Unidos,
            Brasil e Espaço Econômico Europeu (EEE). Como estamos comprometidos em proteger suas informações, tomamos
            medidas
            para garantir que haja salvaguardas apropriadas quando transferimos esses dados. Para dados transferidos do
            EEE para
            o Startup Mundi ou seus provedores de serviços fora do EEE, o Startup Mundi conta com as Cláusulas Modelo da
            UE
            (emitidas pela Comissão Europeia) para fornecer um nível adequado de proteção. <span
            className="text-danger">Para solicitar uma cópia dessas
    Cláusulas Modelo da UE, entre em contato conosco conforme fornecido abaixo.</span>
        </p>

        <p className="font-weight-bold mb-0">
            8. Entre em contato conosco
        </p>

        <p>
            Se você tiver dúvidas, comentários ou preocupações sobre nossa Política de Privacidade, entre em contato
            conosco em:
        </p>

        <p>
            Startup Mundi
            <br/>
            Rua Professor do Lago Almeida, 140, cj 201
            <br/>
            Florianopolis, SC Brasil
            <br/>
            E-mail: contact@startupmundi.com
            <br/>
            Telefone: +55 48 9 91079686
        </p>
    </>;
}


