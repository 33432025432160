import {IS_PRODUCTION_ENV} from "./CONSTANTS";

export const WS_ENDPOINT = 'wss://ahzdi95bbyref-ats.iot.sa-east-1.amazonaws.com/mqtt';

const env = IS_PRODUCTION_ENV ? 'prod' : 'dev';

export const WS_TOPICS = {
    GAME_TOPIC: `smgx-${env}/game-session/$gameSessionId`,
    TEAM_TOPIC: `smgx-${env}/game-session/$gameSessionId/team-$team`,
}
