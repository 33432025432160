import React, {useEffect, useMemo, useState} from "react";
import {Modal} from "react-bootstrap";
import Utils from "utils/Utils";

import "./SkillStoreModal.scss";
import logo from "assets/img/logos/svg/logo_only.svg";
import iconBalance from "assets/img/board/ic_investment_accounting_box.svg";
import SkillCard from "components/cards/SkillCard";
import {useSelector} from "react-redux";
import i18nUtil from "utils/i18nUtil";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CardUtil from "utils/CardUtil";
import {useTranslation} from "react-i18next";
import ToastUtil from "utils/ToastUtil";
import CardService from "services/common/CardService";
import SkillCardModel from "../../../../../../models/SkillCardModel";
import {SKILL_GENDER_GROUP, SKILL_ICONS_METADATA} from "../../../../../../config/SKILL_ICONS";
import DemoSessionService from "../../../../../../services/common/DemoSessionService";
import DemoPlaceholder from "../../ConceptionModal/components/DemoPlaceholder";

export default function SkillStoreModal({onClose}) {
    const {t} = useTranslation('pages/common/board/skills', {keyPrefix: 'components.skill_store_modal'});
    const showBoardForTeam = useSelector(state => state.session.showBoardForTeam);

    const skills = useSelector(state => state.cards.skills);
    const availableForMarket = useSelector(state => state.teams[showBoardForTeam].accounting?.totals?.availableForMarket);
    const canHireCards = useSelector(state => state.game.canHireCards);
    const isFinished = useSelector(state => state.game.isFinished);
    const hiredSkills = useSelector(state => state.teams[showBoardForTeam].skills);
    const isDemonstration = useSelector(state => state.game.isDemonstration);
    const founders = useSelector(state => state.teams[showBoardForTeam].founders);

    const [skillsToDisplay, setSkillsToDisplay] = useState({});
    const [skillAreasToDisplay, setSkillAreasToDisplay] = useState([]);
    const [hiredSkillsCount, setHiredSkillsCount] = useState({});
    const [hiringInProgressFor, setHiringInProgressFor] = useState({});

    const [skillIcons, setSkillIcons] = useState({});
    const [skillGenders, setSkillGenders] = useState({});

    const [filterName, setFilterName] = useState('');
    const [sortBy, setSortBy] = useState('price');
    const [sortOrder, setSortOrder] = useState('asc');

    const teamFoundersIds = useMemo(() => {
        return Object.values(founders).reduce((prev, curr) => {
            return {...prev, [curr.id]: true};
        }, {});
    }, [founders]);

    const handleClearFilter = () => {
        setFilterName('');
    };

    const handleSetHiringInProgressFor = (card, isInProgress = false) => {
        const inProgressFor = {...hiringInProgressFor};
        if (isInProgress) inProgressFor[card.id] = true;
        else delete inProgressFor[card.id];

        setHiringInProgressFor(inProgressFor);
    };

    const handleHire = async (skill, iconNumber, gender) => {
        if (isDemonstration) {
            DemoSessionService.hireSkill({skill, iconNumber, gender});

            ToastUtil.toastSuccess(
                t('toasts.hire_success.title'),
                t('toasts.hire_success.message', {skill: skill.title}),
                {soundEffect: false}
            );

            return;
        }

        handleSetHiringInProgressFor(skill, true);

        const hired = await CardService.hireSkill(skill.id, iconNumber, gender);
        if (hired) {
            ToastUtil.toastSuccess(
                t('toasts.hire_success.title'),
                t('toasts.hire_success.message', {skill: skill.title}),
                {soundEffect: false}
            );
        } else {
            ToastUtil.toastDanger(t('toasts.hire_error.title'), t('toasts.hire_error.message'));
        }

        handleSetHiringInProgressFor(skill, false);
    };

    const handleOpenServiceMarket = () => {
        onClose();
        document.getElementById('btn-open-service-market').click();
    }

    useEffect(() => {
        const newIcons = {};
        const newGenders = {};
        const otherGenderCount = Math.floor(skills.length * 20 / 100);

        for (let i = 0; i < otherGenderCount; i++) {
            const randomSkill = skills[Utils.randomInt(0, skills.length - 1)];

            if (!newGenders[randomSkill.id])
                newGenders[randomSkill.id] = SKILL_GENDER_GROUP.OTHER;
            else
                i--;
        }

        skills.forEach(skill => {
            const randomIconNumber = SkillCardModel.randomIconNumber();

            newIcons[skill.id] = SkillCardModel.getFullPathForIcon(randomIconNumber);

            if (!newGenders[skill.id])
                newGenders[skill.id] = SKILL_ICONS_METADATA[randomIconNumber].gender;
        });

        setSkillIcons(newIcons);
        setSkillGenders(newGenders);
    }, [hiredSkills, skills])

    useEffect(() => {
        let skillsToDisplay = isDemonstration ? skills.slice(0, Math.round(skills.length / 2)) : [...skills];
        let skillAreas = [];
        const res = {};

        if (Utils.isset(filterName)) {
            const filter = filterName.toLowerCase();
            skillsToDisplay = skillsToDisplay.filter(s => {
                return s.title.toLowerCase().includes(filter) || s.area.toLowerCase().includes(filter)
            });
        }

        let sortFn = false;
        if (sortBy === 'name') {
            sortFn = (a, b) => a.title.localeCompare(b.title);
        } else if (sortBy === 'power') {
            sortFn = (a, b) => CardUtil.getPower(a) - CardUtil.getPower(b);
        }

        if (sortFn) {
            skillsToDisplay = skillsToDisplay.sort(sortFn);
        }

        if (sortOrder === 'desc') {
            skillsToDisplay = skillsToDisplay.reverse();
        }

        skillsToDisplay.forEach(service => {
            if (!Utils.isset(res[service.area])) {
                res[service.area] = [];
                skillAreas.push(service.area);
            }

            res[service.area].push(service);
        });

        skillAreas = skillAreas.sort((a, b) => a.localeCompare(b))

        setSkillAreasToDisplay(skillAreas);
        setSkillsToDisplay(res);
    }, [filterName, sortBy, sortOrder, skills]);

    useEffect(() => {
        const hiredSkillsCount = {};

        CardUtil.hiredCardsToArray(hiredSkills).forEach(hiredSkill => {
            if (!hiredSkillsCount[hiredSkill.card.id]) hiredSkillsCount[hiredSkill.card.id] = 0;
            hiredSkillsCount[hiredSkill.card.id]++;
        });

        setHiredSkillsCount(hiredSkillsCount);
    }, [hiredSkills]);

    useEffect(() => {
        if (isDemonstration)
            DemoSessionService.setVisitedMarket();
    }, []);

    return (
        <Modal show={true} size={'xl'} onHide={onClose} className="skill-store-modal" scrollable={true}>
            <Modal.Header closeButton className="bg-dark text-white">
                <div className="modal-header-content w-100 d-flex align-items-center">
                    <img alt="Mundi Game Experiences logo" src={logo} className="mr-4"/>

                    <div>
                        <Modal.Title>{t('texts.title')}</Modal.Title>
                        <p className="mb-1 small">{t('texts.click_info')}</p>
                        <button onClick={handleOpenServiceMarket} className="btn btn-warning px-2 py-0 rounded">
                            {t('buttons.open_service_market')}
                        </button>
                    </div>

                    <div className="available-for-market flex-fill d-flex justify-content-end">
                        <div className="px-3 py-2">
                            <p className="mb-1">{t('texts.available_for_market')}</p>
                            <div className="d-flex justify-content-between align-items-center">
                                <img alt="Balance icon" src={iconBalance} className="mr-2"/>
                                <h5 className="mb-0 text-warning">{i18nUtil.formatMoney(availableForMarket || 0)}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Header>

            <Modal.Body>
                <div
                    className="w-100 p-2 text-white border-primary bg-primary rounded d-flex justify-content-between mb-4">
                    <div className="d-flex form-inline">
                        <div className="form-group mr-2">
                            <label className="small mr-1">{t('inputs.find.label')}</label>
                            <input type="text" className="form-control-sm" value={filterName}
                                   onChange={event => setFilterName(event.target.value)}/>
                        </div>

                        <button type="button" className="btn btn-link p-0 text-light small"
                                onClick={handleClearFilter}>
                            {t('buttons.clear')}
                        </button>
                    </div>

                    <div className="form-inline">
                        <div className="form-group mr-2">
                            <label className="small mr-1">{t('inputs.sort_by.label')}</label>

                            <select className="form-control-sm" value={sortBy}
                                    onChange={event => setSortBy(event.target.value)}>
                                <option value="price">{t('inputs.sort_by.options.price')}</option>
                                <option value="name">{t('inputs.sort_by.options.name')}</option>
                                <option value="power">{t('inputs.sort_by.options.power')}</option>
                            </select>
                        </div>

                        <div className="form-group mr-2">
                            <label className="small mr-1">{t('inputs.sort_order.label')}</label>

                            <select className="form-control-sm" value={sortOrder}
                                    onChange={event => setSortOrder(event.target.value)}>
                                <option value="asc">{t('inputs.sort_order.options.asc')}</option>
                                <option value="desc">{t('inputs.sort_order.options.desc')}</option>
                            </select>
                        </div>
                    </div>
                </div>

                {(!canHireCards || isFinished) && (
                    <div className="d-flex justify-content-center">
                        <div className="py-2 px-5 text-center w-auto rounded d-flex align-items-center">
                            <FontAwesomeIcon icon={['fas', 'exclamation-circle']} className="fa-2x mr-3"/>
                            <h5 className="mb-0 text-uppercase d-inline">
                                {!canHireCards && !isFinished && t('texts.market_closed')}
                                {isFinished && t('texts.game_finished')}
                            </h5>
                        </div>
                    </div>
                )}

                {skillAreasToDisplay.map(area => {
                    return (<div key={area} className="mt-4">
                        <h4 className="ml-3 mb-3">{i18nUtil.area(area)}</h4>
                        <div className="d-flex justify-content-center align-items-stretch flex-wrap">
                            {skillsToDisplay[area].map(skill => {
                                return (
                                    <SkillCard key={skill.id}
                                               skill={skill}
                                               icon={skillIcons[skill.id]}
                                               gender={skillGenders[skill.id]}
                                               isLoading={hiringInProgressFor[skill.id] || false}
                                               hasCreditToHire={availableForMarket >= skill.price}
                                               hirable={!isFinished && canHireCards}
                                               hiredCount={hiredSkillsCount[skill.id] || 0}
                                               isFounder={!!teamFoundersIds[skill.id]}
                                               onHire={handleHire}/>
                                )
                            })}

                            {isDemonstration && (
                                <DemoPlaceholder clasz={"demo-card"}/>
                            )}
                        </div>

                        <hr/>
                    </div>)
                })}

                {Object.entries(skillsToDisplay).length === 0 && (
                    <div className="d-flex justify-content-center w-100">{t('texts.no_results')}</div>
                )}
            </Modal.Body>
        </Modal>
    );
}