import {GAMES} from "../config/CONSTANTS";

let _game = false;

/**
 * Helper class used to identify the game type.
 *
 * This class exists, so we can check the game type without using Redux (i.e. in a non reactive way), to prevent
 * errors when executing Redux actions that need to check the game type
 */
export default {
    isCurrentGameEsg() {
        return _game === GAMES.ESG;
    },

    isCurrentGameSmgx() {
        return _game === GAMES.SMGX;
    },


    setGameType(gameType) {
        _game = gameType;
    }
}