import React from "react";
import GameCardLayout from "components/cards/layouts/GameCardLayout";

import "./ObjectiveCard.scss";
import SimpleBar from "simplebar-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function ObjectiveCard({
                                          title,
                                          description,
                                          actionButtonText,
                                          actionCallback = false,
                                          isLoading = false
                                      }) {
    return (<GameCardLayout>
        <div className="objective-card box d-flex flex-column w-100 h-100 border-warning bg-dark ">
            <div>
                <div className="box-head bg-warning text-uppercase">{title}</div>
            </div>

            <div className="content-container h-100 w-100 d-flex justify-content-center align-items-center">
                <SimpleBar autoHide={false} className="content-scrollable mt-5 w-100" style={{overflowY: 'visible'}}>
                    <div className="value-content flex-fill w-100 text-white px-1 mb-3 text-center">
                        <h5 className="mx-2" dangerouslySetInnerHTML={{__html: description}}/>
                    </div>
                </SimpleBar>
            </div>


            {actionCallback && actionButtonText && (
                <div className="box-footer bg-warning">
                    <button type="button" className="w-100 h-100 bg-transparent border-0 text-uppercase"
                            disabled={isLoading} onClick={actionCallback}>

                        {isLoading && (
                            <FontAwesomeIcon icon={['fas', 'circle-notch']} className="fa-spin"/>
                        )}

                        {!isLoading && actionButtonText}
                    </button>
                </div>
            )}
        </div>
    </GameCardLayout>);
}