import {GAME_STEPS} from "./CONSTANTS";

const ACCOUNTING_CARD_ID = '29902f73-591d-4c7b-8b03-cfc43548aa92';

const GAME_INITIAL_CAPITAL = 5000;
const ACCELERATION_BONUS_VALUES = {};
const GAME_INITIAL_ACCELERATION = 0;

const GAME_COST_PER_STEP = {
    [GAME_STEPS.MVP]: 1000,
    [GAME_STEPS.PITCH_1]: 1000,

    [GAME_STEPS.BETA]: 8000,
    [GAME_STEPS.GTM]: 10000,
    [GAME_STEPS.GROWTH_MODEL]: 12000,
    [GAME_STEPS.PITCH_2]: 15000,

    [GAME_STEPS.SALES_MACHINE]: 30000,
    [GAME_STEPS.CUSTOMER_SUCCESS]: 40000,
    [GAME_STEPS.PRODUCT_SCALABILITY]: 50000,
    [GAME_STEPS.PITCH_3]: 60000
};

const GAME_REVENUE_PER_STEP = {
    [GAME_STEPS.GTM]: 20000,
    [GAME_STEPS.GROWTH_MODEL]: 40000,
    [GAME_STEPS.PITCH_2]: 40000,

    [GAME_STEPS.SALES_MACHINE]: 80000,
    [GAME_STEPS.CUSTOMER_SUCCESS]: 120000,
    [GAME_STEPS.PRODUCT_SCALABILITY]: 180000,
    [GAME_STEPS.PITCH_3]: 220000,
};

const GAME_MONTHS_CAPITAL_PER_STEP = {
    [GAME_STEPS.BETA]: {RIGHT: 20000, WRONG: 10000},
    [GAME_STEPS.GTM]: {RIGHT: 20000, WRONG: 10000},
    [GAME_STEPS.GROWTH_MODEL]: {RIGHT: 20000, WRONG: 10000},

    [GAME_STEPS.SALES_MACHINE]: {RIGHT: 60000, WRONG: 20000},
    [GAME_STEPS.CUSTOMER_SUCCESS]: {RIGHT: 60000, WRONG: 20000},
    [GAME_STEPS.PRODUCT_SCALABILITY]: {RIGHT: 60000, WRONG: 20000}
};

const GAME_MONTHS_TO_ADVANCE_PER_STEP = {
    [GAME_STEPS.CONCEPTION]: 1,
    [GAME_STEPS.MVP]: {RIGHT: 2, WRONG: 4},
    [GAME_STEPS.PITCH_1]: 1,

    [GAME_STEPS.BETA]: {RIGHT: 4, WRONG: 5},
    [GAME_STEPS.GTM]: {RIGHT: 4, WRONG: 5},
    [GAME_STEPS.GROWTH_MODEL]: {RIGHT: 4, WRONG: 5},
    [GAME_STEPS.PITCH_2]: 2,

    [GAME_STEPS.SALES_MACHINE]: {RIGHT: 5, WRONG: 6},
    [GAME_STEPS.CUSTOMER_SUCCESS]: {RIGHT: 5, WRONG: 6},
    [GAME_STEPS.PRODUCT_SCALABILITY]: {RIGHT: 5, WRONG: 6},
    [GAME_STEPS.PITCH_3]: 4,
};

const GAME_TEAM_SPEED_BY_ADVANCED_MONTHS = {
    [GAME_STEPS.CONCEPTION]: {FAST: 1, SLOW: 0},
    [GAME_STEPS.MVP]: {FAST: 3, SLOW: 5},
    [GAME_STEPS.PITCH_1]: {FAST: 4, SLOW: 6},

    [GAME_STEPS.BETA]: {FAST: 8, SLOW: 11},
    [GAME_STEPS.GTM]: {FAST: 11, SLOW: 15},
    [GAME_STEPS.GROWTH_MODEL]: {FAST: 16, SLOW: 20},
    [GAME_STEPS.PITCH_2]: {FAST: 18, SLOW: 22},

    [GAME_STEPS.SALES_MACHINE]: {FAST: 23, SLOW: 28},
    [GAME_STEPS.CUSTOMER_SUCCESS]: {FAST: 28, SLOW: 34},
    [GAME_STEPS.PRODUCT_SCALABILITY]: {FAST: 33, SLOW: 39},
    [GAME_STEPS.PITCH_3]: {FAST: 37, SLOW: 43},
};

const GAME_PITCH_INVESTMENT = {
    [GAME_STEPS.PITCH_1]: {1: 125000, 2: 125000, 3: 125000, 4: 125000},
    [GAME_STEPS.PITCH_2]: {1: 400000, 2: 400000, 3: 400000, 4: 400000},
    [GAME_STEPS.PITCH_3]: {1: 2000000, 2: 1000000, 3: 500000, 4: 0},
};


const ACCOUNTING_SMGX_CONFIG = Object.freeze({
    ACCELERATION_BONUS_VALUES,
    ACCOUNTING_CARD_ID,
    GAME_COST_PER_STEP,
    GAME_REVENUE_PER_STEP,
    GAME_MONTHS_CAPITAL_PER_STEP,
    GAME_MONTHS_TO_ADVANCE_PER_STEP,
    GAME_TEAM_SPEED_BY_ADVANCED_MONTHS,
    GAME_INITIAL_CAPITAL,
    GAME_PITCH_INVESTMENT,
    GAME_INITIAL_ACCELERATION
});

export default ACCOUNTING_SMGX_CONFIG;