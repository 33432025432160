import React, {useMemo} from 'react';
import Header from "./components/Header";
import Footer from "./components/Footer";
import {useTranslation} from "react-i18next";
import {i18nService} from "../../i18n";

export default function TermsLayout({children, version = 'v1.0.0', updatedAt}) {
    const {t, i18n} = useTranslation('pages/common/terms');

    const languageSelected = useMemo(() => {
        if (i18n.language.startsWith('pt')) return 'pt';
        return 'en';
    }, [i18n.language]);

    const handleSetLanguage = (lang) => i18nService.setLanguage(lang);

    return (
        <div className="h-100 w-100 position-relative">
            <Header/>

            <div className="w-100 d-flex justify-content-center px-lg-5 px-4 pb-3 pt-4">
                <div className="w-100 text-justify" style={{maxWidth: 1200}}>
                    <div className="mb-5 text-center text-uppercase w-100">
                        <button
                            className={"btn btn-link mr-3 p-0 text-uppercase " + (languageSelected === 'en' ? 'text-warning' : '')}
                            onClick={() => handleSetLanguage('en')}>
                            English
                        </button>

                        <button
                            className={"btn btn-link mr-3 p-0 text-uppercase " + (languageSelected === 'pt' ? 'text-warning' : '')}
                            onClick={() => handleSetLanguage('pt')}>
                            Português
                        </button>
                    </div>

                    <p className="font-weight-bolder mb-0">
                        {t('version', {version})}
                    </p>

                    {updatedAt && (
                        <p className="font-weight-bolder">
                            {t('updated_at', {date: updatedAt})}
                        </p>
                    )}

                    <div className="pt-3">
                        {children}
                    </div>
                </div>
            </div>

            <Footer/>
        </div>
    );
}


