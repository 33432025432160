import powerPackIcon from "assets/img/board/ic_servive_power.svg";
import servicePackIcon from "assets/img/board/ic_servive_pack.svg";
import basicServiceIcon from "assets/img/board/ic_servive_basic.svg";
import {GAME_CARD_TYPE, SERVICE_CARD_TYPE} from "../config/CONSTANTS";

export default class ServiceCardModel {
    constructor({
                    id,
                    type,
                    title,
                    description,
                    area,
                    price,
                    accelerationPhase2,
                    accelerationPhase3,
                    requirements,
                    // relatedValuePropositions = [],
                    relatedBonus = []
                }) {

        this.id = id;
        this.type = type;
        this.title = title;
        this.description = description;
        this.area = area;
        this.price = price;
        this.accelerationPhase2 = accelerationPhase2;
        this.accelerationPhase3 = accelerationPhase3;
        this.requirements = requirements;
        // this.relatedValuePropositions = relatedValuePropositions;
        this.relatedBonus = relatedBonus;

        this._setIcon();
        this.__type = GAME_CARD_TYPE.SERVICE;
    }

    hasRequirements() {
        return this.requirements !== null && this.requirements !== undefined && this.requirements.length > 0;
    }

    meetRequirements() {
        return !this.hasRequirements();
    }

    _setIcon() {
        switch (this.type) {
            case SERVICE_CARD_TYPE.POWER_PACK:
                this.icon = powerPackIcon;
                break;
            case SERVICE_CARD_TYPE.PACK:
                this.icon = servicePackIcon;
                break;
            default:
                this.icon = basicServiceIcon;
        }
    }
}