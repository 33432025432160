import React, {useMemo, useState} from "react";
import {useSelector} from "react-redux";
import PitchBonusFormModal from "./PitchBonusFormModal";
import i18nUtil from "utils/i18nUtil";
import {useTranslation} from "react-i18next";
import {GAME_STEPS} from "../../../../../../../config/CONSTANTS";
import useTranslationForGame from "../../../../../../../hooks/useTranslationForGame";

export default function PitchBonusButtons({step}) {
    const {t} = useTranslation('pages/game_master/control_panel/game_control', {keyPrefix: "components.pitch_bonus_buttons"});
    const tfg = useTranslationForGame(t);

    const currentGameStep = useSelector(state => state.game.currentStep);
    const bonus = useSelector(state => state.teams.pitchBonus[step]);

    const [showPitchBonusModal, setShowPitchBonusModal] = useState(false);
    const handlePitchBonusModalClose = () => setShowPitchBonusModal(false);
    const handlePitchBonusModalShow = () => setShowPitchBonusModal(true);

    const disabled = useMemo(() => {
        return step > currentGameStep || currentGameStep > GAME_STEPS.PITCH_3;
    }, [step, currentGameStep]);

    return (
        <>
            {!bonus && (
                <button type="button" className="btn btn-primary btn-sm" disabled={disabled}
                        onClick={handlePitchBonusModalShow}>

                    {t('buttons.send')}
                </button>
            )}


            {bonus && (
                <div className="text-success font-weight-bold text-center p-2 w-100" style={{fontSize: '85%'}}>
                    {t('texts.sent_to')}

                    <div className="mt-1">
                        {tfg('texts.acceleration_info', {
                            team: bonus.team,
                            acceleration: i18nUtil.formatPercent(bonus.amount || 0)
                        })}
                    </div>
                </div>
            )}

            {showPitchBonusModal && (<PitchBonusFormModal onClose={handlePitchBonusModalClose} step={step}/>)}
        </>
    )
}