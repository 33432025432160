import React from "react";
import TeamInfo from "./components/TeamInfo";
import {useSelector} from "react-redux";

export default function StatusInfoPerTeam({missingDefinitions}) {
    const teamCount = useSelector(state => state.game.teamCount);

    return (
        <div>
            <TeamInfo number={1} missingDefinitions={missingDefinitions['1']}/>
            {teamCount > 1 && (<TeamInfo number={2} missingDefinitions={missingDefinitions['2']}/>)}
            {teamCount > 2 && (<TeamInfo number={3} missingDefinitions={missingDefinitions['3']}/>)}
            {teamCount > 3 && (<TeamInfo number={4} missingDefinitions={missingDefinitions['4']}/>)}
        </div>
    );
}