import React, {useEffect, useMemo} from 'react';
import {Modal} from 'react-bootstrap';

import "./AccountingDetailsModal.scss";
import {useTranslation} from "react-i18next";
import EsgAccountingDetailsModalContent from "./esg/EsgAccountingDetailsModalContent";
import GameUtil from "utils/GameUtil";
import SmgxAccountingDetailsModalContent from "./smgx/SmgxAccountingDetailsModalContent";
import useTranslationForGame from "hooks/useTranslationForGame";
import DemoSessionService from "../../../../../services/common/DemoSessionService";
import {useSelector} from "react-redux";

export default function AccountingDetailsModal({onClose}) {
    const {t} = useTranslation('pages/common/board/accounting');
    const tfg = useTranslationForGame(t);

    const isDemonstration = useSelector(state => state.game.isDemonstration);
    const isEsg = useMemo(() => GameUtil.isCurrentGameEsg(), []);

    useEffect(() => {
        if (isDemonstration)
            DemoSessionService.setVisitedAccounting();
    }, []);

    return (<Modal show={true} size="lg" onHide={onClose} className="modal-right fade account-details-modal pt-lg-3"
                   scrollable={true}>

        <Modal.Header closeButton className="bg-transparent text-white">
            <Modal.Title>{tfg('texts.title')}</Modal.Title>
        </Modal.Header>

        <Modal.Body className="bg-transparent pt-0">
            {isEsg ? <EsgAccountingDetailsModalContent/> : <SmgxAccountingDetailsModalContent/>}
        </Modal.Body>
    </Modal>);
}
