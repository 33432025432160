import {createSlice} from '@reduxjs/toolkit';
import DEMO_SESSION_CONFIG from "../../config/DEMO_SESSION_CONFIG";

const initialState = {
    demoSessionId: null,
    forcePulsatePagination: false,
    disableChallengesPagination: true,

    state: {
        hasNotifiedSessionEnded: false,

        // question
        revealQuestion: false,
        revealedQuestion: false,

        // unpredictability
        hasPlayedUnpredictabilitySound: false,
        hasRevealedUnpredictability: false,
        hasPlayedRevealedUnpredictabilitySound: false,

        // videos
        hasPlayedIntroVideo: false,
        hasPlayedJourneyVideo: false,
        hasPlayedQuestionVideo: false,
        hasPlayedUnpredictabilityVideo: false,


        // higlights
        pulseAccounting: false,
        pulseMarket: false,
        hasVisitedAccounting: false,
        hasVisitedMarket: false,
    },

    videoPlayerState: {
        enable: false,
        initialized: false,
        fullscreen: false,
        video: '',
        play: true,
        volume: 1,
        isMuted: false,
        position: DEMO_SESSION_CONFIG.VIDEO_PLAYER.INITIAL_POSITION,
        size: DEMO_SESSION_CONFIG.VIDEO_PLAYER.INITIAL_SIZE,
        scale: 1,
        onEnd: () => {

        }
    }
};

export const DemoSlice = createSlice({
    name: 'demo',
    initialState,
    reducers: {
        setDemoSessionId(state, {payload}){
          state.demoSessionId = payload;
        },

        updateDemoState(state, {payload}) {
            state.state = {...state.state, ...payload};
        },

        setForcePulsatePagination(state, {payload}) {
            state.forcePulsatePagination = payload;
        },

        setDisableChallengesPagination(state, {payload}) {
            state.disableChallengesPagination = payload;
        },

        updateVideoPlayerState(state, {payload}) {
            state.videoPlayerState = {...state.videoPlayerState, ...payload};
        }
    }
});

export const {
    setDemoSessionId,
    updateDemoState,
    setForcePulsatePagination,
    updateVideoPlayerState,
    setDisableChallengesPagination
} = DemoSlice.actions;

export default DemoSlice.reducer;