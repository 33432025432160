import maleIcon from "../assets/img/board/skillsGenders/male.svg";
import femaleIcon from "../assets/img/board/skillsGenders/female.svg";
import otherGenderIcon from "../assets/img/board/skillsGenders/lgbt.svg";

export const SKILL_ICONS_GROUP = {
    MAJORITY: 1,
    MINORITY: 2,
}

export const SKILL_GENDER_GROUP = {
    MALE: 'm',
    FEMALE: 'f',
    OTHER: 'o'
}

export const GENDER_ICONS = {
    [SKILL_GENDER_GROUP.MALE]: maleIcon,
    [SKILL_GENDER_GROUP.FEMALE]: femaleIcon,
    [SKILL_GENDER_GROUP.OTHER]: otherGenderIcon
}

export const SKILL_ICONS_METADATA = {
    1: {race: SKILL_ICONS_GROUP.MINORITY, gender: SKILL_GENDER_GROUP.MALE},
    2: {race: SKILL_ICONS_GROUP.MINORITY, gender: SKILL_GENDER_GROUP.FEMALE},
    3: {race: SKILL_ICONS_GROUP.MINORITY, gender: SKILL_GENDER_GROUP.MALE},
    4: {race: SKILL_ICONS_GROUP.MAJORITY, gender: SKILL_GENDER_GROUP.FEMALE},
    5: {race: SKILL_ICONS_GROUP.MAJORITY, gender: SKILL_GENDER_GROUP.MALE},
    6: {race: SKILL_ICONS_GROUP.MAJORITY, gender: SKILL_GENDER_GROUP.FEMALE},
    7: {race: SKILL_ICONS_GROUP.MAJORITY, gender: SKILL_GENDER_GROUP.FEMALE},
    8: {race: SKILL_ICONS_GROUP.MAJORITY, gender: SKILL_GENDER_GROUP.MALE},
    9: {race: SKILL_ICONS_GROUP.MINORITY, gender: SKILL_GENDER_GROUP.MALE},
    10: {race: SKILL_ICONS_GROUP.MINORITY, gender: SKILL_GENDER_GROUP.FEMALE},
    11: {race: SKILL_ICONS_GROUP.MINORITY, gender: SKILL_GENDER_GROUP.MALE},
    12: {race: SKILL_ICONS_GROUP.MINORITY, gender: SKILL_GENDER_GROUP.FEMALE},
    13: {race: SKILL_ICONS_GROUP.MAJORITY, gender: SKILL_GENDER_GROUP.FEMALE},
    14: {race: SKILL_ICONS_GROUP.MAJORITY, gender: SKILL_GENDER_GROUP.MALE},
    15: {race: SKILL_ICONS_GROUP.MAJORITY, gender: SKILL_GENDER_GROUP.FEMALE},
    16: {race: SKILL_ICONS_GROUP.MINORITY, gender: SKILL_GENDER_GROUP.FEMALE},
    17: {race: SKILL_ICONS_GROUP.MAJORITY, gender: SKILL_GENDER_GROUP.MALE},
    18: {race: SKILL_ICONS_GROUP.MAJORITY, gender: SKILL_GENDER_GROUP.FEMALE},
    19: {race: SKILL_ICONS_GROUP.MINORITY, gender: SKILL_GENDER_GROUP.MALE},
    20: {race: SKILL_ICONS_GROUP.MINORITY, gender: SKILL_GENDER_GROUP.FEMALE},
    21: {race: SKILL_ICONS_GROUP.MINORITY, gender: SKILL_GENDER_GROUP.MALE},
    22: {race: SKILL_ICONS_GROUP.MINORITY, gender: SKILL_GENDER_GROUP.FEMALE}
}