import React, {useEffect} from "react";
import './Board.scss';

import {Header} from "./components/Header";

import {Challenges} from "./components/Challenges/Challenges";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import JourneyMap from "./components/JourneyMap/JourneyMapSummaryCard";
import Accounting from "./components/Accounting/AccountSummaryCard";
import Footer from "./components/Footer";
import StartupProfile from "./components/StartupProfile/StartupProfileSummaryCard";
import Services from "./components/Services/ServicesSummaryCard";
import Skills from "./components/Skills/SkillsSummaryCard";
import GameEvents from "./components/GameEvents/GameEvents";
import {GAME_MASTER_ROUTES, PLAYER_ROUTES} from "navigation/ROUTES";
import AccountingService from "services/common/AccountingService";
import {GAME_STEPS} from "config/CONSTANTS";
import AnimatedStepChange from "../../tournament/AnimatedStepChange/AnimatedStepChange";


export default function Board() {
    const isGameMaster = useSelector(state => state.session.isGameMaster);
    const showBoardForTeam = useSelector(state => state.session.showBoardForTeam);
    const currentStep = useSelector(state => state.game.currentStep);
    const showStepChangeTransition = useSelector(state=>state.tournament.state.showStepChangeTransition);

    const navigate = useNavigate();

    useEffect(() => {
        if (!showBoardForTeam) {
            if (isGameMaster) navigate(GAME_MASTER_ROUTES.CONTROL_PANEL);
            else navigate(PLAYER_ROUTES.LOBBY);
        } else {
            AccountingService.calculateForTeam(showBoardForTeam);
        }
    }, []);

    return (
        <>
            <div id="board" className="min-vh-100 overflow-hidden d-flex flex-column">
                <Header/>

                <div
                    className="flex-fill d-flex justify-content-center  w-100 animate__animated animate__fast animate__fadeIn">
                    <div className={'board d-flex justify-content-around w-100 container-custom'}>
                        <div className="d-flex flex-column flex-fill">
                            <div className="d-flex">
                                <div className={'left-content pl-2'}>
                                    <StartupProfile/>

                                    <div className="d-flex">
                                        <Skills/>
                                        <Services/>
                                    </div>
                                </div>

                                <div className={'center-content flex-fill mx-2 h-100'}
                                     style={{zIndex: currentStep === GAME_STEPS.CONCEPTION ? 5 : 1}}>
                                    <div className="h-100">
                                        <Challenges/>
                                    </div>
                                </div>
                            </div>

                            <div className="w-100 pr-4 mx-3 mt-3">
                                <GameEvents/>
                            </div>
                        </div>

                        <div className={'right-content pr-2'}>
                            <JourneyMap/>
                            <Accounting/>
                            <Footer/>
                        </div>
                    </div>
                </div>
            </div>

            {showStepChangeTransition && <AnimatedStepChange/>}
        </>
    );
}

