import React, {useEffect, useMemo, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import store from "../../../redux/store";
import {updateVideoPlayerState} from "../../../redux/slices/demoSlice";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";


export default function Controls({show, onMouseDown, onMouseUp, onSkip, movable = true}) {
    const {t} = useTranslation('components/demo_video_player');
    const [isGrabbing, setGrabbing] = useState(false);

    const {
        volume,
        isMuted,
        play,
        fullscreen
    } = useSelector(state => state.demo.videoPlayerState);


    // ---------------------------- handlers ----------------------------
    const handleMouseDown = (ev) => {
        if (!movable)
            return;

        setGrabbing(true);
        onMouseDown(ev);
    }

    const handleMouseUp = (ev) => {
        if (!movable)
            return;

        setGrabbing(false);
        onMouseUp(ev);
    }

    const handleFirstPlay = (ev) => {
        if (!store.getState().demo.videoPlayerState.initialized) {
            store.dispatch(updateVideoPlayerState({play: true, initialized: true}));
        }
    }

    const handleTogglePlay = () => store.dispatch(updateVideoPlayerState({play: !play}));
    const handleToggleMute = () => store.dispatch(updateVideoPlayerState({isMuted: !isMuted}));
    const handleChangeVolume = (ev) => store.dispatch(updateVideoPlayerState({
        volume: ev.target.valueAsNumber,
        isMuted: false
    }));
    const handleToggleFullscreen = (ev) => store.dispatch(updateVideoPlayerState({
        fullscreen: !store.getState().demo.videoPlayerState.fullscreen
    }));

    // ---------------------------- memos ----------------------------
    const volumeValue = useMemo(() => isMuted ? 0 : volume, [isMuted, volume]);
    const style = useMemo(() => {
        return {
            transition: '0.3s',
            opacity: show ? '1' : '0',
            cursor: movable ? (isGrabbing ? "grabbing" : 'grab') : 'default'
        }
    }, [show, isGrabbing]);


    useEffect(() => setGrabbing(false), [show]);

    return (
        <div style={style} className="position-absolute top left w-100 h-100 video-control" onClick={handleFirstPlay}>
            <div className="video-control-overlay" onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}/>

            <div style={{gap: 8}} onClick={ev => ev.stopPropagation()}
                 className="controls-container d-flex justify-content-between px-2 align-items-center position-absolute bottom py-2 w-100">

                <div className="d-flex align-items-center" style={{gap: 8}}>
                    <button onClick={handleTogglePlay}>
                        {play
                            ? <FontAwesomeIcon icon={['fas', 'pause']}/>
                            : <FontAwesomeIcon icon={['fas', 'play']}/>
                        }
                    </button>


                    <div className="position-relative d-flex align-items-center">
                        <button onClick={handleToggleMute} className="btn-volume">
                            {isMuted || volume === 0
                                ? <FontAwesomeIcon icon={['fas', 'volume-mute']}/>
                                : <FontAwesomeIcon icon={['fas', 'volume-up']}/>
                            }
                        </button>

                        <input className="mx-2"
                               type="range"
                               min={0}
                               max={1}
                               step={0.05}
                               value={volumeValue}
                               onChange={handleChangeVolume}/>
                    </div>
                </div>

                <div className="d-flex align-items-center" style={{gap: 8}}>
                    <button onClick={onSkip} className="btn-skip">
                        {t('texts.skip')}
                    </button>

                    <button onClick={handleToggleFullscreen} className="btn-volume">
                        {fullscreen
                            ? (<FontAwesomeIcon icon={['fas', 'minimize']}/>)
                            : (<FontAwesomeIcon icon={['fas', 'maximize']}/>)}
                    </button>
                </div>
            </div>
        </div>
    );
}


