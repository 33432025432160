import React, {memo} from "react";

import "./PitchChallenge.scss";
import PitchCard from "components/cards/PitchCard";

const PitchChallenge = ({data}) => {
    const {header, content, phase} = data;

    return (
        <div className="pitch-challenge h-100 w-100 d-flex justify-content-center align-items-center flex-column">
            <PitchCard header={header} content={content} phase={phase}/>
        </div>
    );
}

export default memo(PitchChallenge);