import store from "../redux/store";
import {setMessage, setOverlay, setShow} from "../redux/slices/overlaySlice";
import {OVERLAY_THEME} from "../config/CONSTANTS";
import {batch} from "react-redux";

const OverlayUtil = {
    toggleLoadingOverlay(show = false, message = false, theme = OVERLAY_THEME.DEFAULT, animate = true) {
        store.dispatch(setOverlay({show, message, theme, animate}));
    },

    show() {
        batch(() => {
            store.dispatch(setShow(true));
            store.dispatch(setMessage(false));
        });
    },

    hide() {
        store.dispatch(setShow(false));
    },

    setMessage(message) {
        store.dispatch(setMessage(false));
    }
};

export default OverlayUtil;