import React, {memo, useMemo} from "react";
import GameCardLayout from "components/cards/layouts/GameCardLayout";
import SimpleBar from "simplebar-react";

import "./QuestionCard.scss";
import i18nUtil from "utils/i18nUtil";
import {useTranslation} from "react-i18next";
import Utils from "utils/Utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const QuestionCard = ({questionCard}) => {
    const {t} = useTranslation('components/cards', {keyPrefix: "question_card"});
    const {id, step, order, concept, answers} = useMemo(() => questionCard, [questionCard]);

    return (
        <GameCardLayout>
            <div key={'res-' + id} className={"d-flex question-card question-explanation h-100"}>
                <div className="box border-success bg-white h-100 w-100 d-flex flex-column question">
                    <div className="question-header-container">
                        <div className="box-head bg-success text-white">
                            {t('texts.title', {step: i18nUtil.stepName(step), concept})}
                        </div>

                        <div className="box-head-right bg-success text-white">{order}</div>
                    </div>


                    <div className="value-content flex-fill pb-3 ">
                        <SimpleBar autoHide={false} className="content-scrollable">
                            <table className="w-100 mt-n1">
                                <tbody>
                                {!answers?.[0]?.explanation && (
                                    <tr>
                                        <td className="text-muted text-center">
                                            {t('texts.explanation_not_available')}
                                        </td>
                                    </tr>
                                )}

                                {!!answers?.[0]?.explanation && answers.map((answer) => {
                                    return (
                                        <tr>
                                            <td>
                                                {Utils.numberToLetter(answer.order).toLowerCase()}) {answer.explanation}
                                            </td>

                                            <td>
                                                {answer.isCorrect && (
                                                    <FontAwesomeIcon icon={['fas', 'check-circle']}
                                                                     className="text-success"/>
                                                )}

                                                {!answer.isCorrect && (
                                                    <FontAwesomeIcon icon={['fas', 'times-circle']}
                                                                     className="text-danger"/>
                                                )}
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </SimpleBar>
                    </div>
                </div>
            </div>
        </GameCardLayout>
    );
}

export default memo(QuestionCard);