import React, {useMemo, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import BugReportModal from "pages/common/BugReportModal/BugReportModal";
import {useTranslation} from "react-i18next";
import {TERMS_ROUTES} from "../../../navigation/ROUTES";

export default function Footer() {
    const {t} = useTranslation('common');

    const [showBugReportModal, setShowBugReportModal] = useState(false);
    const handleShowBugReportModal = () => setShowBugReportModal(true);
    const handleCloseBugReportModal = () => setShowBugReportModal(false);

    const now = useMemo(() => (new Date()).getUTCFullYear(), []);

    return (
        <>
            <footer id="app-footer" className="text-center text-muted w-100 pb-3 pt-4">
                <p className="mb-0 p-0 small d-inline">
                    @ {now}

                    <a href="https://www.startupmundi.com.br/" className="text-muted text-decoration-underline ml-1"
                       target="_blank" rel="noreferrer">
                        {t('common:company_name')}
                    </a>. {t('common:rights_reserved')}
                </p>

                <p className="mb-0 mx-2 p-0 small d-inline">
                    <a href={TERMS_ROUTES.TERMS_OF_USE} className="text-muted mr-1">{t('common:terms_of_use')}</a>
                    <a href={TERMS_ROUTES.COOKIES_POLICY} className="text-muted mr-1">{t('common:cookies_policy')}</a>
                    <a href={TERMS_ROUTES.PRIVACY_POLICY} className="text-muted">{t('common:privacy_policy')}</a>
                </p>

                <button className="btn btn-link small text-muted p-0 text-decoration-none btn-report-bug"
                        style={{fontSize: '80%'}}
                        onClick={handleShowBugReportModal}>
                    <FontAwesomeIcon icon={['fas', 'bug']}/> {t('common:btn_report_bug')}
                </button>
            </footer>

            {showBugReportModal && (<BugReportModal onClose={handleCloseBugReportModal}/>)}
        </>
    );
}