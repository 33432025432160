import React from 'react';

import {useSelector} from "react-redux";
import i18nUtil from "utils/i18nUtil";
import {useTranslation} from "react-i18next";
import {ACCELERATION_BONUSES, GAME_STEPS} from "config/CONSTANTS";
import BonusBadge from "./components/BonusBadge";
import icon from 'assets/img/board/ic_investment_accounting_box.svg';
import useTranslationForGame from "hooks/useTranslationForGame";

export default function EsgAccountSummaryCardContent() {
    const {t} = useTranslation('pages/common/board/accounting');
    const tfg = useTranslationForGame(t);

    const currentStep = useSelector(state => state.game.currentStep);
    const showBoardForTeam = useSelector(state => state.session.showBoardForTeam);
    const accountingDetails = useSelector(state => state.teams[showBoardForTeam].accounting.totals);
    const accelerationBonuses = useSelector(state => state.teams[showBoardForTeam].accelerationBonuses);

    return (
        <div className="box-content account">
            <h4 className="text-white mb-3">{t('texts.available_for_market')}</h4>

            <div className="market-value d-flex justify-content-between my-3 px-2">
                <img alt="Market value logo" src={icon}/>
                <p className="text-warning">{i18nUtil.formatMoney(accountingDetails.availableForMarket || 0)}</p>
            </div>

            <table>
                <tbody>
                <tr>
                    <td>{tfg('texts.acceleration')}</td>
                    <td>{i18nUtil.formatPercent(accountingDetails.acceleration || 0)}</td>
                </tr>

                <tr>
                    <td>{tfg('texts.revenue')}</td>
                    <td>{i18nUtil.formatDecimal(accountingDetails.revenue || 0)}</td>
                </tr>
                </tbody>
            </table>

            <hr className="my-1"/>

            <div className="d-flex justify-content-center px-2 pt-3 text-muted w-100 h-100 align-items-center">
                <BonusBadge active={currentStep >= GAME_STEPS.BETA}
                            bonus={ACCELERATION_BONUSES.IMPLEMENTATION}
                            tooltip={t('texts.implementation_bonus')}
                            value={accelerationBonuses[ACCELERATION_BONUSES.IMPLEMENTATION]}/>

                <BonusBadge
                    active={currentStep >= GAME_STEPS.GTM && !!accelerationBonuses[ACCELERATION_BONUSES.MONITORING]}
                    bonus={ACCELERATION_BONUSES.MONITORING}
                    tooltip={t('texts.monitoring_bonus')}
                    value={accelerationBonuses[ACCELERATION_BONUSES.MONITORING]}/>

                <BonusBadge
                    active={currentStep >= GAME_STEPS.GROWTH_MODEL && !!accelerationBonuses[ACCELERATION_BONUSES.REPORTING]}
                    bonus={ACCELERATION_BONUSES.REPORTING}
                    tooltip={t('texts.reporting_bonus')}
                    value={accelerationBonuses[ACCELERATION_BONUSES.REPORTING]}/>
            </div>
        </div>
    );
}