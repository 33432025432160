import ApiDataParserService from "../api/ApiDataParserService";
import TeamReduxService from "../../redux/TeamReduxService";
import EventProcessor from "./EventProcessor";
import {batch} from "react-redux";
import store from "redux/store";
import AccountingService from "../AccountingService";
import {setPitchBonus} from "redux/slices/teamsSlice";
import Utils from "utils/Utils";
import AudioService from "../AudioService";
import {SOUND_EFFECTS} from "../../../config/AUDIO_FILES";

function recalculateAccounting(step, team) {
    if (store.getState().session.isGameMaster) {
        AccountingService.recalculateForStep(team, step);
    } else if (store.getState().session.showBoardForTeam) {
        AccountingService.recalculateForStep(store.getState().session.showBoardForTeam, step);
    }
}

function calculateAccounting(step, team) {
    if (store.getState().session.isGameMaster) {
        AccountingService.recalculateForStep(team, step);
    } else if (store.getState().session.showBoardForTeam) {
        AccountingService.calculateForTeam(step);
    }
}

const TeamEventsProcessor = {
    valuePropositionUpdatedEvent(wsMessage) {
        EventProcessor.queue(wsMessage, () => {
            const data = ApiDataParserService.parseTeamData(wsMessage.data || {});
            const number = data.valuePropositions[0] ? 0 : 1;
            TeamReduxService.updateValueProposition(wsMessage.team, number, data.valuePropositions[number]);
        });
    },

    startupUpdatedEvent(wsMessage) {
        EventProcessor.queue(wsMessage, () => {
            const data = ApiDataParserService.parseTeamData(wsMessage.data || {});
            TeamReduxService.updateStartup(wsMessage.team, data.startup);
        });
    },

    founderUpdatedEvent(wsMessage) {
        EventProcessor.queue(wsMessage, () => {
            const data = ApiDataParserService.parseTeamData(wsMessage.data || {});
            const number = data.founders[0] ? 0 : 1;

            TeamReduxService.updateFounderIcon(wsMessage.team, number, data.foundersIcons[number]);
            TeamReduxService.updateFounder(wsMessage.team, number, data.founders[number]);
        });
    },

    questionsUpdatedEvent(wsMessage) {
        EventProcessor.queue(wsMessage, () => {
            batch(() => {
                for (let i = 1; i < 5; i++) {
                    if (wsMessage.data['t' + i]) {
                        const data = ApiDataParserService.parseTeamData(wsMessage.data['t' + i] || {});

                        Object.entries(data.questions).forEach(([step, question]) => {
                            TeamReduxService.setQuestionForStep(i, step, question);
                        });
                    }
                }
            });
        });
    },

    questionAnswerUpdatedEvent(wsMessage, recalculate = false) {
        EventProcessor.queue(wsMessage, () => {
            batch(() => {
                let stepToRecalculate = false;
                let teamToRecalculate = null;

                for (let i = 1; i < 5; i++) {
                    if (wsMessage.data['t' + i]) {
                        const data = ApiDataParserService.parseTeamData(wsMessage.data['t' + i] || {});

                        Object.entries(data.questions).forEach(([step, question]) => {
                            TeamReduxService.updateQuestion(i, step, question);
                            stepToRecalculate = step;
                            teamToRecalculate = i;
                        });
                    }
                }

                if (recalculate && stepToRecalculate) {
                    recalculateAccounting(stepToRecalculate, teamToRecalculate);
                }

                // the condition "!recalculate" makes the sound effect only be played when the answer is updated
                // only played when the answer is updated


                if (!recalculate && store.getState().session.showBoardForTeam && !store.getState().game.isTournament) {
                    AudioService.play(SOUND_EFFECTS.QUESTIONS.ANSWER_UPDATED);
                }
            });

            return true;
        });
    },

    questionAnswerRevealedEvent(wsMessage) {
        return TeamEventsProcessor.questionAnswerUpdatedEvent(wsMessage, true);
    },

    pitchBonusSentEvent(wsMessage) {
        EventProcessor.queue(wsMessage, () => {
            batch(() => {
                const data = ApiDataParserService.parseGameData(wsMessage.data);

                data.pitchBonus.forEach(data => {
                    store.dispatch(setPitchBonus(data));

                    if (store.getState().session.showBoardForTeam) {
                        AudioService.play(SOUND_EFFECTS.ACCOUNTING.INVESTMENT_RECEIVED);
                    }

                    recalculateAccounting(data.step, data.team);
                });
            });
        });
    },

    cardHiredEvent(wsMessage) {
        EventProcessor.queue(wsMessage, () => {
            batch(() => {
                const data = ApiDataParserService.parseTeamData(wsMessage.data['t' + wsMessage.team]);
                let stepToRecalculate = 1;

                if (Utils.isset(data.skills)) {
                    const hiredSkill = data.skills[wsMessage.step][0];
                    TeamReduxService.addSkill(wsMessage.team, wsMessage.step, hiredSkill);
                    stepToRecalculate = hiredSkill.step;
                }

                if (Utils.isset(data.services)) {
                    const hiredService = data.services[wsMessage.step][0];
                    TeamReduxService.addService(wsMessage.team, wsMessage.step, hiredService);
                    stepToRecalculate = hiredService.step;
                }

                if (store.getState().session.showBoardForTeam) {
                    AudioService.play(SOUND_EFFECTS.CARDS.HIRED);
                }

                recalculateAccounting(stepToRecalculate, wsMessage.team);
            });
        });
    },

    cardRefundedEvent(wsMessage) {
        EventProcessor.queue(wsMessage, () => {
            batch(() => {
                const team = wsMessage.team;
                const {hiringId, type, step} = wsMessage.data;

                if (type === 'SKILL') TeamReduxService.refundSkill(team, step, hiringId);
                else TeamReduxService.refundService(team, step, hiringId);

                calculateAccounting(wsMessage.team);
            });
        });
    },

    unpredictabilitySetEvent(wsMessage) {
        EventProcessor.queue(wsMessage, () => {
            batch(() => {
                const team = wsMessage.team;
                const data = ApiDataParserService.parseTeamData(wsMessage.data['t' + team] || {});
                Object.entries(data.unpredictabilities).forEach(([step, data]) => {
                    TeamReduxService.setUnpredictabilityForStep(team, step, data);
                });
            });

            return true;
        });
    },

    unpredictabilityRevealedEvent(wsMessage) {
        EventProcessor.queue(wsMessage, () => {
            batch(() => {
                const team = wsMessage.team;
                const data = ApiDataParserService.parseTeamData(wsMessage.data['t' + team] || {});

                Object.entries(data.unpredictabilities).forEach(([step, data]) => {
                    TeamReduxService.updateUnpredictability(team, step, data);
                    recalculateAccounting(step, team);
                });
            });

            return true;
        });
    },

    unpredictabilityOutcomeRevealedEvent(wsMessage) {
        return TeamEventsProcessor.unpredictabilityRevealedEvent(wsMessage);
    },

    signedAccountingEvent(wsMessage) {
        EventProcessor.queue(wsMessage, () => {
            batch(() => {
                const team = wsMessage.team;
                const data = ApiDataParserService.parseTeamData(wsMessage.data['t' + team] || {});

                Object.entries(data.signedAccounting).forEach(([step, data]) => {
                    TeamReduxService.updateSignedAccounting(team, step, data);
                });
            });
            return true;
        });
    },

    pitchDataUpdated(wsMessage) {
        EventProcessor.queue(wsMessage, () => {
            console.log(wsMessage);
            // TeamReduxService.updatePitchData(wsMessage.team, wsMessage.pitch, wsMessage.data);
            return true;
        });
    },
};

export default TeamEventsProcessor;