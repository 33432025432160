import React, {useMemo, useState} from "react";
import {Button, Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import "./AdjustVolumeModal.scss";
import {useSelector} from "react-redux";
import {DEFAULT_VOLUME_CONFIG, setVolumeConfig} from "../../../redux/slices/sessionSlice";
import store from "../../../redux/store";
import AudioService from "../../../services/common/AudioService";
import {SOUND_EFFECTS} from "../../../config/AUDIO_FILES";
import SessionPersistenceUtil, {AUDIO_CONFIG_KEY} from "../../../utils/SessionPersistenceUtil";

export default function AdjustVolumeModal({onClose}) {
    const {t} = useTranslation(['pages/common/adjust_volume_modal', 'common']);

    const volumeConfig = useSelector(state => state.session.volumeConfig);
    const [isPlayingDemo, setIsPlayingDemo] = useState(false);

    const handleChangeVolume = (event) => {
        const volume = event.target.valueAsNumber;

        store.dispatch(setVolumeConfig({volume}));
        SessionPersistenceUtil.update(AUDIO_CONFIG_KEY, {volume});
    }

    const handleToggleMute = () => {
        const isMuted = !volumeConfig.isMuted;

        store.dispatch(setVolumeConfig({isMuted}));
        SessionPersistenceUtil.update(AUDIO_CONFIG_KEY, {isMuted});
    }

    const handleToggleClickEffect = () => {
        const enableButtonClicks = !volumeConfig.enableButtonClicks;

        store.dispatch(setVolumeConfig({enableButtonClicks}));
        SessionPersistenceUtil.update(AUDIO_CONFIG_KEY, {enableButtonClicks});
    }

    const handlePlaySample = async () => {
        setIsPlayingDemo(true);

        const audio = AudioService.createAudio(SOUND_EFFECTS.DEMO);
        audio.addEventListener('ended', () => {
            setIsPlayingDemo(false);
        });

        audio.play();
    }

    const handleReset = () => {
        store.dispatch(setVolumeConfig(DEFAULT_VOLUME_CONFIG));
        SessionPersistenceUtil.clear(AUDIO_CONFIG_KEY);
    }

    const volumeStyle = useMemo(() => {
        return {
            containerClass: volumeConfig.isMuted ? 'muted' : '',
            buttonClass: volumeConfig.isMuted ? 'btn-danger' : 'btn-primary'
        }
    }, [volumeConfig.isMuted]);


    return (<Modal id={'adjust-volume-modal'} show={true} onHide={onClose}>
        <Modal.Header closeButton>
            <Modal.Title>{t('texts.title')}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <div
                className={'volume-control d-flex justify-content-center align-items-center ' + volumeStyle.containerClass}>
                <button className={"btn btn-sm px-1 py-0 " + volumeStyle.buttonClass} onClick={handleToggleMute}>
                    {volumeConfig.isMuted
                        ? <FontAwesomeIcon icon={['fas', 'volume-mute']}/>
                        : <FontAwesomeIcon icon={['fas', 'volume-up']}/>
                    }
                </button>

                <input className="mx-2 flex-fill"
                       type="range"
                       min={0}
                       max={1}
                       step={0.05}
                       value={volumeConfig.volume}
                       onChange={handleChangeVolume}
                />

                <p className="mb-0 font-weight-bold">{Math.floor(volumeConfig.volume * 100)}%</p>
            </div>

            <div className="form-check form-check-inline mt-3" style={{fontSize: '110%'}}>
                <input className="form-check-input"
                       type="checkbox"
                       id="enable-button-clicks"
                       checked={volumeConfig.enableButtonClicks}
                       onChange={handleToggleClickEffect}/>

                <label className="form-check-label" htmlFor="enable-button-clicks">
                    {t('buttons.enable_clicks')}
                </label>
            </div>

        </Modal.Body>

        <Modal.Footer>
            <Button className="px-2 py-1" variant="outline-danger" onClick={handleReset}>
                {t('buttons.reset')}
            </Button>


            <Button className="px-2 py-1" variant="primary" onClick={handlePlaySample} disabled={isPlayingDemo}>
                {isPlayingDemo ? t('buttons.playing_demo') : t('buttons.play_demo')}
            </Button>

            <Button className="px-2 py-1" variant="secondary"
                    onClick={onClose}>{t('common:modals.buttons.close')}</Button>
        </Modal.Footer>
    </Modal>)
}