import React, {useEffect, useState} from "react";

import "./Join.scss";
import {useNavigate, useParams} from "react-router-dom";
import ButtonSpinner from "components/ButtonSpinner";
import FadeIn from "assets/plugins/react-fade-in/FadeIn";
import OverlayUtil from "utils/OverlayUtil";
import {OVERLAY_THEME, SESSION_STATUS} from "config/CONSTANTS";
import ToastUtil from "utils/ToastUtil";
import GameSessionService from "services/common/GameSessionService";
import AuthService from "services/gameMaster/AuthService";
import {GAME_MASTER_ROUTES, PUBLIC_ROUTES, TERMS_ROUTES} from "navigation/ROUTES";
import gmIcon from "assets/img/board/game_master.svg";
import {useSelector} from "react-redux";

import SessionClosedInfo from "pages/common/sessionStatus/SessionClosedInfo";
import SessionPausedInfo from "pages/common/sessionStatus/SessionPausedInfo";
import BugReportModal from "../../common/BugReportModal/BugReportModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import LoggerService from "services/common/LoggerService";
import {Trans, useTranslation} from "react-i18next";
import SessionReduxService from "../../../services/redux/SessionReduxService";
import SessionNotFoundInfo from "../../common/sessionStatus/SessionNotFoundInfo";


export default function Join() {
    const navigate = useNavigate();
    const {gameSession} = useParams();
    const {t} = useTranslation(['pages/game_master/join', 'common'], {useSuspense: true});

    const sessionName = useSelector(state => state.game.gameInfo.name);
    const theme = useSelector(state => state.theme);

    const [decodedGameSessionId, setDecodedGameSessionId] = useState(false);
    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [status, setStatus] = useState(null);

    const [showBugReportModal, setShowBugReportModal] = useState(false);
    const handleShowBugReportModal = () => setShowBugReportModal(true);
    const handleCloseBugReportModal = () => setShowBugReportModal(false);

    const now = (new Date()).getUTCFullYear();

    useEffect(() => {
        OverlayUtil.toggleLoadingOverlay(true, t('overlays.checking_session_status'), OVERLAY_THEME.DARK);

        const checkGameStatus = async () => {
            const decodedSessionId = decodeURIComponent(gameSession);

            GameSessionService.checkGameSessionStatus(decodedSessionId).then(async response => {
                if ((response?.status && response?.gameSessionId) || response?.sessionStatus === SESSION_STATUS.NOT_STARTED) {
                    setDecodedGameSessionId(response.gameSessionId);

                    const didRejoin = await AuthService.rejoin(
                        window.location.pathname,
                        response.gameSessionId
                    );

                    if (didRejoin)
                        navigate(GAME_MASTER_ROUTES.CONTROL_PANEL);
                }

                setStatus(response.sessionStatus || SESSION_STATUS.NOT_FOUND);
            }).catch(reason => {
                console.error(reason);
                LoggerService.error(reason, {action: 'check game status - game master'});
                SessionReduxService.updateHasFatalError(true);
            }).finally(() => {
                OverlayUtil.toggleLoadingOverlay(false);
            });
        };

        document.title = t('common:app_title');

        checkGameStatus();
    }, []);

    const handleSubmit = async (evt) => {
        evt.preventDefault();

        setIsLoading(true);

        AuthService.join(window.location.pathname, {gameSessionId: decodedGameSessionId, password}).then(response => {
            if (response === true) {
                OverlayUtil.show();

                // await 250ms to prevent the screen from flashing white
                setTimeout(() => navigate(GAME_MASTER_ROUTES.CONTROL_PANEL), 250);
            } else {
                ToastUtil.toastDanger(t('toasts.invalid_password.title'), t('toasts.invalid_password.message'));
                setIsLoading(false);
            }
        }).catch(reason => {
            console.error(reason);
            ToastUtil.toastDanger(t('toasts.login_failed.title'), t('toasts.login_failed.message'));
            setIsLoading(false);
            LoggerService.error(reason, {action: 'join game - game master'});
        });
    };

    if (status === SESSION_STATUS.CLOSED) return (<SessionClosedInfo/>);
    if (status === SESSION_STATUS.PAUSED) return (<SessionPausedInfo/>);
    if (status === SESSION_STATUS.NOT_FOUND) return (<SessionNotFoundInfo/>);

    return (
        <div id="game-master-login-page" className="row h-100 w-100 m-0">
            <div className="col-sm-12 col-md-6 col-xl-5 position-relative">
                <div className="form px-5 w-100 d-flex flex-column justify-content-center">
                    <FadeIn>
                        <div className="w-100 text-center d-md-none mb-5 pt-3">
                            <img alt="Mundi Game Experiences logo" src={theme.logos.join_page_logo}
                                 className="logo-header"/>
                        </div>

                        <h2 className="text-warning mb-1">{t('texts.greetings')}</h2>
                        <h4 className="mb-4">{t('common:app_name')}</h4>
                    </FadeIn>

                    <FadeIn>
                        <h6 className="mb-5">
                            <Trans t={t} values={{session: sessionName}} i18nKey={"texts.session_info"}
                                   components={{1: <b className="text-warning"/>, 2: <b className="text-danger"/>}}/>
                        </h6>
                    </FadeIn>

                    <form onSubmit={handleSubmit}>
                        <div className={'form-group'}>
                            <label className="required">{t('inputs.password.label')}</label>
                            <input type="password" className="form-control" required={true} autoFocus={true}
                                   onChange={e => setPassword(e.target.value)}/>
                            <span className="form-text text-muted small">{t('inputs.password.tip')}</span>
                        </div>

                        <ButtonSpinner type="submit" showAnimation={isLoading}>{t('buttons.access')}</ButtonSpinner>

                        <div className="small text-muted mt-1">
                            <p className="m-0 d-inline">{t('texts.terms_info')} </p>
                            <a href={TERMS_ROUTES.TERMS_OF_USE} target="_blank" className="btn btn-link d-inline p-0 "
                               style={{fontSize: '85%'}}>{t('buttons.terms_of_use')}
                            </a>
                        </div>
                    </form>

                    <div className="mt-2 mb-0 p-0 text-muted small">
                        <p className="mb-1">
                            <a href={TERMS_ROUTES.COOKIES_POLICY} target="_blank"
                               className="text-muted mr-1">{t('common:cookies_policy')}</a>
                            <a href={TERMS_ROUTES.PRIVACY_POLICY} target="_blank"
                               className="text-muted">{t('common:privacy_policy')}</a>
                        </p>

                        <p className="mb-1">
                            @ {now}

                            <a href="https://www.startupmundi.com.br/" rel="noreferrer"
                               className="text-muted text-decoration-underline ml-1"
                               target="_blank">
                                {t('common:company_name')}</a>. {t('common:rights_reserved')}
                        </p>

                        <a className="mr-2 mb-2 mb-md-0 text-muted" href={PUBLIC_ROUTES.VERIFY_CONNECTION} target="_blank">
                            <FontAwesomeIcon icon={['fas', 'signal']}/> Testar conexão
                        </a>

                        <button className="btn btn-link  text-muted p-0 text-decoration-none btn-report-bug"
                                style={{fontSize: '100%'}}
                                onClick={handleShowBugReportModal}>
                            <FontAwesomeIcon icon={['fas', 'bug']}/> {t('common:btn_report_bug')}
                        </button>
                    </div>
                </div>

                <div className="bg">
                    <img src={gmIcon}/>
                </div>
            </div>

            <div className="d-none d-md-block col p-0">
                <div
                    className="w-100 d-flex justify-content-center align-items-center text-white right-card flex-column"
                    style={{background: theme.pages.join.bg_color}}>
                    <img alt="Mundi Game Experiences logo" src={theme.logos.join_page_secondary_logo} className="logo"/>
                </div>
            </div>

            {showBugReportModal && (<BugReportModal onClose={handleCloseBugReportModal}/>)}
        </div>
    );
}