import React from 'react';
import {TERMS_ROUTES} from "../../../../navigation/ROUTES";

export default function TermsEn() {
    return <>
        <h4>Terms of Use</h4>

        <p>
            This website is owned and operated by Startup Mundi. These terms set out our terms and conditions under
            which the website and services offered by us may be used. By accessing or using our service's website, you
            agree that you have read, understood and agree to be bound by these Terms.
        </p>

        <p>
            By using our Services, you acknowledge that our <a target="_blank" href={TERMS_ROUTES.PRIVACY_POLICY}>Privacy
            Policy</a> and <a target="_blank" href={TERMS_ROUTES.COOKIES_POLICY}>Cookies Policy</a> are made available
            here.
        </p>


        <p className="font-weight-bold mb-0">
            Intellectual property, copyright
        </p>

        <p>
            The Service and all materials contained or transferred therein, including, without limitation, software,
            images, text, graphics, logos, patents, trademarks, service marks, copyrights, photographs, audio, videos,
            music and all Intellectual property related to them are the exclusive property of Startup Mundi. Except as
            explicitly provided herein, nothing in these Terms shall be deemed a license in or under such Intellectual
            Property Rights, and you agree not to sell, license, rent, modify, distribute, copy, reproduce, transmit,
            publicly display, perform publicly, publish, adapt, edit or create derivative works.
        </p>


        <p className="font-weight-bold mb-0">
            Right to suspend or cancel the user
        </p>

        <p>
            We may suspend or permanently expel your access to the Service without notice and liability if you interfere
            with your game session or violate any provision of these Terms or any applicable law or regulation.
        </p>

        <p className="font-weight-bold mb-0">
            Right to change and modify the Terms
        </p>

        <p>
            We reserve the right to modify these terms from time to time in our sole discretion. Therefore, you should
            periodically review these pages. When we materially change the Terms, we will notify you. Your continued use
            of the Site or our service after any change constitutes your acceptance of the new Terms. If you do not
            agree with any of these terms or any future version of the Terms, please do not use or access (or continue
            to access) the website or service.
        </p>

        <p className="font-weight-bold mb-0">
            Contact information
        </p>

        <p>
            To contact us use the email suporte@startupmundi.com.br.
        </p>

        <p className="font-weight-bold mb-0">
            Law preference and dispute resolution
        </p>

        <p>
            These Terms, the rights and remedies provided herein, and any and all claims and disputes relating thereto
            and/or the Services, will be governed, interpreted and enforced in all respects solely and exclusively in
            accordance with the domestic laws of Brazil. Any and all claims and disputes will be filed, and you consent
            to them being decided exclusively by a court of competent jurisdiction located in Florianópolis, Santa
            Catarina.
        </p>

        <p>
            Florianópolis, March 20, 2022.
        </p>
    </>;
}


