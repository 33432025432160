import React, {memo, useMemo} from "react";
import i18nUtil from "utils/i18nUtil";

import "./SkillItem.scss";
import CardAccelerationBar from "../../../../../../components/CardAccelerationBar";
import {GENDER_ICONS, SKILL_GENDER_GROUP} from "../../../../../../config/SKILL_ICONS";
import {useSelector} from "react-redux";
import {GAME_PHASES} from "../../../../../../config/CONSTANTS";
import GameUtil from "../../../../../../utils/GameUtil";

const SkillItem = ({hiredSkill, onClick, showAccelerationInfo = true, viewType = 1}) => {
    const currentPhase = useSelector(state => state.game.currentPhase);

    const {id, area, title, accelerationPhase2, accelerationPhase3} = useMemo(() => hiredSkill.card, [hiredSkill.card]);
    const icon = useMemo(() => hiredSkill.icon ?? hiredSkill.card.icon, [hiredSkill]);
    const gender = useMemo(() => hiredSkill.gender ?? null, [hiredSkill]);
    const genderIcon = useMemo(() => GENDER_ICONS[gender] || null, [gender]);
    const handleClick = () => onClick(hiredSkill);

    const accelerationInfo = useMemo(() => {
        if (viewType === 1)
            return null;

        if (GameUtil.isCurrentGameEsg()){
            return (<b className="text-white">+{accelerationPhase2}%</b>)
        }

        return currentPhase === GAME_PHASES.PHASE_2
            ? (<span><b className="text-white">{accelerationPhase2}%</b>/{accelerationPhase3}%</span>)
            : (<span>{accelerationPhase2}%/<b className="text-white">{accelerationPhase3}%</b></span>);
    }, [viewType, currentPhase]);

    return (
        <div key={id} className="py-2 px-2 d-flex hired-service" onClick={handleClick}>
            <img className="skill-face mr-2" src={icon} alt="Founder avatar"/>
            {gender && gender === SKILL_GENDER_GROUP.OTHER && (<img className="gender-icon" src={genderIcon}/>)}

            <div className="w-100">
                <div className="d-flex justify-content-between align-items-start">
                    {viewType === 1 && (
                        <div className="skill-area text-center">{i18nUtil.area(area)}</div>
                    )}

                    {showAccelerationInfo && (
                        <div className="power-rating power-rating-light">
                            <CardAccelerationBar card={hiredSkill.card}/>
                        </div>
                    )}
                </div>

                <p className="skill-name text-break">{title}</p>

                {viewType === 2 && (
                    <p className="text-muted mb-0" style={{fontSize: '95%'}}>{accelerationInfo}</p>
                )}
            </div>
        </div>
    );
};

export default memo(SkillItem);