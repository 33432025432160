import React, {memo, useState} from "react";

import {useSelector} from "react-redux";
import i18nUtil from "utils/i18nUtil";
import GameEventCardLayout from "../GameEventCardLayout/GameEventCardLayout";
import ShowQuestionModal from "./ShowQuestionModal/ShowQuestionModal";
import {useTranslation} from "react-i18next";

const QuestionCardPreview = ({questionData}) => {
    const {t} = useTranslation('pages/common/board/game_events', {keyPrefix: 'components.question_card_preview'});

    const teamCount = useSelector(state => state.game.teamCount);
    const isTournament = useSelector(state => state.game.isTournament);

    const [showModal, setShowModal] = useState(false);

    const {card} = questionData.data;
    const {team} = questionData;

    const handleShowDetails = () => {
        setShowModal(true);
    }

    return (
        <>
            <GameEventCardLayout title={t('texts.title')} color={'danger'} onClick={handleShowDetails} leftContent={(
                <p>{card.question}</p>
            )} rightContent={(
                <>
                    {teamCount >= team && (
                        <div className="mb-2 text-center">
                            <b>{t('texts.team', {team})}</b>
                        </div>
                    )}


                    <div className="text-center mb-2">
                        <p className="mb-0">{t('texts.step')}</p>
                        <b className="text-break">{i18nUtil.stepName(card.step)}</b>
                    </div>

                    <div className="text-center">
                        <p className="mb-0">{t('texts.concept')}</p>
                        <b className="text-break">{card.concept}</b>
                    </div>
                </>
            )}/>

            {showModal && (
                <ShowQuestionModal questionData={questionData} onClose={() => setShowModal(false)}/>)}
        </>
    );
}

export default memo(QuestionCardPreview);