import Utils from "utils/Utils";
import ApiService from "../common/api/ApiService";
import {API_ENDPOINTS} from "config/API_CONSTANTS";
import SessionPersistenceUtil, {GAME_MASTER_KEY} from "utils/SessionPersistenceUtil";
import store from "redux/store";
import {setIsGameMaster, setToken} from "redux/slices/sessionSlice";
import {setGameInfo} from "redux/slices/gameSlice";
import LoggerService from "../common/LoggerService";
import {batch} from "react-redux";

function setUserSession(gameUrl, {gameSessionId, token, refreshToken}) {
    if (!Utils.isset(gameSessionId) || !Utils.isset(token)) {
        throw Error('Cannot set user and session because data is invalid or missing');
    }

    batch(() => {
        store.dispatch(setToken(token));
        store.dispatch(setGameInfo({id: gameSessionId}));
        store.dispatch(setIsGameMaster(true));
    });

    SessionPersistenceUtil.persist(GAME_MASTER_KEY, {gameUrl, refreshToken, gameSessionId});
}

const AuthService = {
    async join(gameUrl, params) {
        return ApiService.post(API_ENDPOINTS.USERS.JOIN_GAME_MASTER, params, false)
            .then(response => {
                if (response.status === true) {
                    setUserSession(gameUrl, response.data);
                    return true;
                } else {
                    SessionPersistenceUtil.clear(GAME_MASTER_KEY);
                    return false;
                }
            }).catch(error => {
                console.error(error);
                SessionPersistenceUtil.clear(GAME_MASTER_KEY);
                LoggerService.error(error, {action: 'join - game master AuthService', params});
                return false;
            });
    },

    async rejoin(gameUrl, gameSessionId) {
        const oldSession = SessionPersistenceUtil.get(GAME_MASTER_KEY);

        if (!!oldSession && oldSession?.gameSessionId === gameSessionId && !!oldSession?.refreshToken) {
            return ApiService.post(API_ENDPOINTS.USERS.JOIN_GAME_MASTER, {
                gameSessionId,
                refreshToken: oldSession.refreshToken
            }, false).then(async response => {
                if (response.status === true) {
                    setUserSession(gameUrl, response.data);
                    return true;
                } else {
                    SessionPersistenceUtil.clear(GAME_MASTER_KEY);
                    return false;
                }
            }).catch(error => {
                console.error(error);
                SessionPersistenceUtil.clear(GAME_MASTER_KEY);
                LoggerService.error(error, {action: 'join - game master AuthService'});
                return false;
            });
        } else {
            // SessionPersistenceUtil.clear(GAME_MASTER_KEY);
            return false;
        }
    },

    async leave() {
        SessionPersistenceUtil.clear(GAME_MASTER_KEY);
        return true;
    },

    isAuthenticated() {
        return Utils.isset(store.getState().session.token);
    }
};

export default AuthService;