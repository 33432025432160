import React from "react";

import "./Message.scss";

export default function Message({message}) {

    return (
        <div className="px-3 py-2 w-100 text-white message mb-3">
            {message.message}

            <div className="w-100 d-flex justify-content-end text-warning small">
                {message.time.toLocaleString()}
            </div>
        </div>
    );
}