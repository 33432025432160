import React, {memo, useEffect, useState} from "react";

import "./UnpredictabilityChallenge.scss";
import UnpredictabilityCard, {UNPREDICTABILITY_CARD_LAYOUT} from "components/cards/UnpredictabilityCard";
import AudioService from "../../../../../../services/common/AudioService";
import {SOUND_EFFECTS} from "../../../../../../config/AUDIO_FILES";
import store from "../../../../../../redux/store";
import {updateDemoState} from "../../../../../../redux/slices/demoSlice";
import {useSelector} from "react-redux";
import DemoSessionService from "../../../../../../services/common/DemoSessionService";
import Utils from "../../../../../../utils/Utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import GameControlService
    from "../../../../../gameMaster/ControlPanel/components/GameControl/services/GameControlService";
import GameService from "../../../../../../services/gameMaster/GameService";
import ToastUtil from "../../../../../../utils/ToastUtil";
import {UNPREDICTABILITY_IMPACT} from "../../../../../../config/CONSTANTS";
import TournamentService from "../../../../../../services/common/TournamentService";
import EventsUtil from "../../../../../../utils/EventsUtil";
import {GAME_EVENTS} from "../../../../../../config/EVENTS";


const UnpredictabilityChallenge = ({data}) => {
    const {t} = useTranslation('common');

    const {card, reveal, revealOutcome, impact, step} = data;

    const [isLoading, setIsLoading] = useState(false);

    const isDemonstration = useSelector(state => state.game.isDemonstration);
    const isTournament = useSelector(state => state.game.isTournament);

    const hasPlayedUnpredictabilityVideo = useSelector(state => state.demo.state.hasPlayedUnpredictabilityVideo);
    const hasPlayedUnpredictabilitySound = useSelector(state => state.demo.state.hasPlayedUnpredictabilitySound);
    const hasRevealedUnpredictability = useSelector(state => state.demo.state.hasRevealedUnpredictability);

    const handleDemoReveal = async () => {
        DemoSessionService.toggleChallengesPagination(false);
        setIsLoading(true);

        await AudioService.play(SOUND_EFFECTS.UNPREDICTABILITY.SUSPENSE_REVEAL_OUTCOME, 150);

        DemoSessionService.revealUnpredictability();
        setIsLoading(false);

        await AudioService.play(SOUND_EFFECTS.UNPREDICTABILITY.POSITIVE_OUTCOME);
        DemoSessionService.toggleChallengesPagination(true);
    }

    const handleTournamentReveal = async () => {
        setIsLoading(true);

        await AudioService.play(SOUND_EFFECTS.UNPREDICTABILITY.SUSPENSE_REVEAL);

        const {
            impact,
            revealOutcome
        } = GameControlService.unpredictabilityImpact(1, store.getState().teams["1"].unpredictabilities[step].card);

        const params2 = {team: 1, step, revealOutcome};
        if (revealOutcome) params2.impact = impact;

        await GameService.revealUnpredictability(params2);

        setIsLoading(false);
    }

    const handleTournamentAdvance = async () => {
        setIsLoading(true);

        if (!await TournamentService.advanceGame()) {
            ToastUtil.toastDanger('Algo inexperado aconteceu!', 'Tente novamente ou entre em contato');
            setIsLoading(false);
        }
    }

    const handlePlayTournamentOutcomeAudio = async (data) => {
        if (data.reveal && data.revealOutcome) {
            let soundToPlay;

            switch (data.impact) {
                case UNPREDICTABILITY_IMPACT.POSITIVE:
                    soundToPlay = SOUND_EFFECTS.UNPREDICTABILITY.POSITIVE_OUTCOME;
                    break;
                case UNPREDICTABILITY_IMPACT.NEGATIVE:
                    soundToPlay = SOUND_EFFECTS.UNPREDICTABILITY.NEGATIVE_OUTCOME;
                    break;
                default:
                    soundToPlay = SOUND_EFFECTS.UNPREDICTABILITY.NEUTRAL_OUTCOME;
            }

            setIsLoading(false);
            await AudioService.play(soundToPlay);
        }
    }

    const handleTournamentRevealOutcome = async () => {
        setIsLoading(true);
        await AudioService.play(SOUND_EFFECTS.UNPREDICTABILITY.SUSPENSE_REVEAL_OUTCOME)

        const {impact} = GameControlService.unpredictabilityImpact(1, card);
        const params = {team: 1, step, impact};

        GameService.revealUnpredictabilityOutcome(params);
    }

    useEffect(() => {
        const fn = async () => {
            if (isDemonstration && !hasPlayedUnpredictabilitySound) {
                DemoSessionService.toggleChallengesPagination(false);

                await AudioService.play(SOUND_EFFECTS.UNPREDICTABILITY.SUSPENSE_REVEAL);
                store.dispatch(updateDemoState({hasPlayedUnpredictabilitySound: true}));

                await Utils.wait(150);
                DemoSessionService.playUnpredictabilityVideo();
            }
        }

        fn();
    }, []);

    useEffect(() => {
        if (hasPlayedUnpredictabilityVideo && !hasRevealedUnpredictability)
            handleDemoReveal();
    }, [hasRevealedUnpredictability, hasPlayedUnpredictabilityVideo]);

    useEffect(() => {
        EventsUtil.subscribe(GAME_EVENTS.UNPREDICTABILITY_CARD_UPDATED, handlePlayTournamentOutcomeAudio);

        return () => {
            EventsUtil.unsubscribe(GAME_EVENTS.UNPREDICTABILITY_CARD_UPDATED, handlePlayTournamentOutcomeAudio);
        };
    }, []);

    return (
        <div
            className="unpredictability-challenge h-100 w-100 d-flex justify-content-center align-items-center flex-column">
            <UnpredictabilityCard unpredictabilityCard={card}
                                  isLoading={isLoading}
                                  revealOutcome={revealOutcome}
                                  reveal={reveal}
                                  impact={impact}
                                  layout={UNPREDICTABILITY_CARD_LAYOUT.CONSEQUENCES_BELLOW}/>

            {isTournament && !reveal && (
                <button className="btn btn-secondary mt-3" onClick={handleTournamentReveal}
                               disabled={isLoading}>
                    Revelar
                </button>
            )}

            {isTournament && reveal && !revealOutcome && (
                <button className="btn btn-secondary mt-3" onClick={handleTournamentRevealOutcome}
                               disabled={isLoading}>
                    Revelar resultado
                </button>
            )}

            {!isLoading && isTournament && reveal && revealOutcome && (
                <button className="btn btn-success shadow mt-3" onClick={handleTournamentAdvance}
                               disabled={isLoading}>
                    Continuar jogo
                </button>
            )}

            {isDemonstration && hasPlayedUnpredictabilityVideo && !isLoading && (
                <button className="btn btn-sm btn-outline-secondary border-0 mt-3"
                        onClick={DemoSessionService.playUnpredictabilityVideo}>

                    <FontAwesomeIcon icon={['fas', 'circle-play']} className="mr-1"/>
                    {t('watch_again')}
                </button>
            )}
        </div>
    );
}

export default memo(UnpredictabilityChallenge);