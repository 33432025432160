import React, {useMemo} from "react";

import "./HiringDetails.scss";
import i18nUtil from "utils/i18nUtil";
import {useTranslation} from "react-i18next";

const HiringDetails = ({accountingDetails}) => {
    const {t} = useTranslation('pages/common/board/accounting', {keyPrefix: "components.accounting_details_modal.components.hiring_details.tables.details"});

    const hasSomethingToDisplay = useMemo(() => Object.keys(accountingDetails).length > 0, [accountingDetails]);

    return (
        <table className="table table-hiring-details table-borderless table-striped">
            <thead>
            <tr>
                <th>{t('header.area')}</th>
                <th>{t('header.count')}</th>
                <th>%</th>
                <th>{t('header.average_power')}</th>
                <th>{t('header.investment')}</th>
            </tr>
            </thead>

            <tbody>
            {hasSomethingToDisplay && Object.entries(accountingDetails)
                .sort((a, b) => a[0].localeCompare(b[0]))
                .map(([area, details]) => {
                    return (<tr key={area}>
                        <td>{i18nUtil.area(area)}</td>
                        <td>{details?.count || 0}</td>
                        <td>{i18nUtil.formatPercent(details?.percent || 0)}</td>
                        <td>{i18nUtil.formatDecimal(details?.meanPower || 0)}</td>
                        <td>{i18nUtil.formatMoney(details?.investment || 0)}</td>
                    </tr>)
                })}

            {!hasSomethingToDisplay && (
                <tr>
                    <td colSpan={5} className="text-center">{t('empty_message')}</td>
                </tr>
            )}
            </tbody>
        </table>
    );
};

export default React.memo(HiringDetails);