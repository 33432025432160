import React, {useEffect} from "react";

import "./Lobby.scss";
import TeamCard from "./components/TeamCard";
import UserList from "./components/UserList";
import Header from "./components/Header/Header";
import FadeIn from "assets/plugins/react-fade-in/FadeIn";
import Footer from "./components/Footer";
import GameSessionService from "services/common/GameSessionService";
import OverlayUtil from "utils/OverlayUtil";
import store from "redux/store";
import GameEventSubscriber from "subscribers/GameEventSubscriber";
import Messages from "./components/Messages/Messages";
import TeamEventSubscriber from "subscribers/TeamEventSubscriber";
import {useSelector} from "react-redux";
import EventProcessor from "services/common/gameEvents/EventProcessor";
import SessionReduxService from "services/redux/SessionReduxService";
import TeamService from "services/player/TeamService";
import LoggerService from "services/common/LoggerService";
import {useTranslation} from "react-i18next";
import useForceUpdate from "../../../hooks/useForceUpdate";

export default function Lobby() {
    const {t} = useTranslation('pages/player/lobby', {useSuspense: true});

    const userTeam = useSelector(state => state.session.team);
    const token = useSelector(state => state.session.token);
    const hasLoadedGameData = useSelector(state => state.session.hasLoadedGameData);

    const customization = useSelector(state => state.game.customization);
    const forceUpdate = useForceUpdate();

    const handleGoToErrorPage = () => {
        return SessionReduxService.updateHasFatalError(true);
    };

    const handleHasConnectionError = () => {
        return SessionReduxService.updateHasConnectionError(true);
    };

    useEffect(() => {
        const fetchData = async () => {
            OverlayUtil.toggleLoadingOverlay(true, t('overlays.loading_data'));

            try {
                EventProcessor.pause();

                const subscribedToEvents = await GameEventSubscriber.subscribe();
                if (!subscribedToEvents) return handleGoToErrorPage();

                if (userTeam) {
                    const hasUnsubscribed = await TeamEventSubscriber.unsubscribeFromAll();
                    if (!hasUnsubscribed) return handleHasConnectionError();

                    const hasSubscribed = TeamEventSubscriber.subscribe(userTeam);
                    if (!hasSubscribed) return handleHasConnectionError();
                }

                const fetchedGameData = await GameSessionService.fetchAllGameData();
                if (!fetchedGameData) return handleGoToErrorPage();

                EventProcessor.resume();
            } catch (e) {
                console.error(e);
                LoggerService.error(e, {action: 'initialization - lobby'});
                SessionReduxService.updateHasFatalError(true);
            } finally {
                OverlayUtil.toggleLoadingOverlay(false);
            }
        };

        if (store.getState().session.hasLoadedGameData === false) {
            fetchData();
            document.title = t('tab_title');
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);

    useEffect(() => {
        const connect = async () => {
            if (!userTeam || !hasLoadedGameData) {
                await TeamEventSubscriber.unsubscribeFromAll();
                if (hasLoadedGameData) EventProcessor.resume();
                return;
            }

            if (store.getState().session.hasLoadedTeamData === userTeam) return;

            OverlayUtil.toggleLoadingOverlay(true, t('overlays.loading_team_data'));
            EventProcessor.pause();

            try {
                const hasUnsubscribed = await TeamEventSubscriber.unsubscribeFromAll();
                if (!hasUnsubscribed) return handleHasConnectionError();

                const hasSubscribed = TeamEventSubscriber.subscribe(userTeam);
                if (!hasSubscribed) return handleHasConnectionError();

                SessionReduxService.updateHasLoadedTeamData(userTeam)

                const hasFetchedTeamData = await TeamService.fetchTeamData(userTeam);
                if (!hasFetchedTeamData) return handleHasConnectionError();

                return EventProcessor.resume();
            } catch (e) {
                console.error(e);
                LoggerService.error(e, {action: 'team data initialization - lobby'});
                SessionReduxService.updateHasFatalError(true);
            } finally {
                OverlayUtil.toggleLoadingOverlay(false);
            }
        }

        connect();
    }, [hasLoadedGameData, token]);

    // re-render on customization change
    useEffect(() => forceUpdate(), [customization]);

    return (
        <div id="lobby" className="d-flex flex-column m-auto h-100">
            <Header/>

            <FadeIn className="flex-fill d-flex justify-content-center">
                <div className="d-flex mt-4 justify-content-center container-custom">
                    <UserList/>

                    <div>
                        <div className="d-flex mb-3">
                            <TeamCard number={1}/>
                            <TeamCard number={2}/>
                        </div>

                        <div className="d-flex">
                            <TeamCard number={3}/>
                            <TeamCard number={4}/>
                        </div>
                    </div>

                    <Messages/>
                </div>
            </FadeIn>

            <Footer/>
        </div>
    );
}