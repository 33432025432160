import Utils from "../utils/Utils";
import {i18nService} from "../i18n";

const TRANSLATION_FOR_POINTS = {
    'pt': 'pontos',
    'en': 'points',
    'es': 'puntons'
};

export default class UnpredictabilityCardModel {
    constructor({
                    id,
                    event,
                    relatedCard,
                    positiveConsequenceDescription,
                    negativeConsequenceDescription,
                    neutralConsequenceDescription,
                    positiveAccelerationImpact,
                    negativeAccelerationImpact,
                    phases
                }) {

        this.id = id;
        this.event = event;
        this.relatedCard = relatedCard;
        this.positiveConsequenceDescription = positiveConsequenceDescription;
        this.negativeConsequenceDescription = negativeConsequenceDescription;
        this.neutralConsequenceDescription = neutralConsequenceDescription;
        this.positiveAccelerationImpact = positiveAccelerationImpact;
        this.negativeAccelerationImpact = negativeAccelerationImpact;
        this.phases = phases;

        this._updateDescriptionWithValues();
    }

    hasDifferentImpacts() {
        if (this._hasDifferentImpacts !== undefined)
            return this._hasDifferentImpacts;

        let counter = 0;

        if (this.positiveConsequenceDescription)
            counter++;

        if (this.negativeConsequenceDescription)
            counter++;

        if (this.neutralConsequenceDescription)
            counter++;

        this._hasDifferentImpacts = counter > 1;

        return this._hasDifferentImpacts;
    }

    _updateDescriptionWithValues() {
        const lang = i18nService.getLanguage().toLowerCase();
        const pointsText = TRANSLATION_FOR_POINTS[lang] ?? TRANSLATION_FOR_POINTS.en;

        if (Utils.isset(this.positiveConsequenceDescription)) {
            this.positiveConsequenceDescription = '<span>'
                + this.positiveConsequenceDescription.replace('(AIV)',
                    `<span class="font-weight-bold">${this.positiveAccelerationImpact} ${pointsText}</span>`)
                + '</span>';
        }

        if (Utils.isset(this.negativeConsequenceDescription)) {
            this.negativeConsequenceDescription = '<span>'
                + this.negativeConsequenceDescription.replace('(AIV)',
                    `<span class="font-weight-bold">${this.negativeAccelerationImpact} ${pointsText}</span>`)
                + '</span>';
        }

        if (Utils.isset(this.neutralConsequenceDescription)) {
            this.neutralConsequenceDescription = '<span>' + this.neutralConsequenceDescription + '</span>';
        }
    }
}