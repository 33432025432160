const _events = {};

function touchEven(event) {
    if (!_events[event])
        _events[event] = [];
}

const EventsUtil = {
    subscribe(event, callback) {
        touchEven(event);
        _events[event].push(callback);
    },

    unsubscribe(event, callback) {
        touchEven(event);

        const index = _events[event].indexOf(callback);

        if (index >= 0)
            _events[event].splice(index, 1);
    },

    publish(event, data) {
        touchEven(event);

        for (let i = 0; i < _events[event].length; i++) {
            _events[event][i](data);
        }
    },
};

export default EventsUtil;