import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useSelector} from "react-redux";

export default function PhaseStatusTD({step}) {
    const currentGameStep = useSelector(state => state.game.currentStep);

    return (<>
        {currentGameStep > step && (<td className="text-success"><FontAwesomeIcon icon={['fas', 'check']}/></td>)}
        {currentGameStep === step && (<td className="text-success"><FontAwesomeIcon icon={['fas', 'play']}/></td>)}
        {currentGameStep < step && (<td><FontAwesomeIcon icon={['fas', 'ellipsis-h']}/></td>)}
    </>)
}