import React from "react";

import logo from "assets/img/logos/svg/default_2.svg"
import "./Kicked.scss";
import {useTranslation} from "react-i18next";
import {TERMS_ROUTES} from "../../../navigation/ROUTES";

export default function PlayerRemoved() {
    const now = (new Date()).getUTCFullYear();
    const {t} = useTranslation(['pages/common/kicked', 'common']);

    return (
        <div className="min-vw-100 min-vh-100 d-flex flex-column bg-dark text-white kicked px-4">
            <div
                className="flex-fill d-flex justify-content-center align-items-center flex-column mb-4 pb-5 text-center">
                <img className="mb-4 pb-4" src={logo}/>
                <h4>{t('texts.title')}</h4>
                <p>{t('texts.message')}</p>

                <p className="mx-3 mt-4 mb-1 text-white">
                    @ {now}

                    <a href="https://www.startupmundi.com.br/" className="text-white text-decoration-underline ml-1"
                       target="_blank" rel="noreferrer">
                        {t('common:app_name')}</a>. {t('common:rights_reserved')}
                </p>

                <p className="mb-0 small d-inline">
                    <a href={TERMS_ROUTES.TERMS_OF_USE} target="_blank"
                       className="text-white mr-1">{t('common:terms_of_use')}</a>
                    <a href={TERMS_ROUTES.COOKIES_POLICY} target="_blank"
                       className="text-white mr-1">{t('common:cookies_policy')}</a>
                    <a href={TERMS_ROUTES.PRIVACY_POLICY} target="_blank"
                       className="text-white">{t('common:privacy_policy')}</a>
                </p>
            </div>

        </div>
    );
}