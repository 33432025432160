import React from 'react';
import {TERMS_ROUTES} from "../../../../navigation/ROUTES";

export default function TermsPtBR() {
    return <>
        <h4>Termos de Uso</h4>

        <p>
            Este site pertence e é operado pela Startup Mundi. Estes Termos estabelecem os termos e condições sob os
            quais você
            pode utilizar nosso site e serviços oferecidos por nós. Ao acessar ou usar o website do nosso serviço, você
            aprova
            que leu, entendeu e concorda em ficar vinculado a estes Termos.
        </p>

        <p>
            Ao usar nossos Serviços, você reconhece que leu nossa a <a target="_blank"
                                                                       href={TERMS_ROUTES.PRIVACY_POLICY}>Política de
            Privacidade</a> e <a target="_blank" href={TERMS_ROUTES.COOKIES_POLICY}>Política de Cookies</a> aqui
            disponibilizada.
        </p>


        <p className="font-weight-bold mb-0">
            Propriedade intelectual, direitos autorais
        </p>

        <p>
            O Serviço e todos os materiais nele contidos ou transferidos, incluindo, sem limitação, software, imagens,
            textos,
            gráficos, logotipos, patentes, marcas registradas, marcas de serviço, direitos autorais, fotografias, áudio,
            vídeos,
            música e todos os Direitos de Propriedade Intelectual relacionados a eles são a propriedade exclusiva de
            Startup
            Mundi. Exceto conforme explicitamente fornecido neste documento, nada nestes Termos deverá ser considerado
            como uma
            licença em ou sob tais Direitos de Propriedade Intelectual, e você concorda em não vender, licenciar,
            alugar,
            modificar, distribuir, copiar, reproduzir, transmitir, exibir publicamente, realizar publicamente, publicar,
            adaptar, editar ou criar trabalhos derivados.
        </p>


        <p className="font-weight-bold mb-0">
            Direito de suspender ou cancelar o usuário
        </p>

        <p>
            Podemos suspender ou expulsar permanentemente seu acesso ao serviço sem aviso prévio e responsabilidade caso
            venha a atrapalhar o andamento da sessão do jogo ou violar qualquer disposição destes Termos ou qualquer lei
            ou regulamentação aplicável.
        </p>

        <p className="font-weight-bold mb-0">
            Direito de alterar e modificar os Termos
        </p>

        <p>
            Reservamo-nos o direito de modificar estes termos de tempos em tempos, a nosso exclusivo critério. Portanto,
            você
            deve revisar essas páginas periodicamente. Quando alterarmos os Termos de maneira material, você será
            notificado.
            Seu uso continuado do Site ou de nosso serviço após qualquer alteração constitui sua aceitação dos novos
            Termos. Se
            você não concordar com algum destes termos ou qualquer versão futura dos Termos, não use ou acesse (ou
            continue a
            acessar) o site ou o serviço.
        </p>

        <p className="font-weight-bold mb-0">
            Informações de contato
        </p>

        <p>
            Para entrar em contato conosco utilize o e-mail suporte@startupmundi.com.br.
        </p>

        <p className="font-weight-bold mb-0">
            Preferência de lei e resolução de disputas
        </p>

        <p>
            Estes Termos, os direitos e recursos aqui previstos, e todas e quaisquer reclamações e disputas relacionadas
            a ele e
            / ou aos serviços, serão regidos, interpretados e aplicados em todos os aspectos única e exclusivamente de
            acordo
            com as leis internas do Brasil. Todas e quaisquer reclamações e disputas serão apresentadas, e você consente
            que
            elas sejam decididas exclusivamente por um tribunal de jurisdição competente localizado em Florianópolis,
            Santa
            Catarina.
        </p>

        <p>
            Florianópolis, 20 de março de 2022.
        </p>


    </>;
}


