import React from "react";
import StepTitle from "../components/StepTitle";

export default function SmgxStep4() {
    return (<div>
        <StepTitle>
            Vejo que você tem todas as habilidades e conhecimentos necessários para vencer esses desafios
        </StepTitle>

        <h5>
            Em breve você será conduzido à sua área de trabalho, onde terá à sua disposição todas as ferramentas
            necessárias para atingir esses objetivos

        </h5>

        <h5>
            Quando chegar lá, atente-se ao quadro central da sua área de trabalho. Será por lá que nos comunicaremos
        </h5>
    </div>)
}