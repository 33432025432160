import Utils from "utils/Utils";

import {GAME_CARD_TYPE} from "../config/CONSTANTS";

export default class SkillCardModel {
    constructor({id, title, description, area, price, accelerationPhase2, accelerationPhase3, relatedBonus = []}) {
        this.id = id;
        this.title = title;
        this.description = description;
        this.area = area;
        this.price = price;
        this.accelerationPhase2 = accelerationPhase2;
        this.accelerationPhase3 = accelerationPhase3;
        this.relatedBonus = relatedBonus;

        this._setIcon();
        this.__type = GAME_CARD_TYPE.SKILL;
    }

    _setIcon() {
        this.icon = SkillCardModel.randomIcon();
    }

    static randomIcon() {
        const number = SkillCardModel.randomIconNumber();

        return `/img/skills/Skill_${number}.svg`;
    }

    static randomIconNumber() {
        return Utils.randomInt(1, 22);
    }

    static getFullPathForIcon(number) {
        return `/img/skills/Skill_${number}.svg`;
    }

    static getNumberForIcon(iconPath) {
        return iconPath.match(/\d+/)[0];
    }
}