import {API} from "@aws-amplify/api";
import store from "../../../redux/store";
import {APIS} from "../../../config/API_CONSTANTS";

const ApiService = {
    post(path, data, passToken = true) {
        const requestParams = {
            body: data || {},
            headers: passToken === true ? {Authorization: store.getState().session.token} : {}
        }

        return API.post(APIS.SMGX_API, path, requestParams)
            .catch(err => {
                console.error({path, data, passToken});
                console.error(err);

                throw err;
            });
    }
};

export default ApiService;