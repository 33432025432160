import React, {useMemo} from "react";

import implementationGoldIcon from 'assets/img/board/accelerationBonus/implementation_gold.svg';
import implementationSilverIcon from 'assets/img/board/accelerationBonus/implementation_silver.svg';
import implementationNoneIcon from 'assets/img/board/accelerationBonus/implementation_none.svg';

import monitoringGoldIcon from 'assets/img/board/accelerationBonus/monitoring_gold.svg';
import monitoringSilverIcon from 'assets/img/board/accelerationBonus/monitoring_silver.svg';
import monitoringNoneIcon from 'assets/img/board/accelerationBonus/monitoring_none.svg';

import reportingGoldIcon from 'assets/img/board/accelerationBonus/reporting_gold.svg';
import reportingSilverIcon from 'assets/img/board/accelerationBonus/reporting_silver.svg';
import reportingNoneIcon from 'assets/img/board/accelerationBonus/reporting_none.svg';
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {ACCELERATION_BONUSES} from "../../../../../../../config/CONSTANTS";
import ACCOUNTING_ESG_CONFIG from "../../../../../../../config/ACCOUNTING_ESG_CONFIG";
import {useTranslation} from "react-i18next";


const ICONS = {
    [ACCELERATION_BONUSES.IMPLEMENTATION]: {
        all: implementationGoldIcon,
        some: implementationSilverIcon,
        none: implementationNoneIcon
    },
    [ACCELERATION_BONUSES.MONITORING]: {
        all: monitoringGoldIcon,
        some: monitoringSilverIcon,
        none: monitoringNoneIcon
    },
    [ACCELERATION_BONUSES.REPORTING]: {
        all: reportingGoldIcon,
        some: reportingSilverIcon,
        none: reportingNoneIcon
    }
}

function BonusBadge({active, bonus, tooltip, value}) {
    const {t} = useTranslation('pages/common/board/accounting');

    const icon = useMemo(() => {
        let amount = 'none';

        if (active) {
            const accelerationValues = ACCOUNTING_ESG_CONFIG.ACCELERATION_BONUS_VALUES[bonus];

            if (value === accelerationValues[Object.keys(accelerationValues).at(-1)]) {
                amount = 'all';
            } else if (value > 0) {
                amount = 'some'
            }
        }

        return ICONS[bonus][amount];
    }, [active, value]);


    const tooltipText = useMemo(() => {
        return active && value
            ? tooltip + `: ${t('texts.bonus_gained', {value})}`
            : tooltip;
    }, [active, value])


    return (<OverlayTrigger placement="bottom" overlay={<Tooltip id={tooltipText}>{tooltipText}</Tooltip>}>
        <img src={icon} className="bonus-icon" alt={tooltip}/>
    </OverlayTrigger>);
}

export default React.memo(BonusBadge);
