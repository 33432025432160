import React, {useEffect, useMemo, useState} from "react";
import Select from "react-select";
import CountryList from "data/country_list.json";
import {Trans, useTranslation} from "react-i18next";
import ToastUtil from "utils/ToastUtil";
import PlayerService from "../../../../services/common/PlayerService";
import ButtonSpinner from "../../../../components/ButtonSpinner";
import LoggerService from "../../../../services/common/LoggerService";
import Utils from "../../../../utils/Utils";

const SELECT_STYLE = {
    control: (provided, state) => ({
        ...provided,
        borderColor: state.isFocused ? '#607aac' : '#ced4da',
        boxShadow: state.isFocused ? "0 0 0 0.2rem rgb(48 63 92 / 25%)" : "none",
        "&:hover": {
            borderColor: "",
            boxShadow: ""
        }
    }),
    menuPortal: base => ({...base, zIndex: 9999})
};
export default function PlayerForm({
                                       id,
                                       player,
                                       rootPlayerId,
                                       certificate,
                                       onClose,
                                       disableCertificateForm,
                                       requireCompany = true,
                                       requireCountry = true
                                   }) {

    const {t} = useTranslation('pages/common/player_form', {keyPrefix: 'components.player_form'});
    const tCountries = useTranslation('countries').t;

    const [formId] = useState(Utils.uuid());
    const [nickname, setNickname] = useState(player.nickname ?? '');
    const [name, setName] = useState(certificate?.name ?? '');
    const [email, setEmail] = useState(certificate?.email ?? '');
    const [company, setCompany] = useState(certificate?.company ?? '');
    const [country, setCountry] = useState(certificate?.country ?? '');
    const [position, setPosition] = useState(certificate?.position ?? '');

    const [isLoading, setIsIsLoading] = useState(false);
    const [showNicknameAlreadyUsedError, setShowNicknameAlreadyUsedError] = useState('');
    const [requestCertificate, setRequestCertificate] = useState(false);

    const isRequestedCertificateValid = useMemo(() => {
        return !!name && !!email && !!position && (!requireCountry || !!country) && (!requireCompany || company);
    }, [name, email, company, country, position]);

    const isNicknameValid = useMemo(() => !!nickname, [nickname]);

    const countryOptions = useMemo(() => CountryList.countries.map(c => {
        return {value: tCountries(c, {ns: 'countries'}), label: tCountries(c, {ns: 'countries'})};
    }), []);

    const handleSubmit = async event => {
        event.preventDefault();

        // prevents the app from crashing if action is done by a GM via the Board page
        if (!rootPlayerId)
            return;

        let params = {};

        try {
            const data = {nickname: nickname.trim()};

            const certificate = !disableCertificateForm && (requestCertificate && isRequestedCertificateValid) ? {
                name: name.trim(),
                email: email.trim(),
                company: company.trim(),
                position: position.trim(),
                country: country ?? ''
            } : null;

            if (!data.nickname || data.nickname.length < 3 || data.nickname.length < 4 || data.nickname.length > 20) {
                setNickname(data.nickname);
                ToastUtil.toastDanger(t('toasts.invalid_nickname.title'), t('toasts.invalid_nickname.message'));
                return;
            }

            setIsIsLoading(true);
            setShowNicknameAlreadyUsedError(null);

            params = {player: data};
            if (certificate) params.certificate = certificate;

            const response = await PlayerService.updateData(rootPlayerId, player.id, params);

            if (response.status === true) {
                if (response?.data?.nicknameAlreadyUsed === true) {
                    setShowNicknameAlreadyUsedError(nickname);
                    setIsIsLoading(false);
                } else {
                    ToastUtil.toastSuccess(t('toasts.update_success.title'), t('toasts.update_success.message'));
                    onClose();
                }
            } else if (response?.data?.maxPlayerCountExceeded === true) {
                ToastUtil.toastDanger(t('toasts.max_player_count_exceeded.title'), t('toasts.max_player_count_exceeded.message'));
                setIsIsLoading(false);
            } else {
                ToastUtil.toastDanger(t('toasts.update_error.title'), t('toasts.update_error.message'));
                setIsIsLoading(false);
            }
        } catch (err) {
            LoggerService.error(err, {
                action: '[PlayerForm] - handleSubmit()',
                params: {player, params}
            });

            ToastUtil.toastDanger(t('toasts.update_error.title'), t('toasts.update_error.message'));
            setIsIsLoading(false);
        }
    }

    useEffect(() => {
        setRequestCertificate(!!certificate);
    }, []);

    return (<form onSubmit={handleSubmit} className="animate__animated animate__fadeIn animate__faster">
        <div className="row mb-3">
            <div className="col-12 mb-0 form-inline d-flex">
                <label>{t('inputs.nickname')}</label>
                <input className="form-control flex-fill"
                       type="text"
                       placeholder={t('texts.required')}
                       value={nickname}
                       onChange={event => setNickname(event.target.value)}
                       required/>

                {showNicknameAlreadyUsedError && (
                    <p className="w-100 mb-0 form-text small text-danger font-weight-bold">
                        {t('texts.nickname_already_used', {nickname: showNicknameAlreadyUsedError})}
                    </p>
                )}
            </div>
        </div>

        <div hidden={disableCertificateForm}>
            <div className="form-check mt-3">
                <input className="form-check-input" type="checkbox" checked={requestCertificate}
                       id={"request-certificate-" + formId}
                       onChange={event => setRequestCertificate(event.target.checked)}/>

                <label className="form-check-label" htmlFor={"request-certificate-" + formId}>
                    {t('inputs.request_certificate')}
                </label>
            </div>

            {/*<h6>*/}
            {/*    <Trans t={t} i18nKey={'texts.certificate_form'} components={{1: <span className="text-muted small"/>}}/>*/}
            {/*</h6>*/}

            {requestCertificate && (<div className="animate__animated animate__fadeIn animate__faster mt-1">
                <div className="row">
                    <div className="col-12 col-md-6 mb-2">
                        <input className="form-control"
                               type="text"
                               placeholder={t('inputs.name')}
                               value={name}
                               onChange={event => setName(event.target.value)}
                               disabled={disableCertificateForm}/>
                    </div>

                    <div className="col-12 col-md-6 mb-2">
                        <input className="form-control"
                               type="email"
                               value={email}
                               onChange={event => setEmail(event.target.value)}
                               placeholder={t('inputs.email')}
                               disabled={disableCertificateForm}/>
                    </div>

                    <div className="col-12 col-md-6 mb-2" hidden={!requireCompany}>
                        <input className="form-control"
                               type="text"
                               value={company}
                               onChange={event => setCompany(event.target.value)}
                               placeholder={t('inputs.company')}
                               disabled={disableCertificateForm}/>
                    </div>

                    <div className="col-12 col-md-6 mb-2">
                        <input className="form-control"
                               type="text"
                               value={position}
                               onChange={event => setPosition(event.target.value)}
                               placeholder={t('inputs.position')}
                               disabled={disableCertificateForm}/>
                    </div>

                    <div className="col-12 col-md-6 mb-2" hidden={!requireCountry}>
                        <Select styles={SELECT_STYLE}
                                options={countryOptions}
                                value={country ? {label: country} : null}
                                onChange={event => setCountry(event?.value ?? null)}
                                placeholder={t('inputs.country')}
                                menuPosition="fixed"
                                menuPortalTarget={document.body}
                                isClearable={true}
                                disabled={disableCertificateForm}/>
                    </div>
                </div>

                <p className="form-text small text-muted mt-1 mb-0">
                    <Trans t={t} i18nKey={'texts.certificate_info'} components={{1: <b/>}}/>
                </p>
            </div>)}
        </div>

        <div className="d-flex justify-content-end mt-2 gutters-1">
            <button type="button" className="btn btn-outline btn-sm" disabled={isLoading} onClick={onClose}>
                {t('buttons.cancel')}
            </button>

            <ButtonSpinner disabled={isLoading || !isNicknameValid || (requestCertificate && !isRequestedCertificateValid)} type="submit"
                           className="btn btn-success btn-sm" showAnimation={isLoading}>
                {t('buttons.submit')}
            </ButtonSpinner>
        </div>
    </form>)
}