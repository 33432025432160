export default class StartupCardModel {
    constructor({id, title, sector, problem, solution, businessModel, defaultFounders}) {
        this.id = id;
        this.title = title;
        this.sector = sector;
        this.problem = problem;
        this.solution = solution;
        this.businessModel = businessModel;
        this.defaultFounders = defaultFounders;
    }

    defaultFounders() {
        return [this.defaultFounder1, this.defaultFounder2];
    }
}