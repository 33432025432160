import React from "react";
import AuthService from "services/gameMaster/AuthService";
import store from "redux/store";
import NotFoundError from "pages/common/errors/NotFoundError";
import DemoSessionService from "../../services/common/DemoSessionService";

export default function DemoRoute({children}) {
    const isAuthenticated = AuthService.isAuthenticated();
    const isDemonstration = store.getState().game.isDemonstration;
    const rejoinData = DemoSessionService.getRejoinData();

    if (isAuthenticated && isDemonstration)
        return children;

    if (rejoinData) {
        window.location.href = rejoinData;
        return <></>;
    }

    return (<NotFoundError/>);
}