import React from "react";
import InfoPageLayout from "./InfoPageLayout/InfoPageLayout";
import {useTranslation} from "react-i18next";

export default function SessionWithRestrictedAccessInfo() {
    const {t} = useTranslation('pages/common/session_status', {keyPrefix: 'components.restricted_access.texts'});

    return (
        <InfoPageLayout>
            <h3 className="mb-3">{t('title')}</h3>
            <h3 className="text-warning mb-3 font-weight-bold">{t('subtitle')}</h3>
            <h3 className="mb-3">{t('message')}</h3>
        </InfoPageLayout>
    );
}