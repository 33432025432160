import React, {useMemo} from "react";

import "./ServiceShow.scss";
import i18nUtil from "utils/i18nUtil";
import {useSelector} from "react-redux";
import SwalModalUtil from "../../../../../../utils/SwalModalUtil";
import OverlayUtil from "../../../../../../utils/OverlayUtil";
import CardService from "../../../../../../services/common/CardService";
import ToastUtil from "../../../../../../utils/ToastUtil";
import {useTranslation} from "react-i18next";
import {GAME_PHASES} from "../../../../../../config/CONSTANTS";
import CardAccelerationBar from "../../../../../../components/CardAccelerationBar";

export default function ServiceShow({service, onClose}) {
    const {t} = useTranslation('pages/common/board/services', {keyPrefix: 'components.service_show'});

    const currentPhase = useSelector(state => state.game.currentPhase);
    const isGameMaster = useSelector(state => state.session.isGameMaster);

    const {icon, title, description, area} = service.card;

    const canRefund = useMemo(() => {
        return isGameMaster && currentPhase <= GAME_PHASES.PHASE_3;
    }, [currentPhase, isGameMaster]);


    const handleRefundCard = ev => {
        ev.stopPropagation();

        if (!canRefund) return;

        SwalModalUtil.confirmModal(
            t('confirms.refund.title', {service: title}),
            t('confirms.refund.message'),
            t('confirms.refund.confirm'),
        ).then(async result => {
            if (result) {
                OverlayUtil.toggleLoadingOverlay(true, t('overlays.refunding'));
                const refunded = await CardService.refundService(service.id);
                OverlayUtil.hide();

                if (refunded) {
                    ToastUtil.toastSuccess(
                        t('toasts.refund_success.title'),
                        t('toasts.refund_success.message', {service: title})
                    );

                    onClose();
                } else {
                    ToastUtil.toastDanger(t('toasts.refund_error.title'), t('toasts.refund_error.message'));
                }
            }
        });
    }

    return (
        <div className="service-show p-3 text-white animate__animated animate__fadeInLeft animate__faster">
            <div className="d-flex">
                <img className="skill-face mr-2" src={icon} alt="skill icon"/>
                <div className="d-flex align-items-center skill-title">{title}</div>
            </div>

            <div className="d-flex justify-content-between align-items-start mt-2">
                <div className="skill-area text-dark text-center text-break">{i18nUtil.area(area)}</div>
                <div className="power-rating power-rating-light">
                    <CardAccelerationBar card={service.card}/>
                </div>
            </div>

            <div>
                <p className="mb-0 mt-3 description text-break">{description}</p>
            </div>

            <div className="w-100 text-center mt-3">
                <button type="button" className="btn btn-danger btn-sm  px-2 py-1"
                        onClick={handleRefundCard} hidden={!canRefund}>
                    {t('buttons.refund')}
                </button>
            </div>
        </div>
    );
}