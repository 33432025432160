import i18n from 'i18next';
import {initReactI18next} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpBackend from 'i18next-http-backend';
import ChainedBackend from "i18next-chained-backend";
import LocalStorageBackend from "i18next-localstorage-backend";
import {i18N_VERSION, IS_PRODUCTION_ENV} from "../config/CONSTANTS";

// configs
const DEFAULT_LANGUAGE = 'en';
const SUPPORTED_LANGUAGES = {
    ENGLISH: 'en',
    PORTUGUESE: 'pt',
    SPANISH: 'es',
};


const i18nService = {
    setLanguage(lang) {
        if (lang && lang.startsWith('pt')) {
            lang = 'pt';
        }

        return i18n.changeLanguage(lang);
    },

    getLanguage() {
        return i18n.language;
    },

    isSupportedLanguage(lang) {
        lang = lang.startsWith('pt') ? 'pt' : lang;

        return Object.values(SUPPORTED_LANGUAGES).includes(lang);
    }
}

window.changeLanguage = i18nService.setLanguage;


i18n
    .use(LanguageDetector)
    .use(ChainedBackend)
    .use(initReactI18next)

    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        // debug: !IS_PRODUCTION_ENV,
        debug: false,
        fallbackLng: DEFAULT_LANGUAGE,
        supportedLngs: ["en", 'pt', 'es'],
        defaultNS: 'common',
        ns: ['common', 'pages/common/errors'],
        nonExplicitSupportedLngs: false,
        // nonExplicitSupportedLngs: true,
        interpolation: {
            escapeValue: false,
        },

        detection: {
            order: ['navigator'],
        },

        backend: {
            backends: [
                LocalStorageBackend,
                HttpBackend
            ],
            backendOptions: [
                {
                    defaultVersion: i18N_VERSION,
                    prefix: 'i18n_',
                    // cache for 7 days for prod but not for dev env
                    expirationTime: IS_PRODUCTION_ENV ? 20160000 : 0,
                    // expirationTime:0,
                },
                // {
                //     parse: function (data) {
                //         return JSON.parse(newData);
                //     },
                // }
            ]
        },

        react: {
            useSuspense: true,
            wait: true,
            // possible fix for app breaking because browser extensions change the DOM
            // credits to: https://github.com/i18next/react-i18next/issues/1254#issuecomment-779620515
            defaultTransParent: ({children}) => {
                return <span style={{display: 'inline'}}>{children}</span>
            }
        }
    });

export {SUPPORTED_LANGUAGES, DEFAULT_LANGUAGE, i18nService, i18n, i18n as i18nProvider};




