import React, {useEffect, useMemo, useState} from "react";
import logo from "../../../assets/img/logos/svg/default_2.svg";
import {DEMO_ROUTES, TERMS_ROUTES} from "../../../navigation/ROUTES";

import "./Join.scss";
import {useTranslation} from "react-i18next";
import {useNavigate, useSearchParams} from "react-router-dom";
import {i18nService} from "../../../i18n";
import DemoSessionService from "../../../services/common/DemoSessionService";
import {GAMES} from "../../../config/CONSTANTS";
import store from "../../../redux/store";
import {setHasFatalError} from "../../../redux/slices/sessionSlice";
import OverlayUtil from "../../../utils/OverlayUtil";


export default function Join() {
    const {t, i18n} = useTranslation('pages/demo/join');
    const t2 = useTranslation('common').t;

    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const [language, setLanguage] = useState(i18n.language);
    const now = useMemo(() => (new Date()).getUTCFullYear(), []);

    const handleJoinSmgx = () => join(GAMES.SMGX);
    const handleJoinEsg = () => join(GAMES.ESG);

    const join = async (game) => {
        try {
            OverlayUtil.show();

            await DemoSessionService.join({
                language,
                game,
                sessionId: searchParams.get('sessionId')
            });

            navigate(DEMO_ROUTES.BOARD);
        } catch (err) {
            console.error(err);
            store.dispatch(setHasFatalError(true));
        } finally {
            OverlayUtil.hide();
        }
    }

    useEffect(() => {
        if (i18n.language !== language)
            i18nService.setLanguage(language);
    }, [language])

    useEffect(() => {
        const langParam = searchParams.get('language');

        if (langParam && i18nService.isSupportedLanguage(langParam)) {
            setLanguage(langParam.substring(0, 2));
        }
    }, []);

    return (<div className="min-vw-100 min-vh-100 d-flex flex-column bg-dark text-white join-demo">
        <div
            className="flex-fill d-flex justify-content-center align-items-center flex-column mb-4 pb-5 text-center">
            <img alt="Logo" className="mb-4 pb-4" src={logo}/>

            <p className="text-center">{t('texts.play_for')}</p>

            <select className="text-center" value={language} onChange={ev => setLanguage(ev.target.value)}>
                <option value='pt'>Portugues (PT-BR)</option>
                <option value='en'>English (EN)</option>
                {/*<option value='es'>ES</option>*/}
            </select>

            <div className="d-flex flex-wrap justify-content-center align-items-center mt-3" style={{gap: 15}}>
                <button className="btn btn-secondary" onClick={handleJoinSmgx}>
                    Startup Mundi Game Experience
                </button>

                <button className="btn btn-secondary" onClick={handleJoinEsg}>
                    ESG Game Experience
                </button>
            </div>


            <p className="mx-3 mt-5 mb-1 text-white">
                @ {now}

                <a href="https://www.startupmundi.com.br/" className="text-white text-decoration-underline ml-1"
                   target="_blank" rel="noreferrer">
                    Mundi Game Experiences</a>. {t2('rights_reserved')}
            </p>

            <p className="mb-0 small d-inline">
                <a href={TERMS_ROUTES.TERMS_OF_USE} target="_blank"
                   className="text-white mr-1">{t2('common:terms_of_use')}</a>
                <a href={TERMS_ROUTES.COOKIES_POLICY} target="_blank"
                   className="text-white mr-1">{t2('common:cookies_policy')}</a>
                <a href={TERMS_ROUTES.PRIVACY_POLICY} target="_blank"
                   className="text-white">{t2('common:privacy_policy')}</a>
            </p>
        </div>
    </div>);
}