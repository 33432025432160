import Utils from "../../../../../../../../utils/Utils";
import CardUtil from "../../../../../../../../utils/CardUtil";
import {GAME_STEPS} from "../../../../../../../../config/CONSTANTS";


// -------------------------------- UTILS --------------------------------
function getHiredCardsSortedUntilPitch2(hiredCards) {
    return CardUtil.hiredCardsToArray(hiredCards)
        .filter(x => x.step < GAME_STEPS.PITCH_2)
        .map(x => x.card)
        .sort((a, b) => b.accelerationPhase2 - a.accelerationPhase2);
}

function getHiredCardsSortedAfterPitch2BeforePitch3(hiredCards) {
    return CardUtil.hiredCardsToArray(hiredCards)
        .filter(x => x.step >= GAME_STEPS.PITCH_2 && x.step <= GAME_STEPS.PITCH_3)
        .map(x => x.card)
        .sort((a, b) => b.accelerationPhase3 - a.accelerationPhase3);
}


// -------------------------------- DATA --------------------------------
function getEsgPitch1Script(teamData, nickname) {
    return [
        [
            {
                id: Utils.uuid(),
                message: 'Você criou o grupo "Stakeholder" <br/> Integrantes: Você, CEO, CFO, COO',
                origin: 'center'
            },
            {
                id: Utils.uuid(),
                message: `Olá ${nickname}. Obrigado por abrir esse canal de comunicação.`,
                origin: 'left',
                from: "CEO"
            },
            {
                id: Utils.uuid(),
                message: 'Sabemos da responsabilidade de aceitar nosso convite para liderar a implementação de práticas ESG dentro da nossa empresa.',
                origin: 'left',
                from: "CEO"
            },
            {
                id: Utils.uuid(),
                message: 'Promover a sustentabilidade dentro da instituição é algo que nos motiva a seguir em frente.',
                origin: 'left',
                from: "CEO"
            },
            {
                id: Utils.uuid(),
                message: 'Estamos bastante curiosos para saber como você pretende cumprir essa missão.',
                origin: 'left',
                from: "COO"
            },
            {
                id: Utils.uuid(),
                message: 'Em particular, saber mais sobre suas escolhas de metas prioritárias',
                origin: 'left',
                from: "CEO"
            },
            {
                id: Utils.uuid(),
                message: `Que tal começar falando da primeira meta: <b>${teamData.valuePropositions[0].title}</b> ? Como ela se encaixa no nosso contexto?`,
                origin: 'left',
                from: "COO"
            },
        ],
        [
            {
                id: Utils.uuid(),
                message: 'Interessante sua visão sobre o tema.',
                origin: 'left',
                from: 'CFO'
            },
            {
                id: Utils.uuid(),
                message: 'Ótimo. Parece um caminho promissor.',
                origin: 'left',
                from: 'CEO'
            },
            {
                id: Utils.uuid(),
                message: `E a segunda meta, <b>${teamData?.valuePropositions?.[1]?.title}</b>? Como ela se relaciona ao nosso contexto atual e qual sua ideia ao escolher essa opção?`,
                origin: 'left',
                from: 'CEO'
            }
        ],
        [
            {
                id: Utils.uuid(),
                message: 'Parece que tem já um planejamento de ação para seguir.',
                origin: 'left',
                from: 'CEO'
            },
            {
                id: Utils.uuid(),
                message: 'E que aproveitou bem esses meses iniciais de planejamento.',
                origin: 'left',
                from: 'CEO'
            },
            {
                id: Utils.uuid(),
                message: 'Vamos falar de pessoas, pois são elas que podem transformar ideias em resultados.',
                origin: 'left',
                from: 'CEO'
            },
            {
                id: Utils.uuid(),
                message: `Me conte porque decidiu escolher <b>${teamData?.founders?.[0]?.title}</b>  como parte da sua equipe inicial?`,
                origin: 'left',
                from: 'COO'
            }
        ],
        [
            {
                id: Utils.uuid(),
                message: `E o <b>${teamData?.founders?.[1]?.title}</b>? Qual a importância dessa habilidade dentro do seu planejamento?`,
                origin: 'left',
                from: 'COO'
            }
        ],
        [
            {
                id: Utils.uuid(),
                message: `Ótimo.`,
                origin: 'left',
                from: 'CFO'
            },
            {
                id: Utils.uuid(),
                message: `Já temos agora as informações necessárias para avaliar a liberação de verba para iniciar a fase de ação.`,
                origin: 'left',
                from: 'CFO'
            },
            {
                id: Utils.uuid(),
                message: `Vamos deliberar levando em conta as etapas futuras de implementar, engajar- monitorar e reportar`,
                origin: 'left',
                from: 'CEO'
            },
            {
                id: Utils.uuid(),
                message: `Após a reunião do Comitê Executivo você deve receber um retorno sobre o valor aprovado para que possa seguir com a implementação da sua estratégia.`,
                origin: 'left',
                from: 'CFO'
            },
            {
                id: Utils.uuid(),
                message: `Continuamos confiante com o seu desempenho.`,
                origin: 'left',
                from: 'CEO'
            },
            {
                id: Utils.uuid(),
                message: `Pode contar com minha ajuda para operacionalizar suas ações quando necessário.`,
                origin: 'left',
                from: 'COO'
            },
            {
                id: Utils.uuid(),
                message: `Bom trabalho. Na nossa próxima conversa vamos querer saber dos resultados alcançados. Até mais.`,
                origin: 'left',
                from: 'COO'
            },
            {
                id: Utils.uuid(),
                message: 'Chat finalizado',
                origin: 'center',
                from: 'COO'
            }
        ]
    ];
}

function getEsgPitch2Script(teamData, nickname) {
    const services = getHiredCardsSortedUntilPitch2(teamData.services);
    const skills = getHiredCardsSortedUntilPitch2(teamData.skills);

    const unpredictabilities = Object.values(teamData.unpredictabilities)
        .sort((a, b) => {
            a = a.card;
            b = b.card;

            const valAToUse = Math.abs(a.positiveAccelerationImpact ?? a.negativeAccelerationImpact ?? 0);
            const valBToUse = Math.abs(b.positiveAccelerationImpact ?? b.negativeAccelerationImpact ?? 0);

            return valBToUse - valAToUse;
        });

    const script = [
        [
            {
                id: Utils.uuid(),
                message: 'Você criou o grupo "Stakeholder" <br/> Integrantes: Você, CEO, CFO, COO',
                origin: 'center'
            },
            {
                id: Utils.uuid(),
                message: `Olá ${nickname}, nos encontramos novamente. Estamos aqui curiosos para descobrir quais os resultados que você e a equipe que criou alcançaram na jornada.`,
                origin: 'left',
                from: "CEO"
            },
            {
                id: Utils.uuid(),
                message: 'Acredito que a primeira informação relevante é saber qual o <b>valor do impacto que vocês alcançaram</b>. O que diz o monitor da sua área de trabalho?',
                origin: 'left',
                from: "COO"
            }
        ],
        [
            {
                id: Utils.uuid(),
                message: 'Muito bem, ótimo trabalho. E quantos <b>créditos ESG</b> conseguiram adquirir?',
                origin: 'left',
                from: "CEO"
            }
        ],
        [
            {
                id: Utils.uuid(),
                message: 'Excelente',
                origin: 'left',
                from: "CFO"
            },
            {
                id: Utils.uuid(),
                message: '&#x1F44F;&#x1F44F;&#x1F44F;',
                origin: 'left',
                from: "CEO"
            }

        ],
    ];

    if (services.length > 0) {
        script[2].push({
            id: Utils.uuid(),
            message: `Dentre suas escolhas de implementação, pode nos contar mais sobre <b>${services[0].title}</b>? Qual o papel que esta escolha teve na sua estratégia de ação?`,
            origin: 'left',
            from: "COO"
        });
    } else {
        script[2].push({
            id: Utils.uuid(),
            message: `Vejo aqui que você <b>não contratou nenhum serviço nas etapas anteriores</b>. Por quê?`,
            origin: 'left',
            from: "COO"
        });
    }

    if (services.length > 1) {
        script.push([
            {
                id: Utils.uuid(),
                message: `Agora conte para nós como o serviço <b>${services[1].title}</b> trouxe um impacto positivo na jornada?`,
                origin: 'left',
                from: "COO"
            },
        ]);
    }


    if (skills.length > 0) {
        script.push([
            {
                id: Utils.uuid(),
                message: `Olhando sua equipe, percebemos que algumas posições contribuíram de forma mais efetiva para a transformação de impacto. Qual foi o papel que <b>${skills[0].title}</b> neste contexto?`,
                origin: 'left',
                from: "CEO"
            },
        ]);
    } else {
        script.push([
            {
                id: Utils.uuid(),
                message: `Hmmm, parece que você <b>não contratou nenhum talento nas etapas anteriores</b>. Por quê?`,
                origin: 'left',
                from: "CEO"
            },
        ]);
    }

    if (skills.length > 1) {
        script.push([
            {
                id: Utils.uuid(),
                message: `E como o <b>${skills[1].title}</b> atuou para a consolidar a sustentabilidade na nossa empresa?`,
                origin: 'left',
                from: "CEO"
            },
        ]);
    }

    if (unpredictabilities.length > 0) {
        script.push([
            {
                id: Utils.uuid(),
                message: `Vemos que passou por algumas imprevisibilidades no caminho. Conte para nós como <b>${unpredictabilities[0].card.event}</b> afetou o desempenho.`,
                origin: 'left',
                from: "CFO"
            },
        ]);
    }


    script.push([
        {
            id: Utils.uuid(),
            message: `Agradecemos sua disponibilidade em nos contar os resultados alcançados. Vamos deliberar aqui sobre os valores conquistados e retornaremos em breve..`,
            origin: 'left',
            from: "CFO"
        },
        {
            id: Utils.uuid(),
            message: `Bom trabalho e siga em frente com essa disposição.`,
            origin: 'left',
            from: "COO"
        },
        {
            id: Utils.uuid(),
            message: 'Chat finalizado',
            origin: 'center',
            from: ''
        }
    ]);

    return script;
}

function getSmgxPitch1Script(teamData, nickname) {
    const acceleratorName = 'Raise';
    const startupName = teamData?.startup?.title;

    return [
        [
            {
                id: Utils.uuid(),
                message: 'Raise Aceleradora entrou na conversa',
                origin: 'center'
            },
            {
                id: Utils.uuid(),
                message: `Olá ${nickname}, estamos contentes com sua inscrição no nosso programa de aceleração.`,
                origin: 'left',
                from: acceleratorName
            },
            {
                id: Utils.uuid(),
                message: 'Avaliando sua ficha de inscrição percebemos que já conseguiu validar sua solução junto ao seu público alvo.',
                origin: 'left',
                from: acceleratorName
            },
            {
                id: Utils.uuid(),
                message: 'Isto é um bom motivo para prosseguirmos a nossa conversa e entender melhor qual sua estratégia para validar seu produto no mercado.',
                origin: 'left',
                from: acceleratorName
            },
            {
                id: Utils.uuid(),
                message: `Para iniciar esta entrevista, conte para nós como a <b>${startupName}</b> resolve um problema real para o seu público alvo?`,
                origin: 'left',
                from: acceleratorName
            },
        ],
        [
            {
                id: Utils.uuid(),
                message: 'Interessante.',
                origin: 'left',
                from: acceleratorName
            },
            {
                id: Utils.uuid(),
                message: `E como o diferencial <b>${teamData?.valuePropositions?.[0]?.title}</b> contribui para essa solução?`,
                origin: 'left',
                from: acceleratorName
            }
        ],
        [
            {
                id: Utils.uuid(),
                message: `E o <b>${teamData?.valuePropositions?.[1]?.title}</b> qual a importância dentro do produto?`,
                origin: 'left',
                from: acceleratorName
            }
        ],
        [
            {
                id: Utils.uuid(),
                message: `Parece que a solução apresentada pela ${startupName} tem coerência.`,
                origin: 'left',
                from: acceleratorName
            },
            {
                id: Utils.uuid(),
                message: 'Vamos falar de pessoas, pois são elas que podem transformar ideias em resultados. ',
                origin: 'left',
                from: acceleratorName
            },
            {
                id: Utils.uuid(),
                message: `Qual a contribuição que o co-fundador <b>${teamData?.founders?.[0]?.title}</b> traz para ${startupName}?`,
                origin: 'left',
                from: acceleratorName
            }
        ],
        [
            {
                id: Utils.uuid(),
                message: `E o <b>${teamData?.founders?.[1]?.title}</b>?`,
                origin: 'left',
                from: acceleratorName
            }
        ],
        [
            {
                id: Utils.uuid(),
                message: `Boas escolhas.`,
                origin: 'left',
                from: acceleratorName
            },
            {
                id: Utils.uuid(),
                message: `Nossa equipe vai agora se reunir para uma avaliação final da ${startupName}. Em breve retornaremos com uma mensagem sobre a sua aprovação no nosso programa. Lembrando que o investimento e apoio recebido tem como objetivo validar sua solução no mercado e conquistar os primeiros clientes.`,
                origin: 'left',
                from: acceleratorName
            },
            {
                id: Utils.uuid(),
                message: `Desde já parabéns pelo resultado alcançado até o momento.`,
                origin: 'left',
                from: acceleratorName
            },
            {
                id: Utils.uuid(),
                message: 'Chat finalizado',
                origin: 'center',
                from: acceleratorName
            }
        ]
    ];
}

function getSmgxPitch2Script(teamData, nickname) {
    const acceleratorName = 'Scale VC';
    const startupName = teamData?.startup?.title;

    const services = getHiredCardsSortedUntilPitch2(teamData.services);
    const skills = getHiredCardsSortedUntilPitch2(teamData.skills);

    const script = [
        [
            {
                id: Utils.uuid(),
                message: 'Scale VC entrou na conversa',
                origin: 'center'
            },
            {
                id: Utils.uuid(),
                message: `Olá ${nickname}, estávamos aguardando esta conversa pois percebemos que a ${startupName} vem percorrendo uma jornada de validação de sucesso.`,
                origin: 'left',
                from: acceleratorName
            },
            {
                id: Utils.uuid(),
                message: `Mas vamos aos números. De acordo com a contabilidade da ${startupName}, <b>qual a aceleração</b> que vocês já alcançaram até aqui?`,
                origin: 'left',
                from: acceleratorName
            }
        ],
        [
            {
                id: Utils.uuid(),
                message: 'E qual o <b>faturamento total</b> que esta aceleração trouxe para vocês?',
                origin: 'left',
                from: acceleratorName
            }
        ],
        [
            {
                id: Utils.uuid(),
                message: 'Excelente',
                origin: 'left',
                from: acceleratorName
            },
            {
                id: Utils.uuid(),
                message: '&#x1F44F;&#x1F44F;&#x1F44F;',
                origin: 'left',
                from: acceleratorName
            }

        ],
    ];

    if (services.length > 0) {
        script[2].push({
            id: Utils.uuid(),
            message: `Para atingir este faturamento foi necessário contar com serviços, correto? Pode nos falar como o <b>${services[0].title}</b> contribui para essas métricas?`,
            origin: 'left',
            from: acceleratorName
        });
    } else {
        script[2].push({
            id: Utils.uuid(),
            message: `Vejo aqui que você <b>não contratou nenhum serviço nas etapas anteriores</b>. Por quê?`,
            origin: 'left',
            from: acceleratorName
        });
    }

    if (services.length > 1) {
        script.push([
            {
                id: Utils.uuid(),
                message: `E o <b>${services[1].title}</b> Qual o impacto positivo que ele trouxe para ${startupName}?`,
                origin: 'left',
                from: acceleratorName
            },
        ]);
    }


    if (skills.length > 0) {
        script.push([
            {
                id: Utils.uuid(),
                message: `Olhando sua equipe, percebemos que alguns talentos contribuíram de forma mais efetiva para a aceleração. Qual foi o papel que <b>${skills[0].title}</b> neste contexto?`,
                origin: 'left',
                from: acceleratorName
            },
        ]);
    } else {
        script.push([
            {
                id: Utils.uuid(),
                message: `Hmmm, parece que você <b>não contratou nenhum talento nas etapas anteriores</b>. Por quê?`,
                origin: 'left',
                from: acceleratorName
            },
        ]);
    }

    if (skills.length > 1) {
        script.push([
            {
                id: Utils.uuid(),
                message: `E como o <b>${skills[1].title}</b> está colaborando para o crescimento da ${startupName}`,
                origin: 'left',
                from: acceleratorName
            },
        ]);
    }

    script.push([
        {
            id: Utils.uuid(),
            message: `Agradecemos sua disponibilidade em nos contar os resultados alcançados. Nossa equipe vai se reunir para decidir sobre a liberação de um aporte para que consigam iniciar o processo de escala. Em breve enviaremos uma mensagem com a nossa decisão. `,
            origin: 'left',
            from: acceleratorName
        },
        {
            id: Utils.uuid(),
            message: `Bom trabalho até aqui. Até breve.`,
            origin: 'left',
            from: acceleratorName
        },
        {
            id: Utils.uuid(),
            message: 'Chat finalizado',
            origin: 'center',
            from: ''
        }
    ]);

    return script;
}

function getSmgxPitch3Script(teamData, nickname) {
    const acceleratorName = 'WIG';
    const startupName = teamData?.startup?.title;

    const services = getHiredCardsSortedAfterPitch2BeforePitch3(teamData.services);
    const skills = getHiredCardsSortedAfterPitch2BeforePitch3(teamData.skills);
    const accountingData = teamData.accounting.phases[3].totals;

    const unpredictabilities = Object.values(teamData.unpredictabilities)
        .sort((a, b) => {
            a = a.card;
            b = b.card;

            const valAToUse = Math.abs(a.positiveAccelerationImpact ?? a.negativeAccelerationImpact ?? 0);
            const valBToUse = Math.abs(b.positiveAccelerationImpact ?? b.negativeAccelerationImpact ?? 0);

            return valBToUse - valAToUse;
        });

    let script = [
        [
            {
                id: Utils.uuid(),
                message: 'World Investor Group - WIG entrou na sala',
                origin: 'center'
            },
            {
                id: Utils.uuid(),
                message: `Olá ${nickname}. Estamos aqui para avaliar sua jornada e os resultados alcançados até aqui.`,
                origin: 'left',
                from: acceleratorName
            },
            {
                id: Utils.uuid(),
                message: `Foram ${accountingData.months} meses de trabalho para desenvolver uma solução real, validar essa solução no mercado e preparar os alicerces de escala.`,
                origin: 'left',
                from: acceleratorName
            },
            {
                id: Utils.uuid(),
                message: `Agora é o momento de apresentar os resultados alcançados.`,
                origin: 'left',
                from: acceleratorName
            },
            {
                id: Utils.uuid(),
                message: `Qual o <b>faturamento total</b> alcançado pela ${startupName}?`,
                origin: 'left',
                from: acceleratorName
            },
        ],
    ];

    if (skills.length) {
        const randomCard = Utils.randomFromArray(skills);

        script.push([
            {
                id: Utils.uuid(),
                message: `Mas todo esse trabalho é fruto de estratégias e pessoas. Apresente o papel que o talento <b>${randomCard.title}</b> desempenhou na ${startupName}?`,
                origin: 'left',
                from: acceleratorName
            },
        ]);
    } else {
        script.push([
            {
                id: Utils.uuid(),
                message: `Parece que você <b>não contratou</b> nenhum novo talento na Etapa 3. Por quê?`,
                origin: 'left',
                from: acceleratorName
            },
        ]);
    }

    if (services.length) {
        const randomCard = Utils.randomFromArray(services);

        script.push([
            {
                id: Utils.uuid(),
                message: `Explique melhor qual a estratégia por trás da escolha do serviço <b>${randomCard.title}</b> para alavancar o crescimento da startup?`,
                origin: 'left',
                from: acceleratorName
            },
        ]);
    } else {
        script.push([
            {
                id: Utils.uuid(),
                message: `Parece que você <b>não contratou</b> nenhum novo serviço na Etapa 3. Por quê?`,
                origin: 'left',
                from: acceleratorName
            },
        ]);
    }


    if (unpredictabilities.length > 0) {
        script.push([
            {
                id: Utils.uuid(),
                message: `A ${startupName} soube lidar com as imprevisibilidades da jornada? Como a <b>${unpredictabilities[0].card.event}</b> impactou vocês?`,
                origin: 'left',
                from: acceleratorName
            },
        ]);
    }

    script.push([
        {
            id: Utils.uuid(),
            message: `Nosso comitê de investidores vai analisar os dados de vocês para tomar uma decisão sobre um novo aporte para que possam prosseguir na jornada. Entraremos em contato em breve com novidades.`,
            origin: 'left',
            from: acceleratorName
        },
        {
            id: Utils.uuid(),
            message: ` Bom trabalho.`,
            origin: 'left',
            from: acceleratorName
        },
        {
            id: Utils.uuid(),
            message: 'Chat finalizado',
            origin: 'center',
            from: ''
        }
    ]);

    return script;
}

export {
    getSmgxPitch1Script, getSmgxPitch2Script, getSmgxPitch3Script,
    getEsgPitch1Script, getEsgPitch2Script
};