import React, {useEffect, useMemo, useRef, useState} from "react";
import SimpleBar from "simplebar-react";
import UnpredictabilityCard from "./components/UnpredictabilityCard/UnpredictabilityCard";

import "./GameEvents.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useSelector} from "react-redux";
import GameEventsComponentService from "./services/GameEventsComponentService";
import {GAME_EVENT_TYPES, GAME_STEPS, GAME_STEPS_WITH_PITCH} from "config/CONSTANTS";
import QuestionCardPreview from "./components/QuestionCard/QuestionCardPreview";
import PitchBonusCard from "./components/PitchBonusCard";
import PitchCapitalCard from "./components/PitchCapitalCard";
import SwalModalUtil from "utils/SwalModalUtil";
import i18nUtil from "utils/i18nUtil";
import store from "redux/store";
import CardUtil from "utils/CardUtil";
import PhaseEventSeparator from "./components/PhaseEventSeparator";
import InfoEvent from "./components/InfoEvent";
import {useTranslation} from "react-i18next";
import AudioService from "../../../../../services/common/AudioService";
import {SOUND_EFFECTS} from "../../../../../config/AUDIO_FILES";
import useTranslationForGame from "../../../../../hooks/useTranslationForGame";
import GameUtil from "../../../../../utils/GameUtil";
import ACCOUNTING_SMGX_CONFIG from "../../../../../config/ACCOUNTING_SMGX_CONFIG";
import ACCOUNTING_ESG_CONFIG from "../../../../../config/ACCOUNTING_ESG_CONFIG";

let lastStep = null;
let didRevealPitch3Bonus = false;

export default function GameEvents() {
    const {t} = useTranslation('pages/common/board/game_events');
    const scrollBarRef = useRef();
    const tfg = useTranslationForGame(t);

    const isTournament = useSelector(state => state.game.isTournament);
    const isEsg = useMemo(() => GameUtil.isCurrentGameEsg(), []);
    const showStepChangeTransition = useSelector(state => state.tournament.state.showStepChangeTransition);

    const showBoardForTeam = useSelector(state => state.session.showBoardForTeam);
    const currentStep = useSelector(state => state.game.currentStep);
    const currentTeamAccounting = useSelector(state => state.teams[showBoardForTeam].accounting);

    const team1Questions = useSelector(state => state.teams[1].questions);
    const team2Questions = useSelector(state => state.teams[2].questions);
    const team3Questions = useSelector(state => state.teams[3].questions);
    const team4Questions = useSelector(state => state.teams[4].questions);

    const team1Unpredictability = useSelector(state => state.teams[1].unpredictabilities);
    const team2Unpredictability = useSelector(state => state.teams[2].unpredictabilities);
    const team3Unpredictability = useSelector(state => state.teams[3].unpredictabilities);
    const team4Unpredictability = useSelector(state => state.teams[4].unpredictabilities);

    const team1AccelerationBonuses = useSelector(state => state.teams[1].accelerationBonuses);
    const team2AccelerationBonuses = useSelector(state => state.teams[2].accelerationBonuses);
    const team3AccelerationBonuses = useSelector(state => state.teams[3].accelerationBonuses);
    const team4AccelerationBonuses = useSelector(state => state.teams[4].accelerationBonuses);

    const ranking = useSelector(state => state.game.ranking);

    const [eventsToDisplay, setEventsToDisplay] = useState([]);

    const scrollTo = (pos, withEffect = true) => {
        scrollBarRef.current.getScrollElement().scrollTo({
            left: pos,
            behavior: withEffect ? "smooth" : 'auto'
        });
    };

    const handleScrollToEnd = () => {
        scrollBarRef.current.getScrollElement().scrollLeft = scrollBarRef.current.getScrollElement().scrollWidth;
    };

    const handleScrollLeft = () => {
        const el = scrollBarRef.current.getScrollElement();
        scrollTo(el.scrollLeft - el.offsetWidth);
    };

    const handleScrollRight = () => {
        const el = scrollBarRef.current.getScrollElement();
        scrollTo(el.scrollLeft + el.offsetWidth);
    };

    useEffect(() => {
        setTimeout(() => requestAnimationFrame(handleScrollToEnd));

        // we need to reset the width of the scroll container
        // without doing it, the width stays the same and can cause a visible overflow
        // couldn't find a proper solution for this using the scroll plugin API
        const resizeListener = function (ev) {
            if (scrollBarRef)
                scrollBarRef.current.placeholderEl.style.width = 'auto';
        }

        window.addEventListener('resize', resizeListener);

        return () => window.removeEventListener('resize', resizeListener);
    }, []);

    useEffect(() => {
        setEventsToDisplay(GameEventsComponentService.touchEventsList());
        setTimeout(() => requestAnimationFrame(handleScrollToEnd), 500);
    }, [
        currentTeamAccounting, currentStep, ranking,
        team1Questions, team2Questions, team3Questions, team4Questions,
        team1Unpredictability, team2Unpredictability, team3Unpredictability, team4Unpredictability,
        team1AccelerationBonuses, team2AccelerationBonuses, team3AccelerationBonuses, team4AccelerationBonuses
    ]);


    useEffect(() => {
        if (showStepChangeTransition)
            return;

        if (lastStep && GAME_STEPS_WITH_PITCH.includes(lastStep) && lastStep !== GAME_STEPS.PITCH_3) {
            const accounting = store.getState().teams[showBoardForTeam]?.accounting;
            const phase = CardUtil.getGamePhaseForStep(lastStep);

            if (accounting && accounting.phases && accounting.phases[phase] && accounting.phases[phase].steps &&
                accounting.phases[phase].steps[lastStep] && accounting.phases[phase].steps[lastStep].capital) {

                const investment = i18nUtil.formatMoney(accounting.phases[phase].steps[lastStep].capital);
                let title, message, buttonText;

                if (isTournament) {
                    title = tfg('confirms.tournament_investment_received.' + lastStep.toString() + '.title', {investment});
                    message = tfg('confirms.tournament_investment_received.' + lastStep.toString() + '.message', {investment});
                    buttonText = t('confirms.tournament_investment_received.' + lastStep.toString() + '.confirm_button');
                } else {
                    title = i18nUtil.stepName(lastStep).toUpperCase();
                    message = t('confirms.investment_received.message', {investment});
                    buttonText = t('confirms.investment_received.confirm_button');
                }

                SwalModalUtil.infoModal(
                    title,
                    message,
                    buttonText
                ).then(() => AudioService.play(SOUND_EFFECTS.ACCOUNTING.INVESTMENT_RECEIVED));
            }
        }

        if (lastStep && ranking && ranking[showBoardForTeam]?.show === true && !didRevealPitch3Bonus) {
            const accountingConfig = isEsg
                ? ACCOUNTING_ESG_CONFIG.GAME_PITCH_INVESTMENT[GAME_STEPS.PITCH_2]
                : ACCOUNTING_SMGX_CONFIG.GAME_PITCH_INVESTMENT[GAME_STEPS.PITCH_3];

            const investmentReceived = accountingConfig[ranking[showBoardForTeam].ranking];

            if (investmentReceived > 0) {
                const investment = i18nUtil.formatMoney(investmentReceived);
                let title, message, buttonText;

                if (isTournament) {
                    title = tfg('confirms.tournament_investment_received.' + lastStep.toString() + '.title', {investment});
                    message = tfg('confirms.tournament_investment_received.' + lastStep.toString() + '.message', {investment});
                    buttonText = t('confirms.tournament_investment_received.' + lastStep.toString() + '.confirm_button');
                } else {
                    title = i18nUtil.stepName(lastStep).toUpperCase();
                    message = t('confirms.investment_received.message', {investment});
                    buttonText = t('confirms.investment_received.confirm_button');
                }

                SwalModalUtil
                    .infoModal(title, message, buttonText)
                    .then(() => AudioService.play(SOUND_EFFECTS.ACCOUNTING.INVESTMENT_RECEIVED));

                didRevealPitch3Bonus = true;
            }
        } else if (ranking && ranking[showBoardForTeam]?.show === true) {
            didRevealPitch3Bonus = true;
        }

        lastStep = currentStep;
    }, [currentStep, ranking, showBoardForTeam, showStepChangeTransition, isEsg]);

    return (
        <div className="game-events w-100 ">
            <div className="h-100 px-4 py-3 w-100 d-flex justify-content-between">
                <div className="m-auto">
                    <button type="button" className="btn btn-link p-0 pr-2 mr-2" onClick={handleScrollLeft}>
                        <FontAwesomeIcon icon={['fas', 'caret-left']} className="fa-4x"/>
                    </button>
                </div>

                <SimpleBar ref={scrollBarRef} style={{overflowY: 'visible', height: '100%'}} className="flex-fill">
                    <div className="h-100 d-flex flex-fill">
                        {eventsToDisplay.map((ev) => {
                            return (
                                <div key={ev.id}>
                                    {ev.type === GAME_EVENT_TYPES.INFO && (
                                        <InfoEvent data={ev.data}/>)}

                                    {ev.type === GAME_EVENT_TYPES.PHASE_SEPARATOR && (
                                        <PhaseEventSeparator data={ev.data}/>)}

                                    {ev.type === GAME_EVENT_TYPES.UNPREDICTABILITY && (
                                        <UnpredictabilityCard data={ev.data}/>)}

                                    {ev.type === GAME_EVENT_TYPES.QUESTION && (
                                        <QuestionCardPreview questionData={ev}/>)}

                                    {ev.type === GAME_EVENT_TYPES.PITCH_BONUS && (
                                        <PitchBonusCard data={ev.data}/>)}

                                    {ev.type === GAME_EVENT_TYPES.PITCH_INVESTMENT && (
                                        <PitchCapitalCard data={ev.data}/>)}
                                </div>
                            )
                        })}
                    </div>
                </SimpleBar>

                <div className="d-flex justify-content-center align-items-center position-relative h-100">
                    <button type="button" className="btn btn-link p-0 pl-2 ml-2" onClick={handleScrollRight}>
                        <FontAwesomeIcon icon={['fas', 'caret-right']} className="fa-4x"/>
                    </button>
                </div>
            </div>
        </div>
    );
}