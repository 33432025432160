import React from "react";
import "./ButtonSpinner.scss";

export default function ButtonSpinner(
    {
        children,
        type = 'button',
        className = 'btn btn-primary',
        showAnimation = false,
        disabled = false,
        onClick,
    }) {

    const claz = className + ' animated ' + (showAnimation ? 'animation-active' : '');

    return (
        <button type={type} className={claz} onClick={onClick} disabled={showAnimation || disabled}>
            <span className="content">
                {children}
            </span>
        </button>
    );
}