import "./i18nLoadingOverlay.scss";

import {useEffect} from "react";
import OverlayUtil from "../utils/OverlayUtil";
import {OVERLAY_THEME} from "../config/CONSTANTS";

export default function I18nLoadingOverlay() {
    useEffect(() => {
        document.body.classList.add('bg-dark');
        document.body.classList.remove('bg-white');

        OverlayUtil.toggleLoadingOverlay(true, false, OVERLAY_THEME.DEFAULT, false);

        return () => {
            document.body.classList.remove('bg-dark');
            document.body.classList.add('bg-white');

            OverlayUtil.toggleLoadingOverlay(false);
        }
    }, [])

    return (<></>);
}