import React, {memo, useMemo} from "react";
import GameCardLayout from "components/cards/layouts/GameCardLayout";

import "./GameEventCardLayout.scss";
import SimpleBar from "simplebar-react";
import {useTranslation} from "react-i18next";

const GameEventCardLayout = ({color = 'primary', title, leftContent, rightContent = null, onClick}) => {
    const {t} = useTranslation('pages/common/board/game_events', {keyPrefix: 'components.game_events_card_layout'});

    const cardClass = useMemo(() => {
        let clasz = 'border-' + color;

        if (!title)
            clasz += ' full';

        return clasz;
    }, [title, color]);

    return (
        <GameCardLayout>
            <div className="d-flex animate__animated animate__fast animate__bounceIn">
                <div className={"box game-event-card bg-white " + cardClass}>
                    {onClick && (
                        <button className="btn btn-sm btn-link p-0" style={{position: 'absolute', top: 2, right: 10}}
                                onClick={onClick}>
                            {t('buttons.details')}
                        </button>
                    )}

                    {title && (
                        <div className={"box-head text-uppercase bg-" + color}>
                            {title}
                        </div>
                    )}


                    <span className="clearfix"/>

                    <div className="w-100 d-flex justify-content-center align-items-center right-card-content">
                        <SimpleBar style={{maxHeight: title ? 115 : 145}} className="w-100">
                            <div
                                className="game-event-card-content text-center d-flex align-items-center justify-content-center w-100 text-break">
                                {leftContent}
                            </div>
                        </SimpleBar>
                    </div>
                </div>

                {rightContent && (
                    <div className={"box game-event-card game-event-card-right pt-1 border-" + color + ' bg-' + color}>
                        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                            <SimpleBar style={{maxHeight: 145}} className="w-100">
                                <div
                                    className="game-event-card-content flex-column text-center d-flex align-items-center justify-content-center w-100">
                                    {rightContent}
                                </div>
                            </SimpleBar>
                        </div>
                    </div>
                )}
            </div>
        </GameCardLayout>
    );
};

export default memo(GameEventCardLayout);