import React from "react";
import {useTranslation} from "react-i18next";

export default function Status({index, title, status}){
    const {t} = useTranslation('pages/public/verify_connection');

    return (<div className="d-flex flex-column flex-md-row mb-2 mb-md-0">
        <p className="pr-1 mb-0 font-weight-bold">
          {title}
        </p>

        <p className={'mb-0 text-' + status.status}>
            {status.message ?? t('status.not_tested')}
        </p>
    </div>)
}