import React, {useEffect} from "react";

import {GAME_MASTER_ROUTES, PLAYER_ROUTES} from "../../../navigation/ROUTES";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

export default function SoftReload() {
    const navigate = useNavigate();
    const isGameMaster = useSelector(state => state.session.isGameMaster);

    useEffect(() => {
        navigate(isGameMaster ? GAME_MASTER_ROUTES.CONTROL_PANEL : PLAYER_ROUTES.LOBBY);
    }, []);

    return (
        <p>If you are seeing this, please manually reload the page to continue</p>
    );
}