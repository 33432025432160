import React from "react";
import "./SummaryCardLayout.scss";
import "./SmgxCardLayout.scss";

export default function SummaryCardLayout({children}) {
    return (
        <div className="smgx-card board-card-preview">
            {children}
        </div>
    );
}