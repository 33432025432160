import React, {useEffect, useMemo, useState} from "react";
import {Button, Modal} from "react-bootstrap";
import {useSelector} from "react-redux";
import ToastUtil from "../../../../../../utils/ToastUtil";
import ButtonSpinner from "../../../../../../components/ButtonSpinner";
import GameService from "../../../../../../services/gameMaster/GameService";
import OverlayUtil from "../../../../../../utils/OverlayUtil";
import {useTranslation} from "react-i18next";

export default function UpdateMaxPlayerCountModal({onClose}) {
    const {t} = useTranslation('pages/game_master/control_panel/player_list', {keyPrefix: 'components.update_max_player_count_modal'});

    const maxPlayerCount = useSelector(state => state.game.maxPlayerCount);
    const [newMaxPlayerCount, setNewMaxPlayerCount] = useState(0);
    const [availableCredits, setAvailableCredits] = useState(0);
    const [formError, setFormError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const playerLimit = useMemo(() => maxPlayerCount + availableCredits, [maxPlayerCount, availableCredits]);


    const handleSubmit = async () => {
        setFormError(false);

        if (newMaxPlayerCount < maxPlayerCount || newMaxPlayerCount > playerLimit) {
            setFormError(t('texts.value_range_info', {min: maxPlayerCount, max: playerLimit}));
            return;
        }

        if (newMaxPlayerCount === maxPlayerCount) {
            ToastUtil.toastSuccess(t('toasts.update_success.title'), t('toasts.update_success.message'));
            onClose();
            return;
        }

        setIsLoading(true);

        const params = {maxPlayerCount: newMaxPlayerCount};

        try {
            if (await GameService.updateMaxPlayerCount(params)) {
                ToastUtil.toastSuccess(t('toasts.update_success.title'), t('toasts.update_success.message'));
                onClose();
            } else {
                ToastUtil.toastDanger(t('toasts.update_error.title'), t('toasts.update_error.message'));
                setIsLoading(false);
            }
        } catch (err) {
            ToastUtil.toastDanger(t('toasts.update_error.title'), t('toasts.update_error.message'));
            setIsLoading(false);
        }
    }

    useEffect(() => {
        const fetchAvailableCredits = async () => {
            try {
                const availablePlayerCredits = await GameService.getAvailablePlayerCredits();

                if (availablePlayerCredits !== false) {
                    setAvailableCredits(availablePlayerCredits);
                } else {
                    ToastUtil.toastDanger(t('toasts.update_error.title'), t('toasts.update_error.message'));
                    onClose();
                }
            } finally {
                OverlayUtil.hide();
            }
        }

        OverlayUtil.show();

        setNewMaxPlayerCount(maxPlayerCount);
        fetchAvailableCredits();
    }, []);


    return (
        <Modal show={true} onHide={onClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{t('texts.title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className="mb-1"><b>{t('texts.limit_info.title')}</b></p>
                <p className="mb-3">{t('texts.limit_info.subtitle')}</p>

                <hr/>
                <p className="font-weight-bold mb-0">{t('texts.limit_info.section_1_title')}</p>
                <p className="mb-2">{t('texts.limit_info.section_1_text_1')}</p>
                <p>{t('texts.limit_info.section_1_text_2')}</p>

                <hr/>
                <p className="font-weight-bold mb-0">{t('texts.limit_info.section_2_title')}</p>
                <p className="mb-4">{t('texts.limit_info.section_2_text_1')}</p>

                <p className="mb-1 font-weight-bold">{t('texts.credits_available', {count: availableCredits})}</p>

                <div className="form-group">
                    <label className="required">{t('texts.new_max_player_count')}</label>
                    <input className="form-control" type="number"
                           min={maxPlayerCount}
                           max={playerLimit}
                           step={1}
                           value={newMaxPlayerCount}
                           onChange={(ev) => setNewMaxPlayerCount(ev.target.valueAsNumber)}/>

                    <p className="small form-text">
                        {t('texts.new_limit_info', {
                            currentLimit: maxPlayerCount,
                            min: maxPlayerCount,
                            max: playerLimit
                        })}
                    </p>
                </div>

                {formError && (
                    <p className="text-danger font-weight-bold animate__animated animate__fadeInDown animate__faster">
                        {formError}
                    </p>
                )}
            </Modal.Body>

            <Modal.Footer>
                <ButtonSpinner className="btn btn-primary" showAnimation={isLoading} onClick={handleSubmit}>
                    {t('buttons.submit')}
                </ButtonSpinner>

                <Button variant="secondary" onClick={onClose}>
                    {t('buttons.close')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}