import React from "react";
import {useSelector} from "react-redux";

import "./Messages.scss";
import "components/cards/layouts/SmgxCardLayout.scss";

import SimpleBar from "simplebar-react";
import Message from "./components/Message";
import {useTranslation} from "react-i18next";

export default function Messages() {
    const {t} = useTranslation(['pages/player/lobby'], {keyPrefix: 'components.messages'});
    const messages = useSelector(state => state.messages.messages);

    return (
        <div className="smgx-card w-100 messages">
            <div
                className="box d-inline-block border-warning w-100 h-100">
                <div className="box-head bg-warning text-uppercase">
                    {t('texts.box_header')}
                </div>
                <p className="small float-right mr-2 mt-1 text-info text-uppercase">
                    {t('texts.latest_on_top')}
                </p>

                <div className="box-content mr-0">
                    <SimpleBar style={{maxHeight: 540}} className="simplebar pr-2">
                        {messages.map((value, index) => (<Message key={index} message={value}/>))}
                    </SimpleBar>
                </div>
            </div>
        </div>
    );
}