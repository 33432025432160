import {CARD_AREA, CARD_AREA_ESG, GAME_PHASES, GAME_STEPS} from "../config/CONSTANTS";
import {i18n, SUPPORTED_LANGUAGES} from "../i18n";
import store from "../redux/store";
import GameUtil from "./GameUtil";

const LANG = 'eng';
const t = i18n.getFixedT(null, 'common');

const I18nUtil = {
    formatMoney(amount) {
        return (new Intl.NumberFormat(LANG, {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        })).format(amount);
    },

    formatPercent(amount) {
        return (new Intl.NumberFormat(LANG, {
            style: 'percent',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        })).format(amount / 100);
    },

    formatDecimal(amount) {
        return (new Intl.NumberFormat(LANG, {
            style: 'decimal',
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
        })).format(amount);
    },

    stepName(step) {
        const customName = store.getState().game.customization?.steps?.[step.toString()] ?? false;

        if (customName)
            return customName;

        const prefix = GameUtil.isCurrentGameEsg() ? 'game_steps_esg' : 'game_steps';
        const translateStep = (key) => t(prefix + '.' + key);

        switch (step) {
            // phase 1
            case GAME_STEPS.ONBOARDING:
                return translateStep('onboarding');
            case GAME_STEPS.CONCEPTION:
                return translateStep('conception');
            case GAME_STEPS.MVP:
                return translateStep('mvp');
            case GAME_STEPS.PITCH_1:
                return translateStep('pitch_1');

            // phase 2
            case GAME_STEPS.BETA:
                return translateStep('beta');
            case GAME_STEPS.GTM:
                return translateStep('gtm');
            case GAME_STEPS.GROWTH_MODEL:
                return translateStep('growth_model');
            case GAME_STEPS.PITCH_2:
                return translateStep('pitch_2');

            // phase 3
            case GAME_STEPS.SALES_MACHINE:
                return translateStep('sales_machine');
            case GAME_STEPS.CUSTOMER_SUCCESS:
                return translateStep('customer_success');
            case GAME_STEPS.PRODUCT_SCALABILITY:
                return translateStep('product_scalability');
            case GAME_STEPS.PITCH_3:
                return translateStep('pitch_3');
            case GAME_STEPS.END_GAME:
                return translateStep('reveal_winners');
            default:
                return '';
        }
    },

    phaseName(phase) {
        switch (phase) {
            case GAME_PHASES.PHASE_1:
                return t('game_phases.phase_1');
            case GAME_PHASES.PHASE_2:
                return t('game_phases.phase_2');
            case GAME_PHASES.PHASE_3:
                return t('game_phases.phase_3');
            default:
                return t('game_phases.end_game');
        }
    },

    ordinalSuffix(number) {
        if (store.getState().session.lang !== SUPPORTED_LANGUAGES.ENGLISH)
            return number + 'º';

        const j = number % 10, k = number % 100;
        if (j === 1 && k !== 11) return number + "st";
        if (j === 2 && k !== 12) return number + "nd";
        if (j === 3 && k !== 13) return number + "rd";
        return number + "th";
    },

    area(area) {
        switch (area) {
            // smgx
            case CARD_AREA.OPERATIONAL:
                return t('game_areas.operational');
            case CARD_AREA.BUSINESS:
                return t('game_areas.business');
            case CARD_AREA.CUSTOMER_SUCCESS:
                return t('game_areas.customer_success');
            case CARD_AREA.PRODUCT:
                return t('game_areas.product');

            //esg
            case CARD_AREA_ESG.INNOVATION:
                return t('game_areas_esg.innovation');
            case CARD_AREA_ESG.ENGAGEMENT:
                return t('game_areas_esg.engagement');
            case CARD_AREA_ESG.MANAGEMENT:
                return t('game_areas_esg.management');
            case CARD_AREA_ESG.SUSTAINABILITY:
                return t('game_areas_esg.sustainability');

            default:
                return area;
        }
    },

    translateForGame(t, key, params = {}) {
        return GameUtil.isCurrentGameEsg() ? t(key + '_esg', params) : t(key, params);
    }
};


export default I18nUtil;