import ApiService from "../common/api/ApiService";
import {API_ENDPOINTS} from "config/API_CONSTANTS";

const PlayerService = {
    async updateData(playerId, localPlayerId, data) {
        return ApiService.post(API_ENDPOINTS.USERS.UPDATE, {data, playerId, localPlayerId})
            .catch(() => false);
    },

    async updatePlayerTeam(playerId, data) {
        return ApiService.post(API_ENDPOINTS.USERS.UPDATE_TEAM, {data, playerId})
            .then(response => response.status === true)
            .catch(() => false);
    },

    async getNpsAndCertificates(playerId) {
        return ApiService.post(API_ENDPOINTS.USERS.GET_CERTIFICATE_AND_NPS_DATA, {playerId})
            .then(response => response.status === true ? response.data : false)
            .catch(() => false);
    },

    async updateNps(playerId, localPlayerId, data) {
        return ApiService.post(API_ENDPOINTS.USERS.UPDATE_NPS, {data, playerId, localPlayerId})
            .then(response => response.status === true)
            .catch(() => false);
    },

    async removeLocalPlayer(playerId, localPlayerId) {
        return ApiService.post(API_ENDPOINTS.USERS.REMOVE_LOCAL_PLAYER, {playerId, localPlayerId})
            .then(response => response.status === true ? response.data : false)
            .catch(() => false);
    },

    // gm only
    async remove(playerId) {
        return ApiService.post(API_ENDPOINTS.USERS.REMOVE_PLAYER, {playerId})
            .then(response => response.status === true)
            .catch(() => false);
    },

    async kick(playerId) {
        return ApiService.post(API_ENDPOINTS.USERS.KICK, {playerId})
            .then(response => response.status === true)
            .catch(() => false);
    }
};

export default PlayerService;