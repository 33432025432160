import React, {useEffect, useMemo, useState} from "react";

import answerCorrectIcon from "assets/img/board/ic_ans_correct_big.svg";
import answerIncorrectIcon from "assets/img/board/ic_ans_wrong_big.svg";

import "./QuestionAnswerModal.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {useSelector} from "react-redux";
import GameService from "services/gameMaster/GameService";
import ToastUtil from "utils/ToastUtil";
import CardUtil from "utils/CardUtil";
import LoggerService from "services/common/LoggerService";
import {useTranslation} from "react-i18next";
import {GAME_STEPS} from "config/CONSTANTS";
import QuestionCard from "../../../../../../../components/cards/QuestionCard";
import {Portal} from "react-portal";
import EventsUtil from "../../../../../../../utils/EventsUtil";
import {GAME_EVENTS} from "../../../../../../../config/EVENTS";
import AudioService from "../../../../../../../services/common/AudioService";
import {SOUND_EFFECTS} from "../../../../../../../config/AUDIO_FILES";
import GameUtil from "../../../../../../../utils/GameUtil";
import QuestionExplanationCard from "../../../../../../../components/cards/QuestionExplanationCard";


export default function QuestionAnswerModal({onClose, step}) {
    const {t} = useTranslation('pages/game_master/control_panel/game_control', {keyPrefix: "components.question_answer_modal"});
    const t2 = useTranslation('common').t;

    const currentStep = useSelector(state => state.game.currentStep);
    const startups = useSelector(state => state.teams.startups);
    const teamCount = useSelector(state => state.game.teamCount);

    const team1Questions = useSelector(state => state.teams[1].questions[step]);
    const team2Questions = useSelector(state => state.teams[2].questions[step]);
    const team3Questions = useSelector(state => state.teams[3].questions[step]);
    const team4Questions = useSelector(state => state.teams[4].questions[step]);

    const [questions, setQuestions] = useState([]);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [resultToShowForQuestions, setResultToShowForQuestions] = useState({});
    const [isPlayingDrumRoll, setIsPlayingDrumRoll] = useState(false);
    const [showExplanation, setShowExplanation] = useState(false);

    const isEsg = useMemo(() => GameUtil.isCurrentGameEsg(), []);

    const showExplanationButton = useMemo(() => {
        return !!questions?.[currentQuestion]?.question?.card?.answers?.[0].explanation
            && (!questions?.[currentQuestion]?.isDesignatedToATeam || questions?.[currentQuestion]?.question?.showResult)
    }, [currentQuestion, questions]);

    useEffect(() => {
        const handleEsc = (event) => {
            if (event.keyCode === 27) {
                onClose();
            }
        };

        window.addEventListener('keydown', handleEsc);

        return () => {
            EventsUtil.unsubscribe(GAME_EVENTS.QUESTION_CARD_UPDATED, questionCardAnswerRevealedListener);
            window.removeEventListener('keydown', handleEsc);
        };
    }, []);

    const handleShowNextChallenge = () => {
        if (currentQuestion === questions.length - 1) {
            setCurrentQuestion(0);
        } else {
            setCurrentQuestion(currentQuestion + 1);
        }
    };

    const handleShowPreviousChallenge = () => {
        if (currentQuestion === 0) {
            setCurrentQuestion(questions.length - 1);
        } else {
            setCurrentQuestion(currentQuestion - 1);
        }
    };

    const handleShowQuestion = (questionNumber) => {
        setCurrentQuestion(questionNumber);
        setSelectedOptions([]);
    };

    const questionCardAnswerRevealedListener = async (data) => {
        const question = questions[currentQuestion];
        if (!question || question.question.card.id !== data.card.id) return;

        if (question.team <= teamCount || resultToShowForQuestions[question.question.card.id]?.submittedAnswers?.length || selectedOptions.length) {
            const isCorrect = data.isCorrect || (resultToShowForQuestions[question.question.card.id]?.isCorrect !== undefined
                ? resultToShowForQuestions[question.question.card.id]?.isCorrect
                : CardUtil.isAnswerCorrect(question.question.card.answers, selectedOptions));

            const audio = await AudioService.queue(isCorrect ? SOUND_EFFECTS.QUESTIONS.RIGHT_ANSWER : SOUND_EFFECTS.QUESTIONS.WRONG_ANSWER);
            audio.play();
        }
    }

    const handleRevealAnswer = () => {
        const question = questions[currentQuestion];
        if (!question) return;

        setIsLoading(true);

        EventsUtil.subscribe(GAME_EVENTS.QUESTION_CARD_UPDATED, questionCardAnswerRevealedListener);

        if (question.team <= teamCount || selectedOptions.length) {
            setIsPlayingDrumRoll(true);

            const audio = AudioService.createAudio(SOUND_EFFECTS.QUESTIONS.DRUM_ROLL);
            audio.addEventListener('ended', () => {
                setIsPlayingDrumRoll(false);
            });

            audio.play();
        }

        const isCorrect = question.team <= teamCount
            ? CardUtil.isAssignedQuestionAnswerCorrect(question.question)
            : false;

        const params = {
            isCorrect: isCorrect,
            team: question.team,
            step
        };

        GameService.revealQuestion(params).then(result => {
            if (result === true) {
                if (question.team > teamCount && selectedOptions.length > 0) {
                    setSelectedOptions([]);
                    setResultToShowForQuestions({
                        ...resultToShowForQuestions,
                        [question.question.card.id]: {
                            isCorrect: CardUtil.isAnswerCorrect(question.question.card.answers, selectedOptions),
                            submittedAnswers: selectedOptions
                        }
                    });
                }
            } else {
                ToastUtil.toastDanger(t('toasts.reveal_question_error.title'), t('toasts.reveal_question_error.message'));
            }
        }).catch(reason => {
            console.error('Could not reveal the answer');
            console.error(reason);
            ToastUtil.toastDanger(t('toasts.reveal_question_error.title'), t('toasts.reveal_question_error.message'));

            LoggerService.error(reason, {
                action: 'reveal answer - control panel',
                params
            });
        }).finally(() => {
            setIsLoading(false);
        });
    };

    const pagination = useMemo(() => {
        const res = [];

        questions.forEach((q, index) => {
            const revealed = q?.question?.showResult === true;

            res.push(
                <h4 key={index}
                    className={"mb-0 mx-2 question-option " + (currentQuestion === index ? 'font-weight-bold text-danger' : 'text-light')}
                    onClick={() => handleShowQuestion(index)}>
                    {revealed && (
                        <FontAwesomeIcon icon={['fas', 'check']} className="mr-1" style={{fontSize: '50%'}}/>)}
                    {index + 1}
                </h4>
            );
        });

        return res;
    }, [currentQuestion, questions]);

    useEffect(() => {
        const teamsQuestions = [team1Questions, team2Questions, team3Questions, team4Questions];
        let allQuestions = [];

        teamsQuestions.forEach((assignedQuestion, index) => {
            if (!assignedQuestion) return;

            const team = index + 1;
            allQuestions.push({
                team: team,
                startup: startups[team],
                question: assignedQuestion,
                isDesignatedToATeam: team <= teamCount
            })
        });

        if (isEsg) {
            allQuestions = allQuestions.sort((a, b) => a.team - b.team);
        } else {
            allQuestions = allQuestions.sort((a, b) => {
                return a.isDesignatedToATeam - -b.isDesignatedToATeam
                    || a.question.card.order - b.question.card.order;
            });
        }

        setQuestions(allQuestions);

        if (currentQuestion > allQuestions.length)
            setCurrentQuestion(0);
    }, [isEsg, currentQuestion, startups, team1Questions, team2Questions, team3Questions, team4Questions]);

    useEffect(() => {
        new Audio(SOUND_EFFECTS.QUESTIONS.DRUM_ROLL);
    }, []);

    useEffect(() => {
        setShowExplanation(false);
    }, [currentQuestion]);

    return (<Portal>
        <div className={"question-answer-modal d-flex justify-content-lg-center pt-xxl-4 pt-md-2 px-lg-4 px-2 show"}>
            <div className="container-custom h-100 w-100">
                <div className="w-100 text-right">
                    <button type="button" className="btn btn-link float-right text-white " onClick={onClose}>
                        <FontAwesomeIcon icon={['fas', 'times']} className={'fa-2x'}/>
                    </button>
                </div>

                <div className="content-container d-flex w-100 justify-content-center">
                    {questions[currentQuestion] && (<>

                        <div className="question-container animate__animated animate__faster animate__slideInLeft">
                            {!showExplanation && (
                                <div
                                    className='w-100 h-100 animate__animated animate__faster animate__fadeInDown d-flex justify-content-center'>
                                    {questions.length > 0 && (
                                        <QuestionCard questionCard={questions[currentQuestion].question.card}
                                                      showResult={questions[currentQuestion].question.showResult}
                                                      showOutcome={questions[currentQuestion].isDesignatedToATeam
                                                          || resultToShowForQuestions[questions[currentQuestion].question.card.id] !== undefined}
                                                      isCorrect={questions[currentQuestion].isDesignatedToATeam
                                                          ? questions[currentQuestion].question.isCorrect
                                                          : (resultToShowForQuestions[questions[currentQuestion].question.card.id]?.isCorrect ?? true)}
                                                      isLoading={isLoading || isPlayingDrumRoll}
                                                      showSubmitButton={false}
                                                      answarable={!questions[currentQuestion].isDesignatedToATeam}
                                                      isDesignatedToATeam={questions[currentQuestion].isDesignatedToATeam}
                                                      onSelectedOptionsUpdate={setSelectedOptions}
                                                      selectedOptions={questions[currentQuestion].isDesignatedToATeam
                                                          ? questions[currentQuestion].question.answer
                                                          : (questions[currentQuestion].question.showResult
                                                              ? resultToShowForQuestions[questions[currentQuestion].question.card.id]?.submittedAnswers
                                                              : selectedOptions)}
                                                      submittedOptions={questions[currentQuestion].isDesignatedToATeam ? questions[currentQuestion].question.answer : selectedOptions}/>
                                    )}
                                </div>
                            )}

                            {showExplanation && (
                                <div
                                    className='w-100 h-100 animate__animated animate__faster animate__fadeInDown d-flex justify-content-center'>
                                    <QuestionExplanationCard
                                        questionCard={questions[currentQuestion].question.card}/>
                                </div>
                            )}
                        </div>

                        <div
                            className="d-flex flex-column h-100 ml-5 animate__animated animate__faster animate__slideInRight">

                            <div className="w-100 d-flex justify-content-center mt-3">
                                <button type="button" className="btn btn-primary bg-transparent border-0 py-0 px-2"
                                        onClick={handleShowPreviousChallenge}>
                                    <OverlayTrigger placement="bottom"
                                                    overlay={<Tooltip>{t('buttons.previous')}</Tooltip>}>
                                        <FontAwesomeIcon icon={['fas', 'angle-left']} className="fa-2x"/>
                                    </OverlayTrigger>
                                </button>

                                {pagination}

                                <button type="button" className="btn btn-primary bg-transparent border-0 py-0 px-2"
                                        onClick={handleShowNextChallenge}>
                                    <OverlayTrigger placement="bottom"
                                                    overlay={<Tooltip>{t('buttons.next')}</Tooltip>}>
                                        <FontAwesomeIcon icon={['fas', 'angle-right']} className="fa-2x"/>
                                    </OverlayTrigger>
                                </button>
                            </div>

                            {(questions[currentQuestion]?.question?.showResult !== true || isLoading || isPlayingDrumRoll) && (
                                <div
                                    className="box box-answer-result border-0 bg-transparent mt-4">
                                    <button type="button" className="btn btn-info btn-lg btn-reveal-answer"
                                            onClick={handleRevealAnswer}
                                            disabled={isLoading || isPlayingDrumRoll || currentStep === GAME_STEPS.END_GAME}>
                                        {t('buttons.reveal')}
                                    </button>
                                </div>
                            )}


                            {!isLoading && !isPlayingDrumRoll && questions[currentQuestion]?.question?.showResult === true
                                && (questions[currentQuestion].isDesignatedToATeam ? true : resultToShowForQuestions[questions[currentQuestion].question.card.id]?.isCorrect) !== undefined
                                && (<div
                                    className="box box-answer-result border-0 bg-transparent mt-4 animate__animated animate__faster animate__fadeIn d-flex justify-content-center">

                                    <img
                                        src={(questions[currentQuestion].isDesignatedToATeam && questions[currentQuestion].question.isCorrect)
                                        || resultToShowForQuestions[questions[currentQuestion].question.card.id]?.isCorrect
                                            ? answerCorrectIcon : answerIncorrectIcon}/>
                                </div>)}

                            {!isLoading && !isPlayingDrumRoll && questions[currentQuestion]?.question?.showResult === true
                                && !questions[currentQuestion].isDesignatedToATeam && resultToShowForQuestions[questions[currentQuestion].question.card.id]?.isCorrect === undefined
                                && (<div className="box box-answer-result border-0 bg-transparent mt-4">
                                    <button type="button" className="btn btn-info btn-lg btn-reveal-answer"
                                            disabled={true}>
                                        {t('buttons.revealed')}
                                    </button>
                                </div>)}


                            <div className={"team-title text-white mt-4 pt-2 text-center text-break"}>
                                <h2 className="mb-1">
                                    {teamCount >= questions[currentQuestion].team && t('texts.team', {team: questions[currentQuestion].team})}
                                </h2>
                                <h4>
                                    {teamCount >= questions[currentQuestion].team && questions[currentQuestion].startup?.title}
                                </h4>
                            </div>

                            {showExplanationButton && (
                                <div className="text-center mt-2">
                                    <button className="btn btn-sm btn-outline-secondary  mt-3"
                                            onClick={() => setShowExplanation(!showExplanation)}
                                            disabled={isLoading}>

                                        {showExplanation && t2('show_question')}
                                        {!showExplanation && t2('show_explanation')}
                                    </button>
                                </div>
                            )}
                        </div>
                    </>)}
                </div>
            </div>
        </div>
    </Portal>);
}