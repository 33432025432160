import React, {useMemo, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import BugReportModal from "../../../common/BugReportModal/BugReportModal";
import {useTranslation} from "react-i18next";
import {TERMS_ROUTES} from "../../../../navigation/ROUTES";
import AdjustVolumeModal from "../../../common/AdjustVolumeModal/AdjustVolumeModal";
import {useSelector} from "react-redux";

export default function Footer() {
    const {t} = useTranslation('common');
    const now = (new Date()).getUTCFullYear();

    const volumeConfig = useSelector(state => state.session.volumeConfig);

    const [showBugReportModal, setShowBugReportModal] = useState(false);
    const handleShowBugReportModal = () => setShowBugReportModal(true);
    const handleCloseBugReportModal = () => setShowBugReportModal(false);

    const [showAdjustVolumeModal, setShowAdjustVolumeModal] = useState(false);
    const handleShowAdjustVolumeModal = () => setShowAdjustVolumeModal(true);
    const handleCloseAdjustVolumeModal = () => setShowAdjustVolumeModal(false);

    const volumeButtonStyle = useMemo(() => {
        const hasVolume = !volumeConfig.isMuted && volumeConfig.volume > 0;

        return {
            class: hasVolume ? 'btn-outline-light text-muted' : 'btn-outline-danger',
            valueToDisplay: (!volumeConfig.isMuted ? Math.floor(volumeConfig.volume * 100) : 0),
            icon: hasVolume ? 'volume-up' : 'volume-mute'
        }
    }, [volumeConfig]);

    return (
        <>
            <footer id="app-footer" className=" text-center text-muted w-100 py-1">
                <p className="mb-0 p-0 small d-inline">
                    @ {now}

                    <a href="https://www.startupmundi.com.br/" className="text-muted text-decoration-underline ml-1"
                       target="_blank" rel="noreferrer">
                        {t('common:company_name')}
                    </a>. {t('common:rights_reserved')}
                </p>

                <p className="mb-0 mx-2 small d-inline">
                    <a href={TERMS_ROUTES.TERMS_OF_USE} target="_blank"
                       className="text-muted mr-1">{t('common:terms_of_use')}</a>
                    <a href={TERMS_ROUTES.COOKIES_POLICY} target="_blank"
                       className="text-muted mr-1">{t('common:cookies_policy')}</a>
                    <a href={TERMS_ROUTES.PRIVACY_POLICY} target="_blank"
                       className="text-muted">{t('common:privacy_policy')}</a>
                </p>

                <button className="btn btn-link small text-muted p-0 text-decoration-none btn-report-bug"
                        style={{fontSize: '80%'}}
                        onClick={handleShowBugReportModal}>
                    <FontAwesomeIcon icon={['fas', 'bug']}/> {t('btn_report_bug')}
                </button>

                <button className={"btn btn-adjust-volume small py-0 px-1 ml-2 " + volumeButtonStyle.class}
                        style={{fontSize: '80%'}}
                        onClick={handleShowAdjustVolumeModal}>

                    <FontAwesomeIcon icon={['fas', volumeButtonStyle.icon]} className="mr-1"/>
                    {t('adjust_volume', {volume: volumeButtonStyle.valueToDisplay})}
                </button>
            </footer>

            {showBugReportModal && (<BugReportModal onClose={handleCloseBugReportModal}/>)}
            {showAdjustVolumeModal && (<AdjustVolumeModal onClose={handleCloseAdjustVolumeModal}/>)}
        </>
    );
}