import React, {useEffect, useMemo, useState} from "react";
import {Button, Modal} from "react-bootstrap";
import PlayerItem from "./components/PlayerItem";

import "./PlayerFormModal.scss";
import {useSelector} from "react-redux";
import Utils from "utils/Utils";
import OverlayUtil from "utils/OverlayUtil";
import LoggerService from "services/common/LoggerService";
import ToastUtil from "utils/ToastUtil";
import PlayerService from "services/common/PlayerService";
import {GAME_STEPS} from "config/CONSTANTS";
import {Trans, useTranslation} from "react-i18next";
import SwalModalUtil from "utils/SwalModalUtil";

export default function PlayerFormModal({onClose, player}) {
    const {t} = useTranslation('pages/common/player_form');

    const requireCompany = useSelector(state => state.game.isOwnSession);
    const requireCountry = useSelector(state => !state.game.isNationalSession);
    const isGameMaster = useSelector(state => state.session.isGameMaster);
    const currentStep = useSelector(state => state.game.currentStep);
    const teamCount = useSelector(state => state.game.teamCount);
    const maxPlayerCount = useSelector(state => state.game.maxPlayerCount);
    const playersCounter = useSelector(state => state.game.playersCounter);
    const isTemporary = useSelector(state => state.game.isTemporary);
    const allowCertificateRequest = useSelector(state => state.game.allowCertificateRequest);

    const [players, setPlayers] = useState([]);
    const [certificates, setCertificates] = useState({});
    const [nps, setNps] = useState({});
    const [team, setTeam] = useState('');

    const [newLocalPlayers, setNewLocalPlayers] = useState([]);
    const [newCertificates, setNewCertificates] = useState([]);

    const [showOverlay, setShowOverlay] = useState(false);
    const [hasShowedOverlay, setHasShowedOverlay] = useState(false);

    const disableCertificateForm = useMemo(() => {
        return !allowCertificateRequest || currentStep < GAME_STEPS.ONBOARDING || currentStep >= GAME_STEPS.END_GAME
    }, [allowCertificateRequest, currentStep]);

    const hideCertificateInfo = useMemo(() => {
        return !allowCertificateRequest || currentStep < GAME_STEPS.ONBOARDING;
    }, [allowCertificateRequest, currentStep]);

    const disableNpsForm = useMemo(() => {
        return !allowCertificateRequest || currentStep < GAME_STEPS.END_GAME
    }, [allowCertificateRequest, currentStep]);

    const hideTextsForPlayer = useMemo(() => isGameMaster && player, [isGameMaster, player]);
    const hideGameMasterContent = useMemo(() => !isGameMaster || !player, []);
    const canAddNewLocalPlayer = useMemo(
        () => !maxPlayerCount || isTemporary || maxPlayerCount > playersCounter.total,
        [isTemporary, playersCounter, maxPlayerCount]
    );

    const handleAddPlayer = () => {
        if (!canAddNewLocalPlayer) return;

        const id = Utils.uuid();

        setNewLocalPlayers([...newLocalPlayers, {
            id,
            isTemporary: true,
        }]);

        if (newCertificates[players[0].id]) {
            setNewCertificates({
                ...newCertificates,
                [id]: {
                    company: certificates[players[0].id].company ?? '',
                    country: certificates[players[0].id].country ?? '',
                }
            })
        }
    };

    const handleRemoveLocalPlayer = (playerId) => {
        if (player && playerId === player.id)
            return;

        setNewLocalPlayers(newLocalPlayers.filter(p => p.id !== playerId));

        const newCertificatesToSet = {...newCertificates};
        delete newCertificatesToSet[playerId];

        setNewCertificates(newCertificatesToSet);
    };

    const handleSetTeam = async (team) => {
        setShowOverlay(true);

        const params = {team: parseInt(team)};

        PlayerService.updatePlayerTeam(player.id, params).then(result => {
            if (result)
                ToastUtil.toastSuccess(t('toasts.update_team_success.title'), t('toasts.update_team_success.message'));
            else
                ToastUtil.toastDanger(t('toasts.update_team_error.title'), t('toasts.update_team_error.message'));
        }).catch(err => {
            LoggerService.error(err, {
                action: '[PlayerFormModal] handleSetTeam()',
                params: {params, player}
            })
        }).finally(() => setShowOverlay(false));
    }

    const handleRemovePlayer = () => {
        SwalModalUtil.confirmModal(t('confirms.remove.title'), t('confirms.remove.message'))
            .then(result => {
                if (result) {
                    OverlayUtil.toggleLoadingOverlay(true, t('overlays.removing'));

                    PlayerService.remove(player.id).then(response => {
                        if (response === true) {
                            ToastUtil.toastSuccess(t('toasts.remove_success.title'), t('toasts.remove_success.message'));
                            onClose();
                        } else {
                            ToastUtil.toastDanger(t('toasts.remove_error.title'), t('toasts.remove_error.message'));
                        }
                    }).catch(reason => {
                        console.error(reason);
                        ToastUtil.toastDanger(t('toasts.remove_error.title'), t('toasts.remove_error.message'));

                        LoggerService.error(reason, {
                            action: 'remove player - player form',
                        });
                    }).finally(() => OverlayUtil.toggleLoadingOverlay(false));
                }
            });
    }

    const handleKickPlayer = () => {
        SwalModalUtil.confirmModal(t('confirms.kick.title'), t('confirms.kick.message'))
            .then(result => {
                if (result) {
                    OverlayUtil.toggleLoadingOverlay(true, t('overlays.kicking'));

                    PlayerService.kick(player.id).then(response => {
                        if (response === true) {
                            ToastUtil.toastSuccess(t('toasts.kick_success.title'), t('toasts.kick_success.message'));

                            onClose();
                        } else {
                            ToastUtil.toastDanger(t('toasts.kick_error.title'), t('toasts.kick_error.message'));
                        }
                    }).catch(reason => {
                        console.error(reason);
                        ToastUtil.toastDanger(t('toasts.kick_error.title'), t('toasts.kick_error.message'));

                        LoggerService.error(reason, {
                            action: 'kick player - player form',
                        });
                    }).finally(() => OverlayUtil.toggleLoadingOverlay(false));
                }
            });
    }


    useEffect(() => {
        const checkUserRequestedCertificates = async () => {
            try {
                if (!hasShowedOverlay)
                    setShowOverlay(true);

                const npsAndCertificatesData = await PlayerService.getNpsAndCertificates(player.id);
                if (npsAndCertificatesData === false)
                    throw new Error('Could not fetch player data');

                // parse to form list
                const rootPlayer = {...player};
                rootPlayer.isRoot = true;
                rootPlayer.localPlayersCount = rootPlayer.localPlayers?.length ?? 0;

                delete rootPlayer['localPlayers'];
                let playerList = [rootPlayer];

                if (player.localPlayers)
                    playerList = [...playerList, ...player.localPlayers];


                // set data
                setPlayers(playerList);
                setCertificates(npsAndCertificatesData.certificates ?? {});
                setNps(npsAndCertificatesData.nps ?? {});
            } catch (e) {
                console.error('Error: could not fetch certificate request');
                console.error(e);
                LoggerService.error(e, {
                    action: 'fetchCertificateRequest - PlayerForm.js component',
                    params: {player}
                });

                onClose();
            } finally {
                if (!hasShowedOverlay) {
                    setShowOverlay(false);
                    setHasShowedOverlay(true);
                }
            }
        }

        if (player) {
            checkUserRequestedCertificates();
            setTeam(player.team ?? '');
        } else {
            setPlayers([{
                id: Utils.uuid(),
                isRoot: true,
                nickname: "Game Master"
            }]);
        }
    }, [player]);

    useEffect(() => OverlayUtil.toggleLoadingOverlay(showOverlay), [showOverlay])

    return (<Modal id="player-form-modal" size="lg" show={true}>
        <Modal.Header>
            <Modal.Title>
                {hideTextsForPlayer ? t('texts.edit_player', {player: player.nickname}) : t('texts.players')}
            </Modal.Title>
        </Modal.Header>

        <Modal.Body className="overflow-auto">
            <div className="mb-4">
                <p className="mb-2" hidden={hideTextsForPlayer}>{t('texts.info_message')}</p>
                <p className="text-muted" hidden={hideCertificateInfo}>{t('texts.certificate_agreement')}</p>
                <p className="text-muted" hidden={hideCertificateInfo}>{t('texts.certificate_send_time')}</p>
            </div>

            <hr hidden={hideTextsForPlayer}/>

            <div className="form-group mb-4" hidden={hideGameMasterContent}>
                <label>{t('inputs.team.name')}</label>

                <select className="form-control" onChange={(event) => handleSetTeam(event.target.value)}
                        value={team} disabled={currentStep < GAME_STEPS.ONBOARDING}>
                    <option value={0}>{t('inputs.team.no_team')}</option>
                    <option value={1}>1</option>
                    {teamCount > 1 && (<option value={2}>2</option>)}
                    {teamCount > 2 && (<option value={3}>3</option>)}
                    {teamCount > 3 && (<option value={4}>4</option>)}
                </select>
            </div>

            <hr hidden={hideGameMasterContent}/>

            <div>
                {
                    players.map((p => <PlayerItem key={p.id}
                                                  player={p}
                                                  rootPlayerId={player?.id}
                                                  certificate={certificates[p.id]}
                                                  answeredNps={nps[p.id]}
                                                  canRemove={!p.isRoot}
                                                  onRemoveNewLocalPlayer={handleRemoveLocalPlayer}
                                                  requireCountry={requireCountry}
                                                  requireCompany={requireCompany}
                                                  disableCertificateForm={disableCertificateForm}
                                                  hideCertificateInfo={hideCertificateInfo}
                                                  disableNpsForm={disableNpsForm}/>))
                }

                {
                    newLocalPlayers.map((p => <PlayerItem key={p.id}
                                                          player={p}
                                                          rootPlayerId={player?.id}
                                                          certificate={certificates[p.id]}
                                                          answeredNps={nps[p.id]}
                                                          canRemove={!p.isRoot}
                                                          onRemoveNewLocalPlayer={handleRemoveLocalPlayer}
                                                          requireCountry={requireCountry}
                                                          requireCompany={requireCompany}
                                                          disableCertificateForm={disableCertificateForm}
                                                          hideCertificateInfo={hideCertificateInfo}
                                                          disableNpsForm={disableNpsForm}/>))
                }
            </div>

            <div className="d-flex flex-column align-items-end mt-5">
                <button type="button" className="btn btn-primary btn-sm ml-auto" onClick={handleAddPlayer}
                        disabled={!canAddNewLocalPlayer}>
                    {t('buttons.add_local_player')}
                </button>

                <p className="small pt-1 text-right" style={{maxWidth: '60%'}}>
                    {canAddNewLocalPlayer && (
                        <Trans t={t} i18nKey={'texts.add_local_player_info'}
                               components={{b: <b className="text-info"/>}}/>
                    )}

                    {!canAddNewLocalPlayer && (
                        <span className="text-danger font-weight-bold">{t('texts.max_player_count_reached')}</span>
                    )}
                </p>
            </div>
        </Modal.Body>

        <Modal.Footer className="pt-4">
            <div hidden={!isGameMaster || !player}>
                <button type="button" className="btn btn-danger text-white" onClick={handleKickPlayer}>
                    {t('buttons.kick')}
                </button>
            </div>

            <div hidden={!isGameMaster || !player}>
                <button type="button" className="btn btn-outline-danger" onClick={handleRemovePlayer}>
                    {t('buttons.remove')}
                </button>
            </div>

            <Button variant="secondary" className="btn-close-player-form" onClick={onClose}>
                {t('buttons.close')}
            </Button>
        </Modal.Footer>
    </Modal>)
}