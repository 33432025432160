import {CHALLENGE_TYPE, GAME_STEPS, GAMES, UNPREDICTABILITY_IMPACT} from "config/CONSTANTS";
import store from "redux/store";
import {i18n} from "i18n";
import DEMO_SESSION_CONFIG from "../../../../../../config/DEMO_SESSION_CONFIG";
import DemoSessionService from "../../../../../../services/common/DemoSessionService";
import GameUtil from "../../../../../../utils/GameUtil";

const t = i18n.getFixedT(null, 'pages/common/board/challenges', 'services.demo_challenges_service');

const tfg = (key, params = {}) => {
    key = GameUtil.isCurrentGameEsg() ? key + '_esg' : key;
    return t(key, params);
}

const DemoChallengesComponentService = {
    touchChallengesList(handleShowConceptionModal) {
        let res = [];

        const currentStep = store.getState().game.currentStep;
        const showBoardForTeam = store.getState().session.showBoardForTeam;
        const teamData = store.getState().teams[showBoardForTeam];

        const game = store.getState().game.gameInfo.game;
        const isEsg = game === GAMES.ESG;

        const {revealedQuestion, hasRevealedUnpredictability} = store.getState().demo.state;

        if (currentStep === GAME_STEPS.ONBOARDING) {
            res.push({
                type: CHALLENGE_TYPE.OBJECTIVE,
                data: {
                    title: t('welcome.title'),
                    description: tfg('welcome.message'),
                    isDemoIntro: true,
                }
            });

            res.push({
                type: CHALLENGE_TYPE.OBJECTIVE,
                data: {
                    title: t('journey_info.title'),
                    description: t('journey_info.message'),
                    actionButtonText: t('journey_info.action'),
                    actionCallback: () => document.getElementById('btn-show-game-map').click()
                }
            });
        }


        if (currentStep === GAME_STEPS.CONCEPTION) {
            res.push({
                type: CHALLENGE_TYPE.OBJECTIVE,
                data: {
                    title: t('conception.title'),
                    description: tfg('conception.message'),
                    actionButtonText: t('conception.action'),
                    isConception: true,
                    actionCallback: handleShowConceptionModal
                }
            });
        }

        if (currentStep === DEMO_SESSION_CONFIG.QUESTION_STEP) {
            if (!revealedQuestion) {
                res.push({
                    type: CHALLENGE_TYPE.OBJECTIVE,
                    data: {
                        title: t('question_info.title'),
                        description: tfg('question_info.message')
                    }
                });
            }

            res.push({
                type: CHALLENGE_TYPE.QUESTION,
                data: teamData.questions[DEMO_SESSION_CONFIG.QUESTION_STEP]
            });


            if (revealedQuestion) {
                res.push({
                    type: CHALLENGE_TYPE.OBJECTIVE,
                    data: {
                        title: t('market_info.title'),
                        description: tfg('market_info.message'),
                        onShow: () => DemoSessionService.pulseMarket(true),
                        onHide: () => DemoSessionService.pulseMarket(false),
                    }
                });

                res.push({
                    type: CHALLENGE_TYPE.OBJECTIVE,
                    data: {
                        title: tfg('accounting_info.title'),
                        description: tfg('accounting_info.message'),
                        actionButtonText: t('accounting_info.action'),
                        actionCallback: () => document.getElementById('btn-accounting-details').click(),
                        onShow: () => DemoSessionService.pulseAccounting(true),
                        onHide: () => DemoSessionService.pulseAccounting(false),
                    }
                });


                if (hasRevealedUnpredictability) {
                    res.push({
                        type: CHALLENGE_TYPE.UNPREDICTABILITY,
                        data: {
                            id: 'demo',
                            reveal: true,
                            revealOutcome: true,
                            impact: UNPREDICTABILITY_IMPACT.POSITIVE,
                            positiveAccelerationImpact: 0,
                            card: {
                                event: tfg('unpredictability.event'),
                                positiveConsequenceDescription: t('unpredictability.consequence_reveal')
                            }
                        }
                    });
                } else {
                    res.push({
                        type: CHALLENGE_TYPE.UNPREDICTABILITY,
                        data: {
                            id: 'demo',
                            reveal: true,
                            revealOutcome: false,
                            card: {
                                event: tfg('unpredictability.event'),
                                neutralConsequenceDescription: t('unpredictability.consequence')
                            }
                        }
                    });
                }

                res.push({
                    type: CHALLENGE_TYPE.OBJECTIVE,
                    data: {
                        title: t('pitch.title'),
                        description: tfg("pitch.message")
                    }
                });


                res.push({
                    type: CHALLENGE_TYPE.OBJECTIVE,
                    data: {
                        title: t('almost_done.title'),
                        description: tfg("almost_done.message")
                    }
                });

                res.push({type: CHALLENGE_TYPE.DEMO_ENDING});
            }
        }

        return res;
    }
};

export default DemoChallengesComponentService;