import React from "react";

import "./InfoPageLayout.scss";
import img from "assets/img/waiting_room.svg";
import logo from "assets/img/logos/svg/without_tagline_3.svg";
import {useTranslation} from "react-i18next";
import {TERMS_ROUTES} from "../../../../navigation/ROUTES";

export default function InfoPageLayout({children, withGreetings = true, withFarewell = true}) {
    const {t} = useTranslation(['pages/common/session_status', 'common'])
    const now = (new Date()).getUTCFullYear();

    return (
        <div className="h-100 w-100 session-not-started-yet text-white d-flex justify-content-center">
            <div className="px-5 w-100 h-100 text-content-container">
                <div className="pb-3 h-100 d-flex justify-content-center flex-column text-content">
                    <img alt="logo" className="logo-header mx-auto mx-md-0 mt-2 mb-5" src={logo}/>

                    {withGreetings && (<h3 className="mb-4 mt-3 mt-md-5">{t('texts.greetings')}</h3>)}

                    {children}

                    {withFarewell && (<h3 className="mb-3">{t('texts.footnote')}</h3>)}

                    <div className="text-center text-md-left">
                        <p className="mt-3 p-0 mb-1 text-white small">
                            @ {now}

                            <a href="https://www.startupmundi.com.br/" rel="noreferrer"
                               className="text-white text-decoration-underline ml-1"
                               target="_blank">
                                Mundi Game Experiences</a>. {t('common:rights_reserved')}
                        </p>

                        <p className="mb-0 small d-inline">
                            <a href={TERMS_ROUTES.TERMS_OF_USE} target="_blank"
                               className="text-white mr-1">{t('common:terms_of_use')}</a>
                            <a href={TERMS_ROUTES.COOKIES_POLICY} target="_blank"
                               className="text-white mr-1">{t('common:cookies_policy')}</a>
                            <a href={TERMS_ROUTES.PRIVACY_POLICY} target="_blank"
                               className="text-white">{t('common:privacy_policy')}</a>
                        </p>
                    </div>
                </div>

                <div className="bg overflow-hidden">
                    <img alt="logo" className="bg-img" src={img}/>
                    <img alt="logo" className="logo" src={logo}/>
                </div>
            </div>
        </div>
    );
}