import React, {useEffect, useRef, useState} from "react";

import "./TeamRankingModal.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TeamStepInPodium from "./components/TeamStepInPodium";
import {useSelector} from "react-redux";
import ConfettiModule from "canvas-confetti";
import OverlayUtil from "utils/OverlayUtil";
import AccountingService from "services/common/AccountingService";
import GameService from "services/gameMaster/GameService";
import ToastUtil from "utils/ToastUtil";
import {OVERLAY_THEME} from "config/CONSTANTS";
import LoggerService from "services/common/LoggerService";
import {useTranslation} from "react-i18next";
import {Portal} from "react-portal";
import AudioService from "../../../../../../../services/common/AudioService";
import {SOUND_EFFECTS} from "../../../../../../../config/AUDIO_FILES";

export default function TeamRankingModal({onClose}) {
    const {t} = useTranslation('pages/game_master/control_panel/game_control', {keyPrefix: "components.team_ranking_modal"});
    const ranking = useSelector(state => state.game.ranking);
    const teamCount = useSelector(state => state.game.teamCount);

    const [firstToThirdPlace, setFirstToThirdPlace] = useState([]);
    const [fourthPlaces, setFourthPlaces] = useState([]);
    const [playAudio, setPlayAudio] = useState(false);

    const canvas = useRef();

    const handleReveal = async (teamData) => {
        try {
            let particleCount;

            switch (teamData.ranking) {
                case 1:
                    particleCount = 150;
                    break;
                case 2:
                    particleCount = 30;
                    break;
                case 3:
                    particleCount = 15;
                    break;
                default:
                    particleCount = 0;
            }

            const audio = AudioService.createAudio(SOUND_EFFECTS.RANKING[teamData.ranking]);
            audio.play();

            const confetti = ConfettiModule.create(canvas.current);
            confetti({
                particleCount,
                spread: 50,
                origin: {y: 1},
            });
        } catch (e) {
            console.error(e);
        }
    }

    const calculateRanking = async (onError) => {
        OverlayUtil.toggleLoadingOverlay(true, t('overlays.calculating'), OVERLAY_THEME.DARK);

        let params = {};

        try {
            params = {
                ranking: Object.values(AccountingService.calculateRanking()),
                rankingPerStep: AccountingService.calculateRankingForAllSteps(),
                finalAccounting: AccountingService.calculateFinalAccountingForAllTeams()
            };

            if (!await GameService.updateRanking(params)) {
                ToastUtil.toastDanger(t('toasts.update_ranking_error.title'), t('toasts.update_ranking_error.message'));
                onError();
            }
        } catch (e) {
            console.error(e);
            ToastUtil.toastDanger(t('toasts.update_ranking_error.title'), t('toasts.update_ranking_error.message'));

            LoggerService.error(e, {
                action: 'calculate teams ranking - control panel',
                params
            });
            onError();
        } finally {
            OverlayUtil.toggleLoadingOverlay(false);
        }
    }

    useEffect(() => {
        const handleEsc = (event) => {
            if (event.keyCode === 27) {
                onClose();
            }
        };

        window.addEventListener('keydown', handleEsc);
        return () => window.removeEventListener('keydown', handleEsc);
    }, []);

    useEffect(() => {
        if (Object.values(ranking).length === 0) {
            calculateRanking(onClose);
            setPlayAudio(true);
        } else {
            const groupedByRanking = {};
            let revealed = 0;

            Object.entries(ranking).forEach(([team, teamData]) => {
                if (!groupedByRanking[teamData.ranking]) groupedByRanking[teamData.ranking] = [];
                groupedByRanking[teamData.ranking].push({...teamData, team: team});

                if (teamData.show)
                    revealed++;
            });

            const sortedForRanking = [];
            if (groupedByRanking[2]) sortedForRanking.push(...groupedByRanking[2]);
            if (groupedByRanking[1]) sortedForRanking.push(...groupedByRanking[1]);
            if (groupedByRanking[3]) sortedForRanking.push(...groupedByRanking[3]);

            setFirstToThirdPlace(sortedForRanking);
            setFourthPlaces(groupedByRanking[4] || []);

            if (revealed + 1 >= teamCount)
                setPlayAudio(false);
        }
    }, [ranking]);

    return (<Portal>
        <div className={"team-ranking-modal pt-5 px-lg-4 px-2 show"}>
            <div className="h-100 w-100 position-relative">
                <button type="button" className="btn btn-link float-right text-white position-absolute right"
                        onClick={onClose}>
                    <FontAwesomeIcon icon={['fas', 'times']} className={'fa-2x'}/>
                </button>


                <div className="d-flex w-100 justify-content-center text-white">

                    <div className="d-flex">
                        <div className="d-flex ranking align-items-end">
                            {firstToThirdPlace.map(teamRanking => <TeamStepInPodium teamRanking={teamRanking}
                                                                                    key={teamRanking.team}
                                                                                    onReveal={handleReveal}
                                                                                    playAudio={playAudio}/>)}
                        </div>

                        <div className="d-flex ranking align-items-end ml-5 pl-4">
                            {fourthPlaces.map(teamRanking => <TeamStepInPodium teamRanking={teamRanking}
                                                                               key={teamRanking.team}
                                                                               onReveal={handleReveal}
                                                                               playAudio={playAudio}/>)}
                        </div>
                    </div>
                </div>

                <div className="position-absolute top left right bottom overflow-hidden d-flex justify-content-center"
                     style={{zIndex: -1}}>
                    <canvas width={500} height={500} ref={canvas}/>
                </div>
            </div>
        </div>
    </Portal>);
}