import React from "react";
import {Route, Routes, useLocation} from "react-router-dom";

import {
    COMMON_ROUTES,
    DEMO_ROUTES,
    GAME_MASTER_ROUTES,
    PLAYER_ROUTES, PUBLIC_ROUTES,
    TERMS_ROUTES,
    TOURNAMENT_ROUTES
} from "navigation/ROUTES";
import GuestRoute from "./auth/GuestRoute";
import PlayerRoute from "./auth/PlayerRoute";
import GameMasterRoute from "./auth/GameMasterRoute";


// player
import PlayerJoin from "../pages/player/Join/Join";
import Lobby from "../pages/player/Lobby/Lobby";
import Exit from "../pages/player/Exit/Exit";

// game master
import GameMasterJoin from "../pages/gameMaster/Join/Join";
import ControlPanel from "../pages/gameMaster/ControlPanel/ControlPanel";

// common - authenticated
import Board from "../pages/common/Board/Board";

// common
import NotFoundError from "../pages/common/errors/NotFoundError";
import UnrecoverableError from "../pages/common/errors/UnrecoverableError";
import PlayerRemoved from "../pages/common/Kicked/PlayerRemoved";

// demo
import DemoJoin from "../pages/demo/Join/Join";

// tournament
import TournamentJoin from "../pages/tournament/Join/Join";
import AutoLobby from "../pages/tournament/AutoLobby/AutoLobby";

// terms and policies
import CookiesPolicy from "../pages/terms/CookiesPolicy/CookiesPolicy";
import PrivacyPolicy from "../pages/terms/PrivacyPolicy/PrivacyPolicy";
import TermsOfUse from "../pages/terms/TermsOfUse/TermsOfUse";
import DemoRoute from "./auth/DemoRoute";
import SoftReload from "../pages/common/SoftReload/SoftReload";

// public
import VerifyConnection from "../pages/public/VerifyConnection/VerifyConnection";


export const RouterConfig = () => {
    const location = useLocation();

    return (
        <>
            <Routes location={location}>
                {/** common routes */}
                <Route path={COMMON_ROUTES.SOFT_RELOAD} element={<SoftReload/>}/>
                <Route path={COMMON_ROUTES.ERROR} element={<UnrecoverableError/>}/>
                <Route path={COMMON_ROUTES.PLAYER_REMOVED} element={<PlayerRemoved/>}/>

                {/** common terms routes */}
                <Route path={TERMS_ROUTES.TERMS_OF_USE} element={<TermsOfUse/>}/>
                <Route path={TERMS_ROUTES.PRIVACY_POLICY} element={<PrivacyPolicy/>}/>
                <Route path={TERMS_ROUTES.COOKIES_POLICY} element={<CookiesPolicy/>}/>


                {/* player routes */}
                <Route path={PLAYER_ROUTES.EXIT} element={<Exit/>}/>
                <Route path={PLAYER_ROUTES.JOIN} element={<GuestRoute children={<PlayerJoin/>}/>}/>
                <Route path={PLAYER_ROUTES.BOARD} element={<PlayerRoute children={<Board/>}/>}/>
                <Route path={PLAYER_ROUTES.LOBBY} element={<PlayerRoute children={<Lobby/>}/>}/>


                {/* game master routes*/}
                <Route path={GAME_MASTER_ROUTES.JOIN} element={<GuestRoute children={<GameMasterJoin/>}/>}/>
                <Route path={GAME_MASTER_ROUTES.CONTROL_PANEL}
                       element={<GameMasterRoute children={<ControlPanel/>}/>}/>


                {/* tournament routes */}
                <Route path={TOURNAMENT_ROUTES.JOIN} element={<GuestRoute children={<TournamentJoin/>}/>}/>
                <Route path={TOURNAMENT_ROUTES.LOBBY} element={<PlayerRoute children={<AutoLobby/>}/>}/>

                {/* demo routes*/}

                <Route path={DEMO_ROUTES.JOIN} element={<DemoJoin/>}/>
                <Route path={DEMO_ROUTES.BOARD} element={<DemoRoute children={<Board/>}/>}/>


                {/* public routes*/}
                <Route path={PUBLIC_ROUTES.VERIFY_CONNECTION} element={<VerifyConnection/>}/>


                {/*  generic 404-Not Found route */}
                <Route path="*" element={<NotFoundError/>}/>
            </Routes>
        </>
    );
};
