import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import GameService from "services/gameMaster/GameService";
import ToastUtil from "utils/ToastUtil";

import "./UnpredictabilityTeamRow.scss";
import LoggerService from "services/common/LoggerService";
import {useTranslation} from "react-i18next";
import GameControlService from "../../../services/GameControlService";
import {GAME_STEPS, UNPREDICTABILITY_IMPACT} from "config/CONSTANTS";
import UnpredictabilityCard, {UNPREDICTABILITY_CARD_LAYOUT} from "components/cards/UnpredictabilityCard";
import AudioService from "../../../../../../../../services/common/AudioService";
import {SOUND_EFFECTS} from "../../../../../../../../config/AUDIO_FILES";
import EventsUtil from "../../../../../../../../utils/EventsUtil";
import {GAME_EVENTS} from "../../../../../../../../config/EVENTS";

export default function UnpredictabilityTeamRow({team, unpredictability}) {
    const {t} = useTranslation('pages/game_master/control_panel/game_control', {keyPrefix: "components.unpredictability_modal.components.unpredictability_team_row"});
    const {card, reveal, revealOutcome, impact} = unpredictability;

    const currentStep = useSelector(state => state.game.currentStep);
    const startup = useSelector(state => state.teams[team].startup);

    const [isLoading, setIsLoading] = useState(false);
    const [isPlayingSuspenseEffect, setIsPlayingSuspenseEffect] = useState(false);

    const revealedListener = async (data) => {

        if (data.reveal && data.revealOutcome) {
            let soundToPlay;

            switch (data.impact) {
                case UNPREDICTABILITY_IMPACT.POSITIVE:
                    soundToPlay = SOUND_EFFECTS.UNPREDICTABILITY.POSITIVE_OUTCOME;
                    break;
                case UNPREDICTABILITY_IMPACT.NEGATIVE:
                    soundToPlay = SOUND_EFFECTS.UNPREDICTABILITY.NEGATIVE_OUTCOME;
                    break;
                default:
                    soundToPlay = SOUND_EFFECTS.UNPREDICTABILITY.NEUTRAL_OUTCOME;
            }

            const audio = await AudioService.queue(soundToPlay);
            audio.play();
        }
    }

    const handleReveal = () => {
        setIsLoading(true);
        setIsPlayingSuspenseEffect('reveal');

        const audio = AudioService.createAudio(SOUND_EFFECTS.UNPREDICTABILITY.SUSPENSE_REVEAL)
        audio.addEventListener('ended', () => {
            setIsPlayingSuspenseEffect(false);
        });

        audio.play();

        const {impact, revealOutcome} = GameControlService.unpredictabilityImpact(team, unpredictability.card);
        const params = {team, step: unpredictability.step, revealOutcome};
        if (revealOutcome) params.impact = impact;

        GameService.revealUnpredictability(params).then(result => {
            if (!result) ToastUtil.toastDanger(t('toasts.reveal_error.title'), t('toasts.reveal_error.message'));
        }).catch(error => {
            console.error(error);
            ToastUtil.toastDanger(t('toasts.reveal_error.title'), t('toasts.reveal_error.message'));

            LoggerService.error(error, {
                action: 'reveal unpredictability - control panel',
                params
            });
        }).finally(() => setIsLoading(false));
    };

    const handleShowOutcome = () => {
        setIsLoading(true);
        setIsPlayingSuspenseEffect('outcome');

        const audio = AudioService.createAudio(SOUND_EFFECTS.UNPREDICTABILITY.SUSPENSE_REVEAL_OUTCOME)
        audio.addEventListener('ended', () => {
            setIsPlayingSuspenseEffect(false);
        });

        audio.play();

        const {impact} = GameControlService.unpredictabilityImpact(team, unpredictability.card);
        const params = {team, step: unpredictability.step, impact};

        GameService.revealUnpredictabilityOutcome(params).then(result => {
            if (!result) ToastUtil.toastDanger(t('toasts.reveal_outcome_error.title'), t('toasts.reveal_outcome_error.message'));
        }).catch(error => {
            console.error(error);
            ToastUtil.toastDanger(t('toasts.reveal_outcome_error.title'), t('toasts.reveal_outcome_error.message'));

            LoggerService.error(error, {
                action: 'reveal unpredictability outcome - control panel',
                params
            });
        }).finally(() => setIsLoading(false));
    };

    useEffect(() => {
        EventsUtil.subscribe(GAME_EVENTS.UNPREDICTABILITY_CARD_UPDATED, revealedListener);

        return () => {
            EventsUtil.unsubscribe(GAME_EVENTS.UNPREDICTABILITY_CARD_UPDATED, revealedListener);
        };
    }, []);

    return (
        <>
            <div className="unpredictability-team-row d-flex align-items-center flex-column mb-5">
                <div className="animate__animated animate__faster animate__fadeInDown text-center mb-2 pb-4 text-white">
                    <h2 className="mb-0">{t('texts.team', {team})}</h2>
                    <h2>{startup?.title || ''}</h2>
                </div>

                <div className="animate__animated animate__fadeIn card-container" key={card.id}>
                    <UnpredictabilityCard unpredictabilityCard={card}
                                          layout={UNPREDICTABILITY_CARD_LAYOUT.CONSEQUENCES_ON_RIGHT_SIDE}
                                          reveal={reveal && (card.hasDifferentImpacts() ? isPlayingSuspenseEffect !== 'reveal' : (revealOutcome && !isPlayingSuspenseEffect))}
                                          revealOutcome={revealOutcome && !isPlayingSuspenseEffect}
                                          impact={impact}
                                          isLoading={isLoading || isPlayingSuspenseEffect}
                                          cardColor={'team-' + team}/>
                </div>

                <div className="d-flex justify-content-center mt-4 btns-container">
                    {!unpredictability.reveal && (
                        <button className={"btn btn-lg text-white btn-team-" + team}
                                disabled={isLoading || isPlayingSuspenseEffect || currentStep === GAME_STEPS.END_GAME}
                                onClick={handleReveal}>
                            {t('buttons.reveal')}
                        </button>
                    )}

                    {unpredictability.reveal && !unpredictability.revealOutcome && (
                        <button className={"btn btn-lg text-white btn-team-" + team}
                                disabled={isLoading || isPlayingSuspenseEffect || currentStep === GAME_STEPS.END_GAME}
                                onClick={handleShowOutcome}>{t('buttons.show_outcome')}</button>)}
                </div>
            </div>
        </>
    );
}