import React, {memo, useEffect, useMemo, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import SummaryCardLayout from "components/cards/layouts/SummaryCardLayout";

import rightAnswerIcon from "assets/img/board/ic_ans__correct-small.svg";
import wrongAnswerIcon from "assets/img/board/ic_ans_wrong_small.svg";
import monthsToGoIcon from "assets/img/board/ic_month_journey_box.svg";
import balanceIcon from "assets/img/board/ic_investment_journey_box.svg";
import bestPitchIcon from "assets/img/board/ic_best_pitch.svg";

import "./JourneyMapSummaryCard.scss";
import i18nUtil from "utils/i18nUtil";
import {GAME_STEPS} from "config/CONSTANTS";
import {useSelector} from "react-redux";
import CardUtil from "utils/CardUtil";
import Utils from "utils/Utils";
import {useTranslation} from "react-i18next";
import JourneyMapModal from "../../../JourneyMap/JourneyMapModal";
import GameUtil from "../../../../../utils/GameUtil";


const JourneyMapSummaryCard = () => {
    const {t} = useTranslation('pages/common/board/journey_map');

    const showBoardForTeam = useSelector(state => state.session.showBoardForTeam);
    const currentStep = useSelector(state => state.game.currentStep);
    const isPocket = useSelector(state => state.game.isPocket);
    const isDemonstration = useSelector(state => state.game.isDemonstration);

    const [stepToShow, setStepToShow] = useState(currentStep);
    const [stepToShowPhase, setStepToShowPhase] = useState(false);

    const pitchBonus = useSelector(state => state.teams[showBoardForTeam].pitchBonus[stepToShow]);

    const isEsg = useMemo(() => GameUtil.isCurrentGameEsg(), []);

    const accountingDetails = useSelector(state => {
        if (!stepToShow) return null;

        const phaseToShow = CardUtil.getGamePhaseForStep(stepToShow);

        if (stepToShow && state.teams[showBoardForTeam].accounting.phases && state.teams[showBoardForTeam].accounting.phases[phaseToShow]
            && state.teams[showBoardForTeam].accounting.phases[phaseToShow].steps && state.teams[showBoardForTeam].accounting.phases[phaseToShow].steps[stepToShow]) {
            return state.teams[showBoardForTeam].accounting.phases[phaseToShow].steps[stepToShow];
        }
        return {};
    });

    const totalAccountingDetails = useSelector(state => state.teams[showBoardForTeam].accounting);

    const handleShowNextStep = () => {
        if (currentStep === GAME_STEPS.CONCEPTION) return;

        let nextStepToShow = stepToShow;
        if (stepToShow >= currentStep || stepToShow >= (isPocket ? GAME_STEPS.PITCH_2 : GAME_STEPS.PITCH_3)) nextStepToShow = GAME_STEPS.CONCEPTION;
        else nextStepToShow++;

        setStepToShow(nextStepToShow);
    };

    const handleShowPreviousStep = () => {
        if (currentStep === GAME_STEPS.CONCEPTION) return;

        let nextStepToShow = stepToShow;
        if (stepToShow === GAME_STEPS.CONCEPTION) {
            if (isPocket) nextStepToShow = (currentStep > GAME_STEPS.PITCH_2 ? GAME_STEPS.PITCH_2 : currentStep);
            else nextStepToShow = (currentStep > GAME_STEPS.PITCH_3 ? GAME_STEPS.PITCH_3 : currentStep);
        } else {
            nextStepToShow--;
        }

        setStepToShow(nextStepToShow);
    }

    const [showModal, setShowModal] = useState(false);
    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    useEffect(() => {
        if (isPocket && currentStep >= GAME_STEPS.PITCH_2) setStepToShow(GAME_STEPS.PITCH_2);
        else if (currentStep > GAME_STEPS.PITCH_3) setStepToShow(GAME_STEPS.PITCH_3);
        else setStepToShow(currentStep);
    }, [currentStep, isPocket]);

    useEffect(() => {
        setStepToShowPhase(CardUtil.getGamePhaseForStep(stepToShow));
    }, [stepToShow]);

    const showContent = currentStep > GAME_STEPS.ONBOARDING;

    return (
        <>
            <SummaryCardLayout>
                <div
                    className={"box journey-map-summary-card " + (showContent ? 'border-danger' : 'not-unlocked')}>
                    {showContent && (
                        <div
                            className="box-head text-uppercase bg-danger">{t('texts.phase', {phase: stepToShowPhase})}</div>
                    )}

                    {(showContent || isDemonstration) && (
                        <button type="button" id="btn-show-game-map"
                                className="btn btn-outline-info btn-sm float-right mt-1 mr-2  p-1 border-0 radius-4"
                                onClick={handleShowModal}>
                            {t('buttons.map')}
                        </button>)}

                    {showContent && (
                        <div className="box-content text-white">
                            {Utils.isset(pitchBonus) && (
                                <img alt='Best pitch badge' src={bestPitchIcon} className="best-pitch-badge"/>)}

                            <div className="d-flex align-items-center phase-box">
                                <div
                                    className="phase text-white d-flex justify-content-center align-items-center float-left">
                                    {stepToShow - 1}
                                </div>

                                <h3 className="text-danger d-inline">{i18nUtil.stepName(stepToShow)}</h3>
                            </div>


                            {isEsg && (
                                <table className="account-resume">
                                    <tbody>
                                    <tr>
                                        <td>{t('texts.revenue_esg')}</td>
                                        <td>
                                            {i18nUtil.formatDecimal(accountingDetails.revenue || 0)}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            )}

                            {!isEsg && (
                                <table className="account-resume">
                                    <tbody>
                                    <tr>
                                        <td>{t('texts.monthly_cost')}</td>
                                        <td>
                                            {i18nUtil.formatMoney(accountingDetails.costPerMonth || 0)}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            )}


                            <h4 className="text-danger mb-4">{t('texts.result')}</h4>
                            <div className="step-result row">
                                <div className="result-col d-flex align-items-center justify-content-center">
                                    {accountingDetails.hasQuestion && accountingDetails.revealQuestionAnswer && (<>
                                        {accountingDetails.questionResultStatus && (
                                            <img alt='Answer status icon' src={rightAnswerIcon}/>)}

                                        {!accountingDetails.questionResultStatus && (
                                            <img alt='Answer status icon' src={wrongAnswerIcon}/>)}
                                    </>)}

                                    {accountingDetails.hasQuestion && !accountingDetails.revealQuestionAnswer && (
                                        <FontAwesomeIcon icon={['fas', 'question-circle']}/>
                                    )}

                                    {!accountingDetails.hasQuestion && !Utils.isset(pitchBonus) && (
                                        <FontAwesomeIcon icon={['fas', 'exclamation-circle']} className="text-info"/>
                                    )}

                                    {Utils.isset(pitchBonus) && (
                                        <FontAwesomeIcon icon={['fas', 'crown']} className="text-warning"/>
                                    )}
                                </div>

                                <div className="summary-col">

                                    <OverlayTrigger placement="bottom"
                                                    overlay={<Tooltip
                                                        id="tooltip-disabled">{t('tooltips.months_advanced')}</Tooltip>}>
                                        <div className="d-flex align-items-center mb-1">
                                            <img src={monthsToGoIcon} alt="Months to go icon"/>

                                            <p>
                                                {((accountingDetails.hasQuestion && accountingDetails.revealQuestionAnswer)
                                                    || (!accountingDetails.hasQuestion && accountingDetails.hasAdvancedMonths)) && t('texts.month', {count: accountingDetails.monthsAdvanced || 0})}

                                                {accountingDetails.hasQuestion && !accountingDetails.revealQuestionAnswer && '???'}
                                                {!accountingDetails.hasQuestion && !accountingDetails.hasAdvancedMonths && ('-')}
                                            </p>
                                        </div>
                                    </OverlayTrigger>

                                    <OverlayTrigger placement="bottom"
                                                    overlay={<Tooltip
                                                        id="tooltip-disabled">{t('tooltips.for_market')}</Tooltip>}>
                                        <div className="d-flex align-items-center mb-1">
                                            <img src={balanceIcon} alt="Balance icon"/>

                                            {accountingDetails.hasQuestion && accountingDetails.revealQuestionAnswer && (
                                                <>{i18nUtil.formatMoney(accountingDetails.questionCapitalReceived || 0)}</>
                                            )}

                                            {accountingDetails.hasQuestion && !accountingDetails.revealQuestionAnswer && '???'}
                                            {!accountingDetails.hasQuestion && ('-')}
                                        </div>
                                    </OverlayTrigger>

                                    {isEsg && (
                                        <OverlayTrigger placement="bottom"
                                                        overlay={<Tooltip id="tooltip-disabled">{t('texts.acceleration_esg')}</Tooltip>}>
                                            <div className="d-flex align-items-center">
                                                <FontAwesomeIcon icon={['fas', 'percent']}/>

                                                {accountingDetails.acceleration}%
                                            </div>
                                        </OverlayTrigger>
                                    )}

                                    {!isEsg && (
                                        <OverlayTrigger placement="bottom"
                                                        overlay={<Tooltip
                                                            id="tooltip-disabled">{t('tooltips.total_month_cost')}</Tooltip>}>
                                            <div className="d-flex align-items-center">
                                                <FontAwesomeIcon icon={['far', 'minus-square']}/>

                                                {((accountingDetails.hasQuestion && accountingDetails.revealQuestionAnswer)
                                                    || (!accountingDetails.hasQuestion && accountingDetails.hasAdvancedMonths)) && (
                                                    <>{i18nUtil.formatMoney(accountingDetails.totalCost || 0)}</>
                                                )}

                                                {accountingDetails.hasQuestion && !accountingDetails.revealQuestionAnswer && '???'}
                                                {!accountingDetails.hasQuestion && !accountingDetails.hasAdvancedMonths && ('-')}
                                            </div>
                                        </OverlayTrigger>
                                    )}


                                </div>
                            </div>

                            <div className="bt-step mt-4 pt-3 pb-4">
                                <button className="btn btn-link text-decoration-none p-0"
                                        onClick={handleShowPreviousStep}>
                                    <FontAwesomeIcon icon={['fas', 'angle-left']}/>
                                </button>

                                <button className="btn btn-link text-decoration-none p-0"
                                        onClick={handleShowNextStep}>
                                    <FontAwesomeIcon icon={['fas', 'angle-right']}/>
                                </button>
                            </div>

                            <table className="account-resume">
                                <tbody>
                                <tr>
                                    <td>{t('texts.months_covered')}</td>
                                    <td>{totalAccountingDetails?.totals?.monthsAdvanced || '0'}</td>
                                </tr>
                                <tr>
                                    <td>{t('texts.startup_speed')}</td>
                                    <td>{totalAccountingDetails?.speed || '-'}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    )}

                    {!showContent && (
                        <div className="box-content justify-content-center align-items-center h-100 pb-2 d-flex">
                            <FontAwesomeIcon icon={['fas', 'lock']} className="fa-3x mb-5 text-danger"/>
                        </div>
                    )}
                </div>
            </SummaryCardLayout>

            {showModal && <JourneyMapModal onClose={handleCloseModal}/>}
        </>
    );
}

export default memo(JourneyMapSummaryCard);