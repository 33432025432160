import React, {useEffect, useMemo, useState} from "react";
import SummaryCardLayout from "components/cards/layouts/SummaryCardLayout";
import SimpleBar from 'simplebar-react';

import "./SkillsSummaryCard.scss";
import SkillItem from "./components/SkillItem";
import SkillShow from "./components/SkillShow";
import {useSelector} from "react-redux";
import {ACCELERATION_BONUSES, GAME_PHASES} from "config/CONSTANTS";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SkillStoreModal from "./components/SkillStoreModal";
import {Trans, useTranslation} from "react-i18next";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

import diversityIcon from 'assets/img/board/skillsGenders/diversity.svg';


export default function SkillsSummaryCard() {
    const {t, i18n} = useTranslation('pages/common/board/skills');

    const [hiredSkillsToShow, setHiredSkillsToShow] = useState([]);
    const [skillToShow, setSkillToShow] = useState(false);
    const [showContent, setShowContent] = useState(false);

    const showBoardForTeam = useSelector(state => state.session.showBoardForTeam);
    const currentPhase = useSelector(state => state.game.currentPhase);
    const hiredSkills = useSelector(state => state.teams[showBoardForTeam].skills);
    const accelerationBonuses = useSelector(state => state.teams[showBoardForTeam].accelerationBonuses);

    const isDemonstration = useSelector(state => state.game.isDemonstration);
    const hasVisitedMarket = useSelector(state => state.demo.state.hasVisitedMarket);
    const pulseMarket = useSelector(state => state.demo.state.pulseMarket);

    const [showSkillModal, setShowSkillModal] = useState(false);
    const handleShowSkillModal = () => setShowSkillModal(true);
    const handleCloseSkillModal = () => setShowSkillModal(false);

    const handleShowAllSkills = () => {
        if (!skillToShow) return;
        setSkillToShow(false);
    };

    useEffect(() => setShowContent(currentPhase > GAME_PHASES.PHASE_1), [currentPhase]);

    useEffect(() => {
        const skillsToShow = Object.values(hiredSkills).reduce((acc, hiredSkills) => {
            return [...acc, ...hiredSkills]
        }, []);

        setSkillToShow(false);
        setHiredSkillsToShow(skillsToShow);
    }, [showBoardForTeam, hiredSkills]);

    const cardClass = useMemo(() => {
        return (skillToShow !== false ? 'showing-skill ' : '')
            + (showContent ? 'border-warning ' : 'not-unlocked ')
            + (showContent && isDemonstration && pulseMarket && !hasVisitedMarket ? 'pulsating ' : '');
    }, [showContent, skillToShow, pulseMarket, hasVisitedMarket, isDemonstration]);


    const diversityTooltip = useMemo(() => {
        const raceBonus = accelerationBonuses[ACCELERATION_BONUSES.RACE_DIVERSITY] || '0';
        const genderBonus = accelerationBonuses[ACCELERATION_BONUSES.GENDER_DIVERSITY] || '0';

        return (<Tooltip id="diversity-tooltip" aria-multiline={true} >
            <Trans t={t} i18nKey={'texts.bonus'} values={{genderBonus, raceBonus}}/>
        </Tooltip>);
    }, [t, i18n.language, accelerationBonuses]);

    const totalDiversityBonus = useMemo(() => {
        return (accelerationBonuses[ACCELERATION_BONUSES.RACE_DIVERSITY] ?? 0)
            + (accelerationBonuses[ACCELERATION_BONUSES.GENDER_DIVERSITY] ?? 0)
    }, [accelerationBonuses])

    return (
        <>
            <SummaryCardLayout>
                <div className={"box d-inline-block skills-summary-card " + cardClass} onClick={handleShowAllSkills}>
                    <div className={"box-head text-uppercase " + (showContent ? 'bg-warning' : 'transparent')}>
                        {showContent && (<>{t('texts.title')} <span
                            className="ml-3">{hiredSkillsToShow.length}</span></>)}
                        {!showContent && (<>??????</>)}
                    </div>

                    {totalDiversityBonus > 0 && (
                        <OverlayTrigger placement="top" overlay={diversityTooltip}>
                            <div className="diversity-bonus-container">
                                <p className="mb-0 font-weight-bold pr-1 d-inline">
                                    +{totalDiversityBonus}%
                                </p>
                                <img src={diversityIcon} width="25"/>
                            </div>
                        </OverlayTrigger>
                    )}


                    <div className={"box-content " + (showContent ? '' : 'd-none')}>
                        {hiredSkillsToShow.length > 0 && (
                            <SimpleBar style={{maxHeight: 290}}>
                                {skillToShow === false && (
                                    <div className="animate__animated animate__fadeInLeft animate__faster">
                                        {hiredSkillsToShow.map(hiredSkill =>
                                            <SkillItem key={hiredSkill.id} hiredSkill={hiredSkill}
                                                       onClick={setSkillToShow}/>
                                        )}
                                    </div>
                                )}

                                {skillToShow !== false && (
                                    <SkillShow skill={skillToShow} onClose={handleShowAllSkills}/>)}
                            </SimpleBar>
                        )}

                        {hiredSkillsToShow.length === 0 && (
                            <div
                                className="w-100 h-100 text-center d-flex justify-content-center align-items-center pb-5 text-muted">
                                <h4 className="text-uppercase">{t('texts.no_hired')}</h4>
                            </div>
                        )}
                    </div>

                    <div
                        className={"box-content justify-content-center align-items-center " + (showContent ? 'd-none' : 'd-flex')}>
                        <FontAwesomeIcon icon={['fas', 'lock']} className="fa-3x mb-5 text-warning"/>
                    </div>

                    <div className={"box-footer " + (showContent ? 'bg-warning' : 'transparent')}>
                        <button id="btn-open-skill-market" type="button" hidden={skillToShow !== false}
                                className="w-100 h-100 bg-transparent border-0 text-uppercase"
                                onClick={handleShowSkillModal}>
                            {t("buttons.open_market")}
                        </button>

                        {skillToShow !== false && (
                            <button type="button" className="w-100 h-100 bg-transparent border-0 text-uppercase"
                                    onClick={handleShowAllSkills}>
                                {t("buttons.show_all")}
                            </button>
                        )}
                    </div>
                </div>
            </SummaryCardLayout>

            {showSkillModal && (<SkillStoreModal onClose={handleCloseSkillModal}/>)}
        </>
    );
}