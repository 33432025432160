import store from "redux/store";
import CardUtil from "utils/CardUtil";
import Utils from "utils/Utils";
import {GAME_STEPS} from "config/CONSTANTS";
import GameUtil from "../../../../../../utils/GameUtil";

function unpredictabilityImpact(team, unpredictability) {
    let impact = '';
    let revealOutcome = true;

    if (Utils.isset(unpredictability.relatedCard)) {
        // get hired cards from object with format: { STEP: [ARRAY_OF_CARDS] }
        const getCardsFn = hiredCards => Object.values(hiredCards).reduce((prev, curr) => [...prev, ...curr], []);

        // get founders and map to format [ {card: FOUNDER} ]
        const founders = Object.values(store.getState().teams[team].founders)
            .filter(c => !!c)
            .map(c => ({card: c}));

        const hiredCards = [
            ...getCardsFn((store.getState().teams[team].services)),
            ...getCardsFn((store.getState().teams[team].skills)),
            ...founders
        ];

        let hasRelatedCard = false;

        if (hiredCards.length) {
            for (let i = 0; i < hiredCards.length; i++) {
                if (hiredCards[i].card.id === unpredictability.relatedCard.id) {
                    hasRelatedCard = true;
                    break;
                }
            }
        }

        impact = hasRelatedCard ? 'POSITIVE' : 'NEGATIVE';
        revealOutcome = false;
    } else if (Utils.isset(unpredictability.neutralConsequenceDescription)) {
        impact = 'NEUTRAL';
    } else if (Utils.isset(unpredictability.positiveConsequenceDescription)) {
        impact = 'POSITIVE';
    } else {
        impact = 'NEGATIVE'
    }

    return {impact, revealOutcome};
}

const GameControlService = {
    unpredictabilityImpact,

    getQuestionsForTeams(step) {
        const questions = store.getState().cards.questions;
        const result = [];

        let questionsToUse = CardUtil.getQuestionsForStep(questions, step);


        /**
         * Strategy for selecting the questions:
         *
         * ESG - each startup card is associated with a question, so they are NOT selected randomly
         *
         * SMGX - choose randomly
         */

        if (GameUtil.isCurrentGameEsg()) {
            const teamStartups = store.getState().teams.startups;
            const teamCount = store.getState().game.teamCount;

            for (let i = 1; i <= teamCount; i++) {
                const startup = teamStartups[i];
                let question;

                if (startup)
                    question = questionsToUse.find(q => q.relatedStartup?.id === startup.id);


                // fallback - choose a random question
                if (!question)
                    question = Utils.randomFromArray(questionsToUse);

                questionsToUse = questionsToUse.filter(q => q.id !== question.id);
                result.push({team: i, question: question.id});
            }
        } else {
            // if is tournament, get only one!
            const stopAt = store.getState().game.isTournament ? 2 : 5;

            for (let i = 1; i < stopAt; i++) {
                const question = Utils.randomFromArray(questionsToUse);
                questionsToUse = questionsToUse.filter(q => q.id !== question.id);

                result.push({team: i, question: question.id});
            }
        }

        return result;
    },

    getUnpredictabilitiesForTeams() {
        const teamCount = store.getState().game.teamCount;
        const currentGameStep = store.getState().game.currentStep;
        const currentPhase = store.getState().game.currentPhase;
        const teams = store.getState().teams;
        const unpredictabilities = store.getState().cards.unpredictabilities;
        const result = [];

        const usedUnpredictabilitiesPerTeam = {};
        let allUsedUnpredictabilities = [];

        for (let i = 1; i < teamCount + 1; i++) {
            for (let j = GAME_STEPS.BETA; j < currentGameStep; j++) {
                let unp = teams[i].unpredictabilities[j];

                if (unp) {
                    if (!usedUnpredictabilitiesPerTeam[i]) usedUnpredictabilitiesPerTeam[i] = [];
                    usedUnpredictabilitiesPerTeam[i].push(unp.card);
                    allUsedUnpredictabilities.push(unp.card.id);
                }
            }
        }

        let unpredictabilitiesToUse = unpredictabilities.filter(unp => !allUsedUnpredictabilities.includes(unp.id) && unp.phases.includes(currentPhase));

        if (unpredictabilitiesToUse.length - allUsedUnpredictabilities.length >= teamCount) {
            for (let i = 1; i < teamCount + 1; i++) {
                const unpredictability = Utils.randomFromArray(unpredictabilitiesToUse);

                unpredictabilitiesToUse = unpredictabilitiesToUse.filter(unp => unp.id !== unpredictability.id);

                const {impact, revealOutcome} = unpredictabilityImpact(i, unpredictability);
                const unp = {team: i, unpredictability: unpredictability.id, revealOutcome};
                if (revealOutcome) unp.impact = impact;

                result.push(unp);
            }
        } else {
            const usedForThisRound = [];

            for (let i = 1; i < teamCount + 1; i++) {
                let assignedToOtherTeams = Object.entries(usedUnpredictabilitiesPerTeam).reduce((previousValue, [team, unpredictabilities]) => {
                    if (team !== i.toString()) previousValue = [...previousValue, ...unpredictabilities];
                    return previousValue;
                }, []);

                const toUse = [...unpredictabilitiesToUse, ...assignedToOtherTeams].filter(unp => !usedForThisRound.includes(unp.id) && unp.phases.includes(currentPhase));

                const unpredictability = Utils.randomFromArray(toUse);
                unpredictabilitiesToUse = unpredictabilitiesToUse.filter(unp => unp.id !== unpredictability.id);
                usedForThisRound.push(unpredictability.id);

                const {impact, revealOutcome} = unpredictabilityImpact(i, unpredictability);
                const unp = {team: i, unpredictability: unpredictability.id, revealOutcome};
                if (revealOutcome) unp.impact = impact;

                result.push(unp);
            }
        }

        return result;
    }
};

export default GameControlService;