import React, {useState} from "react";

import "./GameConfig.scss";
import DefineStartupsModal from "./components/DefineStartupsModal";
import {useSelector} from "react-redux";
import {GAME_STEPS, PLAYER_ACCESS_CONTROL} from "config/CONSTANTS";
import PreSelectedStartupBadge from "./components/PreSelectedStartupBadge";
import GameService from "services/gameMaster/GameService";
import ToastUtil from "utils/ToastUtil";
import OverlayUtil from "utils/OverlayUtil";
import LoggerService from "services/common/LoggerService";
import {useTranslation} from "react-i18next";
import SkillCardModel from "../../../../../models/SkillCardModel";
import useTranslationForGame from "../../../../../hooks/useTranslationForGame";


export default function GameConfig() {
    const {t} = useTranslation('pages/game_master/control_panel/game_config');
    const tfg = useTranslationForGame(t);

    const teamCount = useSelector(state => state.game.teamCount);
    const currentGameStep = useSelector(state => state.game.currentStep);
    const usePreSelectedStartups = useSelector(state => state.game.usePreSelectedStartups);
    const preSelectedStartups = useSelector(state => state.game.preSelectedStartups);
    const canJoinTeam = useSelector(state => state.game.canJoinTeam);
    const canHireCards = useSelector(state => state.game.canHireCards);
    const isTemporary = useSelector(state => state.game.isTemporary);
    const playerAccessControl = useSelector(state => state.game.playerAccessControl);
    const allowCertificateRequest = useSelector(state => state.game.allowCertificateRequest);

    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);


    async function updateGameConfig(params) {
        OverlayUtil.toggleLoadingOverlay(true, t('overlays.updating'));

        return GameService.updateGameConfig(params).then(result => {
            if (result) ToastUtil.toastSuccess(t('toasts.config_updated.title'), t('toasts.config_updated.message'));
            else ToastUtil.toastDanger(t('toasts.config_update_error.title'), t('toasts.config_update_error.message'));
            return result;
        }).catch(reason => {
            ToastUtil.toastDanger(t('toasts.config_update_error.title'), t('toasts.config_update_error.message'));
            console.error(reason);
            LoggerService.error(reason, {
                action: 'update game config - control panel',
                params
            });

            return false;
        }).finally(() => {
            OverlayUtil.toggleLoadingOverlay(false);
        });
    }

    const handleSetTeamCount = async (val) => {
        if (val < 1 || (val === 1 && !isTemporary) || val > 4) return;
        await updateGameConfig({teamCount: val});
    };

    const handleSetCanJoinTeams = async (val) => {
        await updateGameConfig({canJoinTeams: val});
    };

    const handleSetCanHireCards = async (val) => {
        await updateGameConfig({canHireCards: val})
    };

    const handleSetPlayerAccessControl = async (whoCanJoin) => {
        await updateGameConfig({playerAccessControl: whoCanJoin});
    };

    const handleSetAllowCertificateRequest = async (val) => {
        await updateGameConfig({allowCertificateRequest: val});
    };

    const handleSetUsePreSelectedStartups = async (val) => {
        if (val) handleShowModal();
        else await updateGameConfig({usePreSelectedStartups: val});
    };

    const handleSetDefaultStartups = async (startups) => {
        const preSelectedStartups = {};
        const preSelectedFoundersIcons = {};


        Object.entries(startups).forEach(([key, value]) => {
            preSelectedStartups[key.toString()] = value.id;
        });

        for (let i = 1; i < 5; i++) {
            preSelectedFoundersIcons[i] = {
                '0': SkillCardModel.randomIconNumber(),
                '1': SkillCardModel.randomIconNumber()
            };
        }

        const params = {
            usePreSelectedStartups: true,
            preSelectedStartups,
            preSelectedFoundersIcons
        };


        if (await updateGameConfig(params)) {
            handleCloseModal();
        }
    };

    return (<>
        <div className="w-100 game-config">
            <h4 className="mb-3">{t('texts.title_players')}</h4>

            <div className="form-group ">
                <label>{t('inputs.access_control.label')}</label>

                <select className="form-control" value={playerAccessControl}
                        onChange={(event) => handleSetPlayerAccessControl(event.target.value)}>
                    <option value={PLAYER_ACCESS_CONTROL.ALL}>{t('inputs.access_control.options.all')}</option>
                    <option
                        value={PLAYER_ACCESS_CONTROL.IN_SESSION}>{t('inputs.access_control.options.rejoin')}</option>
                    <option
                        value={PLAYER_ACCESS_CONTROL.NONE}>{t('inputs.access_control.options.none')}</option>
                </select>

                <div className="form-text small text-muted ml-2">{t('inputs.access_control.tip')}</div>
            </div>

            <div className="form-check mt-3">
                <input className="form-check-input" type="checkbox" checked={allowCertificateRequest}
                       id="allow-certificate-request-check"
                       onChange={event => handleSetAllowCertificateRequest(event.target.checked)}/>

                <label className="form-check-label" htmlFor="allow-certificate-request-check">
                    {t('inputs.allow_certificate_request.label')}
                </label>

                <div className="form-text small text-muted ml-n3">
                    {t('inputs.allow_certificate_request.tip')}
                </div>
            </div>

            <hr/>

            <h4 className="mb-3">{t('texts.title')}</h4>

            <div className="form-check mb-3">
                <input className="form-check-input" type="checkbox" checked={canJoinTeam} id="lock-teams-check"
                       onChange={event => handleSetCanJoinTeams(event.target.checked)}/>
                <label className="form-check-label" htmlFor="lock-teams-check">{t('inputs.lock_teams.label')}</label>
                <div className="form-text d-inline small text-muted ml-2">{t('inputs.lock_teams.tip')}</div>
            </div>

            <div className="form-check mb-3">
                <input className="form-check-input" type="checkbox" checked={canHireCards} id="can-hire-cards-check"
                       disabled={currentGameStep < GAME_STEPS.BETA || currentGameStep >= GAME_STEPS.END_GAME}
                       onChange={event => handleSetCanHireCards(event.target.checked)}/>
                <label className="form-check-label"
                       htmlFor="can-hire-cards-check">{t('inputs.can_hire_cards.label')}</label>
                <div className="form-text d-inline small text-muted ml-2">{t('inputs.can_hire_cards.tip')}</div>
            </div>


            <div className="mb-3">
                <div className="form-group d-flex align-items-center  game-info-input">
                    <label>{t('inputs.team_count.label')}</label>
                    <select className="form-control" onChange={(event) => handleSetTeamCount(event.target.value)}
                            value={teamCount} disabled={currentGameStep > GAME_STEPS.AWAITING}>

                        {isTemporary && (<option value={1}>1</option>)}

                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                    </select>
                    <div className="form-text small text-muted ml-2">{t('inputs.team_count.tip')}</div>
                </div>
            </div>

            <div>
                <div className="form-check-inline">
                    <input className="form-check-input" type="checkbox" checked={usePreSelectedStartups}
                           id="start-w-pre-defined-teams-check" disabled={currentGameStep > GAME_STEPS.ONBOARDING}
                           onChange={event => handleSetUsePreSelectedStartups(event.target.checked)}/>
                    <label className="form-check-label" htmlFor="start-w-pre-defined-teams-check">
                        {tfg('inputs.start_with_pre_selected_startups.label')}
                    </label>
                    <div
                        className="form-text small text-muted ml-2">{t('inputs.start_with_pre_selected_startups.tip')}</div>
                </div>

                {usePreSelectedStartups === true && (<>
                    <p className="mt-3 mb-0">{tfg('texts.pre_defined_startups')}</p>
                    <div className="team-startups">
                        <PreSelectedStartupBadge startup={preSelectedStartups[1]} team={1}/>
                        {teamCount > 1 && <PreSelectedStartupBadge startup={preSelectedStartups[2]} team={2}/>}
                        {teamCount > 2 && <PreSelectedStartupBadge startup={preSelectedStartups[3]} team={3}/>}
                        {teamCount > 3 && <PreSelectedStartupBadge startup={preSelectedStartups[4]} team={4}/>}
                    </div>
                </>)}
            </div>

            <hr/>
        </div>

        {showModal && (<DefineStartupsModal onClose={handleCloseModal} onSubmit={handleSetDefaultStartups}/>)}
    </>);
};