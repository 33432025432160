import React, {useState} from "react";
import {useSelector} from "react-redux";
import ValuePropositionsModal from "./ValuePropositionsModal";
import {Trans, useTranslation} from "react-i18next";
import CardPlaceholder from "../CardPlaceholder";
import ValuePropositionCard, {VALUE_PROPOSITION_LAYOUT} from "components/cards/ValuePropositionCard";

import "./ValuePropositionTab.scss";
import useTranslationForGame from "../../../../../../../hooks/useTranslationForGame";

export default function ValuePropositionTab({onToggleModal, slideFrom}) {
    const {t} = useTranslation('pages/common/board/conception_modal', {keyPrefix: 'components.value_propositions_tab'});
    const tfg = useTranslationForGame(t);

    const isTournament = useSelector(state => state.game.isTournament);
    const showBoardForTeam = useSelector(state => state.session.showBoardForTeam);
    const valuePropositions = useSelector(state => state.teams[showBoardForTeam].valuePropositions);

    const [valuePropositionDataToDisplayInForm, setValuePropositionDataToDisplayInForm] = useState(null);
    const [selectedValuePropositionDataToDisplayInForm, setSelectedValuePropositionDataToDisplayInForm] = useState(null);


    const [showValuePropositionsModal, setShowValuePropositionsModal] = useState(false);
    const handleCloseValuePropositionsModal = () => {
        setShowValuePropositionsModal(false);
        onToggleModal(false);
    }
    const handleShowValuePropositionsModal = (number) => {
        setValuePropositionDataToDisplayInForm(number);
        setShowValuePropositionsModal(true);
        setSelectedValuePropositionDataToDisplayInForm(valuePropositions[number] || null);
        onToggleModal(true);
    };

    return (
        <>
            <div className="conception-modal-value-proposition-tab">
                <div className={"w-100 animate__animated animate__faster " + slideFrom}>
                    <h4>
                        <Trans t={t} i18nKey={tfg('texts.title', {}, true)}
                               components={{1: <span className='text-warning'/>}}/>
                    </h4>

                    {isTournament && (<p>{tfg('texts.description')}</p>)}
                </div>

                <div className={"w-100 animate__animated animate__faster pb-4 " + slideFrom}>
                    <div className="w-100 d-flex justify-content-center mt-4">
                        <div className="text-center">
                            {(!valuePropositions || !valuePropositions[0]) && (
                                <CardPlaceholder color="info" onClick={() => handleShowValuePropositionsModal(0)}/>
                            )}
                            {valuePropositions && valuePropositions[0] && (
                                <ValuePropositionCard valueProposition={valuePropositions[0]}
                                                      selectable={false}
                                                      layout={VALUE_PROPOSITION_LAYOUT.FULL}/>
                            )}

                            <button
                                className={"btn btn-info text-white " + (!valuePropositions || !valuePropositions[0] ? 'pulsating' : '')}
                                onClick={() => handleShowValuePropositionsModal(0)}>
                                {valuePropositions && valuePropositions[0] ? t('buttons.change') : t('buttons.select')}
                            </button>
                        </div>
                        <div className="text-center">
                            {(!valuePropositions || !valuePropositions[1]) && (
                                <CardPlaceholder color="info" onClick={() => handleShowValuePropositionsModal(1)}/>
                            )}
                            {valuePropositions && valuePropositions[1] && (
                                <ValuePropositionCard valueProposition={valuePropositions[1]}
                                                      selectable={false}
                                                      layout={VALUE_PROPOSITION_LAYOUT.FULL}/>
                            )}

                            <button
                                className={"btn btn-info text-white " + (!valuePropositions || !valuePropositions[1] ? 'pulsating' : '')}
                                onClick={() => handleShowValuePropositionsModal(1)}>
                                {valuePropositions && valuePropositions[1] ? t('buttons.change') : t('buttons.select')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {showValuePropositionsModal && (<ValuePropositionsModal onClose={handleCloseValuePropositionsModal}
                                                                    number={valuePropositionDataToDisplayInForm}
                                                                    selected={selectedValuePropositionDataToDisplayInForm}/>)}
        </>
    );
}