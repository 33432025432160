import React, {memo, useMemo} from "react";
import Utils from "utils/Utils";
import GameUtil from "../../../../../../utils/GameUtil";

const PreSelectedStartupBadge = ({startup, team}) => {
    const isEsg = useMemo(() => GameUtil.isCurrentGameEsg(), []);

    if (!Utils.isset(startup))
        return (<></>);

    return (
        <span className={"badge badge-team-" + team}>
            #{team} - {startup.title} - {startup.sector} {!isEsg && '- ' + startup.businessModel}
        </span>
    );
};

export default memo(PreSelectedStartupBadge);