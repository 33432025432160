import React, {useEffect, useState} from "react";
import {Button, Modal} from "react-bootstrap";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

export default function TeamPlayersModal({onClose}) {
    const {t} = useTranslation('pages/common/board/team_players_modal');
    const [team, setTeam] = useState([]);

    const isGameMaster = useSelector(state => state.session.isGameMaster);
    const showBoardForTeam = useSelector(state => state.session.showBoardForTeam);

    const player = useSelector(state => state.session.user);
    const players = useSelector(state => state.game.players);

    useEffect(() => {
        if (isGameMaster) {
            const teammates = players.filter(p => p.team === showBoardForTeam);
            setTeam([...teammates]);
        } else {
            const teammates = players.filter(p => p.team === showBoardForTeam && p.id !== player.id);
            setTeam([player, ...teammates]);
        }
    }, [player, showBoardForTeam, players, isGameMaster]);

    return (
        <Modal show={true} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>{t('texts.title')}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <ul className="list-group list-group-flush">
                    {team.map((user, index) => {
                        return (
                            <li key={index}
                                className={"list-group-item d-flex justify-content-between align-items-center " + (player.id === user.id ? 'current' : '')}>
                                <div>
                                    <p className="m-0 font-weight-bold">{user.nickname}</p>
                                    {user?.localPlayers && user?.localPlayers?.length > 0 &&
                                        (
                                            <span>{user.localPlayers.map(lp => lp.nickname).join(', ')}</span>
                                        )}
                                </div>
                                <span
                                    className="badge badge-primary badge-pill ml-2">
                                        {user?.localPlayers?.length ? user?.localPlayers?.length + 1 : 1}
                                    </span>
                            </li>
                        )
                    })}

                    {team.length === 0 && (
                        <li className="list-group-item text-muted text-center py-1">{t('texts.empty')}</li>
                    )}
                </ul>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>{t('buttons.close')}</Button>
            </Modal.Footer>
        </Modal>
    )
}