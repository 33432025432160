import React, {useEffect, useMemo, useState} from "react";
import {Nav, Tab} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Phase1 from "./phases/Phase1";
import Phase2 from "./phases/Phase2";
import Phase3 from "./phases/Phase3";
import PhaseEnd from "./phases/PhaseEnd";

import "./GameControl.scss";
import SwalModalUtil from "utils/SwalModalUtil";
import QuestionAnswerModal from "./components/QuestionAnswerModal/QuestionAnswerModal";
import {useSelector} from "react-redux";
import {GAME_PHASES, GAME_STEPS, GAME_STEPS_WITH_QUESTIONS} from "config/CONSTANTS";
import store from "redux/store";
import OverlayUtil from "utils/OverlayUtil";
import GameService from "services/gameMaster/GameService";
import ToastUtil from "utils/ToastUtil";
import GameControlService from "./services/GameControlService";
import UnpredictabilityModal from "./components/UnpredictabilityModal/UnpredictabilityModal";
import i18nUtil from "utils/i18nUtil";
import TeamRankingModal from "./components/TeamRankingModal/TeamRankingModal";
import LoggerService from "services/common/LoggerService";
import {useTranslation} from "react-i18next";
import AdvanceGameButtons from "./components/AdvanceGameButtons";
import GameUtil from "../../../../../utils/GameUtil";

const TABS = [
    'phase_1', 'phase_2', 'phase_3', 'phase_end'
];

export default function GameControl() {
    const {t} = useTranslation(['pages/game_master/control_panel/game_control', 'common']);

    const currentGamePhase = useSelector(state => state.game.currentPhase);
    const currentGameStep = useSelector(state => state.game.currentStep);
    const hasRevealedWinners = useSelector(state => state.game.hasRevealedWinners);
    const isPocket = useSelector(state => state.game.isPocket);

    const isEsg = useMemo(() => GameUtil.isCurrentGameEsg(), []);

    const [tabToShow, setTabToShow] = useState(TABS[0]);

    const [showTeamRankingModal, setShowTeamRankingModal] = useState(false);
    const handleShowTeamRankingModal = () => setShowTeamRankingModal(true);
    const handleCloseTeamRankingModal = () => setShowTeamRankingModal(false);


    const [showQuestionsForStep, setShowQuestionsForStep] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);

    const [showUnpredictabilitiesForStep, setShowUnpredictabilitiesForStep] = useState(false);
    const [showUnpredictabilityModal, setShowUnpredictabilityModal] = useState(false);
    const handleShowUnpredictabilityModal = () => setShowUnpredictabilityModal(true);
    const handleCloseUnpredictabilityModal = () => setShowUnpredictabilityModal(false);

    const handleShowQuestionsForStep = (step) => {
        setShowQuestionsForStep(step);
        handleShowModal();
    };


    const handleDrawUnpredictabilities = (step) => {
        if (step < GAME_STEPS.PITCH_1 || !GAME_STEPS_WITH_QUESTIONS.includes(step)) return;

        if (store.getState().teams[1].unpredictabilities[step]) {
            SwalModalUtil.infoModal(t('confirms.cannot_send_unpredictability.title'), t('confirms.cannot_send_unpredictability.message'));
            return;
        }

        SwalModalUtil.confirmModal(t('confirms.send_unpredictability.title'), t('confirms.send_unpredictability.message', {step: i18nUtil.stepName(step)}))
            .then(async result => {
                if (result === true) {
                    let params = {};

                    try {
                        OverlayUtil.toggleLoadingOverlay(true, t('overlays.sending_unpredictability'));
                        const unpredictabilities = GameControlService.getUnpredictabilitiesForTeams();
                        params = {step, unpredictabilities};

                        if (await GameService.updateTeamsUnpredictabilities(params)) {
                            ToastUtil.toastSuccess(t('toasts.send_unpredictability_success.title'), t('toasts.send_unpredictability_success.message'));
                        } else {
                            ToastUtil.toastDanger(t('toasts.send_unpredictability_error.title'), t('toasts.send_unpredictability_error.message'));
                        }
                    } catch (e) {
                        console.error(e);
                        ToastUtil.toastDanger(t('toasts.send_unpredictability_error.title'), t('toasts.send_unpredictability_error.message'));

                        LoggerService.error(e, {
                            action: 'send unpredictabilities - control panel',
                            params
                        });
                    } finally {
                        OverlayUtil.toggleLoadingOverlay(false);
                    }
                }
            });
    };

    const handleShowUnpredictabilities = (step) => {
        setShowUnpredictabilitiesForStep(step);
        handleShowUnpredictabilityModal();
    };

    useEffect(() => {
        if (currentGameStep === GAME_STEPS.END_GAME) setTabToShow(TABS[3]);
        else setTabToShow(TABS[currentGamePhase - 1]);

    }, [currentGamePhase, currentGameStep]);

    return (<>
        <div className="game-control mb-3">

            <div className="mt-4">
                <h4>{t('texts.title')}</h4>

                {isPocket && (<p className="text-success">{t('texts.game_ended_pocket')}</p>)}

                <div className="game-phases-container mt-3">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="phase_1" activeKey={tabToShow}>

                        <Nav variant="pills">
                            <Nav.Item>
                                <Nav.Link eventKey="phase_1" onSelect={() => setTabToShow('phase_1')}>
                                    {currentGamePhase > GAME_PHASES.PHASE_1 && (
                                        <FontAwesomeIcon icon={['fas', 'check']} className="mr-2"/>)}
                                    {currentGamePhase === GAME_PHASES.PHASE_1 && (
                                        <FontAwesomeIcon icon={['fas', 'play']} className="mr-2"/>)}
                                    {t('common:game_phases.phase_1')}
                                </Nav.Link>
                            </Nav.Item>


                            <Nav.Item>
                                <Nav.Link eventKey="phase_2" onSelect={() => setTabToShow('phase_2')}>
                                    {currentGamePhase > GAME_PHASES.PHASE_2 && (
                                        <FontAwesomeIcon icon={['fas', 'check']} className="mr-2"/>)}
                                    {currentGamePhase === GAME_PHASES.PHASE_2 && (
                                        <FontAwesomeIcon icon={['fas', 'play']} className="mr-2"/>)}
                                    {t('common:game_phases.phase_2')}
                                </Nav.Link>
                            </Nav.Item>


                            <Nav.Item hidden={isEsg === true}>
                                <Nav.Link eventKey="phase_3" onSelect={() => setTabToShow('phase_3')}>
                                    {currentGamePhase >= GAME_PHASES.PHASE_3 && currentGameStep === GAME_STEPS.END_GAME && (
                                        <FontAwesomeIcon icon={['fas', 'check']} className="mr-2"/>)}
                                    {currentGamePhase === GAME_PHASES.PHASE_3 && currentGameStep < GAME_STEPS.END_GAME && (
                                        <FontAwesomeIcon icon={['fas', 'play']} className="mr-2"/>)}
                                    {t('common:game_phases.phase_3')}
                                </Nav.Link>
                            </Nav.Item>

                            <Nav.Item>
                                <Nav.Link eventKey="phase_end" onSelect={() => setTabToShow('phase_end')}>
                                    {currentGameStep >= GAME_STEPS.END_GAME && hasRevealedWinners && (
                                        <FontAwesomeIcon icon={['fas', 'check']} className="mr-2"/>)}
                                    {currentGameStep >= GAME_STEPS.END_GAME && !hasRevealedWinners && (
                                        <FontAwesomeIcon icon={['fas', 'play']} className="mr-2"/>)}
                                    {t('common:game_phases.end_game')}
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>

                        <Tab.Content>
                            <hr/>

                            <Tab.Pane eventKey="phase_1">
                                <Phase1 showQuestionsCallback={handleShowQuestionsForStep}/>
                            </Tab.Pane>

                            <Tab.Pane eventKey="phase_2">
                                <Phase2 showQuestionsCallback={handleShowQuestionsForStep}
                                        drawUnpredictabilitiesCallback={handleDrawUnpredictabilities}
                                        showUnpredictabilitiesCallback={handleShowUnpredictabilities}/>
                            </Tab.Pane>

                            <Tab.Pane eventKey="phase_3">
                                <Phase3 showQuestionsCallback={handleShowQuestionsForStep}
                                        drawUnpredictabilitiesCallback={handleDrawUnpredictabilities}
                                        showUnpredictabilitiesCallback={handleShowUnpredictabilities}/>
                            </Tab.Pane>

                            <Tab.Pane eventKey="phase_end">
                                <PhaseEnd showTeamRanking={handleShowTeamRankingModal}/>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>

                </div>

                <span className="clearfix"/>

                <AdvanceGameButtons/>
            </div>
        </div>

        {showTeamRankingModal && (<TeamRankingModal onClose={handleCloseTeamRankingModal}/>)}
        {showModal && (<QuestionAnswerModal onClose={handleCloseModal} step={showQuestionsForStep}/>)}
        {showUnpredictabilityModal && (
            <UnpredictabilityModal onClose={handleCloseUnpredictabilityModal} step={showUnpredictabilitiesForStep}/>)}
    </>);
}