import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useSelector} from "react-redux";
import {OVERLAY_THEME} from "../config/CONSTANTS";

import "./LoadingOverlay.scss";
import {ReactComponent as LogoDark} from "assets/img/logos/svg/default_2.svg";
import {ReactComponent as LogoLight} from "assets/img/logos/svg/default.svg";

const MAX_VISIBLE_TIME = 1000; // 1 second
const MIN_VISIBLE_TIME = 250; // in ms
const VISIBILITY_TIMEOUT = 600; // in ms

export default function LoadingOverlay() {
    const overlayData = useSelector(state => state.overlay);

    const [show, setShow] = useState(false);
    const [shownAt, setShownAt] = useState(null);
    const [theme, setTheme] = useState(OVERLAY_THEME.DARK);
    const [overlayClass, setOverlayClass] = useState(OVERLAY_THEME.DARK);
    const [message, setMessage] = useState(false);

    // timeouts
    const [hideTimeout, setHideTimeout] = useState(null);
    const [visibilityTimeout, setVisibilityTimeout] = useState(null);

    function animateHide() {
        // this is a 2-step animation
        // first 'animate' a fade out
        // then 'hide' this component

        const visibleFor = (new Date()) - shownAt;
        const hideInMs = Math.max(MIN_VISIBLE_TIME, MAX_VISIBLE_TIME - visibleFor);

        const newHideTimeout = setTimeout(() => {
            setOverlayClass(theme + ' fading-out animate__animated animate__fadeOut');

            const newVisibilityTimeout = setTimeout(() => {
                setShow(false);
            }, VISIBILITY_TIMEOUT);

            setVisibilityTimeout(newVisibilityTimeout);
        }, hideInMs);

        setHideTimeout(newHideTimeout);
    }

    function clearTimeouts() {
        clearTimeout(hideTimeout);
        clearTimeout(visibilityTimeout);
    }

    useEffect(() => {
        let newOverlayClass = overlayData.theme;

        clearTimeouts();

        // keep previous message if overlayData.show === false
        // prevents the message from returning to the 'dots' animation
        if (overlayData.show)
            setMessage(overlayData.message);


        if (!overlayData.show) {
            animateHide();
        } else if (!show) {
            if (overlayData.animate)
                newOverlayClass += ' animate__animated animate__fadeIn';

            setOverlayClass(newOverlayClass);
            setTheme(overlayData.theme);
            setShow(true);
            setShownAt(new Date());
        }
    }, [overlayData]);

    useEffect(clearTimeouts, []);

    if (!show) return null;

    return (<div id="loading-overlay" className={overlayClass}>
        <div
            className="logo-container mb-3 d-flex justify-content-center align-items-center animate__animated animate__fastest animate__fadeInDown">
            {theme === OVERLAY_THEME.DARK && <LogoDark/>}
            {theme === OVERLAY_THEME.DEFAULT && <LogoLight/>}
        </div>

        <div className="text-container animate__animated animate__fadeIn">
            {message && (
                <h6 className="text-message text-uppercase">
                    <FontAwesomeIcon icon={['fas', 'circle-notch']} spin={true} className="mr-2"/>
                    {message}
                </h6>
            )}

            {!message && (
                <h6 className="loading-circles"><span>.</span><span>.</span><span>.</span></h6>
            )}
        </div>

        <div className="bg position-absolute top left right bottom"/>
    </div>)
}