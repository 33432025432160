import React, {useMemo} from "react";

import "../../smgx/components/Summary.scss";
import {useSelector} from "react-redux";
import i18nUtil from "../../../../../../../utils/i18nUtil";
import {useTranslation} from "react-i18next";
import useTranslationForGame from "../../../../../../../hooks/useTranslationForGame";

export default function StepDetails({step, bonusDetails, bonusGiven, color}) {
    const {t} = useTranslation('pages/common/board/accounting', {keyPrefix: 'texts'});
    const tfg = useTranslationForGame(t);

    const showBoardForTeam = useSelector(state => state.session.showBoardForTeam);
    const accountingDetails = useSelector(state => state.teams[showBoardForTeam].accounting.phases[2].steps[step]);

    const impactElementClass = useMemo(() => {
        return  accountingDetails.acceleration < 0 ? 'text-danger' : 'text-success';
    }, [accountingDetails]);

    return (<div className={"text-white p-2 rounded border-" + color}>
        <div>
            <h5 className={"text-uppercase text-"+color}>
                {t('step_summary', {step: i18nUtil.stepName(step)})}
            </h5>

            <div className="row accounting-summary mb-3 pt-1">
                <div className="col-6 details">
                    <div>
                        <p>{t('extra_costs')}</p>
                        <p>{i18nUtil.formatMoney(accountingDetails.extraCosts || 0)}</p>
                    </div>
                    <div>
                        <p>{t('received_for_market')}</p>
                        <p>{i18nUtil.formatMoney(accountingDetails.questionCapitalReceived || 0)}</p>
                    </div>

                    <div>
                        <p>{tfg('acceleration_bonus')}</p>
                        <p>{i18nUtil.formatPercent(bonusGiven || 0)}</p>
                    </div>
                </div>

                <div className="col-6 details">
                    <div>
                        <p>{t('months')}</p>
                        <p>{accountingDetails.monthsAdvanced}</p>
                    </div>
                    <div>
                        <p>{tfg('revenue')}</p>
                        <p>{i18nUtil.formatDecimal(accountingDetails.revenue || 0)}</p>
                    </div>
                    <div>
                        <p>{tfg('acceleration')}</p>
                        <p className={"font-weight-bold "+ impactElementClass}>
                            {i18nUtil.formatPercent(accountingDetails.acceleration || 0)}
                        </p>
                    </div>
                </div>
            </div>
        </div>

        {bonusDetails.length > 0 && (<>
            <p className="text-info">{t('step_related_hires')}</p>

            <div className="d-flex flex-wrap hired-cards-container">
                {bonusDetails}
            </div>
        </>)}
    </div>);
}