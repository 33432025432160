import React, {useMemo} from "react";

export default function NpsScore({value, disabled, onChange}) {
    const id = useMemo(() => 'nps-score-' + value, [value]);

    return (
        <li className="nps-score-option">
            <input name="score"
                   type="radio"
                   id={id}
                   required={true}
                   value={value}
                   onChange={() => onChange(value)}
                   disabled={disabled}/>

            <label htmlFor={id}>{value}</label>
        </li>
    );
}