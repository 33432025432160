import React, {memo} from "react";

import i18nUtil from "utils/i18nUtil";
import GameEventCardLayout from "./GameEventCardLayout/GameEventCardLayout";
import img from "assets/img/board/ic_best_pitch.svg";
import {Trans, useTranslation} from "react-i18next";
import useTranslationForGame from "../../../../../../hooks/useTranslationForGame";

const PitchBonusCard = ({data}) => {
    const {t} = useTranslation('pages/common/board/game_events', {keyPrefix: 'components.pitch_bonus_card'});
    const tfg = useTranslationForGame(t);
    const {step, bonus} = data;

    return (
        <GameEventCardLayout title={i18nUtil.stepName(step)} color={'success'} leftContent={(
            <div>
                <p><Trans t={t} i18nKey={tfg('texts.title', {}, true)} components={{1: <b/>}}/></p>
                <h5 className="mt-2 font-weight-bold">{i18nUtil.formatPercent(bonus || 0)}</h5>
            </div>
        )} rightContent={(
            <img alt="Best pitch badge" src={img} width={110}/>
        )}/>
    );
}

export default memo(PitchBonusCard);

