import React from "react";
import i18nUtil from "utils/i18nUtil";

import "./PhaseEventSeparator.scss";

export default function PhaseEventSeparator({data}) {
    return (
        <div className="phase-event-separator mx-4 h-100 text-white d-flex justify-content-center align-items-center">
            <div className="">
                <h4 className="mb-0 pl-2 text-uppercase">{i18nUtil.phaseName(data.phase)}</h4>
            </div>
        </div>
    );
}