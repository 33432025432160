import React from "react";
import {Navigate} from "react-router-dom";
import Utils from "utils/Utils";
import AuthService from "services/gameMaster/AuthService";
import SessionPersistenceUtil, {PLAYER_KEY} from "utils/SessionPersistenceUtil";
import store from "redux/store";
import {GAME_MASTER_ROUTES} from "../ROUTES";
import NotFoundError from "pages/common/errors/NotFoundError";

export default function PlayerRoute({children}) {
    const isAuthenticated = AuthService.isAuthenticated();
    const isGameMaster = store.getState().session.isGameMaster;
    const gameUrl = SessionPersistenceUtil.get(PLAYER_KEY)?.gameUrl;

    if (isAuthenticated && isGameMaster)
        return <Navigate to={{pathname: GAME_MASTER_ROUTES.CONTROL_PANEL}}/>

    if (isAuthenticated)
        return children;

    if (Utils.isset(gameUrl))
        return (<Navigate to={{pathname: gameUrl}}/>);

    return (<NotFoundError/>);
}