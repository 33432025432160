import React, {useEffect, useState} from "react";

import {useSelector} from "react-redux";
import Utils from "utils/Utils";
import {GAME_STEPS} from "config/CONSTANTS";
import TeamCard from "./components/TeamCard";
import {useTranslation} from "react-i18next";

export default function TeamControl() {
    const {t, i18n} = useTranslation('pages/game_master/control_panel/team_control');

    const players = useSelector(state => state.game.players);
    const currentStep = useSelector(state => state.game.currentStep);
    const teamCount = useSelector(state => state.game.teamCount);
    const selectedStartups = useSelector(state => state.teams.startups);

    const [canAccessTeamScreens, setCanAccessTeamScreens] = useState(false);
    const [totalPlayersPerTeam, setTotalPlayersPerTeam] = useState({
        1: {online: 0, total: 0},
        2: {online: 0, total: 0},
        3: {online: 0, total: 0},
        4: {online: 0, total: 0},
        'noTeam': {online: 0, total: 0}
    });

    useEffect(() => {
        const playersPerTeam = {
            1: {online: 0, total: 0},
            2: {online: 0, total: 0},
            3: {online: 0, total: 0},
            4: {online: 0, total: 0},
            'noTeam': {online: 0, total: 0}
        };

        players.forEach(p => {
            if (Utils.isset(p.team) && p.team !== false) {
                if (p.isConnected) playersPerTeam[p.team].online++;
                playersPerTeam[p.team].total++;
            } else {
                if (p.isConnected) playersPerTeam.noTeam.online++;
                playersPerTeam.noTeam.total++
            }
        });

        setTotalPlayersPerTeam(playersPerTeam)
    }, [players]);

    useEffect(() => {
        setCanAccessTeamScreens(currentStep > GAME_STEPS.AWAITING);
    }, [currentStep]);


    return (<div className={"team-control mt-4"}>
        <h4>{t('texts.title')}</h4>

        <div className="w-100">
            <TeamCard number={1} disabled={!canAccessTeamScreens}
                      playersOnline={totalPlayersPerTeam[1].online}
                      totalPlayers={totalPlayersPerTeam[1].total}
                      startup={selectedStartups[1]}/>

            <TeamCard number={2} disabled={!canAccessTeamScreens || teamCount < 2}
                      playersOnline={totalPlayersPerTeam[2].online}
                      totalPlayers={totalPlayersPerTeam[2].total}
                      startup={selectedStartups[2]}/>
        </div>

        <div className="w-100">
            <TeamCard number={3} disabled={!canAccessTeamScreens || teamCount < 3}
                      playersOnline={totalPlayersPerTeam[3].online}
                      totalPlayers={totalPlayersPerTeam[3].total}
                      startup={selectedStartups[3]}/>


            <TeamCard number={4} disabled={!canAccessTeamScreens || teamCount < 4}
                      playersOnline={totalPlayersPerTeam[4].online}
                      totalPlayers={totalPlayersPerTeam[4].total}
                      startup={selectedStartups[4]}/>
        </div>

        <div className="mt-2 w-100 d-flex">
            <p className="font-weight-bold mb-0 mr-2">{t('texts.without_a_team')}</p>
            <p>{t('texts.players_online', {
                online: totalPlayersPerTeam.noTeam.online,
                total: totalPlayersPerTeam.noTeam.total
            })}</p>
        </div>
    </div>);
}