export const PLAYER_KEY = '__p';
export const GAME_MASTER_KEY = '__gm';
export const AUDIO_CONFIG_KEY = '__audio';


const SessionPersistenceUtil = {
    persist(key, data) {
        localStorage.setItem(key, JSON.stringify(data));
    },

    update(key, data) {
        let keyData = this.get(key);
        keyData = keyData ? {...keyData, ...data} : data;
        this.persist(key, keyData);
    },

    get(key) {
        return JSON.parse(localStorage.getItem(key));
    },

    clear(key) {
        localStorage.removeItem(key);
    }
};

export default SessionPersistenceUtil;