import React from "react";
import {Button, Modal} from "react-bootstrap";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

export default function TournamentPlayerInfoModal({onClose}) {
    const {t} = useTranslation('pages/common/board/team_players_modal');

    const tournamentInfo = useSelector(state => state.game.tournamentInfo);
    const player = useSelector(state => state.session.user);

    return (
        <Modal show={true} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Informações da sessão</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <h5 className="mb-3">Jogando como:</h5>

                <div className="row">
                    <div className="form-group col-12 col-md-6">
                        <label>Apelido</label>
                        <p>{player.nickname ?? '-'}</p>
                    </div>

                    <div className="form-group col-12 col-md-6">
                        <label>E-mail</label>
                        <p>{player.email ?? '-'}</p>
                    </div>

                </div>


                <hr/>

                {tournamentInfo.canRequestCertificate && (<>
                    <h6 className="text-success font-weight-bold">
                        Você receberá seu certificado em até 2 horas, após finalizar esta sessão!
                    </h6>
                </>)}

                {!tournamentInfo.canRequestCertificate && (<>
                    <h6 className="text-info">
                        Parece que esta não é a sua primeira rodada.
                        Por isso, nenhum certificado será gerado ao finalizar a sessão.
                    </h6>
                </>)}

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>{t('buttons.close')}</Button>
            </Modal.Footer>
        </Modal>
    )
}