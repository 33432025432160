import React, {useMemo} from 'react';
import TermsLayout from "../TermsLayout";
import {useTranslation} from "react-i18next";
import TermsPtBR from "./partials/TermsPtBR";
import TermsEn from "./partials/TermsEn";

export default function TermsOfUse() {
    const {i18n} = useTranslation();

    const languageToDisplay = useMemo(() => {
        if (i18n.language.startsWith('pt')) return 'pt';
        return 'en';
    }, [i18n.language]);

    return <TermsLayout version={'v1.0.0'} updatedAt={'2022/03/20'}>
        {languageToDisplay === 'pt' && (<TermsPtBR/>)}
        {languageToDisplay === 'en' && <TermsEn/>}
    </TermsLayout>
}
