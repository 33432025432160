import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import Utils from "utils/Utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";

export default function StatusOnboarding() {
    const {t} = useTranslation('pages/game_master/control_panel/step_status', {keyPrefix: 'components.status_onboarding.texts'});
    const players = useSelector(state => state.game.players);

    const [totalPlayersPerTeam, setTotalPlayersPerTeam] = useState({1: 0, 2: 0, 3: 0, 4: 0, 'noTeam': 0});

    useEffect(() => {
        const playersPerTeam = {1: 0, 2: 0, 3: 0, 4: 0, 'noTeam': 0};

        players.forEach(p => {
            if (p.isConnected) {
                if (Utils.isset(p.team) && p.team !== false) playersPerTeam[p.team]++;
                else playersPerTeam.noTeam++;
            }
        });
        setTotalPlayersPerTeam(playersPerTeam);
    }, [players]);

    return (
        <div>
            {totalPlayersPerTeam.noTeam > 0 && (
                <h6 className="text-warning">
                    <FontAwesomeIcon icon={['fas', 'exclamation-circle']} className="mr-2"/>
                    {t('not_ready', {count: totalPlayersPerTeam.noTeam})}
                </h6>
            )}

            {totalPlayersPerTeam.noTeam === 0 && (
                <h6 className="text-success">
                    <FontAwesomeIcon icon={['fas', 'check-circle']} className="mr-2"/>
                    {t('ready')}
                </h6>
            )}
        </div>
    );
}