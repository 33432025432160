import {applyMiddleware, combineReducers, compose, createStore} from "redux";
import thunk from "redux-thunk";

import {CardsSlice} from "./slices/cardsSlice";
import {GameSlice} from "./slices/gameSlice";
import {OverlaySlice} from "./slices/overlaySlice";
import {SessionSlice} from "./slices/sessionSlice";
import {TeamsSlice} from "./slices/teamsSlice";
import {MessagesSlice} from "./slices/messagesSlice";
import {ThemeSlice} from "./slices/themeSlice";
import {DemoSlice} from "./slices/demoSlice";
import {TournamentSlice} from "./slices/tournamentSlice";

// devtools for debugging in dev environment.
const devTools =
    // eslint-disable-next-line no-undef
    process.env.NODE_ENV !== "production" && window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
        window.__REDUX_DEVTOOLS_EXTENSION__()
        : (a) => a;


const store = createStore(
    combineReducers({
        cards: CardsSlice.reducer,
        game: GameSlice.reducer,
        overlay: OverlaySlice.reducer,
        session: SessionSlice.reducer,
        teams: TeamsSlice.reducer,
        messages: MessagesSlice.reducer,
        theme: ThemeSlice.reducer,
        demo: DemoSlice.reducer,
        tournament: TournamentSlice.reducer,
    }),

    compose(applyMiddleware(thunk), devTools)
);

export default store;
