import Utils from "../utils/Utils";
import GameUtil from "../utils/GameUtil";

import ACCOUNTING_ESG_CONFIG from "../config/ACCOUNTING_ESG_CONFIG";
import ACCOUNTING_SMGX_CONFIG from "../config/ACCOUNTING_SMGX_CONFIG";

export default class QuestionCardModel {
    constructor({id, phase, step, concept, question, order, answers = [], relatedStartup = null}) {
        this.id = id;
        this.phase = phase;
        this.step = step;
        this.order = Utils.isset(order) ? order + 1 : order;
        this.concept = concept;
        this.question = question;
        this.answers = answers;
        this.relatedStartup = relatedStartup;
    }

    getInvestmentConfig() {
        const investmentConfig = GameUtil.isCurrentGameEsg()
            ? ACCOUNTING_ESG_CONFIG.GAME_MONTHS_CAPITAL_PER_STEP[this.step]
            : ACCOUNTING_SMGX_CONFIG.GAME_MONTHS_CAPITAL_PER_STEP[this.step];

        return {
            investmentRightAnswer: investmentConfig?.RIGHT ?? 0,
            investmentWrongAnswer: investmentConfig?.WRONG ?? 0,
        }
    }

    getMonthsConfig() {
        const monthsToAdvanceConfig = GameUtil.isCurrentGameEsg()
            ? ACCOUNTING_ESG_CONFIG.GAME_MONTHS_TO_ADVANCE_PER_STEP[this.step]
            : ACCOUNTING_SMGX_CONFIG.GAME_MONTHS_TO_ADVANCE_PER_STEP[this.step];

        return {
            monthsRightAnswer: monthsToAdvanceConfig?.RIGHT ?? 0,
            monthsWrongAnswer: monthsToAdvanceConfig?.WRONG ?? 0,
        }
    }
}