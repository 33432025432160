import store from "redux/store";
import Utils from "utils/Utils";
import IotTopicSubscriber from "./IotTopicSubscriber";
import {WS_TOPICS} from "config/WS_CONSTANTS";
import GameEventProcessor from "services/common/gameEvents/GameEventProcessor";
import TeamEventsProcessor from "../services/common/gameEvents/TeamEventsProcessor";
import {IS_PRODUCTION_ENV} from "config/CONSTANTS";

let gameSessionSubscription = null;

function processEvent(wsMessage) {
    if (!IS_PRODUCTION_ENV)
        console.debug('Message received', wsMessage);

    const eventProcessors = {
        // session's updates
        RELOAD: GameEventProcessor.reloadEvent,
        ADVANCE_GAME: GameEventProcessor.advanceGameEvent,
        GAME_CONFIG_UPDATED: GameEventProcessor.gameConfigUpdatedEvent,
        RANKING_UPDATED: GameEventProcessor.rankingUpdatedEvent,
        MAX_PLAYER_COUNT_UPDATED: GameEventProcessor.maxPlayerCountUpdated,

        // players' updates
        PLAYER_UPDATED: GameEventProcessor.playerUpdatedEventProcessor,
        PLAYER_NPS_UPDATED: GameEventProcessor.playerNpsUpdatedEventProcessor,
        PLAYER_TEAM_UPDATED: GameEventProcessor.playerTeamUpdatedEventProcessor,
        PLAYER_REMOVED: GameEventProcessor.playerRemovedEventProcessor,
        PLAYER_KICKED: GameEventProcessor.playerKickedEventProcessor,

        // teams' updated
        STARTUP_UPDATED: TeamEventsProcessor.startupUpdatedEvent,
        QUESTIONS_UPDATED: TeamEventsProcessor.questionsUpdatedEvent,
        QUESTION_REVEALED: TeamEventsProcessor.questionAnswerRevealedEvent,
    }

    if (!wsMessage.event || !eventProcessors[wsMessage.event]) {
        console.error('unknown game event received');
        console.log(wsMessage);

        return false;
    }

    return eventProcessors[wsMessage.event](wsMessage);
}

const GameEventSubscriber = {
    async subscribe() {
        if (gameSessionSubscription !== null)
            return true;

        const gameSessionId = store.getState()?.game?.gameInfo?.id;

        if (!Utils.isset(gameSessionId))
            return false;

        const gameSessionTopic = WS_TOPICS.GAME_TOPIC.replace('$gameSessionId', gameSessionId);

        gameSessionSubscription = await IotTopicSubscriber.subscribe(
            gameSessionTopic,
            data => processEvent(data)
        );

        if (gameSessionSubscription === false) {
            gameSessionSubscription = null;

            return false;
        }

        return true;
    },

    async unsubscribe() {
        if (gameSessionSubscription) {
            await gameSessionSubscription.unsubscribe();
            gameSessionSubscription = null;
        }

        return true;
    }
};

export default GameEventSubscriber;