import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    startups: [],
    pitches: [],
    skills: [],
    services: [],
    questions: [],
    unpredictabilities: [],
    valuePropositions: [],
};

export const CardsSlice = createSlice({
    name: 'cards',
    initialState,
    reducers: {
        updateStartups(state, {payload}) {
            state.startups = payload;
        },
        setPitches(state, {payload}) {
            state.pitches = payload;
        },
        setSkills(state, {payload}) {
            state.skills = payload;
        },
        setServices(state, {payload}) {
            state.services = payload;
        },
        setQuestions(state, {payload}) {
            state.questions = payload;
        },
        setUnpredictabilites(state, {payload}) {
            state.unpredictabilities = payload;
        },
        setValuePropositions(state, {payload}) {
            state.valuePropositions = payload;
        }
    }
});

export const {
    updateStartups,
    setPitches,
    setSkills,
    setServices,
    setQuestions,
    setUnpredictabilites,
    setValuePropositions
} = CardsSlice.actions;
export default CardsSlice.reducer;