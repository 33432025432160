import React, {useEffect, useState} from 'react';
import SummaryCardLayout from 'components/cards/layouts/SummaryCardLayout';

import './StartupProfileSummaryCard.scss';
import startupIcon from 'assets/img/board/ic_startup_default.svg';
import valuePropositionIcon from 'assets/img/board/ic_value_proposition_default.svg';
import {useSelector} from "react-redux";
import {GAME_STEPS} from "config/CONSTANTS";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import i18nUtil from "utils/i18nUtil";
import StartupProfileModal from "./components/StartupProfileModal/StartupProfileModal";
import {useTranslation} from "react-i18next";
import useTranslationForGame from "../../../../../hooks/useTranslationForGame";

export default function StartupProfileSummaryCard({clickable = true}) {
    const {t} = useTranslation('pages/common/board/startup');
    const tfg = useTranslationForGame(t);

    const showBoardForTeam = useSelector(state => state.session.showBoardForTeam);
    const startup = useSelector(state => state.teams[showBoardForTeam]?.startup);
    const founders = useSelector(state => state.teams[showBoardForTeam]?.founders);
    const foundersIcons = useSelector(state=>state.teams[showBoardForTeam].foundersIcons);
    const valuePropositions = useSelector(state => state.teams[showBoardForTeam]?.valuePropositions);
    const currentStep = useSelector(state => state.game.currentStep);

    const [showStartupModal, setShowStartupModal] = useState(false);
    const handleShowStartupModal = () => setShowStartupModal(true);
    const handleCloseStartupModal = () => setShowStartupModal(false);

    const [showContent, setShowContent] = useState(false);

    useEffect(() => {
        setShowContent(currentStep >= GAME_STEPS.CONCEPTION);
    }, [currentStep]);

    return (
        <>
            <SummaryCardLayout>
                <div
                    className={'box double-width startup-profile-summary-card ' + (showContent ? 'border-info' : 'not-unlocked')}>

                    <div className={"box-head text-uppercase " + (showContent ? 'bg-info' : 'transparent')}>
                        {tfg('texts.title')}
                    </div>

                    {showContent && clickable && (
                        <button type="button"
                                className="btn btn-outline-info btn-sm float-right mt-1 mr-2  p-1 border-0 radius-4"
                                onClick={handleShowStartupModal}>
                            {t('buttons.details')}
                        </button>)}

                    <div className={"box-content profile-startup " + (showContent ? '' : 'd-none')}>
                        <div className="d-flex align-items-center">
                            <img className="skill-face" src={startupIcon} alt="StartupProfile icon"/>
                            <h3 className="ml-2 text-white startup-name overflow-ellipsis">
                                {(startup && startup.title) || '-'}
                            </h3>
                        </div>

                        <div className="row">
                            <table className="mx-3 not-styled w-100">
                                <tbody>
                                <tr>
                                    <td className="w-50">
                                        <h4 className="text-warning my-1">{tfg('texts.founders')}</h4>
                                    </td>
                                    <td className="w-50">
                                        <h4 className="text-warning my-1">{tfg('texts.value_propositions')}</h4>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        {founders && founders[0] && (
                                            <div className="d-flex ">
                                                <img src={foundersIcons[0]} className="skill-face mr-2"
                                                     alt="Founder avatar"/>

                                                <div>
                                                    <div
                                                        className="skill-area text-center">{i18nUtil.area(founders[0].area)}</div>
                                                    <p className="skill-name overflow-ellipsis">{founders[0].title}</p>
                                                </div>
                                            </div>
                                        )}
                                        {(!founders || !founders[0]) && (
                                            <div className="d-flex align-items-center text-white ml-1">
                                                {t('texts.to_be_defined')}
                                            </div>
                                        )}
                                    </td>

                                    <td>
                                        {valuePropositions && valuePropositions[0] && (
                                            <div className="d-flex align-items-center">
                                                <img
                                                    className="skill-face mr-2" src={valuePropositionIcon}
                                                    alt="Value proposition avatar"/>
                                                <p className="skill-name overflow-ellipsis">{valuePropositions[0].title}</p>
                                            </div>
                                        )}
                                        {(!valuePropositions || !valuePropositions[0]) && (
                                            <div className="d-flex align-items-center text-white ml-1">
                                                {t('texts.to_be_defined')}
                                            </div>
                                        )}
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        {founders && founders[1] && (
                                            <div className="d-flex ">
                                                <img src={foundersIcons[1]} className="skill-face mr-2"
                                                     alt="Founder avatar"/>

                                                <div>
                                                    <div
                                                        className="skill-area text-center">{i18nUtil.area(founders[1].area)}</div>
                                                    <p className="skill-name overflow-ellipsis">{founders[1].title}</p>
                                                </div>
                                            </div>
                                        )}
                                        {(!founders || !founders[1]) && (
                                            <div className="d-flex align-items-center text-white ml-1">
                                                {t('texts.to_be_defined')}
                                            </div>
                                        )}
                                    </td>
                                    <td>
                                        {valuePropositions && valuePropositions[1] && (
                                            <div className="d-flex align-items-center">
                                                <img
                                                    className="skill-face mr-2" src={valuePropositionIcon}
                                                    alt="Value proposition avatar"/>
                                                <p className="skill-name overflow-ellipsis">{valuePropositions[1].title}</p>
                                            </div>
                                        )}
                                        {(!valuePropositions || !valuePropositions[1]) && (
                                            <div className="d-flex align-items-center text-white ml-1">
                                                {t('texts.to_be_defined')}
                                            </div>
                                        )}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div
                        className={"box-content justify-content-center h-75 align-items-center " + (showContent ? 'd-none' : 'd-flex')}>
                        <FontAwesomeIcon icon={['fas', 'lock']} className="fa-3x mb-5 text-info"/>
                    </div>
                </div>
            </SummaryCardLayout>

            {showStartupModal && (<StartupProfileModal onClose={handleCloseStartupModal}/>)}
        </>
    );
}
