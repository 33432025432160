import React, {useEffect, useMemo, useState} from "react";

import "./Join.scss";
import {useNavigate, useParams} from "react-router-dom";
import ButtonSpinner from "components/ButtonSpinner";
import FadeIn from "assets/plugins/react-fade-in/FadeIn";
import OverlayUtil from "utils/OverlayUtil";
import {IS_PRODUCTION_ENV, OVERLAY_THEME, SESSION_STATUS} from "config/CONSTANTS";
import ToastUtil from "utils/ToastUtil";
import GameSessionService from "services/common/GameSessionService";
import AuthService from "services/player/AuthService";
import {PUBLIC_ROUTES, TERMS_ROUTES, TOURNAMENT_ROUTES} from "navigation/ROUTES";

import {useSelector} from "react-redux";

import SessionClosedInfo from "pages/common/sessionStatus/SessionClosedInfo";
import SessionPausedInfo from "pages/common/sessionStatus/SessionPausedInfo";
import SessionNotStartedInfo from "pages/common/sessionStatus/SessionNotStartedInfo";
import SessionWithRestrictedAccessInfo from "../../common/sessionStatus/SessionWithRestrictedAccessInfo";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import BugReportModal from "../../common/BugReportModal/BugReportModal";
import LoggerService from "services/common/LoggerService";
import {useTranslation} from "react-i18next";
import SessionReduxService from "../../../services/redux/SessionReduxService";
import SessionNotFoundInfo from "../../common/sessionStatus/SessionNotFoundInfo";
import AccessCodeModal from "./components/AccessCodeModal";
import TournamentService from "../../../services/common/TournamentService";

export default function Join() {
    const navigate = useNavigate();
    const {t} = useTranslation(['pages/player/join', 'common'], {useSuspense: true});
    const {gameSession} = useParams();

    const theme = useSelector(state => state.theme);
    const game = useSelector(state => state.game.gameInfo.game);
    const owner = useSelector(state => state.game.gameInfo.owner);

    const [decodedGameSessionId, setDecodedGameSessionId] = useState(false);
    const [email, setEmail] = useState("");
    const [sendMail, setSendMail] = useState(true);

    const [isLoading, setIsLoading] = useState(false);
    const [status, setStatus] = useState('');
    const [maxPlayerCountExceededError, setMaxPlayerCountExceededError] = useState(false);

    const [showBugReportModal, setShowBugReportModal] = useState(false);
    const handleShowBugReportModal = () => setShowBugReportModal(true);
    const handleCloseBugReportModal = () => setShowBugReportModal(false);

    const [showAccessCodeModal, setShowAccessCodeModal] = useState(false);
    const now = useMemo(() => (new Date()).getUTCFullYear(), []);

    const showSkipMailCodeOption = useMemo(() => {
        return window.location.href.includes('localhost') && !IS_PRODUCTION_ENV;
    }, []);

    useEffect(() => {
        OverlayUtil.toggleLoadingOverlay(true, t('overlays.checking_session_status'), OVERLAY_THEME.DARK);

        const checkGameStatus = async () => {
            GameSessionService.checkGameSessionStatus(decodeURIComponent(gameSession)).then(async response => {
                if (response && response.status) {
                    if (!response.gameSessionId) {
                        setStatus(SESSION_STATUS.NOT_FOUND);
                        return false;
                    }

                    setDecodedGameSessionId(response.gameSessionId);

                    return AuthService.rejoin(window.location.pathname, response.gameSessionId);
                }

                setStatus(response.sessionStatus || SESSION_STATUS.NOT_FOUND);
                return false;
            }).then(response => {
                if (response === true) {
                    navigate(TOURNAMENT_ROUTES.LOBBY);
                } else {
                    OverlayUtil.toggleLoadingOverlay(false);
                }
            }).catch(reason => {
                console.error(reason);
                OverlayUtil.toggleLoadingOverlay(false);
                LoggerService.error(reason, {action: 'check game status - player'});
                SessionReduxService.updateHasFatalError(true);
            });
        };

        document.title = t('common:app_title');

        checkGameStatus();
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);

    const handleSubmit = async (evt) => {
        evt.preventDefault();

        setIsLoading(true);

        let disableLoadingStatus = false;
        const response = await TournamentService.requestAccessCode({
            gameSessionId: decodedGameSessionId,
            email,
            sendMail
        });


        if (response?.status === true) {
            setShowAccessCodeModal(true);
        } else if (response?.data?.maxPlayerCountExceeded === true) {
            setMaxPlayerCountExceededError(true);
            disableLoadingStatus = true;
        } else {
            disableLoadingStatus = true;
            ToastUtil.toastDanger('Não foi possível completar a sua solicitação', 'Por favor, tente novamente ou entre em contato conosco');
        }

        setIsLoading(!disableLoadingStatus);
    };

    if (status === SESSION_STATUS.NOT_STARTED) return (<SessionNotStartedInfo/>);
    if (status === SESSION_STATUS.CLOSED) return (<SessionClosedInfo/>);
    if (status === SESSION_STATUS.PAUSED) return (<SessionPausedInfo/>);
    if (status === SESSION_STATUS.LIMITED) return (<SessionWithRestrictedAccessInfo/>);
    if (status === SESSION_STATUS.NOT_FOUND) return (<SessionNotFoundInfo/>);

    if (showAccessCodeModal)
        return <AccessCodeModal email={email} gameSessionId={decodedGameSessionId}/>

    return (<div id="player-login-page" className="row h-100 w-100 m-0">
            <div className="col-sm-12 col-md-6 col-xl-5 position-relative">
                <div className="form px-5 w-100 d-flex flex-column justify-content-center">
                    <FadeIn>
                        <div className="w-100 text-center d-md-none mb-5 pt-3">
                            <img alt="Mundi Game Experiences logo" src={theme.logos.join_page_logo}
                                 className="logo-header"/>
                        </div>

                        <h2 className="mb-1 text-warning">{t('texts.greetings')}</h2>
                        <h4 className="mb-4">
                            {game === 'esg' && 'ESG Game Experience'}
                            {game === 'smgx' && 'Startup Mundi Game Experience'}
                            {!game && t('common:app_name')}
                        </h4>
                    </FadeIn>

                    <FadeIn>
                        <h6 className="mb-5">
                            Você está prestes a se juntar a uma sessão de jogo individual, organizada por:
                            <span className="text-warning ml-1">{owner ?? 'Mundi Game Experiences'}</span>
                        </h6>
                    </FadeIn>


                    {maxPlayerCountExceededError && (
                        <div className="alert alert-warning animate__animated animate__faster animate__fadeInDown">
                            <b> O número máximo de jogadores ou sessões foi atingido.</b>
                            <br/>
                            Por favor, entre em contato com o responsável por este torneio.
                        </div>
                    )}

                    <form onSubmit={handleSubmit}>
                        {showSkipMailCodeOption && (<div>
                            <div className="form-check form-check">
                                <input className="form-check-input"
                                       type="checkbox"
                                       id="enable-button-clicks"
                                       checked={sendMail}
                                       onChange={ev => setSendMail(!sendMail)}/>

                                <label className="form-check-label" htmlFor="enable-button-clicks">
                                    Enviar email?
                                </label>

                                <p className="form-text small text-muted">
                                    [APENAS AMBIENTE DEV] - tire o check para pular o envio do email com o código.
                                    Para acessar, use o código "123456";
                                </p>
                            </div>
                        </div>)}

                        <div className={'form-group'}>
                            <label className="required">Para continuar, informe o seu e-mail</label>
                            <input className="form-control" required={true} autoFocus={true}
                                   value={email} autoComplete="nope" type="email"
                                   onChange={e => setEmail(e.target.value)} min={3}/>
                            <span className="form-text small text-muted">
                                Um e-mail será enviado para você, contendo um código de acesso.
                            </span>
                        </div>

                        <ButtonSpinner type="submit" showAnimation={isLoading}>{t('buttons.play')}</ButtonSpinner>

                        <div className="small text-muted mt-1">
                            <p className="m-0 d-inline">{t('texts.terms_info')} </p>
                            <a href={TERMS_ROUTES.TERMS_OF_USE} target="_blank" className="btn btn-link d-inline p-0 "
                               style={{fontSize: '85%'}}>{t('buttons.terms_of_use')}
                            </a>
                        </div>
                    </form>

                    <div className="mt-2 mb-0 p-0 text-muted small">
                        <p className="mb-1">
                            <a href={TERMS_ROUTES.COOKIES_POLICY} target="_blank"
                               className="text-muted mr-1">{t('common:cookies_policy')}</a>
                            <a href={TERMS_ROUTES.PRIVACY_POLICY} target="_blank"
                               className="text-muted">{t('common:privacy_policy')}</a>
                        </p>

                        <p className="mb-1">
                            @ {now}

                            <a href="https://www.startupmundi.com.br/" rel="noreferrer"
                               className="text-muted text-decoration-underline ml-1"
                               target="_blank">
                                {t('common:company_name')}</a>. {t('common:rights_reserved')}
                        </p>

                        <a className="mr-2 mb-2 mb-md-0 text-muted" href={PUBLIC_ROUTES.VERIFY_CONNECTION}
                           target="_blank">
                            <FontAwesomeIcon icon={['fas', 'signal']}/> Testar conexão
                        </a>

                        <button className="btn btn-link  text-muted p-0 text-decoration-none btn-report-bug"
                                style={{fontSize: '100%'}}
                                onClick={handleShowBugReportModal}>
                            <FontAwesomeIcon icon={['fas', 'bug']}/> {t('common:btn_report_bug')}
                        </button>
                    </div>
                </div>
            </div>

            <div className="d-none d-md-block col p-0">
                <div
                    className="w-100 d-flex justify-content-center align-items-center text-white right-card flex-column"
                    style={{background: theme.pages.join.bg_color}}>
                    <img alt="Mundi Game Experiences logo" src={theme.logos.join_page_secondary_logo} className="logo"/>
                </div>
            </div>

            {showBugReportModal && (<BugReportModal onClose={handleCloseBugReportModal}/>)}
        </div>
    );
}