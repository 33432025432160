import React, {useMemo} from "react";
import i18nUtil from "utils/i18nUtil";

import "./TeamRanking.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {GAME_STEPS} from "config/CONSTANTS";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import GameUtil from "../../../../../../../utils/GameUtil";
import ACCOUNTING_ESG_CONFIG from "../../../../../../../config/ACCOUNTING_ESG_CONFIG";
import ACCOUNTING_SMGX_CONFIG from "../../../../../../../config/ACCOUNTING_SMGX_CONFIG";

export default function TeamRanking({data}) {
    const {t} = useTranslation('pages/common/board/challenges', {keyPrefix: 'components.team_ranking.texts'});
    const {ranking} = data;

    const isDemonstration = useSelector(state => state.game.isDemonstration);
    const isEsg = useMemo(() => GameUtil.isCurrentGameEsg(), []);

    const pitchBonus = useMemo(() => {
        return isEsg
            ? ACCOUNTING_ESG_CONFIG.GAME_PITCH_INVESTMENT[GAME_STEPS.PITCH_2][ranking] || 0
            : ACCOUNTING_SMGX_CONFIG.GAME_PITCH_INVESTMENT[GAME_STEPS.PITCH_3][ranking] || 0;
    }, [data, isEsg]);

    return (
        <div className="d-flex h-100 w-100 justify-content-center align-items-center pb-3">
            <div
                className="ranking-player-team d-flex w-100 justify-content-center flex-column align-items-center text-white  border-warning">
                <div className="w-100">
                    <div className="box-head bg-warning text-white text-uppercase">
                        {t('title')}
                    </div>
                </div>

                <div className="d-flex flex-column justify-content-center align-items-center">
                    <h4>{t('placed')}</h4>
                    <h2>{i18nUtil.ordinalSuffix(ranking)}</h2>

                    <div className={"mt-3 animate__animated animate__bounceIn text-ranking-" + ranking}>
                        {ranking === 1 && <FontAwesomeIcon icon={['fas', 'trophy']} className="fa-8x"/>}
                        {(ranking === 2 || ranking === 3) &&
                            <FontAwesomeIcon icon={['fas', 'medal']} className="fa-8x"/>}
                        {ranking === 4 && <FontAwesomeIcon icon={['fas', 'award']} className="fa-8x"/>}
                    </div>

                    <h5 className="mt-4 mb-4">
                        {pitchBonus > 0 && t('received_investment', {investment: i18nUtil.formatMoney(pitchBonus)})}
                    </h5>

                    <h4 className="mb-4">
                        {isDemonstration && "Obrigado por jogar!"}
                        {!isDemonstration && t('congrats')}
                    </h4>
                </div>
            </div>
        </div>
    );
}