import ApiService from "../common/api/ApiService";
import {API_ENDPOINTS} from "config/API_CONSTANTS";
import store from "../../redux/store";
import {GAME_STEPS, GAME_STEPS_WITH_QUESTIONS} from "../../config/CONSTANTS";
import GameControlService from "../../pages/gameMaster/ControlPanel/components/GameControl/services/GameControlService";

const GameService = {
    async advanceGamev2(nextStep = store.getState().game.currentStep + 1) {
        if (nextStep > GAME_STEPS.END_GAME)
            return false;

        if (GAME_STEPS_WITH_QUESTIONS.includes(nextStep)) {
            const questions = GameControlService.getQuestionsForTeams(nextStep);

            if (!await GameService.updateTeamsQuestions({step: nextStep, questions}))
                return false
        }

        return GameService.advanceGame({desiredStep: nextStep});
    },

    async sendUnpredictability(step = store.getState().game.currentStep) {
        const unpredictabilities = GameControlService.getUnpredictabilitiesForTeams();

        return GameService.updateTeamsUnpredictabilities({step, unpredictabilities});
    },

    async advanceGame(data) {
        return ApiService.post(API_ENDPOINTS.GAME_SESSION.UPDATE_GAME_STEP, data)
            .then(result => result.status === true)
            .catch(reason => false);
    },

    async updateGameConfig(data) {
        return ApiService.post(API_ENDPOINTS.GAME_SESSION.UPDATE_SETTINGS, {data})
            .then(result => result.status === true)
            .catch(reason => false);
    },

    async updateTeamsQuestions(params) {
        return ApiService.post(API_ENDPOINTS.TEAMS.SET_QUESTIONS, params)
            .then(result => result.status === true)
            .catch(reason => false);
    },

    async revealQuestion(params) {
        return ApiService.post(API_ENDPOINTS.TEAMS.REVEAL_QUESTION, params)
            .then(result => result.status === true)
            .catch(reason => false);
    },

    async updateTeamsUnpredictabilities(params) {
        return ApiService.post(API_ENDPOINTS.TEAMS.SET_UNPREDICTABILITY, params)
            .then(result => result.status === true)
            .catch(reason => false);
    },

    async revealUnpredictability(params) {
        return ApiService.post(API_ENDPOINTS.TEAMS.REVEAL_UNPREDICTABILITY, params)
            .then(result => result.status === true)
            .catch(reason => false);
    },

    async revealUnpredictabilityOutcome(params) {
        return ApiService.post(API_ENDPOINTS.TEAMS.REVEAL_UNPREDICTABILITY_OUTCOME, params)
            .then(result => result.status === true)
            .catch(reason => false);
    },

    async sendPitchBonus(params) {
        return ApiService.post(API_ENDPOINTS.TEAMS.SEND_PITCH_BONUS, params)
            .then(result => result.status === true)
            .catch(reason => false);
    },

    async updateRanking(params) {
        return ApiService.post(API_ENDPOINTS.TEAMS.SET_RANKING, params)
            .then(result => result.status === true)
            .catch(reason => false);
    },

    async revealRanking(params) {
        return ApiService.post(API_ENDPOINTS.TEAMS.REVEAL_RANKING, params)
            .then(result => result.status === true)
            .catch(reason => false);
    },

    async getAvailablePlayerCredits() {
        return ApiService.post(API_ENDPOINTS.GAME_SESSION.GET_AVAILABLE_PLAYER_CREDITS, {})
            .then(result => result.status === true ? result.data.creditsAvailable : false)
            .catch(reason => false)
    },

    async updateMaxPlayerCount(data) {
        return ApiService.post(API_ENDPOINTS.GAME_SESSION.UPDATE_MAX_PLAYER_COUNT, {data})
            .then(result => result.status === true)
            .catch(reason => false)
    }
};

export default GameService;