import React from "react";
import PhaseStatusTD from "./components/PhaseStatusTD";
import {GAME_STEPS} from "config/CONSTANTS";
import PitchBonusButtons from "./components/PitchBonusButtons";
import QuestionsButton from "./components/QuestionsButton";
import UnpredictabilitiesButton from "./components/UnpredictabilitiesButton";
import i18nUtil from "../../../../../../utils/i18nUtil";
import {Trans, useTranslation} from "react-i18next";
import useTranslationForGame from "../../../../../../hooks/useTranslationForGame";

export default function Phase3({
                                   showQuestionsCallback,
                                   drawUnpredictabilitiesCallback,
                                   showUnpredictabilitiesCallback
                               }) {

    const {t} = useTranslation(['pages/game_master/control_panel/game_control', 'common'], {keyPrefix: "components.phases"});
    const tfg = useTranslationForGame(t);

    return (
        <table className="table-switches table table-borderless table-striped">
            <tbody>
            <tr>
                <PhaseStatusTD step={GAME_STEPS.SALES_MACHINE}/>
                <td>{i18nUtil.stepName(GAME_STEPS.SALES_MACHINE)}</td>
                <td>
                    <Trans t={t} i18nKey={tfg('texts.release_questions_phase_3', {}, true)}
                           components={{step: i18nUtil.stepName(GAME_STEPS.SALES_MACHINE)}}/>
                </td>
                <td>
                    <QuestionsButton step={GAME_STEPS.SALES_MACHINE} onClick={showQuestionsCallback}/>
                    <UnpredictabilitiesButton step={GAME_STEPS.SALES_MACHINE}
                                              onShow={showUnpredictabilitiesCallback}
                                              onDraw={drawUnpredictabilitiesCallback}/>
                </td>
            </tr>

            <tr>
                <PhaseStatusTD step={GAME_STEPS.CUSTOMER_SUCCESS}/>
                <td>{i18nUtil.stepName(GAME_STEPS.CUSTOMER_SUCCESS)}</td>
                <td>
                    <Trans t={t} i18nKey={tfg('texts.release_questions_phase_3', {}, true)}
                           components={{step: i18nUtil.stepName(GAME_STEPS.CUSTOMER_SUCCESS)}}/>
                </td>
                <td>
                    <QuestionsButton step={GAME_STEPS.CUSTOMER_SUCCESS} onClick={showQuestionsCallback}/>
                    <UnpredictabilitiesButton step={GAME_STEPS.CUSTOMER_SUCCESS}
                                              onShow={showUnpredictabilitiesCallback}
                                              onDraw={drawUnpredictabilitiesCallback}/>
                </td>
            </tr>

            <tr>
                <PhaseStatusTD step={GAME_STEPS.PRODUCT_SCALABILITY}/>
                <td>{i18nUtil.stepName(GAME_STEPS.PRODUCT_SCALABILITY)}</td>
                <td>
                    <Trans t={t} i18nKey={tfg('texts.release_questions_phase_3', {}, true)}
                           components={{step: i18nUtil.stepName(GAME_STEPS.PRODUCT_SCALABILITY)}}/>
                </td>
                <td>
                    <QuestionsButton step={GAME_STEPS.PRODUCT_SCALABILITY} onClick={showQuestionsCallback}/>
                    <UnpredictabilitiesButton step={GAME_STEPS.PRODUCT_SCALABILITY}
                                              onShow={showUnpredictabilitiesCallback}
                                              onDraw={drawUnpredictabilitiesCallback}/>
                </td>
            </tr>

            <tr>
                <PhaseStatusTD step={GAME_STEPS.PITCH_3}/>
                <td>{i18nUtil.stepName(GAME_STEPS.PITCH_3)}</td>
                <td>
                    <Trans t={t} i18nKey={'texts.release_pitch'}
                           components={{step: i18nUtil.stepName(GAME_STEPS.PITCH_3)}}/>
                </td>
                <td>
                    <PitchBonusButtons step={GAME_STEPS.PITCH_3}/>
                </td>
            </tr>

            </tbody>
        </table>
    );
}