import ApiService from "./api/ApiService";
import {API_ENDPOINTS} from "../../config/API_CONSTANTS";
import {setUser} from "../../redux/slices/sessionSlice";
import AuthService from "../player/AuthService";
import store from "../../redux/store";
import {setTournamentInfo} from "../../redux/slices/gameSlice";
import GameService from "../gameMaster/GameService";
import {GAME_PHASES, GAME_STEPS, GAME_STEPS_WITH_QUESTIONS} from "../../config/CONSTANTS";
import {updateTournamentState} from "../../redux/slices/tournamentSlice";
import CardUtil from "../../utils/CardUtil";
import AccountingService from "./AccountingService";
import {i18nService} from "../../i18n";
import TOURNAMENT_CONFIG from "../../config/TOURNAMENT_CONFIG";
import GameUtil from "../../utils/GameUtil";

const STORAGE_KEY = '__ts';
const DEFAULT_TEAM = 1;

const TournamentService = {
    initializeSession() {
        // const storageData = this.getLocalState();
        // this.touchTournamentState(storageData);
    },

    requestAccessCode(params) {
        this.initializeSession();

        return ApiService.post(API_ENDPOINTS.TOURNAMENTS.REQUEST_ACCESS_CODE, params)
            .then(result => {

                if (result.status === true)
                    store.dispatch(setUser({id: result.data.id, email: params.email}));

                return result;
            }).catch(() => false);
    },

    validateAccessCode(gameUrl, tournamentId, params) {
        return ApiService.post(API_ENDPOINTS.TOURNAMENTS.VALIDATE_ACCESS_CODE, params)
            .then(result => {
                if (!result.status)
                    return false;

                AuthService.setUserSessionForTournament(gameUrl, tournamentId, result.data);
                this.clearLocalState();

                store.dispatch(setTournamentInfo({
                    canRequestCertificate: result.data.canRequestCertificate === true,
                    needSetup: result.data.needsToSetupSession === true,
                }));

                return result.data;
            }).catch(() => false);
    },

    async advanceGame() {
        const currentStep = store.getState().game.currentStep;
        const tournamentState = store.getState().tournament.state;
        const isEsg = GameUtil.isCurrentGameEsg();

        const phaseForStep = CardUtil.getGamePhaseForStep(currentStep);
        const isStepWithMarket = phaseForStep === GAME_PHASES.PHASE_2 || (!isEsg && phaseForStep === GAME_PHASES.PHASE_3);
        const hasUnpredictability = !!store.getState().teams[DEFAULT_TEAM]?.unpredictabilities?.[currentStep];


        if (GAME_STEPS_WITH_QUESTIONS.includes(currentStep) && !hasUnpredictability) {

            // if game === smgx, show accounting objective for MVP step
            if (!isEsg && currentStep === GAME_STEPS.MVP && !tournamentState.showAccountingChallenge) {
                TournamentService.touchTournamentState({showAccountingChallenge: true});
                return true;
            }

            if (isStepWithMarket) {
                if (!tournamentState.showAvailableForMarketChallenge) {
                    TournamentService.touchTournamentState({showAvailableForMarketChallenge: true});
                    return true;
                } else if (!tournamentState.showAccountingChallenge) {
                    TournamentService.touchTournamentState({showAccountingChallenge: true});
                    return true;
                } else if ((isEsg && phaseForStep === 2) || (!isEsg && phaseForStep === 3)) {
                    return GameService.sendUnpredictability().then(res => {
                        if (res) {
                            TournamentService.touchTournamentState({
                                showAvailableForMarketChallenge: false,
                                showAccountingChallenge: false
                            });
                        }

                        return res;
                    });
                }
            }
        }


        if ((currentStep === GAME_STEPS.PITCH_2 && isEsg) || currentStep === GAME_STEPS.PITCH_3) {
            await this.notifyBetaSessionEnded();

            const rankingData = {
                ranking: Object.values(AccountingService.calculateRanking()),
                rankingPerStep: AccountingService.calculateRankingForAllSteps(),
                finalAccounting: AccountingService.calculateFinalAccountingForAllTeams()
            };

            for (let i = 0; i < rankingData.ranking.length; i++) {
                rankingData.ranking[i].show = true;
            }

            if (!await GameService.updateRanking(rankingData))
                return false;


            return GameService.advanceGamev2(GAME_STEPS.END_GAME);
        }

        return GameService.advanceGamev2().then(res => {
            if (res) {
                TournamentService.touchTournamentState({
                    hasShownQuestionInfoCard: false,
                    showAvailableForMarketChallenge: false,
                    showAccountingChallenge: false
                });
            }

            return res;
        });
    },

    restartGame() {
        this.clearLocalState();
        return GameService.advanceGame({desiredStep: 99});
    },

    startAnotherSession() {
        return ApiService.post(API_ENDPOINTS.TOURNAMENTS.START_ANOTHER_SESSION, {})
            .then(result => result.status === true)
            .catch(reason => false);
    },

    updatePitchData(data) {
        return ApiService.post(API_ENDPOINTS.TEAMS.UPDATE_PITCH_DATA, data)
            .then(result => result.status === true)
            .catch(reason => false);
    },

    getLocalState() {
        const data = localStorage.getItem(STORAGE_KEY);

        return JSON.parse((data ?? '{}'));
    },

    clearLocalState() {
        localStorage.removeItem(STORAGE_KEY);
        store.dispatch(updateTournamentState({
            hasShownQuestionInfoCard: false,
            hasFinishedConception: false,
        }));
    },

    touchTournamentState(newData) {
        // const existingData = TournamentService.getLocalState();
        // const data = {...existingData, ...newData};

        // store.dispatch(updateTournamentState(data));
        // localStorage.setItem(STORAGE_KEY, JSON.stringify(data));


        store.dispatch(updateTournamentState(newData));
    },

    async notifyBetaSessionEnded() {
        if (window.location.href.includes('localhost') || window.location.href.includes('127.0.0.1'))
            return


        const sessionId = store.getState().game.gameInfo.id;
        const game = store.getState().game.gameInfo.game;
        const language = i18nService.getLanguage();
        const email = store.getState().session.user.email;

        // parse from format '2023-11-23T20:06:02.966Z' to '2023-11-23 20:06:03 UTC'
        const finishedAt = (new Date()).toISOString()
            .replace('T', ' ')
            .slice(0, -5) + ' UTC';


        if (!TOURNAMENT_CONFIG.WEBHOOK_NOTIFY_FINISHED_ENDPOINT)
            return;

        const params = {
            id: sessionId,
            game,
            finished_at: finishedAt,
            language,
            email,
        };

        return fetch(TOURNAMENT_CONFIG.WEBHOOK_NOTIFY_FINISHED_ENDPOINT, {
            method: 'POST',
            body: JSON.stringify(params)
        }).catch(err => {
            console.error(err);
        });
    },
};

export default TournamentService;