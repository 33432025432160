import React from 'react';

import Summary from "./components/Summary";
import Tabs from "./components/Tabs";

export default function SmgxAccountingDetailsModalContent({onClose}) {
    return (
        <>
            <Summary/>
            <hr className="border-light"/>
            <Tabs/>
        </>
    )
}
