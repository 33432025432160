import React, {useEffect, useMemo, useState} from "react";

import "./UnpredictabilityModal.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useSelector} from "react-redux";
import UnpredictabilityTeamRow from "./components/UnpredictabilityTeamRow";
import {useTranslation} from "react-i18next";
import {Portal} from "react-portal";

export default function UnpredictabilityModal({onClose, step}) {
    const {t} = useTranslation('pages/game_master/control_panel/game_control', {keyPrefix: "components.unpredictability_modal"});

    const teamCount = useSelector(state => state.game.teamCount);
    const team1Unpredictability = useSelector(state => state.teams[1].unpredictabilities[step]);
    const team2Unpredictability = useSelector(state => state.teams[2].unpredictabilities[step]);
    const team3Unpredictability = useSelector(state => state.teams[3].unpredictabilities[step]);
    const team4Unpredictability = useSelector(state => state.teams[4].unpredictabilities[step]);

    const [currentTeam, setCurrentTeam] = useState(1);

    const handleShowNextTeam = () => {
        if (currentTeam === teamCount) {
            setCurrentTeam(1);
        } else {
            setCurrentTeam(currentTeam + 1);
        }
    };

    const handleShowPreviousTeam = () => {
        if (currentTeam === 1) {
            setCurrentTeam(teamCount);
        } else {
            setCurrentTeam(currentTeam - 1);
        }
    };

    const handleShowTeam = (number) => {
        setCurrentTeam(number);
    };


    const teamToShow = useMemo(() => {
        const unp = [team1Unpredictability, team2Unpredictability, team3Unpredictability, team4Unpredictability][currentTeam - 1];
        return (<UnpredictabilityTeamRow team={currentTeam} unpredictability={unp}/>)
    }, [team1Unpredictability, team2Unpredictability, team3Unpredictability, team4Unpredictability, teamCount, currentTeam]);

    const pagination = useMemo(() => {
        const res = [];
        const teamsUnpredictabilities = [team1Unpredictability, team2Unpredictability, team3Unpredictability, team4Unpredictability];

        for (let i = 1; i < teamCount + 1; i++) {
            const revealed = teamsUnpredictabilities[i - 1]?.revealOutcome === true && teamsUnpredictabilities[i - 1]?.reveal === true;

            res.push((
                <h4 id={`label-unp-t${i}`}  className={"mb-0 mx-3 team-option " + (currentTeam === i ? 'font-weight-bold text-team-' + i : 'text-light')}
                    onClick={() => handleShowTeam(i)}>
                    {revealed && (
                        <FontAwesomeIcon icon={['fas', 'check']} className="mr-2" style={{fontSize: '50%'}}/>)}
                    {t('texts.team', {team: i})}
                </h4>))
        }

        return res;
    }, [team1Unpredictability, team2Unpredictability, team3Unpredictability, team4Unpredictability, teamCount, currentTeam]);

    useEffect(() => {
        const handleEsc = (event) => {
            if (event.keyCode === 27) {
                onClose();
            }
        };

        window.addEventListener('keydown', handleEsc);
        return () => window.removeEventListener('keydown', handleEsc);
    }, []);

    return (<Portal>
        <div className={"unpredictability-modal pt-3 px-lg-4 px-2 overflow-hide"}>
            <div>
                <div className="text-right">
                    <button type="button" className="btn btn-link text-white " onClick={onClose}>
                        <FontAwesomeIcon icon={['fas', 'times']} className={'fa-2x'}/>
                    </button>
                </div>

                <div className="d-flex flex-column justify-content-center align-items-center h-75">
                    <div className="w-100 ">{teamToShow}</div>

                    <div
                        className="w-100 d-flex justify-content-center position-relative bottom  mt-3 animate__animated animate__faster animate__fadeInUp">
                        <button type="button" className="btn btn-primary bg-transparent border-0 py-0 mr-1"
                                onClick={handleShowPreviousTeam}>
                            <FontAwesomeIcon icon={['fas', 'angle-left']} className="fa-2x"/>
                        </button>

                        {pagination}

                        <button type="button" className="btn btn-primary bg-transparent border-0 py-0 mr-1"
                                onClick={handleShowNextTeam}>
                            <FontAwesomeIcon icon={['fas', 'angle-right']} className="fa-2x"/>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </Portal>);
}