import React, {useEffect, useRef} from "react";

import "./TournamentEnding.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import ConfettiModule from "canvas-confetti";
import useTranslationForGame from "../../../../../../../hooks/useTranslationForGame";
import SimpleBar from "simplebar-react";

export default function TournamentEnding({data}) {
    const {t} = useTranslation('pages/common/board/challenges', {keyPrefix: 'components.tournament_ending.texts'});
    const canvas = useRef();
    const tfg = useTranslationForGame(t);

    useEffect(() => {
        const confetti = ConfettiModule.create(canvas.current);
        confetti({
            particleCount: 400,
            spread: 90,
            origin: {y: 0.6},
        });
    }, []);

    return (<>
        <div className="d-flex h-100 w-100 justify-content-center align-items-center pb-3">
            <div
                className="ranking-player-team d-flex w-100 justify-content-center flex-column align-items-center text-white  border-warning">
                <div className="w-100">
                    <div className="box-head bg-warning text-dark text-uppercase">
                        Missão Cumprida
                    </div>
                </div>

                <SimpleBar className="content-scrollable  w-100" style={{maxHeight: 470}}>
                    <div className="d-flex flex-column justify-content-center align-items-center text-center px-2">
                        <h5 className="mb-4">
                            {tfg('line_1')}
                        </h5>

                        <div className={"mt-3 animate__animated animate__bounceIn text-warning "} style={{zIndex: 3}}>
                            <FontAwesomeIcon icon={['fas', 'trophy']} className="fa-8x"/>
                        </div>

                        <div
                            className="position-absolute top left right bottom overflow-hidden d-flex justify-content-center"
                            style={{zIndex: 1}}>
                            <canvas width={600} height={600} ref={canvas}/>
                        </div>

                        <h5 className="mt-5">
                            {tfg('line_2')}
                        </h5>

                        <h5 className="mb-3">
                            Desde já obrigado pelo seu trabalho. Hora de seguir adiante. Utilize o as setas abaixo para
                            continuar.
                        </h5>
                    </div>
                </SimpleBar>
            </div>
        </div>
    </>);
}