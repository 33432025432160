import React from "react";
import {GAME_STEPS} from "config/CONSTANTS";
import PhaseStatusTD from "./components/PhaseStatusTD";
import PitchBonusButtons from "./components/PitchBonusButtons";
import QuestionsButton from "./components/QuestionsButton";
import {Trans, useTranslation} from "react-i18next";
import i18nUtil from "../../../../../../utils/i18nUtil";
import useTranslationForGame from "../../../../../../hooks/useTranslationForGame";

export default function Phase1({showQuestionsCallback}) {
    const {t} = useTranslation(['pages/game_master/control_panel/game_control'], {keyPrefix: "components.phases"});
    const tfg = useTranslationForGame(t);

    return (
        <div className="row">
            <table className="table-switches table table-borderless table-striped">
                <tbody>
                <tr>
                    <PhaseStatusTD step={GAME_STEPS.ONBOARDING}/>
                    <td>{i18nUtil.stepName(GAME_STEPS.ONBOARDING)}</td>
                    <td><Trans t={t} i18nKey={'components.phase_1.texts.onboarding'}/></td>
                    <td></td>
                </tr>

                <tr>
                    <PhaseStatusTD step={GAME_STEPS.CONCEPTION}/>
                    <td>{i18nUtil.stepName(GAME_STEPS.CONCEPTION)}</td>
                    <td><Trans t={t} i18nKey={tfg('components.phase_1.texts.conception', {}, true)}/></td>
                    <td></td>
                </tr>

                <tr>
                    <PhaseStatusTD step={GAME_STEPS.MVP}/>
                    <td>{i18nUtil.stepName(GAME_STEPS.MVP)}</td>
                    <td>
                        <Trans t={t} i18nKey={'components.phase_1.texts.mvp'}
                               values={{step: i18nUtil.stepName(GAME_STEPS.MVP)}}/>
                    </td>
                    <td>
                        <QuestionsButton step={GAME_STEPS.MVP} onClick={showQuestionsCallback}/>
                    </td>
                </tr>

                <tr>
                    <PhaseStatusTD step={GAME_STEPS.PITCH_1}/>
                    <td>{i18nUtil.stepName(GAME_STEPS.PITCH_1)}</td>

                    <td>
                        <Trans t={t} i18nKey={'texts.release_pitch'}
                               components={{step: i18nUtil.stepName(GAME_STEPS.PITCH_1)}}/>
                    </td>
                    <td>
                        <PitchBonusButtons step={GAME_STEPS.PITCH_1}/>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    );
}