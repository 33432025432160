export default class PitchCardModel {
    constructor({id, phase, header, content, monthsForward, monthCost, revenue, investmentPerRanking}) {
        this.id = id;
        this.phase = phase;
        this.header = header;
        this.content = content;
        this.monthsForward = monthsForward;
        this.monthCost = monthCost;
        this.revenue = revenue;
        this.investmentPerRanking = investmentPerRanking;
    }
}