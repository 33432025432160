import React from "react";
import {Navigate} from "react-router-dom";
import AuthService from "services/player/AuthService";
import SessionPersistenceUtil, {GAME_MASTER_KEY} from "utils/SessionPersistenceUtil";
import store from "redux/store";
import Utils from "utils/Utils";
import {PLAYER_ROUTES, TOURNAMENT_ROUTES} from "../ROUTES";
import NotFoundError from "pages/common/errors/NotFoundError";

export default function GameMasterRoute({children, ...rest}) {
    const isAuthenticated = AuthService.isAuthenticated();
    const isGameMaster = store.getState().session.isGameMaster;
    const gameUrl = SessionPersistenceUtil.get(GAME_MASTER_KEY)?.gameUrl;

    if (isAuthenticated && !isGameMaster) {
        if (store.getState().game.isTournament) {
            return <Navigate to={{pathname: TOURNAMENT_ROUTES.LOBBY}}/>
        } else {
            return <Navigate to={{pathname: PLAYER_ROUTES.LOBBY}}/>
        }
    }

    if (isAuthenticated)
        return children;

    if (Utils.isset(gameUrl))
        return (<Navigate to={{pathname: gameUrl}}/>);

    return (<NotFoundError/>);
}