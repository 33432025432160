import React, {useMemo} from "react";
import {Button, Modal} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useSelector} from "react-redux";

export default function HelpModal({onClose}) {
    const gameInfo = useSelector(state => state.game.gameInfo);
    const userEmail = useSelector(state => state.session.user.email);


    const wppShareText = useMemo(() => {
        let text = 'Olá! Poderia me ajudar com uma sessão de jogo de um torneio?';

        if (userEmail)
            text += '\r\nJogador: ' + userEmail;

        text += '\r\nTorneio: ' + gameInfo.owner
            + '\r\n Jogo: ' + gameInfo.game.toUpperCase();

        return encodeURIComponent(text);
    }, [gameInfo, userEmail]);

    return (
        <Modal show={true} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Preciso de ajuda
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <h5>Olá!</h5>

                <p>
                    Caso você tenha alguma dúvida, ou precise de ajuda, entre em contato conosco:
                </p>

                <hr/>
                <a className="btn btn-success text-white px-3" data-action="share/whatsapp/share"
                   href={"https://wa.me/554891079686?text=" + wppShareText} target="_blank">

                    <FontAwesomeIcon icon={['fab', 'whatsapp']} className="mr-2"
                                     style={{fontSize: '140%'}}/>

                    Entrar em contato pelo WhatsApp
                </a>

                <hr/>

                <p className="mb-0">Ou envie um e-mail para:</p>

                <p className="font-weight-bold">
                    help.torneio@startupmundi.com.br
                </p>

                <p className="mb-0">Com o assunto:</p>
                <p>Ajuda com sessão do torneio</p>
                <hr/>

                <p className="mt-4 mb-0">
                    Responderemos assim que possível!
                </p>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>Fechar</Button>
            </Modal.Footer>
        </Modal>
    )
}