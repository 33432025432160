import store from "redux/store";
import Utils from "utils/Utils";
import {WS_TOPICS} from "config/WS_CONSTANTS";
import IotTopicSubscriber from "./IotTopicSubscriber";
import TeamEventsProcessor from "services/common/gameEvents/TeamEventsProcessor";
import {IS_PRODUCTION_ENV} from "config/CONSTANTS";

const subscriptions = {};

function processEvent(wsMessage) {
    if (!IS_PRODUCTION_ENV)
        console.debug('Message received', wsMessage);

    const eventProcessors = {
        STARTUP_UPDATED: TeamEventsProcessor.startupUpdatedEvent,
        FOUNDER_UPDATED: TeamEventsProcessor.founderUpdatedEvent,
        VALUE_PROPOSITION_UPDATED: TeamEventsProcessor.valuePropositionUpdatedEvent,
        QUESTION_ANSWER_UPDATED: TeamEventsProcessor.questionAnswerUpdatedEvent,
        PITCH_BONUS_SENT: TeamEventsProcessor.pitchBonusSentEvent,
        CARD_HIRED: TeamEventsProcessor.cardHiredEvent,
        CARD_REFUNDED: TeamEventsProcessor.cardRefundedEvent,
        UNPREDICTABILITY_SET: TeamEventsProcessor.unpredictabilitySetEvent,
        UNPREDICTABILITY_REVEALED: TeamEventsProcessor.unpredictabilityRevealedEvent,
        UNPREDICTABILITY_OUTCOME_REVEALED: TeamEventsProcessor.unpredictabilityOutcomeRevealedEvent,
        SIGNED_ACCOUNTING: TeamEventsProcessor.signedAccountingEvent,
        PITCH_DATA_UPDATED: TeamEventsProcessor.pitchDataUpdated,
    }

    if (!wsMessage.event || !eventProcessors[wsMessage.event]) {
        console.error('unknown team event received');
        console.log(wsMessage);

        return false;
    }

    return eventProcessors[wsMessage.event](wsMessage);
}

const TeamEventSubscriber = {
    async subscribe(team, gameSessionId = store.getState()?.game?.gameInfo?.id) {
        if (!IS_PRODUCTION_ENV)
            console.debug('Subscribing to team topic ' + team);

        if (Utils.isset(subscriptions[team]))
            return true;

        if (!Utils.isset(gameSessionId))
            return false;


        const gameSessionTopic = WS_TOPICS.TEAM_TOPIC
            .replace('$gameSessionId', gameSessionId)
            .replace('$team', team);


        subscriptions[team] = await IotTopicSubscriber.subscribe(
            gameSessionTopic,
            data => processEvent(data)
        );

        if (subscriptions[team] === false) {
            subscriptions[team] = null;
            return false;
        }

        return true;
    },

    async unsubscribe(team) {
        if (subscriptions[team]) {
            await subscriptions[team].unsubscribe();
            subscriptions[team] = null;
        }

        return true;
    },

    async unsubscribeFromAll() {
        if (!IS_PRODUCTION_ENV)
            console.debug('Unsubscribing from all team event listeners');

        for (const [key] of Object.entries(subscriptions)) {
            if (!await this.unsubscribe(key))
                return false;
        }

        return true;
    }
};

export default TeamEventSubscriber;