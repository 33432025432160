import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import StatusInfoPerTeam from "./StatusInfoPerTeam/StatusInfoPerTeam";
import {useTranslation} from "react-i18next";
import GameUtil from "../../../../../../utils/GameUtil";
import I18nUtil from "../../../../../../utils/i18nUtil";

const MIN_AVAILABLE_FOR_MARKET_TO_SHOW_INFO = 6000;

export default function StatusStepWithQuestions({step}) {
    const {t} = useTranslation('pages/game_master/control_panel/step_status', {keyPrefix: 'components.status_step_with_question.texts'});

    const teamCount = useSelector(state => state.game.teamCount);

    const team1Questions = useSelector(state => state.teams['1'].questions[step]);
    const team2Questions = useSelector(state => state.teams['2'].questions[step]);
    const team3Questions = useSelector(state => state.teams['3'].questions[step]);
    const team4Questions = useSelector(state => state.teams['4'].questions[step]);

    const team1Unpredictability = useSelector(state => state.teams['1'].unpredictabilities[step]);
    const team2Unpredictability = useSelector(state => state.teams['2'].unpredictabilities[step]);
    const team3Unpredictability = useSelector(state => state.teams['3'].unpredictabilities[step]);
    const team4Unpredictability = useSelector(state => state.teams['4'].unpredictabilities[step]);

    const team1HiredAccounting = useSelector(state => state.teams['1'].hiredAccountingService);
    const team2HiredAccounting = useSelector(state => state.teams['2'].hiredAccountingService);
    const team3HiredAccounting = useSelector(state => state.teams['3'].hiredAccountingService);
    const team4HiredAccounting = useSelector(state => state.teams['4'].hiredAccountingService);

    const team1SignedAccounting = useSelector(state => state.teams['1'].signedAccounting[step]);
    const team2SignedAccounting = useSelector(state => state.teams['2'].signedAccounting[step]);
    const team3SignedAccounting = useSelector(state => state.teams['3'].signedAccounting[step]);
    const team4SignedAccounting = useSelector(state => state.teams['4'].signedAccounting[step]);

    const team1Accounting = useSelector(state => state.teams['1'].accounting.totals?.availableForMarket);
    const team2Accounting = useSelector(state => state.teams['2'].accounting.totals?.availableForMarket);
    const team3Accounting = useSelector(state => state.teams['3'].accounting.totals?.availableForMarket);
    const team4Accounting = useSelector(state => state.teams['4'].accounting.totals?.availableForMarket);

    const [missingDefinitions, setMissingDefinitions] = useState({1: [], 2: [], 3: [], 4: []});

    useEffect(() => {
        const missing = {1: [], 2: [], 3: [], 4: []};
        const forceShowAccountingInfoFor = {};
        const teamsQuestions = [team1Questions, team2Questions, team3Questions, team4Questions];
        const teamsUnpredictabilities = [team1Unpredictability, team2Unpredictability, team3Unpredictability, team4Unpredictability];
        const teamHiredAccounting = [team1HiredAccounting, team2HiredAccounting, team3HiredAccounting, team4HiredAccounting];
        const teamSignedAccounting = [team1SignedAccounting, team2SignedAccounting, team3SignedAccounting, team4SignedAccounting];
        const accountingData = [team1Accounting, team2Accounting, team3Accounting, team4Accounting];

        teamsQuestions.forEach((teamQuestions, index) => {
            if (!teamQuestions) return;

            if ((!teamQuestions?.answer || teamQuestions?.answer?.length === 0) && teamQuestions?.showResult !== true) {
                missing[index + 1].push(t('awaiting_answer'));
            } else if (teamQuestions.showResult !== true) {
                missing[index + 1].push(t('awaiting_to_show_answer'));
            } else if (!GameUtil.isCurrentGameEsg() && teamQuestions.showResult === true && (!teamHiredAccounting[index] && !teamSignedAccounting[index])) {
                missing[index + 1].push(t('awaiting_check_accounting'));
                forceShowAccountingInfoFor[index + 1] = true;
            }
        });

        teamsUnpredictabilities.forEach((teamQuestions, index) => {
            if (!teamQuestions) return;

            if (!teamQuestions?.reveal) {
                missing[index + 1].push(t('awaiting_to_reveal_unpredictability'));
            } else if (teamQuestions.revealOutcome !== true) {
                missing[index + 1].push(t('awaiting_to_reveal_unpredictability_outcome'));
            }
        });

        for (let teamIndex = 1; teamIndex < teamCount + 1; teamIndex++) {
            if (forceShowAccountingInfoFor[teamIndex] !== true && missing[teamIndex].length !== 0)
                continue;

            const teamAccounting = accountingData[teamIndex - 1];

            if (!!teamAccounting && teamAccounting >= MIN_AVAILABLE_FOR_MARKET_TO_SHOW_INFO) {
                const amount = I18nUtil.formatMoney(teamAccounting);
                missing[teamIndex].push(t('amount_available_for_market', {amount}));
            }
        }

        setMissingDefinitions(missing);
    }, [
        team1Questions, team2Questions, team3Questions, team4Questions,
        team1Unpredictability, team2Unpredictability, team3Unpredictability, team4Unpredictability,
        team1HiredAccounting, team2HiredAccounting, team3HiredAccounting, team4HiredAccounting,
        team1SignedAccounting, team2SignedAccounting, team3SignedAccounting, team4SignedAccounting,
        team1Accounting, team2Accounting, team3Accounting, team4Accounting
    ]);

    return (<>
        <p className="text-muted small mb-2 mt-n1">
            {t('info_market')}
        </p>

        <StatusInfoPerTeam missingDefinitions={missingDefinitions}/>
    </>);
}