import Utils from "utils/Utils";
import ApiService from "../common/api/ApiService";
import {API_ENDPOINTS} from "config/API_CONSTANTS";
import SessionPersistenceUtil, {PLAYER_KEY} from "utils/SessionPersistenceUtil";
import store from "redux/store";
import {setTeam, setToken, setUser} from "redux/slices/sessionSlice";
import {setGameInfo, setTournamentInfo} from "redux/slices/gameSlice";
import ApiDataParserService from "../common/api/ApiDataParserService";
import {batch} from "react-redux";
import {addMessage} from "redux/slices/messagesSlice";
import SessionReduxService from "../redux/SessionReduxService";
import LoggerService from "../common/LoggerService";
import {i18n} from "../../i18n";
import GameUtil from "../../utils/GameUtil";
import TournamentService from "../common/TournamentService";

/**
 *  This attribute is used to cache the user data, after it tried to join the session.
 *
 *  It is used in conjunction with the functionality to rejoin a session with the same nickname, even on
 *  different devices and networks. If the app detects that the nickname was already used, it caches the response
 *  from the API_ENDPOINTS.PLAYER.JOIN endpoint and waits for the user to confirm its identity.
 *  The 'join' action changes are only applied if the function AuthService.commitPendingJoinAction() is called.
 *
 * @type {boolean}
 * @private
 */
let _pendingUserJoinData = false;
let _gameUrl = '';


function setUserSession(gameUrl, {gameSessionId, token, user}, oldSession = false) {
    if (!Utils.isset(gameSessionId) || !Utils.isset(token) || !Utils.isset(user)) {
        throw Error('Cannot set user and session because data is invalid or missing');
    }

    const dataToPersist = {gameUrl, gameSessionId, id: user.id};

    if (oldSession) {
        if (oldSession.shown_check_in !== undefined)
            dataToPersist.shown_check_in = oldSession.shown_check_in;

        if (oldSession.shown_nps_form !== undefined)
            dataToPersist.shown_nps_form = oldSession.shown_nps_form;
    }

    batch(() => {
        store.dispatch(setUser(user));
        store.dispatch(setToken(token));
        store.dispatch(setGameInfo({id: gameSessionId}));

        // if (store.getState().game.isTournament) {
        //     store.dispatch(setGameInfo({id: user.id}));
        // } else {
        //     store.dispatch(setGameInfo({id: gameSessionId}));
        // }

        if (user.team) {
            store.dispatch(setTeam(user.team));
            SessionReduxService.updateShowBoardForTeam(user.team);
        }
    });

    SessionPersistenceUtil.persist(PLAYER_KEY, dataToPersist);
}

function setUserSessionForTournament(gameUrl, tournamentId, {gameSessionId, token, user}, oldSession = false) {
    if (!Utils.isset(gameSessionId) || !Utils.isset(token) || !Utils.isset(user)) {
        throw Error('Cannot set user and session because data is invalid or missing');
    }

    const dataToPersist = {gameUrl, gameSessionId: tournamentId, id: user.id};

    if (oldSession) {
        if (oldSession.shown_check_in !== undefined)
            dataToPersist.shown_check_in = oldSession.shown_check_in;

        if (oldSession.shown_nps_form !== undefined)
            dataToPersist.shown_nps_form = oldSession.shown_nps_form;
    }

    batch(() => {
        store.dispatch(setUser(user));
        store.dispatch(setToken(token));
        store.dispatch(setGameInfo({id: gameSessionId}));

        // if (store.getState().game.isTournament) {
        //     store.dispatch(setGameInfo({id: user.id}));
        // } else {
        //     store.dispatch(setGameInfo({id: gameSessionId}));
        // }

        if (user.team) {
            store.dispatch(setTeam(user.team));
            SessionReduxService.updateShowBoardForTeam(user.team);
        }
    });

    SessionPersistenceUtil.persist(PLAYER_KEY, dataToPersist);
}

async function getT() {
    return i18n.loadNamespaces('services/player/auth_service')
        .then(() => i18n.getFixedT(null, 'services/player/auth_service', 'texts'));
}

const AuthService = {
    setUserSessionForTournament,
    async join(gameUrl, params) {
        _gameUrl = gameUrl;

        return ApiService.post(API_ENDPOINTS.USERS.JOIN_PLAYER, params, false)
            .then(async response => {
                const responseData = response.data;

                _pendingUserJoinData = responseData;

                if (response.status === true) {
                    // commit 'join' action only if it's a new nickname
                    if (responseData.nicknameAlreadyUsed !== true && responseData.nicknameOnline !== true) {
                        await AuthService.commitPendingJoinAction();
                        return true;
                    }

                    return responseData;
                } else if (responseData && Object.keys(responseData).length) {
                    return responseData;
                } else {
                    SessionPersistenceUtil.clear(PLAYER_KEY);
                    return false;
                }
            }).catch(error => {
                console.error(error);
                LoggerService.error(error, {action: 'jon - player AuthService', params});
                SessionPersistenceUtil.clear(PLAYER_KEY);
                return false;
            });
    },

    async rejoin(gameUrl, gameSessionId) {
        const oldSession = SessionPersistenceUtil.get(PLAYER_KEY);

        if (Utils.isset(oldSession) && oldSession?.gameSessionId === gameSessionId && Utils.isset(oldSession?.id)) {
            return ApiService.post(API_ENDPOINTS.USERS.JOIN_PLAYER, {
                gameSessionId,
                id: oldSession.id
            }, false).then(async response => {
                if (response.status === true) {
                    const user = ApiDataParserService.parsePlayer(response.data.user);
                    setUserSession(gameUrl, {...response.data, user}, oldSession);

                    if (response.data.user.activeSessionId)
                        store.dispatch(setGameInfo({id: response.data.user.activeSessionId}));


                    // for tournaments
                    if (response.data.canRequestCertificate) {
                        store.dispatch(setTournamentInfo({
                            canRequestCertificate: response.data.canRequestCertificate
                        }));
                    }

                    if (store.getState().game.isTournament)
                        TournamentService.initializeSession();

                    const greetingsMessageKey = GameUtil.isCurrentGameEsg() ? 'rejoin_greetings_esg' : 'rejoin_greetings';
                    const greetings = (await getT())(greetingsMessageKey, {name: user.name});
                    store.dispatch(addMessage(greetings));
                    return true;
                } else {
                    SessionPersistenceUtil.clear(PLAYER_KEY);
                    return false;
                }
            }).catch(error => {
                console.error(error);
                SessionPersistenceUtil.clear(PLAYER_KEY);

                if (error?.message !== 'Request failed with status code 404') {
                    LoggerService.error(error, {action: 'rejoin - player AuthService'});
                }

                return false;
            });
        } else {
            // SessionPersistenceUtil.clear(PLAYER_KEY);
            return false;
        }
    },

    async commitPendingJoinAction() {
        const response = _pendingUserJoinData;

        const user = ApiDataParserService.parsePlayer(response.user);
        setUserSession(_gameUrl, {...response, user});

        const greetingsMessageKey = GameUtil.isCurrentGameEsg() ? 'join_greetings_esg' : 'join_greetings';
        const greetings = (await getT())(greetingsMessageKey, {name: user.nickname});
        store.dispatch(addMessage(greetings));

        _pendingUserJoinData = null;
    },

    async leave() {
        SessionPersistenceUtil.clear(PLAYER_KEY);
        return true;
    },

    isAuthenticated() {
        return Utils.isset(store.getState().session.token);
    }
};

export default AuthService;