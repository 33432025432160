import React, {useMemo, useState} from "react";
import NpsScore from "pages/common/NpsFormModal/components/NpsScore";
import {useSelector} from "react-redux";
import ButtonSpinner from "components/ButtonSpinner";
import LoggerService from "services/common/LoggerService";
import PlayerService from "services/common/PlayerService";
import ToastUtil from "utils/ToastUtil";
import {useTranslation} from "react-i18next";

const SCORES_COUNT = 10;
const COMMENT_MAX_LENGTH = 1000;

export default function NpsForm({rootPlayerId, player, onSubmit}) {
    const {t} = useTranslation('pages/common/nps_form', {keyPrefix: 'components.form'});

    const isGameMaster = useSelector(state => state.session.isGameMaster);

    const [score, setScore] = useState(null);
    const [comment, setComment] = useState(null);
    const [disableFields, setDisableFields] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const npsScores = useMemo(() => {
        const scores = [];

        for (let i = 1; i <= SCORES_COUNT; i++) {
            scores.push((<NpsScore key={i} value={i} disabled={disableFields} onChange={setScore}/>))
        }

        return scores;
    }, [disableFields]);


    const handleSubmit = async (event) => {
        event.preventDefault();

        if (isGameMaster) return;

        if (score === null || score === undefined || score === '')
            return;

        setDisableFields(true);
        setIsLoading(true);

        const parsedComment = !comment || comment.length <= COMMENT_MAX_LENGTH ? comment : comment.substring(0, COMMENT_MAX_LENGTH - 1);
        const params = {score, comment: parsedComment};

        try {
            if ((await PlayerService.updateNps(rootPlayerId, player.id, params)) !== false) {
                event.target.reset();
                onSubmit(player);
            } else {
                ToastUtil.toastDanger(t('toasts.update_error.title'), t('toasts.update_error.message'));
            }

        } catch (err) {
            LoggerService.error(err, {action: 'submit nps', rootPlayerId, player, params});
        } finally {
            setDisableFields(false);
            setIsLoading(false);
        }
    };

    return (<div>
        <h4 className="mb-1">
            <b>{player.nickname}</b>

            <span className="text-muted small ml-1" hidden={!player.name || player.nickname === player.name}>
                ({player.name})
            </span>
        </h4>

        <p className="mb-4">{t('texts.title')}</p>

        <form onSubmit={handleSubmit}>
            <div className="form-group score-container">
                <label className="required">{t('texts.recommend_probability')}</label>

                <ul className="nps-scores d-flex">{npsScores}</ul>

                <div className="d-flex justify-content-between subtitle small">
                    <p>{t('texts.low')}</p>
                    <p>{t('texts.high')}</p>
                </div>
            </div>


            <div className="form-group pt-4">
                <label>{t('texts.comment')} <span className="small text-muted">- {t('texts.optional')}</span></label>
                <textarea rows={5} maxLength={1000} className="form-control" name="comment"
                          onChange={event => setComment(event.target.value.trim())} disabled={disableFields}></textarea>

                <p className="form-text text-muted small">
                    {t('texts.comment_length', {count: comment?.length ?? 0, max: COMMENT_MAX_LENGTH})}
                </p>
            </div>

            <ButtonSpinner type="submit" showAnimation={isLoading} disabled={isGameMaster}>
                {t('buttons.submit')}
            </ButtonSpinner>
        </form>
    </div>);
}