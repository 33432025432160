import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import Utils from "utils/Utils";
import StatusInfoPerTeam from "./StatusInfoPerTeam/StatusInfoPerTeam";
import {useTranslation} from "react-i18next";
import useTranslationForGame from "../../../../../../hooks/useTranslationForGame";

export default function StatusConception() {
    const {t} = useTranslation('pages/game_master/control_panel/step_status', {keyPrefix: 'components.status_conception.texts'});
    const tfg = useTranslationForGame(t);

    const team1 = useSelector(state => state.teams['1']);
    const team2 = useSelector(state => state.teams['2']);
    const team3 = useSelector(state => state.teams['3']);
    const team4 = useSelector(state => state.teams['4']);

    const [missingDefinitions, setMissingDefinitions] = useState({1: [], 2: [], 3: [], 4: []});

    useEffect(() => {
        const missing = {1: [], 2: [], 3: [], 4: []};
        const teams = [team1, team2, team3, team4];

        teams.forEach((team, index) => {
            if (!Utils.isset(team.startup)) missing[index + 1].push(tfg('startup'));


            if (!Utils.isset(team.founders) || (!Utils.isset(team.founders[0]) && !Utils.isset(team.founders[1])))
                missing[index + 1].push(tfg('founder', {count: 2}));
            else if (!Utils.isset(team.founders[0]) || !Utils.isset(team.founders[1]))
                missing[index + 1].push(tfg('founder', {count: 1}));

            if (!team.valuePropositions || (!Utils.isset(team.valuePropositions[0]) && !Utils.isset(team.valuePropositions[1])))
                missing[index + 1].push(tfg('value_proposition', {count: 2}));
            else if (!Utils.isset(team.valuePropositions[0]) || !Utils.isset(team.valuePropositions[1]))
                missing[index + 1].push(tfg('value_proposition', {count: 1}));

            if (Utils.isset(team.startup)) {
                teams.forEach((team2, i) => {
                    if (team2 !== team && team2.startup && team2.startup === team.startup)
                        missing[index + 1].push(tfg('same_startup', {team: i + 1}));
                });
            }
        });

        setMissingDefinitions(missing);
    }, [team1, team2, team3, team4]);

    return (
        <StatusInfoPerTeam missingDefinitions={missingDefinitions}/>
    );
}