import AudioService from "../services/common/AudioService";
import {useEffect} from "react";
import {useSelector} from "react-redux";
import SessionPersistenceUtil, {AUDIO_CONFIG_KEY} from "../utils/SessionPersistenceUtil";
import Utils from "../utils/Utils";
import {setVolumeConfig} from "../redux/slices/sessionSlice";
import store from "../redux/store";

// used to prevent double-clicking sounds
const DELAY_BETWEEN_CLICKS = 150;
let _lastClickAt = null;

function addEventListener() {
    document.addEventListener('click', buttonClickedListener);
}

function removeEventListener() {
    document.removeEventListener('click', buttonClickedListener);
}

function buttonClickedListener(event) {
    const now = new Date();

    if (_lastClickAt && now - _lastClickAt > DELAY_BETWEEN_CLICKS)
        AudioService.playButtonClicked();

    _lastClickAt = now;
}

export default function SoundEffectWrapper({children}) {
    const token = useSelector(state => state.session.token);
    const volumeConfig = useSelector(state => state.session.volumeConfig);

    useEffect(() => {
        if (token && volumeConfig.enableButtonClicks && !volumeConfig.isMuted)
            addEventListener();
        else
            removeEventListener();
    }, [token, volumeConfig]);


    // load config from storage
    useEffect(() => {
        const storageData = SessionPersistenceUtil.get(AUDIO_CONFIG_KEY);

        if (storageData) {
            const config = {};
            const keysToGet = ['enableButtonClicks', 'volume', 'isMuted'];

            keysToGet.forEach(key => {
                if (Utils.isset(storageData[key]))
                    config[key] = storageData[key];
            });

            store.dispatch(setVolumeConfig(config));
        }

        // remove listener on unload
        return removeEventListener;
    }, []);

    return children;
}