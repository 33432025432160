import React from "react";
import {Modal} from "react-bootstrap";

import "./ModalForm.scss";
import logo from "assets/img/logos/svg/logo_only.svg";
import {useTranslation} from "react-i18next";

export default function ModalForm({children, title, subtitle, onClose, modalClass = ''}) {
    const {t} = useTranslation('pages/common/board/conception_modal', {keyPrefix: 'components.modal_form'});

    return (
        <Modal show={true} size={'xl'} onHide={onClose} scrollable={true}
               className={"conception-modal-form " + modalClass}>
            <Modal.Header closeButton className="bg-dark text-white">
                <div className="modal-header-content w-100 d-flex align-items-center">
                    <img alt="Mundi Game Experiences logo" src={logo} className="mr-4"/>

                    <div>
                        <Modal.Title>{title}</Modal.Title>
                        <p className="mb-1 small">
                            {subtitle || t('texts.subtitle')}
                        </p>
                    </div>
                </div>
            </Modal.Header>

            <Modal.Body>
                <div className="d-flex justify-content-center flex-wrap">
                    {children}
                </div>
            </Modal.Body>
        </Modal>
    );
}