import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";

import "./PlayerList.scss";
import Utils from "utils/Utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Trans, useTranslation} from "react-i18next";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import PlayerFormModal from "pages/common/PlayerFormModal/PlayerFormModal";
import UpdateMaxPlayerCountModal from "./components/UpdateMaxPlayerCountModal";

export default function PlayerList() {
    const {t} = useTranslation('pages/game_master/control_panel/player_list');

    const allPlayers = useSelector(state => state.game.players);
    const maxPlayerCount = useSelector(state => state.game.maxPlayerCount);
    const playersCounter = useSelector(state => state.game.playersCounter);
    const isTemporary = useSelector(state => state.game.isTemporary);
    const allowCertificateRequest = useSelector(state => state.game.allowCertificateRequest);

    const [players, setPlayers] = useState([]);
    const [playerToEdit, setPlayerToEdit] = useState(false);

    const [showPlayerFormModal, setShowPlayerFormModal] = useState(false);
    const handlePlayerFormModalClose = () => setShowPlayerFormModal(false);
    const handlePlayerFormModalShow = (player) => {
        setPlayerToEdit(player);
        setShowPlayerFormModal(true)
    };

    const [showUpdateMaxPlayerCountModal, setShowUpdateMaxPlayerCountModal] = useState(false);
    const handleUpdateMaxPlayerCountModalClose = () => setShowUpdateMaxPlayerCountModal(false);
    const handleUpdateMaxPlayerCountModalShow = (player) => setShowUpdateMaxPlayerCountModal(true);

    const [teamFilter, setTeamFilter] = useState('all');
    const filterTeamOptions = [
        {name: t('inputs.team.options.all'), value: 'all'},
        {name: t('inputs.team.options.team_1'), value: 1},
        {name: t('inputs.team.options.team_2'), value: 2},
        {name: t('inputs.team.options.team_3'), value: 3},
        {name: t('inputs.team.options.team_4'), value: 4},
        {name: t('inputs.team.options.none'), value: 'no_team'},
    ];

    const [isConnectedFilter, setIsConnectedFilter] = useState('all');
    const isConnectedOptions = [
        {name: t('inputs.user_status.options.all'), value: 'all'},
        {name: t('inputs.user_status.options.online'), value: 'online'},
        {name: t('inputs.user_status.options.offline'), value: 'offline'}
    ];


    useEffect(() => {
        let players = [...allPlayers];

        if (teamFilter !== 'all') {
            players = players.filter(p => {
                if (teamFilter === 'no_team') {
                    return !Utils.isset(p.team);
                } else {
                    return p.team === parseInt(teamFilter);
                }
            });
        }

        if (isConnectedFilter !== 'all') {
            const filter = isConnectedFilter === 'online';
            players = players.filter(p => filter ? p.isConnected === true : p.isConnected !== true);
        }

        players = players.sort((a, b) => {
            if (a?.nickname && b?.nickname) return a.nickname.localeCompare(b.nickname)
            return 0;
        });

        if (playerToEdit)
            setPlayerToEdit(players.find(p => p.id === playerToEdit.id));

        setPlayers(players);
    }, [allPlayers, teamFilter, isConnectedFilter]);


    return (
        <div className="player-list d-flex flex-column w-100 mt-5 mt-md-0 border-bottom">
            <h4>{t('texts.title')}</h4>

            <div className="h-100 w-100">
                <div className="d-flex">
                    <div className="form-group w-50 mb-1 pr-1">
                        <select className="form-control" onChange={(event) => setTeamFilter(event.target.value)}
                                value={teamFilter}>
                            {filterTeamOptions.map(op => (<option key={op.value} value={op.value}>{op.name}</option>))}
                        </select>
                    </div>

                    <div className="form-group w-50 mb-1 pl-1">
                        <select className="form-control" onChange={(event) => setIsConnectedFilter(event.target.value)}
                                value={isConnectedFilter}>
                            {isConnectedOptions.map(op => (<option key={op.value} value={op.value}>{op.name}</option>))}
                        </select>
                    </div>
                </div>

                <div className="card shadow">
                    <div className="card-body p-0">
                        <div className="table-responsive table-players">
                            <table className="table table-striped  m-0">
                                <thead>
                                <tr>
                                    <th className="text-center fit-content">#</th>
                                    <th className="text-center fit-content">{t('tables.users.header.team')}</th>
                                    <th>{t('tables.users.header.player')}</th>
                                    <OverlayTrigger placement="bottom"
                                                    overlay={<Tooltip
                                                        id="req-certificate">{t('tables.users.tooltips.requested_certificate')}</Tooltip>}>
                                        <th className="text-center fit-content">
                                            {t('tables.users.header.certificate')}
                                            <FontAwesomeIcon icon={['fas', 'question-circle']} className="ml-1 small"/>
                                        </th>
                                    </OverlayTrigger>
                                    <th className="text-center fit-content">{t('tables.users.header.online')}</th>
                                    <th className="text-center fit-content">
                                        <FontAwesomeIcon icon={['fas', 'cogs']}/>
                                    </th>
                                </tr>
                                </thead>

                                <tbody>
                                {players.map((p, index) => (
                                    <tr key={p.id}>
                                        <td className="text-center">{index + 1}</td>
                                        <td className="text-center">
                                            <span className={`badge badge-team-${p.team}`}>{p.team || '?'}</span>
                                        </td>

                                        <td>
                                            {p.nickname}

                                            {p.localPlayers && p.localPlayers.length > 0 && (
                                                <p className="small text-muted m-0">
                                                    {p.localPlayers.map(lp => lp.nickname).join(', ')}
                                                </p>)}
                                        </td>

                                        <td className="text-center">
                                            <div className="d-inline">
                                                {allowCertificateRequest && p.certificatesRequested > 0
                                                    ? <div
                                                        className="bg-secondary border border-success rounded d-inline px-1 py-0 text-success font-weight-bold small">
                                                        {p.certificatesRequested}<span className="small">x</span>
                                                    </div>
                                                    : <FontAwesomeIcon icon={['fas', 'times-circle']}
                                                                       className="text-warning"/>
                                                }
                                            </div>

                                            <span style={{paddingLeft: 2, paddingRight: 2, color: '#dedede'}}>|</span>

                                            <div className="d-inline">
                                                {p.npsCount > 0
                                                    ? <div
                                                        className="bg-secondary border border-success rounded d-inline px-1 py-0 text-success font-weight-bold small">
                                                        {p.npsCount}<span className="small">x</span>
                                                    </div>
                                                    : <FontAwesomeIcon icon={['fas', 'times-circle']}
                                                                       className="text-warning"/>
                                                }
                                            </div>
                                        </td>

                                        <td className="text-center player-status">
                                            <span className={p.isConnected ? 'player-online' : 'player-offline'}/>
                                        </td>

                                        <td className="text-center">
                                            <button type="button" className="btn btn-sm btn-link p-1"
                                                    onClick={() => handlePlayerFormModalShow(p)}>
                                                {t('buttons.edit')}
                                            </button>
                                        </td>
                                    </tr>
                                ))}

                                {players.length === 0 && (<tr>
                                    <td colSpan={6} className="text-muted text-center">
                                        {t('tables.users.empty_message')}
                                    </td>
                                </tr>)}
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>

            <div className="mt-2 d-flex flex-wrap">
                <div className="flex-fill pr-2">
                    <div hidden={!maxPlayerCount}>
                        <Trans t={t} i18nKey={'texts.max_player_count'}
                               values={{count: maxPlayerCount}}
                               components={{b: <b/>}}/>
                    </div>

                    <div className="mt-1">
                        <Trans t={t} i18nKey={'texts.total_players'}
                               values={{count: playersCounter.total}}
                               components={{b: <b/>}}/>

                        <p className="mb-0 small text-muted">
                            <Trans t={t} i18nKey={'texts.players_count_info'}
                                   values={{
                                       devices: playersCounter.devices,
                                       localPlayers: playersCounter.localPlayers
                                   }}
                                   components={{b: <b/>}}/>
                        </p>
                    </div>
                </div>

                <div className="mt-1" hidden={!maxPlayerCount || isTemporary}>
                    <button className="btn btn-sm px-2 btn-primary" onClick={handleUpdateMaxPlayerCountModalShow}>
                        {t('buttons.increase_player_limit')}
                    </button>
                </div>
            </div>

            <hr/>


            {showUpdateMaxPlayerCountModal && (
                <UpdateMaxPlayerCountModal onClose={handleUpdateMaxPlayerCountModalClose}/>)}

            {showPlayerFormModal && (<PlayerFormModal onClose={handlePlayerFormModalClose} player={playerToEdit}/>)}
        </div>
    );
}