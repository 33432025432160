import {
    CARD_AREA,
    CARD_AREA_ESG,
    GAME_PHASES,
    GAME_STEPS,
    REQUIREMENT_AREA,
    SERVICE_CARD_TYPE
} from "../config/CONSTANTS";


const ApiDataUtil = {
    getGamePhaseForStep(step) {
        switch (step) {
            case 'onb':
            case 'con':
            case 'mvp':
            case 'p1':
                return GAME_PHASES.PHASE_1;
            case 'beta':
            case 'gtm':
            case 'gm':
            case 'p2':
                return GAME_PHASES.PHASE_2;
            case 'sl':
            case 'cs':
            case 'ps':
            case 'p3':
                return GAME_PHASES.PHASE_3;
            case 'end':
                return GAME_PHASES.END_GAME;
            default:
                return GAME_PHASES.PHASE_1;
        }
    },

    parseGameStep(step) {
        switch (step) {
            // phase 1
            case 'onb':
                return GAME_STEPS.ONBOARDING;
            case 'con':
                return GAME_STEPS.CONCEPTION;
            case 'mvp':
                return GAME_STEPS.MVP;
            case 'p1':
                return GAME_STEPS.PITCH_1;

            // phase 2
            case 'beta':
                return GAME_STEPS.BETA;
            case 'gtm':
                return GAME_STEPS.GTM;
            case 'gm':
                return GAME_STEPS.GROWTH_MODEL;
            case 'p2':
                return GAME_STEPS.PITCH_2;

            // phase 3
            case 'sl':
                return GAME_STEPS.SALES_MACHINE;
            case 'cs':
                return GAME_STEPS.CUSTOMER_SUCCESS;
            case 'ps':
                return GAME_STEPS.PRODUCT_SCALABILITY;
            case 'p3':
                return GAME_STEPS.PITCH_3;

            // start, end
            case 'end':
                return GAME_STEPS.END_GAME;
            default:
                return GAME_STEPS.AWAITING;
        }
    },

    parseArea(area) {
        switch (area) {
            // smgx
            case 'op':
                return CARD_AREA.OPERATIONAL;
            case 'b':
                return CARD_AREA.BUSINESS;
            case 'cs':
                return CARD_AREA.CUSTOMER_SUCCESS;
            case 'p':
                return CARD_AREA.PRODUCT;

            // esg
            case 'Innovation':
                return CARD_AREA_ESG.INNOVATION;
            case 'Management':
                return CARD_AREA_ESG.MANAGEMENT;
            case 'Sustainability':
                return CARD_AREA_ESG.SUSTAINABILITY;
            case 'Engagement':
                return CARD_AREA_ESG.ENGAGEMENT;

            default:
                return area;
        }
    },

    parseServiceType(type) {
        switch (type) {
            case 'p':
                return SERVICE_CARD_TYPE.PACK;
            case 'pp':
                return SERVICE_CARD_TYPE.POWER_PACK;
            default:
                return SERVICE_CARD_TYPE.BASIC;
        }
    },

    parseRequirementType(type) {
        switch (type) {
            case 'sk':
                return REQUIREMENT_AREA.SKILL;
            case 'se':
                return REQUIREMENT_AREA.SERVICE;
            default:
                return false;
        }
    },

    parseQuestionStep(step) {
        return this.parseGameStep(step);
    },
};


export default ApiDataUtil;