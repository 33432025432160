import React from "react";
import StepTitle from "../components/StepTitle";

export default function EsgStep2() {

    return (<div>
        <StepTitle>
            Seu objetivo é:
        </StepTitle>

        <h5>
            Transformar impactos negativos em positivos, e assim adquirir créditos ESG para alcançar o sucesso na
            jornada
        </h5>
    </div>)
}