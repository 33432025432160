import React from "react";
import {useSelector} from "react-redux";
import StatusOnboarding from "./components/StatusOnboarding";
import {GAME_STEPS, GAME_STEPS_WITH_QUESTIONS} from "config/CONSTANTS";
import StatusConception from "./components/StatusConception";
import StatusStepWithQuestions from "./components/StatusStepWithQuestions";
import i18nUtil from "utils/i18nUtil";
import {useTranslation} from "react-i18next";
import StatusPitch3 from "./components/StatusPitch3";
import StatusPitch2 from "./components/StatusPitch2";
import StatusEndGame from "./components/StatusEndGame";

const STEPS_WITH_INFO = [
    GAME_STEPS.ONBOARDING, GAME_STEPS.CONCEPTION, GAME_STEPS.PITCH_2, GAME_STEPS.PITCH_3, GAME_STEPS.END_GAME,
    ...GAME_STEPS_WITH_QUESTIONS,
];

export default function StepStatus() {
    const {t} = useTranslation('pages/game_master/control_panel/step_status');
    const currentStep = useSelector(state => state.game.currentStep);

    if (!STEPS_WITH_INFO.includes(currentStep))
        return null;

    return (
        <div className="game-status">
            <h4>{t('texts.title', {step: `(${i18nUtil.stepName(currentStep)})`})}</h4>

            {currentStep === GAME_STEPS.ONBOARDING && (<StatusOnboarding/>)}
            {currentStep === GAME_STEPS.CONCEPTION && (<StatusConception/>)}
            {currentStep === GAME_STEPS.PITCH_2 && (<StatusPitch2/>)}
            {currentStep === GAME_STEPS.PITCH_3 && (<StatusPitch3/>)}
            {currentStep === GAME_STEPS.END_GAME && (<StatusEndGame/>)}
            {GAME_STEPS_WITH_QUESTIONS.includes(currentStep) && (<StatusStepWithQuestions step={currentStep}/>)}
        </div>
    );
}