import React, {memo, useMemo, useState} from "react";
import GameCardLayout from "components/cards/layouts/GameCardLayout";

import bagIcon from "assets/img/board/ic_money_market_cards.svg";

import "./SkillCard.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import I18nUtil from "utils/i18nUtil";
import i18nUtil from "utils/i18nUtil";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import CardAccelerationBar from "../CardAccelerationBar";
import SkillCardModel from "../../models/SkillCardModel";
import {GENDER_ICONS, SKILL_GENDER_GROUP} from "../../config/SKILL_ICONS";
import useTranslationForGame from "../../hooks/useTranslationForGame";

const SkillCard = ({
                       skill,
                       hiredCount = 0,
                       isFounder = false,
                       onHire = false,
                       isLoading = false,
                       hirable = true,
                       hasCreditToHire = false,
                       icon = SkillCardModel.randomIcon(),
                       gender = null,
                   }) => {

    const {t} = useTranslation('components/cards', {keyPrefix: 'skill_card'});
    const tfg = useTranslationForGame(t);

    const {title, description, area, price} = skill;

    const [showConfirm, setShowConfirm] = useState(false);

    const toggleConfirm = (toggle) => {
        if (!hirable || !hasCreditToHire) return;
        setShowConfirm(toggle);
    }

    const handleConfirm = (event) => {
        event.stopPropagation();
        if (hirable && hasCreditToHire && onHire) {
            const iconNumber = parseInt(icon.replace(/[^0-9]/g, ''));

            setShowConfirm(false);
            onHire(skill, iconNumber, gender);
        }
    }

    const hiredAmountClass = useMemo(() => hirable && hasCreditToHire ? 'bg-danger' : 'bg-gray', [hirable, hasCreditToHire]);
    const cardClass = useMemo(() => {
        let res = '';

        if (hirable) {
            if (isLoading) res += 'show-loading ';
            if (!hasCreditToHire) res += 'not-enough-credits ';
            if (showConfirm) res += 'show-confirm ';
        } else {
            res += 'show-only ';
        }

        return res;
    }, [hasCreditToHire, hirable, isLoading, showConfirm]);

    const skillArea = useMemo(() => i18nUtil.area(area), [area]);
    const priceFormatted = useMemo(() => I18nUtil.formatMoney(price), [price]);
    const genderIcon = useMemo(() => {
        if (!gender)
            return null;

        return GENDER_ICONS[gender];
    }, [gender]);

    return (
        <GameCardLayout>
            <div
                className={"box bg-white skill-card animate__animated animate__faster animate__fadeIn h-100 d-flex flex-column " + cardClass}>

                <div className="box-skill-head d-flex align-items-center">
                    <img className="skill-face position-absolute" src={icon} alt="skill icon"/>
                    {gender && gender === SKILL_GENDER_GROUP.OTHER && (<img className="gender-icon" src={genderIcon}/>)}

                    <div>{title}</div>

                    {!isFounder && hirable && hiredCount > 0 && (
                        <span
                            className={"hired-amount position-absolute top right px-2 text-center py-1 small font-weight-bold " + hiredAmountClass}>
                        {hiredCount}
                     </span>
                    )}

                    {isFounder && (
                        <span style={{fontSize: "0.8em"}}
                              className={"hired-amount text-dark position-absolute top right text-center py-1 small font-weight-bold " + hiredAmountClass}>
                                {tfg('texts.founder_info')}
                        </span>
                    )}
                </div>

                <div className="box-content skill-content flex-fill" onClick={() => toggleConfirm(true)}
                     onMouseLeave={() => toggleConfirm(false)}>
                    <div className="d-flex justify-content-between align-items-start card-summary">
                        <div
                            className="skill-area text-break text-center">{skillArea}</div>
                        <div className="power-rating power-rating-dark">
                            <CardAccelerationBar card={skill}/>
                        </div>
                    </div>

                    <div className="position-relative overflow-hidden h-100">
                        <p className="mb-0 mt-1 card-description">{description}</p>

                        <div
                            className={"confirm-overlay position-absolute top bottom left right justify-content-center align-items-center text-center"}>

                            <button type="submit" className="btn btn-primary" onClick={handleConfirm}>
                                {t('buttons.confirm_buy')}
                            </button>
                        </div>

                        <div
                            className={"loading-overlay position-absolute top bottom left right  justify-content-center align-items-center text-center"}>

                            <FontAwesomeIcon icon={['fas', 'circle-notch']} className="fa-spin fa-3x"/>
                        </div>
                    </div>
                </div>

                <div className="box-footer text-light d-flex justify-content-between px-3 py-2 align-items-center">
                    <img alt="Cost icon" src={bagIcon}/>
                    <div className="line mx-2"/>
                    <p className="mb-0">{priceFormatted}</p>

                    {hirable && !hasCreditToHire && (
                        <OverlayTrigger placement="auto" overlay={<Tooltip>{t('tooltips.not_enough_money')}</Tooltip>}>
                            <FontAwesomeIcon icon={['fas', 'exclamation-circle']} className="ml-2 h3 m-0 text-warning"/>
                        </OverlayTrigger>
                    )}
                </div>
            </div>
        </GameCardLayout>
    );
}

export default memo(SkillCard);