import React, {memo, useMemo, useState} from "react";

import "./StartupCard.scss";
import GameCardLayout from "components/cards/layouts/GameCardLayout";
import SimpleBar from "simplebar-react";
import {Trans, useTranslation} from "react-i18next";

import icon from "assets/img/board/ic_startup_dark.svg";
import useTranslationForGame from "../../hooks/useTranslationForGame";

const StartupCard = ({startup, onSelect, selectable = false, previousSelected}) => {
    const {t} = useTranslation('components/cards', {keyPrefix: 'startup_card'});
    const tfg = useTranslationForGame(t);

    const [showConfirm, setShowConfirm] = useState(false);
    const handleShowConfirm = () => {
        if (selectable) setShowConfirm(true);
    };
    const handleHideConfirm = () => setShowConfirm(false);

    const handleClick = () => {
        if (selectable && onSelect) onSelect(startup);
    };


    const cardClass = useMemo(() => selectable ? 'selectable' : '', [selectable]);
    const overlayClass = useMemo(() => showConfirm ? 'show' : '', [showConfirm]);

    return (<GameCardLayout>
        <div className={"box border-info bg-white startup-card text-left d-flex flex-column " + cardClass}>

            <div className="box-full-head bg-info d-flex align-items-center">
                <img src={icon} className="skill-face position-absolute" alt="Founder avatar"/>
                <div className="w-100">{startup.title}</div>
            </div>

            <div className="startup-card-content-container flex-fill position-relative"
                 onClick={handleShowConfirm} onMouseLeave={handleHideConfirm}>
                <SimpleBar className="content-scrollable">
                    <h5> {t('texts.sector')}</h5>
                    <p className="mb-1">{startup.sector}</p>

                    <h5>{t('texts.problem')}</h5>
                    <p className="mb-2">{startup.problem}</p>

                    <h5>{t('texts.solution')}</h5>
                    <p className="mb-2">{startup.solution}</p>

                    <h5>{tfg('texts.business_model')}</h5>
                    <p className="mb-1">{startup.businessModel}</p>
                </SimpleBar>

                {selectable && (<div
                    className={"confirm-overlay h-100 position-absolute top left right  d-flex justify-content-center flex-column align-items-center text-center " + overlayClass}>
                        <span className="small pb-1 text-primary">
                            <Trans t={t} i18nKey={'texts.change'} components={{1: <b/>}}
                                   values={{from: previousSelected?.title || t('texts.none'), to: startup.title}}/>
                        </span>

                    <button type="submit" className="btn btn-info text-white" onClick={handleClick}>
                        {t('buttons.select')}
                    </button>
                </div>)}
            </div>
        </div>
    </GameCardLayout>);
}

export default memo(StartupCard);