/**
 * Send logs to AWS Cloudwatch
 */

import Amplify, {AWSCloudWatchProvider, ConsoleLogger as Logger} from '@aws-amplify/core';
import {default as awsExports} from "aws-exports";
import {LOG_TYPE} from "@aws-amplify/core/lib/Logger";
import store from "../../redux/store";
import ApiService from "./api/ApiService";
import {API_ENDPOINTS} from "../../config/API_CONSTANTS";
import {v4 as uuidv4} from 'uuid';
import {IS_PRODUCTION_ENV} from "../../config/CONSTANTS";

// ----------------------- CONFIG -----------------------

const DEFAULT_CONFIG = {
    logGroupName: 'SMGX/frontend',
    // WIP: revert change bellow when the bug related to process being undefined is resolved
    // logStreamName: process?.env?.NODE_ENV || 'default',
    logStreamName: IS_PRODUCTION_ENV ? 'production' : 'development',
    credentials: Amplify.Credentials,
    region: awsExports.aws_project_region,
}

const DEFAULT_USER_REPORT_CONFIG = {
    ...DEFAULT_CONFIG,
    logStreamName: IS_PRODUCTION_ENV ? 'production-user-report' : 'development-user-report'
}



// ----------------------- SETUP -----------------------
const AwsLogger = new Logger('', LOG_TYPE.VERBOSE);
AwsLogger.addPluggable(new AWSCloudWatchProvider(DEFAULT_CONFIG));



function mapReduxToJson() {
    try {
        const storeState = store.getState();
        const formatFn = (data) => JSON.stringify(data, null, 0);

        return {
            gameSlice: formatFn(storeState.game),
            sessionSlice: formatFn(storeState.session),
            teams: formatFn(storeState.teams),
        };
    } catch (e) {
        return {};
    }
}

function getUserInfo() {
    try {
        return {
            userAgent: navigator.userAgent,
            isMobile: navigator?.userAgentData?.mobile,
            vendor: navigator.vendor,
            connection: navigator.connection ? {
                type: navigator.connection.type || navigator.connection.effectiveType,
                link: navigator.connection.downlink
            } : {},
            memory: navigator.deviceMemory,
            threads: navigator.hardwareConcurrency,
            language: navigator.language,
            screen: `${window?.screen?.width}x${window?.screen?.height}`
        }
    } catch (e) {
        return {};
    }
}

async function takeSnapshotIfPossible(id) {
    if (store.getState().session.token) {
        await ApiService.post(API_ENDPOINTS.UTILS.TAKE_SESSION_SNAPSHOT, {id})
            .then(value => {
                if (!value || !value.status) console.error('Could not request snapshot');
            }).catch(reason => {
                console.error(reason);
            });
    }
}

function buildLog(type, error, context) {
    const id = uuidv4();
    let parsedDetails = '';

    try {
        parsedDetails = JSON.stringify(error, error.stack ? Object.getOwnPropertyNames(error) : null)
            .replace(/\\n/g, "\\\n");
    } catch (e) {
        console.error(e);
        parsedDetails = JSON.stringify(error);
    }

    const message = 'Report ID: ' + id
        + '\n\n\n\n ---- current url ---- \n\n' + (window?.location?.href ?? '')
        + (context ? '\n\n\n\n ---- context ---- \n\n' + JSON.stringify(context) : '')
        + '\n\n\n\n ---- details ---- \n\n' + parsedDetails
        + '\n\n\n\n ---- redux ---- \n\n ' + JSON.stringify(mapReduxToJson())
        + '\n\n\n\n ---- user info ---- \n\n' + JSON.stringify(getUserInfo());

    return {id, message};
}



const LoggerService = {
    async error(error, context) {
        try {
            console.error(error);
            console.error(context);

            if (!window.location.href.includes('smgx.startupmundi.com'))
                return true;

            const {id, message} = buildLog('ERROR', error, context);
            AwsLogger.error(message);

            await takeSnapshotIfPossible(id);

            return true;
        } catch (e) {
            console.error('Could not send log to cloudwatch');
        }
    },

    async userReportedError(error, context) {
        try {
            const userReportLogger = new Logger('', LOG_TYPE.VERBOSE);
            userReportLogger.addPluggable(new AWSCloudWatchProvider(DEFAULT_USER_REPORT_CONFIG));

            console.error(error);
            console.error(context);

            const {id, message} = buildLog('ERROR', error, context);
            userReportLogger.error(message);

            await takeSnapshotIfPossible(id);

            return true;
        } catch (e) {
            console.error('Could not send log to cloudwatch');
        }
    }
};

export default LoggerService;