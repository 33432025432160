import React, {useEffect, useState} from "react";

import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import useForceUpdate from "../../../hooks/useForceUpdate";
import SessionReduxService from "../../../services/redux/SessionReduxService";
import OverlayUtil from "../../../utils/OverlayUtil";
import EventProcessor from "../../../services/common/gameEvents/EventProcessor";
import GameEventSubscriber from "../../../subscribers/GameEventSubscriber";
import TeamEventSubscriber from "../../../subscribers/TeamEventSubscriber";
import GameSessionService from "../../../services/common/GameSessionService";
import LoggerService from "../../../services/common/LoggerService";
import {useNavigate} from "react-router-dom";
import {PLAYER_ROUTES} from "../../../navigation/ROUTES";
import TournamentEventSubscriber from "../../../subscribers/TournamentEventSubscriber";
import TeamService from "../../../services/player/TeamService";
import FirstAccessModal from "../FirstAccessModal/FirstAccessModal";
import store from "../../../redux/store";
import TutorialModal from "../TutorialModal/TutorialModal";
import TournamentService from "../../../services/common/TournamentService";

export default function AutoLobby() {
    const {t} = useTranslation('pages/player/lobby', {useSuspense: true});
    // autoload this translation file
    const t2 = useTranslation('services/tournament/tournament_challenges', {useSuspense: true}).t;

    const navigate = useNavigate();

    const [showFirstAccessModal, setShowFirstAccessModal] = useState(false);
    const [showTutorialModal, setShowTutorialModal] = useState(false);

    const userTeam = useSelector(state => state.session.team);
    const userId = useSelector(state => state.session.user.id);
    const hasLoadedGameData = useSelector(state => state.session.hasLoadedGameData);
    const hasTeamData = useSelector(state => state.session.hasLoadedTeamData);

    const customization = useSelector(state => state.game.customization);
    const forceUpdate = useForceUpdate();

    const handleShowTutorialModal = () => {
        setShowFirstAccessModal(false);
        setShowTutorialModal(true);
    }

    const handleGoToErrorPage = () => {
        return SessionReduxService.updateHasFatalError(true);
    };

    const handleHasConnectionError = () => {
        return SessionReduxService.updateHasConnectionError(true);
    };

    const handleStartGame = () => {
        navigate(PLAYER_ROUTES.BOARD);
    };

    const handleStartGameWithTransition = () => {
        navigate(PLAYER_ROUTES.BOARD);
        TournamentService.touchTournamentState({showStepChangeTransition: true});
    };

    useEffect(() => {
        const fetchData = async () => {
            OverlayUtil.toggleLoadingOverlay(true, t('overlays.loading_data'));

            try {
                EventProcessor.pause();

                let subscribedToEvents = await GameEventSubscriber.subscribe();
                if (!subscribedToEvents) return handleGoToErrorPage();

                subscribedToEvents = await TournamentEventSubscriber.subscribe(userId);
                if (!subscribedToEvents) return handleGoToErrorPage();

                const hasSubscribed = TeamEventSubscriber.subscribe(userTeam);
                if (!hasSubscribed) return handleHasConnectionError();

                const fetchedGameData = await GameSessionService.fetchAllGameData();
                if (!fetchedGameData) return handleGoToErrorPage();

                const hasFetchedTeamData = await TeamService.fetchTeamData(userTeam);
                if (!hasFetchedTeamData) return handleHasConnectionError();

                EventProcessor.resume();
            } catch (e) {
                console.error(e);
                LoggerService.error(e, {action: 'initialization - lobby'});
                SessionReduxService.updateHasFatalError(true);
            } finally {
                OverlayUtil.toggleLoadingOverlay(false);
            }
        };

        if (hasLoadedGameData === false) {
            fetchData();
            document.title = t('tab_title');
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);

    useEffect(() => {
        if (hasLoadedGameData && hasTeamData) {
            if (!store.getState().game.tournamentInfo.needSetup) {
                handleStartGame();
            } else {
                setShowFirstAccessModal(true);
            }
        }
    }, [hasLoadedGameData, hasTeamData]);

    // re-render on customization change
    useEffect(() => forceUpdate(), [customization]);

    if (showFirstAccessModal)
        return <FirstAccessModal onClose={handleShowTutorialModal}/>;

    if (showTutorialModal)
        return <TutorialModal onClose={handleStartGameWithTransition}/>

    return (<></>);
}