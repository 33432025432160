import React, {useEffect, useMemo, useState} from "react";

import "./Challanges.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useSelector} from "react-redux";
import {CHALLENGE_TYPE, GAME_STEPS} from "config/CONSTANTS";
import ConceptionModal from "../ConceptionModal/ConceptionModal";
import Ranking from "./components/Ranking/Ranking";
import TeamRanking from "./components/TeamRanking/TeamRanking";
import ChallengesComponentService from "./services/ChallengesComponentService";
import {useTranslation} from "react-i18next";
import QuestionChallenge from "./components/QuestionChallange";
import ObjectiveChallenge from "./components/ObjectiveChallenge";
import PitchChallenge from "./components/PitchChallenge";
import UnpredictabilityChallenge from "./components/UnpredictabilityChallenge";
import DemoSessionService from "../../../../../services/common/DemoSessionService";
import {setForcePulsatePagination} from "../../../../../redux/slices/demoSlice";
import store from "../../../../../redux/store";
import DemoVideoPlayer from "../../../../../components/DemoVideoPlayer/DemoVideoPlayer";
import DemoChallengesComponentService from "./services/DemoChallengesComponentService";
import DemoEnding from "./components/DemoEnding";
import TournamentChallengesComponentService from "./services/TournamentChallengesComponentService";
import TournamentPitchChallenge from "./components/TournamentPitch/TournamentPitchChallenge";
import TournamentEnding from "./components/TournamentEnding/TournamentEnding";
import TournamentBetaNps from "./components/TournamentBetaNps/TournamentBetaNps";
import TournamentRanking from "./components/TournamentRanking/TournamentRanking";

export function Challenges() {
    const {t} = useTranslation('pages/common/board/challenges');

    const currentStep = useSelector(state => state.game.currentStep);
    const ranking = useSelector(state => state.game.ranking);
    const theme = useSelector(state => state.theme);

    const forcePulsatePagination = useSelector(state => state.demo.forcePulsatePagination);
    const revealedDemoQuestion = useSelector(state => state.demo.state.revealedQuestion);

    const isTournament = useSelector(state => state.game.isTournament);
    const tournamentState = useSelector(state => state.tournament.state);
    const showStepChangeTransition = useSelector(state => state.tournament.state.showStepChangeTransition);

    const isDemonstration = useSelector(state => state.game.isDemonstration);
    const disablePagination = useSelector(state => state.demo.disableChallengesPagination);
    const hasPlayedIntroVideo = useSelector(state => state.demo.state.hasPlayedIntroVideo);

    const showBoardForTeam = useSelector(state => state.session.showBoardForTeam);
    const teamQuestions = useSelector(state => state?.teams[showBoardForTeam]?.questions);
    const signedAccounting = useSelector(state => state?.teams[showBoardForTeam]?.signedAccounting);
    const teamUnpredictability = useSelector(state => state?.teams[showBoardForTeam]?.unpredictabilities);
    const teamStartup = useSelector(state => state?.teams[showBoardForTeam]?.startup);
    const teamFounders = useSelector(state => state?.teams[showBoardForTeam]?.founders);
    const teamValuePropositions = useSelector(state => state?.teams[showBoardForTeam]?.valuePropositions);
    const teamSkills = useSelector(state => state?.teams[showBoardForTeam]?.skills);
    const teamServices = useSelector(state => state?.teams[showBoardForTeam]?.services);
    const availableForMarket = useSelector(state => state?.teams[showBoardForTeam]?.accounting?.totals?.availableForMarket);

    const [questionsCount, setQuestionsCount] = useState({total: 0, answered: 0});
    const [currentChallenge, setCurrentChallenge] = useState(0);
    const [challengesToDisplay, setChallengesToDisplay] = useState([]);
    const [pulsatePagination, setPulsatePagination] = useState(false);


    const [showConceptionModal, setShowConceptionModal] = useState(false);
    const handleShowConceptionModal = () => setShowConceptionModal(true);
    const handleCloseConceptionModal = () => setShowConceptionModal(false);

    const navigationClass = useMemo(() => {
        if (pulsatePagination && (!isDemonstration || (isDemonstration && !disablePagination)))
            return 'pulsating';

        if (isDemonstration && disablePagination)
            return 'disabled';

        return '';
    }, [pulsatePagination, isDemonstration, disablePagination]);

    const handleShowNextChallenge = () => {
        setPulsatePagination(false);

        if (currentChallenge === challengesToDisplay.length - 1) {
            setCurrentChallenge(0);
        } else {
            setCurrentChallenge(currentChallenge + 1);
        }
    };

    const handleShowPreviousChallenge = () => {
        setPulsatePagination(false);

        if (currentChallenge === 0) {
            setCurrentChallenge(challengesToDisplay.length - 1);
        } else {
            setCurrentChallenge(currentChallenge - 1);
        }
    }

    const showMarketInfoForTournament = useMemo(() => {
        const isQuestion = challengesToDisplay[currentChallenge]?.type === CHALLENGE_TYPE.QUESTION
            && challengesToDisplay[currentChallenge].data.showResult === true;

        const isPitch = challengesToDisplay[currentChallenge]?.type === CHALLENGE_TYPE.PITCH_CARD

        return isTournament && availableForMarket > 5000 && (isPitch || isQuestion);
    }, [isTournament, currentChallenge, challengesToDisplay]);

    useEffect(() => {
        if (isTournament && showStepChangeTransition) {
            setCurrentChallenge(0);
            setChallengesToDisplay([]);

            return;
        }

        let challenges = [];

        if (isDemonstration)
            challenges = DemoChallengesComponentService.touchChallengesList(handleShowConceptionModal);
        else if (isTournament)
            challenges = TournamentChallengesComponentService.touchChallengesList(handleShowConceptionModal);
        else
            challenges = ChallengesComponentService.touchChallengesList(handleShowConceptionModal);

        const questionsCount = {total: 0, answered: 0};
        challenges.forEach(c => {
            if (c.type === CHALLENGE_TYPE.QUESTION) {
                if (c.data.answer && c.data.answer?.length) questionsCount.answered++;
                questionsCount.total++;
            }
        });

        if (currentChallenge >= challenges.length) setCurrentChallenge(0);
        setQuestionsCount(questionsCount);
        setChallengesToDisplay(challenges);
    }, [
        signedAccounting, currentStep, teamQuestions, teamUnpredictability, ranking, showBoardForTeam, teamStartup,
        teamFounders, teamValuePropositions, currentChallenge, teamServices, teamSkills,
        tournamentState, showStepChangeTransition
    ]);

    useEffect(() => {
        if (forcePulsatePagination) {
            setPulsatePagination(true);
            store.dispatch(setForcePulsatePagination(false));
        }
    }, [forcePulsatePagination]);

    useEffect(() => {
        if (revealedDemoQuestion)
            setCurrentChallenge(0);
    }, [revealedDemoQuestion]);

    useEffect(() => {
        if (isDemonstration && !hasPlayedIntroVideo)
            DemoSessionService.playWelcomeVideo();
    }, []);

    return (
        <div id="challenges" className={'h-100 w-100'}>
            <div className="p-4 h-100 w-100 challenges-container">
                {challengesToDisplay.length > 0 && (
                    <>
                        <div className="h-100 w-100 animate__animated animate__faster animate__fadeInDown"
                             style={{paddingBottom: challengesToDisplay[currentChallenge]?.type === CHALLENGE_TYPE.TOURNAMENT_PITCH ? 0 : '3rem'}}
                             key={currentChallenge + challengesToDisplay[currentChallenge]?.type + challengesToDisplay[currentChallenge]?.data?.title}>

                            {challengesToDisplay[currentChallenge]?.type === CHALLENGE_TYPE.OBJECTIVE &&
                                <ObjectiveChallenge
                                    key={challengesToDisplay[currentChallenge].data.title}
                                    data={challengesToDisplay[currentChallenge].data}/>
                            }

                            {challengesToDisplay[currentChallenge]?.type === CHALLENGE_TYPE.DEMO_ENDING &&
                                <DemoEnding data={challengesToDisplay[currentChallenge].data}/>
                            }

                            {challengesToDisplay[currentChallenge]?.type === CHALLENGE_TYPE.TOURNAMENT_ENDING &&
                                <TournamentEnding data={challengesToDisplay[currentChallenge].data}/>
                            }

                            {challengesToDisplay[currentChallenge]?.type === CHALLENGE_TYPE.TOURNAMENT_BETA_NPS &&
                                <TournamentBetaNps data={challengesToDisplay[currentChallenge].data}/>
                            }

                            {challengesToDisplay[currentChallenge]?.type === CHALLENGE_TYPE.TOURNAMENT_RANKING &&
                                <TournamentRanking data={challengesToDisplay[currentChallenge].data}/>
                            }

                            {challengesToDisplay[currentChallenge]?.type === CHALLENGE_TYPE.QUESTION &&
                                <QuestionChallenge
                                    key={challengesToDisplay[currentChallenge].data.card.id}
                                    data={challengesToDisplay[currentChallenge].data}/>
                            }

                            {challengesToDisplay[currentChallenge]?.type === CHALLENGE_TYPE.TOURNAMENT_PITCH &&
                                <TournamentPitchChallenge data={challengesToDisplay[currentChallenge].data}/>
                            }

                            {challengesToDisplay[currentChallenge]?.type === CHALLENGE_TYPE.PITCH_CARD &&
                                <PitchChallenge data={challengesToDisplay[currentChallenge].data}/>
                            }

                            {challengesToDisplay[currentChallenge]?.type === CHALLENGE_TYPE.UNPREDICTABILITY &&
                                <UnpredictabilityChallenge data={challengesToDisplay[currentChallenge].data}/>
                            }

                            {challengesToDisplay[currentChallenge]?.type === CHALLENGE_TYPE.RANKING &&
                                <Ranking data={challengesToDisplay[currentChallenge].data}/>
                            }

                            {challengesToDisplay[currentChallenge]?.type === CHALLENGE_TYPE.TEAM_RANKING &&
                                <TeamRanking data={challengesToDisplay[currentChallenge].data}/>
                            }
                        </div>

                        {questionsCount.total > 1 && (
                            <p className="mb-0 ml-4 mb-4 position-absolute left bottom text-white small">
                                {t('texts.questions_answered', questionsCount)}
                            </p>
                        )}

                        {showMarketInfoForTournament && (
                            <div
                                className="w-100 navigation d-flex justify-content-center position-absolute bottom mb-3 text-light animate__animated animate__fadeIn animate__faster">

                                <p className="m-0 small text-center text-warning font-weight-bold">
                                    {currentStep === GAME_STEPS.PITCH_2 && (
                                        "Esta é a última etapa para utilizar o capital disponível para mercado! Vamos contratar serviços ou talentos?"
                                    )}

                                    {currentStep !== GAME_STEPS.PITCH_2 && (
                                        "Você tem capital disponível para mercado. Vamos contratar serviços ou talentos?"
                                    )}
                                </p>
                            </div>
                        )}


                        {challengesToDisplay.length > 1 && (
                            <div
                                className={"navigation d-flex justify-content-center position-absolute bottom mb-3 text-light " + navigationClass}>

                                <button type="button" onClick={handleShowPreviousChallenge}
                                        disabled={isDemonstration && disablePagination}
                                        className={"btn btn-outline-light border-0 px-2 py-0 mr-1 "}>
                                    <FontAwesomeIcon icon={['fas', 'angle-left']} className="fa-2x"/>
                                </button>

                                <h4 className="mb-0 mx-4 font-weight-bold">
                                    {currentChallenge + 1}
                                    <span className="small">/</span>
                                    {challengesToDisplay.length}
                                </h4>

                                <button type="button" onClick={handleShowNextChallenge}
                                        disabled={isDemonstration && disablePagination}
                                        className={"btn btn-outline-light border-0 px-2 py-0 mr-1 "}>
                                    <FontAwesomeIcon icon={['fas', 'angle-right']} className="fa-2x"/>
                                </button>
                            </div>
                        )}
                    </>)
                }
            </div>

            <div
                className={'position-absolute top bottom left right bottom h-100 w-100 d-flex justify-content-center align-items-center background'}
                style={{zIndex: -3}}>
                <img src={theme.logos.challenge_logo} alt="Game Experience logo"/>
            </div>

            {currentStep === GAME_STEPS.CONCEPTION && showConceptionModal && (
                <ConceptionModal onClose={handleCloseConceptionModal}/>)}

            {isDemonstration && (
                <DemoVideoPlayer/>
            )}
        </div>
    );
}