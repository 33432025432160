import React, {useEffect, useState} from "react";

import TeamStepInPodium from "./components/TeamStepInPodium";
import "./Ranking.scss";
import {useTranslation} from "react-i18next";

export default function Ranking({data}) {
    const {t} = useTranslation('pages/common/board/challenges', {keyPrefix: 'components.ranking'});

    const [firstToThirdPlace, setFirstToThirdPlace] = useState([]);
    const [fourthPlaces, setFourthPlaces] = useState([]);

    useEffect(() => {
        const groupedByRanking = {};

        Object.entries(data).forEach(([team, teamData]) => {
            if (!groupedByRanking[teamData.ranking]) groupedByRanking[teamData.ranking] = [];
            groupedByRanking[teamData.ranking].push({...teamData, team: team});
        });

        const sortedForRanking = [];
        if (groupedByRanking[2]) sortedForRanking.push(...groupedByRanking[2]);
        if (groupedByRanking[1]) sortedForRanking.push(...groupedByRanking[1]);
        if (groupedByRanking[3]) sortedForRanking.push(...groupedByRanking[3]);

        setFirstToThirdPlace(sortedForRanking);
        setFourthPlaces(groupedByRanking[4] || []);

    }, [data]);

    return (
        <div
            className="ranking-player d-flex w-100 justify-content-center flex-column align-items-center text-white  border-warning">
            <div className="w-100">
                <div className="box-head bg-warning text-white text-uppercase">
                    {t('texts.podium')}
                </div>
            </div>

            <div className="d-flex ">
                <div className="d-flex  align-items-end">
                    {firstToThirdPlace.map(teamRanking => <TeamStepInPodium teamRanking={teamRanking}
                                                                            key={teamRanking.team}/>)}
                </div>

                <div className="d-flex  align-items-end ml-4">
                    {fourthPlaces.map(teamRanking => <TeamStepInPodium teamRanking={teamRanking}
                                                                       key={teamRanking.team}/>)}
                </div>
            </div>
        </div>
    );
}