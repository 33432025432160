import React, {useEffect, useMemo, useState} from "react";
import {useSelector} from "react-redux";

import OverlayUtil from "utils/OverlayUtil";
import TeamService from "services/player/TeamService";
import ToastUtil from "utils/ToastUtil";
import ModalForm from "../ModalForm";
import i18nUtil from "utils/i18nUtil";
import store from "redux/store";
import LoggerService from "services/common/LoggerService";
import {useTranslation} from "react-i18next";
import FounderCard from "components/cards/FounderCard";

import "./FounderModal.scss";
import SkillCardModel from "../../../../../../../models/SkillCardModel";
import useTranslationForGame from "../../../../../../../hooks/useTranslationForGame";
import DemoSessionService from "../../../../../../../services/common/DemoSessionService";
import DemoPlaceholder from "../DemoPlaceholder";

export default function FoundersModal({onClose, number}) {
    const {t} = useTranslation('pages/common/board/conception_modal', {keyPrefix: 'components.founders_modal'});
    const tfg = useTranslationForGame(t);

    const isDemonstration = useSelector(state => state.game.isDemonstration);
    const isGameMaster = useSelector(state => state.session.isGameMaster);
    const showBoardForTeam = useSelector(state => state.session.showBoardForTeam);

    const skills = useSelector(state => state.cards.skills);
    const selectedFounders = useSelector(state => state.teams[showBoardForTeam].founders);

    const [skillsToDisplay, setSkillsToDisplay] = useState({});

    const skillIcons = useMemo(() => {
        return skills.reduce((icons, skill) => {
            icons[skill.id] = SkillCardModel.randomIcon();
            return icons;
        }, {});
    }, [skills]);

    const handleCardClick = (founder, iconNumber) => {
        if (isDemonstration) {
            DemoSessionService.setFounder(number, founder, iconNumber);
            ToastUtil.toastSuccess(t('toasts.updated_success.title'), tfg('toasts.updated_success.message'));
            onClose();

            return;
        }

        OverlayUtil.toggleLoadingOverlay(true, tfg('overlays.updating'));

        const params = {founder: {id: founder.id, number, icon: iconNumber}};
        if (isGameMaster) params.team = store.getState().session.showBoardForTeam;

        TeamService.updateFounder(params).then(value => {
            if (value) {
                ToastUtil.toastSuccess(t('toasts.updated_success.title'), tfg('toasts.updated_success.message'));
                onClose();
            } else {
                ToastUtil.toastDanger(t('toasts.updated_error.title'), tfg('toasts.updated_error.message'));
            }
        }).catch(reason => {
            console.error('Could not update founder');
            console.error(reason);
            ToastUtil.toastDanger(t('toasts.updated_error.title'), tfg('toasts.updated_error.message'));

            LoggerService.error(reason, {
                action: 'update team founder - team board',
                params: params
            });
        }).finally(() => OverlayUtil.toggleLoadingOverlay(false));
    }

    useEffect(() => {
        const selectedFoundersIds = [];
        if (selectedFounders[0]) selectedFoundersIds.push(selectedFounders[0].id);
        if (selectedFounders[1]) selectedFoundersIds.push(selectedFounders[1].id);

        const skillsPerArea = {};
        skills.filter(skill => !selectedFoundersIds.includes(skill.id)).forEach(skill => {
            if (!skillsPerArea[skill.area]) skillsPerArea[skill.area] = [skill];
            else skillsPerArea[skill.area].push(skill);
        });

        if (isDemonstration) {
            // show only half of the skills
            Object.keys(skillsPerArea).forEach(area => {
                skillsPerArea[area] = skillsPerArea[area].slice(0, Math.round(skillsPerArea[area].length / 2))
            });
        }

        setSkillsToDisplay(skillsPerArea);
    }, [skills, selectedFounders]);


    return (
        <ModalForm showModal={true} onClose={onClose} title={tfg('texts.title')} modalClass="conception-founder-modal">
            {Object.entries(skillsToDisplay).map(([area, skills], index) => (
                <div key={area} className="w-100 text-white">
                    {index !== 0 && (<hr className="mb-4"/>)}

                    <h4 className="ml-5 mb-3 pl-5">{i18nUtil.area(area)}</h4>

                    <div className="d-flex justify-content-center align-items-stretch flex-wrap">
                        {skills.map(skill => {
                            return (
                                <div key={skill.id} className="text-left mb-3">
                                    <FounderCard founder={skill}
                                                 icon={skillIcons[skill.id]}
                                                 selectable={true}
                                                 previousSelected={selectedFounders[number]}
                                                 onSelect={handleCardClick}/>
                                </div>
                            )
                        })}

                        {isDemonstration && (
                            <div className="text-left mb-3">
                                <DemoPlaceholder clasz={"founder-card-placeholder"}/>
                            </div>
                        )}
                    </div>
                </div>
            ))}
        </ModalForm>
    );
}