import React, {memo, useCallback, useMemo, useState} from "react";
import GameCardLayout from "components/cards/layouts/GameCardLayout";

import "./ServiceCard.scss";
import iconFooter from "assets/img/board/ic_money_market_cards.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import I18nUtil from "utils/i18nUtil";
import i18nUtil from "utils/i18nUtil";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import CardAccelerationBar from "../CardAccelerationBar";

const ServiceCard = ({
                         service,
                         onHire = false,
                         isLoading = false,
                         hirable = true,
                         hasCreditToHire = false,
                         meetRequirements = false,
                     }) => {

    const {t} = useTranslation('components/cards', {keyPrefix: 'service_card'});

    const {type, title, description, area, price, icon} = service;

    const [showConfirm, setShowConfirm] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);

    const toggleConfirm = (toggle) => {
        if (!hirable || !hasCreditToHire || !meetRequirements) return;
        setShowConfirm(toggle);
    }

    const handleConfirm = (event) => {
        event.stopPropagation();
        if (hirable && hasCreditToHire && meetRequirements && onHire) {
            setShowConfirm(false);
            onHire(service);
        }
    }

    const handleTooltipToggle = useCallback((show) => {
        if (hirable && !meetRequirements && hasCreditToHire) {
            setShowTooltip(show);
        } else {
            setShowTooltip(false);
        }
    }, [hirable, meetRequirements]);


    const cardClass = useMemo(() => {
        let res = 'service-type-' + type + ' ';

        if (hirable) {
            if (isLoading) res += 'show-loading ';
            if (!meetRequirements) res += 'missing-requirements ';
            if (!hasCreditToHire) res += 'not-enough-credits ';
            if (showConfirm) res += 'show-confirm ';
        } else {
            res += 'show-only ';
        }

        return res;
    }, [hasCreditToHire, hirable, isLoading, meetRequirements, showConfirm, type]);

    const serviceArea = useMemo(() => i18nUtil.area(area), [area]);
    const priceFormatted = useMemo(() => I18nUtil.formatMoney(price), [price]);

    return (
        <GameCardLayout>
            <OverlayTrigger show={showTooltip} onToggle={handleTooltipToggle} placement="auto"
                            overlay={<Tooltip>{t('tooltips.missing_requirements')}</Tooltip>}>

                <div
                    className={"box bg-white service-card d-flex flex-column h-100 animate__animated animate__faster animate__fadeIn " + cardClass}>

                    <div className="box-skill-head d-flex align-items-center">
                        <img className="skill-face position-absolute" src={icon} alt="service icon"/>
                        <div>{title}</div>
                    </div>

                    <div className="box-content skill-content flex-fill" onClick={() => toggleConfirm(true)}
                         onMouseLeave={() => toggleConfirm(false)}>
                        <div className="d-flex justify-content-between align-items-start card-summary">
                            <div
                                className="skill-area text-center text-break text-center">{serviceArea}</div>
                            <div className="power-rating power-rating-dark">
                                <CardAccelerationBar card={service}/>
                            </div>
                        </div>

                        <div className="position-relative overflow-hidden h-100">
                            <p className="mb-0 mt-1 card-description">{description}</p>

                            <div
                                className={"confirm-overlay position-absolute top bottom left right justify-content-center align-items-center text-center"}>

                                <button type="submit" className="btn btn-primary" onClick={handleConfirm}>
                                    {t('buttons.confirm_buy')}
                                </button>
                            </div>

                            <div
                                className={"loading-overlay position-absolute top bottom left right justify-content-center align-items-center text-center"}>

                                <FontAwesomeIcon icon={['fas', 'circle-notch']} className="fa-spin fa-3x"/>
                            </div>
                        </div>
                    </div>

                    <div
                        className="box-footer text-light d-flex justify-content-between px-3 py-2 align-items-center">
                        {hirable && !meetRequirements && (
                            <OverlayTrigger placement="auto"
                                            overlay={<Tooltip>{t('tooltips.missing_requirements')}</Tooltip>}>
                                <FontAwesomeIcon icon={['fas', 'exclamation-circle']}
                                                 className="mr-2 h3 m-0 text-warning"/>
                            </OverlayTrigger>
                        )}

                        <img alt="Cost icon" src={iconFooter}/>
                        <div className="line mx-2"/>
                        <p className="mb-0">{priceFormatted}</p>

                        {hirable && !hasCreditToHire && (
                            <OverlayTrigger placement="auto"
                                            overlay={<Tooltip>{t('tooltips.not_enough_money')}</Tooltip>}>
                                <FontAwesomeIcon icon={['fas', 'exclamation-circle']}
                                                 className="ml-2 h3 m-0 text-warning"/>
                            </OverlayTrigger>
                        )}
                    </div>
                </div>
            </OverlayTrigger>
        </GameCardLayout>
    );
}

export default memo(ServiceCard);