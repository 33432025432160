import React, {memo, useMemo} from "react";
import i18nUtil from "utils/i18nUtil";
import {useSelector} from "react-redux";

import "./ServiceItem.scss";
import CardAccelerationBar from "../../../../../../components/CardAccelerationBar";
import GameUtil from "../../../../../../utils/GameUtil";
import {GAME_PHASES} from "../../../../../../config/CONSTANTS";

const ServiceItem = ({hiredService, onClick, showAccelerationInfo = true, viewType = 1}) => {
    const currentPhase = useSelector(state => state.game.currentPhase);

    const {id, title, area, icon, accelerationPhase2, accelerationPhase3} = hiredService.card;
    const handleClick = () => onClick(hiredService);

    const accelerationInfo = useMemo(() => {
        if (viewType === 1)
            return null;

        if (GameUtil.isCurrentGameEsg()){
            return (<b className="text-white">+{accelerationPhase2}%</b>)
        }

        return currentPhase === GAME_PHASES.PHASE_2
            ? (<span><b className="text-white">{accelerationPhase2}%</b>/{accelerationPhase3}%</span>)
            : (<span>{accelerationPhase2}%/<b className="text-white">{accelerationPhase3}%</b></span>);
    }, [viewType, currentPhase]);

    return (
        <div key={id} onClick={handleClick}
             className="py-2 px-2 d-flex hired-service clickable bg-on-hover">
            <img className="skill-face mr-2" src={icon} alt="Service avatar"/>

            <div className="w-100">
                <div className="d-flex justify-content-between align-items-start">
                    {viewType === 1 && (
                        <div className="skill-area text-center">{i18nUtil.area(area)}</div>
                    )}

                    {showAccelerationInfo && (
                        <div className="power-rating power-rating-light">
                            <CardAccelerationBar card={hiredService.card}/>
                        </div>
                    )}
                </div>

                <p className="skill-name text-break">{title}</p>

                {viewType === 2 && (
                    <p className="text-muted mb-0" style={{fontSize: '95%'}}>{accelerationInfo}</p>
                )}
            </div>
        </div>
    );
}

export default memo(ServiceItem);