import React, {memo, useMemo} from "react";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {GAME_STEPS} from "../../../../../../../config/CONSTANTS";

const UnpredictabilitiesButton = ({onDraw, onShow, step}) => {
    const {t} = useTranslation('pages/game_master/control_panel/game_control', {keyPrefix: "components.unpredictability_button"});

    const currentGameStep = useSelector(state => state.game.currentStep);
    const teamCount = useSelector(state => state.game.teamCount);
    const team1Unpredictability = useSelector(state => state.teams['1'].unpredictabilities[step]);
    const team2Unpredictability = useSelector(state => state.teams['2'].unpredictabilities[step]);
    const team3Unpredictability = useSelector(state => state.teams['3'].unpredictabilities[step]);
    const team4Unpredictability = useSelector(state => state.teams['4'].unpredictabilities[step]);

    const disabled = useMemo(() => {
        return step > currentGameStep || (!team1Unpredictability && currentGameStep > GAME_STEPS.PITCH_3);
    }, [currentGameStep, step]);

    const allRevealed = useMemo(() => {
        if (currentGameStep < step)
            return false;

        const all = [team1Unpredictability, team2Unpredictability, team3Unpredictability, team4Unpredictability];

        for (let i = 0; i < teamCount; i++) {
            if (all[i]?.revealOutcome !== true || all[i]?.reveal !== true)
                return false;
        }

        return true;
    }, [currentGameStep, step, team1Unpredictability, team2Unpredictability, team3Unpredictability, team4Unpredictability, teamCount]);

    const handleDraw = () => onDraw(step);
    const handleShow = () => onShow(step);

    return (
        <div>
            <hr className="my-2"/>

            {!team1Unpredictability && (
                <button type="button" className={"btn btn-info w-100 btn-sm mt-1 text-white"} onClick={handleDraw}
                        disabled={disabled}>
                    {t('buttons.draw')}
                </button>
            )}

            {!!team1Unpredictability && (<>
                <button type="button" className={"btn btn-info w-100 btn-sm mt-1 text-white"} onClick={handleShow}>
                    {t('buttons.show')}
                </button>

                {currentGameStep !== GAME_STEPS.END_GAME && currentGameStep >= step && allRevealed && (
                    <p className="mb-0 mt-1 text-success w-100 text-center small">{t('texts.all_done')}</p>)}
                {currentGameStep !== GAME_STEPS.END_GAME && currentGameStep >= step && !allRevealed && (
                    <p className="m-0 mt-1 text-danger w-100 text-center small">{t('texts.awaiting_action')}</p>)}
            </>)}
        </div>
    )
};

export default memo(UnpredictabilitiesButton);