import React from 'react';
import {TERMS_ROUTES} from "../../../../navigation/ROUTES";

export default function CookiesPtBR() {
    return <>
        <h4>Política de Cookies</h4>

        <p>
            Esta Política de Cookies descreve os diferentes tipos de cookies, web beacons e tecnologias semelhantes que
            usamos
            para coletar automaticamente informações sobre o uso deste site. Referimo-nos a essas tecnologias
            coletivamente como
            “cookies e outras tecnologias”.
        </p>

        <p>
            Esta Política de Cookies é parte integrante e deve ser lida em conjunto com
            nossa <a target="_blank" href={TERMS_ROUTES.PRIVACY_POLICY}>Política de Privacidade</a>.
        </p>

        <p className="font-weight-bold mb-0">
            1. Como Usamos Cookies e Tecnologias Similares
        </p>

        <p className="mb-0">
            Usamos – e permitimos que terceiros usem – cookies e tecnologias semelhantes para fazer nosso site funcionar
            e
            melhorar sua experiência enquanto você usa o site. Os usos específicos de cookies e tecnologias semelhantes
            em nosso
            site são:
        </p>

        <ul>
            <li>Medindo o uso do site</li>
            <li>Obtendo suporte de fornecedores</li>
            <li>Realização de análises do site</li>
            <li>Personalizando o conteúdo que mostramos em nosso site</li>
        </ul>

        <p className="font-weight-bold mb-0">
            2. Como Controlar o Uso de Seus Dados
        </p>

        <p>
            Para obter mais informações sobre seus direitos e formas de limitar como usamos os dados pessoais que
            coletamos,
            visite a Seção 6 de nossa <a target="_blank" href={TERMS_ROUTES.PRIVACY_POLICY}>Política de
            Privacidade</a>.
            Você é livre para definir as configurações do seu navegador ou sistema operacional para limitar certos
            rastreamentos
            ou recusar cookies, mas ao fazer isso, você pode não conseguir usar certos recursos do Site ou aproveitar ao
            máximo
            todas as nossas ofertas.

        </p>

        <p className="font-weight-bold mb-0">
            3. Identificação de Cookies Usados em Nosso Site
        </p>

        <p>
            A lista a seguir apresenta alguns dos cookies e tecnologias semelhantes que usamos em nosso site.
            Especificamente, a
            lista fornece informações sobre cookies persistentes, mas não sobre cookies de sessão ou outras tecnologias
            de
            armazenamento transitórias.
        </p>

        <ul>
            <li>
                <b>acceptedPoliciesVersion:</b> Armazena informações sobre as últimas informações de política exibidas
                ao usuário. Usado para mostrá-las
                novamente quando uma nova versão é publicada.
            </li>

            <li>
                <b>cookiesPreferences:</b> Usado para lembrar a preferência de um usuário ao atualizar suas
                configurações de cookies.
            </li>

            <li>
                <b>user_country:</b> Usado para localizar o aplicativo para o usuário, exibindo informações em seu
                idioma nativo.
            </li>
        </ul>
    </>;
}


