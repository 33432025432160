import React, {memo, useMemo} from "react";

import "./TeacCard.scss";
import SessionReduxService from "services/redux/SessionReduxService";
import {useTranslation} from "react-i18next";
import useTranslationForGame from "../../../../../../hooks/useTranslationForGame";

const TeamCard = ({number, totalPlayers, playersOnline, startup, disabled}) => {
    const {t, i18n} = useTranslation('pages/game_master/control_panel/team_control');
    const tfg = useTranslationForGame(t);

    const handleShowBoardForTeam = () => {
        if (!disabled) {
            document.getElementById('App').scrollTo(0, 0);
            SessionReduxService.updateShowBoardForTeam(number);
        }
    };

    const boxClass = useMemo(() => {
        return 'bg-team-' + number + (disabled ? ' disabled' : '')
    }, [number, disabled]);

    return (
        <div className={"team-box " + boxClass} onClick={handleShowBoardForTeam}>
            <div className="w-100 py-2 px-1">
                {t('components.team_card.texts.team', {team: number})}
                <div
                    className="text-center team-startup-name-container p-1 d-flex justify-content-center align-items-center">
                    <p className="m-0 font-weight-bold">{startup?.title || tfg('components.team_card.texts.no_startup')}</p>
                </div>
                <div className="d-flex small">{t('texts.players_online', {
                    online: playersOnline,
                    total: totalPlayers
                })}</div>
            </div>
        </div>
    );
};

export default memo(TeamCard);