import React from 'react';
import {TERMS_ROUTES} from "../../../../navigation/ROUTES";

export default function CookiesEn() {
    return <>
        <h4>Cookies Policy</h4>

        <p>
            This Cookie Policy describes the different types of cookies, web beacons, and similar technologies we use to
            automatically collect information about your use of this site. We refer to these technologies collectively
            as
            “cookies and other technologies.”
        </p>

        <p>
            This Cookie Policy is integral to, and should be read in conjunction with, our <a target="_blank"
                                                                                              href={TERMS_ROUTES.PRIVACY_POLICY}>Privacy
            Policy</a>.
        </p>

        <p className="font-weight-bold mb-0">
            1. How We Use Cookies and Similar Technologies
        </p>

        <p className="mb-0">
            We use – and we allow third parties to use – cookies and similar technologies to make our site function and
            to
            improve your experience while you use the site. The specific uses of cookies and similar technologies on our
            site
            are:
        </p>

        <ul>
            <li>Measuring site usage</li>
            <li>Obtaining support from vendors</li>
            <li>Performing site analytics</li>
            <li>Personalizing the content we show on our site</li>
        </ul>

        <p className="font-weight-bold mb-0">
            2. How to Control Our Use of Your Data
        </p>

        <p>
            For more information about your rights and ways to limit how we use personal data we collect, please visit
            Section 6
            of our <a target="_blank" href={TERMS_ROUTES.PRIVACY_POLICY}>Privacy Policy</a>. You are free to set your
            browser
            or operating system settings to limit certain tracking or to decline cookies, but
            by doing so, you may not be able to use certain features on the Site or take full advantage of all of our
            offerings.
        </p>

        <p className="font-weight-bold mb-0">
            3. Identification of Cookies Used on Our Site
        </p>

        <p>
            The following list shows some cookies and similar technologies we use on our site. Specifically, the
            list provides information about persistent cookies but not session cookies or other transitory storage
            technologies.
        </p>

        <ul>
            <li>
                <b>acceptedPoliciesVersion:</b> Stores information about the last policy info displayed to the user.
                Used to show it again when a new
                version is published.
            </li>

            <li>
                <b>cookiesPreferences:</b> Used to remember a user’s preference when updating its cookies settings.
            </li>

            <li>
                <b>user_country:</b> Used to localize the application for the user, displaying information in its native
                language.
            </li>
        </ul>
    </>;
}


