import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import "./DemoPlaceholder.scss";
import {useTranslation} from "react-i18next";

export default function DemoPlaceholder({clasz}) {
    const {t} = useTranslation('common');

    return (
        <div className={"demo-placeholder "+ clasz}>
            <div className="d-flex justify-content-center align-items-center flex-column text-center h-100 pb-3 py-1 px-3">
                <FontAwesomeIcon icon={['fas', 'plus']} className="fa-2x mb-2 plus-icon"/>
                <p className="mb-0">{t('more_at_full_game')}</p>
            </div>
        </div>
    );
}