import React from "react";
import {GAME_STEPS} from "config/CONSTANTS";
import {useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";

export default function PhaseEnd({showTeamRanking}) {
    const {t} = useTranslation(['pages/game_master/control_panel/game_control'], {keyPrefix: "components.phases.components.phase_end"});
    const t2 = useTranslation('common').t;

    const hasRevealedWinners = useSelector(state => state.game.hasRevealedWinners);
    const currentGameStep = useSelector(state => state.game.currentStep);

    return (<div className="row">
        <table className="table-switches table table-borderless table-striped">
            <tbody>
            <tr>
                {currentGameStep === GAME_STEPS.END_GAME && hasRevealedWinners && (
                    <td className="text-success"><FontAwesomeIcon icon={['fas', 'check']}/></td>)}

                {currentGameStep === GAME_STEPS.END_GAME && !hasRevealedWinners && (
                    <td className="text-success"><FontAwesomeIcon icon={['fas', 'play']}/></td>)}

                {currentGameStep < GAME_STEPS.END_GAME && (
                    <td><FontAwesomeIcon icon={['fas', 'ellipsis-h']}/></td>)}

                <td>{t2('game_steps.reveal_winners')}</td>
                <td></td>
                <td></td>
                <td>
                    <button type="button" className="btn btn-primary btn-sm float-right"
                            disabled={currentGameStep < GAME_STEPS.END_GAME} onClick={showTeamRanking}>
                        {t('buttons.show_ranking')}
                    </button>
                </td>
            </tr>

            <tr>
                <td colSpan={5} className="text-center w-100 font-weight-bold text-uppercase">
                    {t('texts.end')}
                </td>
            </tr>
            </tbody>
        </table>
    </div>);
}