import React from "react";
import {Navigate} from "react-router-dom";
import {GAME_MASTER_ROUTES, PLAYER_ROUTES, TOURNAMENT_ROUTES} from "../ROUTES";
import AuthService from "services/player/AuthService";
import store from "redux/store";

export default function GuestRoute({children}) {
    const isAuthenticated = AuthService.isAuthenticated();

    const getRedirectToRoute = () => {
        if (store.getState().session.isGameMaster)
            return GAME_MASTER_ROUTES.CONTROL_PANEL;

        if (store.getState().game.isTournament)
            return TOURNAMENT_ROUTES.LOBBY;

        return PLAYER_ROUTES.LOBBY;
    };

    return isAuthenticated ? (<Navigate to={{pathname: getRedirectToRoute()}} replace={true}/>) : children;
}