import React, {useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

import TeamPlayersModal from "./TeamPlayers/TeamPlayersModal";

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {GAME_MASTER_ROUTES, PLAYER_ROUTES} from "navigation/ROUTES";

import './Header.scss';
import SessionReduxService from "../../../../services/redux/SessionReduxService";
import {Trans, useTranslation} from "react-i18next";
import PlayerActionsButtons from "components/PlayerActionsButtons";
import AuthService from "../../../../services/player/AuthService";
import SessionPersistenceUtil, {PLAYER_KEY} from "../../../../utils/SessionPersistenceUtil";
import TournamentPlayerInfoModal from "./TournamentPlayerInfoModal/TournamentPlayerInfoModal";
import TutorialModal from "../../../tournament/TutorialModal/TutorialModal";
import TournamentService from "../../../../services/common/TournamentService";
import ToastUtil from "../../../../utils/ToastUtil";
import {IS_LOCAL_ENV} from "../../../../config/CONSTANTS";
import HelpModal from "../../../tournament/HelpModal/HelpModal";

const USERS_TO_DISPLAY_IN_HEADER = 3;

const CONTACT_LINK = 'https://www.startupmundi.com';

export function Header() {
    const {t, i18n} = useTranslation('pages/common/board/header');
    const navigate = useNavigate();

    const isDemonstration = useSelector(state => state.game.isDemonstration);
    const isTournament = useSelector(state => state.game.isTournament);
    const game = useSelector(state => state.game.gameInfo.game);

    const isGameMaster = useSelector(state => state.session.isGameMaster);
    const showBoardForTeam = useSelector(state => state.session.showBoardForTeam);

    const player = useSelector(state => state.session.user);
    const players = useSelector(state => state.game.players);

    const [teamInHeader, setTeamInHeader] = useState([]);
    const [teamInTooltip, setTeamInTooltip] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const [showTutorialModal, setShowTutorialModal] = useState(false);
    const [showHelpModal, setShowHelpModal] = useState(false);

    const [showTeamPlayersModal, setShowTeamPlayersModal] = useState(false);
    const handleShowTeamPlayersModal = () => setShowTeamPlayersModal(true);
    const handleCloseTeamPlayersModal = () => setShowTeamPlayersModal(false);

    const contactLink = useMemo(() => {
        return CONTACT_LINK + (i18n.language === 'pt' ? '/pt-br' : '');
    }, [i18n.language]);

    const backToLobby = () => {
        if (isGameMaster) {
            SessionReduxService.updateShowBoardForTeam(null);
            navigate(GAME_MASTER_ROUTES.CONTROL_PANEL);
        } else {
            navigate(PLAYER_ROUTES.LOBBY);
        }
    }

    const handleRestart = async () => {
        setIsLoading(true);

        if (!await TournamentService.restartGame()) {
            setIsLoading(false);
            ToastUtil.toastDanger('Não foi possível avançar o jogo');
        }

        setIsLoading(false);
    }

    const handleQuitDemo = () => {
        window.location.reload();
        // navigate(0);
    }

    const handleQuitGame = () => {
        const gameLink = SessionPersistenceUtil.get(PLAYER_KEY).gameUrl;

        AuthService.leave();
        window.location.replace(gameLink);
    }

    useEffect(() => {
        const teammates = players.filter(p => p.team === showBoardForTeam && p?.id !== player?.id);

        const usersInHeader = teammates.length > USERS_TO_DISPLAY_IN_HEADER ? teammates.slice(0, USERS_TO_DISPLAY_IN_HEADER) : teammates;
        const usersInTooltip = teammates.length > USERS_TO_DISPLAY_IN_HEADER ? teammates.slice(USERS_TO_DISPLAY_IN_HEADER) : [];

        setTeamInHeader([player, ...usersInHeader]);
        setTeamInTooltip(usersInTooltip);
    }, [isGameMaster, player, players, showBoardForTeam]);

    return (
        <>
            <div id="board-header"
                 className={'w-100 d-flex justify-content-center px-3 py-2 mb-2 bg-team-' + showBoardForTeam}>
                <div className="container-custom p-0 w-100 d-flex justify-content-between">
                    <div className="h-100">
                        {!isDemonstration && !isTournament && (
                            <button type={"button"} className={'btn btn-secondary back-button py-0 mb-2 h-100 mr-3'}
                                    onClick={backToLobby}>
                                <FontAwesomeIcon icon={['fas', 'angle-left']} className={'mr-1'}/>

                                <Trans t={t}
                                       i18nKey={!isGameMaster ? 'texts.back_to_lobby' : 'texts.back_to_control_panel'}/>
                            </button>
                        )}


                        <div className="d-inline-flex align-items-center h-100">
                            <h4 className="m-0 d-inline team-number">
                                {isDemonstration && "Startup Mundi Demo Session"}
                                {isTournament && "TORNEIO " + (game ? game.toUpperCase() : '')}
                                {!isDemonstration && !isTournament && t('texts.team', {team: showBoardForTeam})}
                            </h4>
                        </div>

                        {isGameMaster &&
                            <h6 className="m-0 ml-3 d-inline text-uppercase">{t('texts.game_master_view')}</h6>}
                    </div>

                    <div className="d-flex align-items-center">
                        {!isDemonstration && !isTournament && (
                            <PlayerActionsButtons checkinButtonClass="btn btn-secondary btn-sm mr-1 px-3"
                                                  npsButtonClass="btn btn-secondary btn-sm mr-1 px-3"
                                                  editPlayerButtonClass="btn btn-secondary btn-sm mr-1 px-3"/>
                        )}

                        {isDemonstration && (<>
                            <button className="btn btn-outline-secondary border-0 btn-sm mr-1 px-3"
                                    onClick={handleQuitDemo}>
                                {t('buttons.quit')}
                            </button>

                            <a href={contactLink} id="btn-sm-page" target="_blank"
                               className="btn btn-link text-white btn-sm mr-1 px-3">
                                {t('buttons.more_info')}
                            </a>
                        </>)}

                        {isTournament && (<>
                            {IS_LOCAL_ENV && (<>
                                <button className="btn btn-outline-secondary border-0 btn-sm mr-2 px-3"
                                        onClick={handleRestart}
                                        disabled={isLoading}>

                                    Reiniciar sessão
                                </button>
                            </>)}


                            <button className="btn btn-outline-secondary border-0 btn-sm mr-2 px-3"
                                    onClick={() => setShowHelpModal(true)}>
                                Precisa de ajuda?
                            </button>

                            <button className="btn btn-outline-secondary border-0 btn-sm mr-2 px-3"
                                    onClick={() => setShowTutorialModal(true)}>
                                Rever tutorial
                            </button>

                            <button className="btn btn-outline-secondary border-0 btn-sm mr-1 px-3"
                                    onClick={handleQuitGame}>
                                {t('buttons.quit')}
                            </button>
                        </>)}


                        <div className="separator"/>

                        <h6 className="mr-3 mb-0">
                            {isTournament && (
                                <span>Jogando <br/> como:</span>
                            )}

                            {!isTournament && (
                                <Trans t={t} i18nKey={'texts.team_members'}/>
                            )}
                        </h6>

                        <OverlayTrigger placement="bottom"
                                        overlay={<Tooltip id="tooltip-disabled">{t('tooltips.show_more')}</Tooltip>}>
                            <div className={'d-flex align-items-center cursor-pointer'}
                                 onClick={handleShowTeamPlayersModal}>

                                {!isGameMaster && (
                                    <div className={'text-center d-inline-block px-2 user current'}>
                                        <FontAwesomeIcon
                                            icon={['fas', (player?.localPlayers?.length ? 'users' : 'user')]}
                                            size={"2x"}/>
                                        <p className={'font-weight-bold m-0'}>{player.nickname}</p>
                                    </div>
                                )}


                                {teamInHeader.map((user, index) => {
                                    return (user.id !== player.id &&
                                        (
                                            <div key={index} className={'text-center d-inline-block px-2 user'}>
                                                <FontAwesomeIcon
                                                    icon={['fas', (user?.localPlayers?.length ? 'users' : 'user')]}
                                                    size={"2x"}/>
                                                <p className={'m-0'}>{user.nickname}</p>
                                            </div>
                                        )
                                    )
                                })}

                                {isGameMaster && teamInHeader.length === 1 && (
                                    <div className={'text-center d-inline-block px-2'}>
                                        <FontAwesomeIcon
                                            icon={['fas', 'question']}
                                            size={"2x"}/>
                                        <p className={'m-0'}>-</p>
                                    </div>
                                )}

                                {teamInTooltip.length > 0 &&
                                    (
                                        <div className={'text-center d-inline-block px-2'}>
                                            {t('texts.and_more')}
                                            <p className={'my-1'}>{teamInTooltip.length}</p>
                                        </div>
                                    )}
                            </div>
                        </OverlayTrigger>
                    </div>
                </div>
            </div>

            {showTeamPlayersModal && !isTournament && (<TeamPlayersModal onClose={handleCloseTeamPlayersModal}/>)}
            {showTeamPlayersModal && isTournament && (
                <TournamentPlayerInfoModal onClose={handleCloseTeamPlayersModal}/>)}

            {showTutorialModal && (<TutorialModal onClose={() => setShowTutorialModal(false)}/>)}
            {showHelpModal && (<HelpModal onClose={()=>setShowHelpModal(false)}/>)}
        </>
    );
}