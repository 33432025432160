export const PLAYER_ROUTES = {
    JOIN: '/join/:gameSession',
    LOBBY: '/lobby',
    BOARD: '/board',
    EXIT: '/bye',
};

export const TOURNAMENT_ROUTES = {
    JOIN: '/t/:gameSession',
    LOBBY: '/t/lobby',
};


export const GAME_MASTER_ROUTES = {
    JOIN: '/gm/join/:gameSession',
    CONTROL_PANEL: '/gm/control-panel'
};

export const COMMON_ROUTES = {
    ERROR: '/error',
    PLAYER_REMOVED: '/removed-by-game-master',
    SOFT_RELOAD: '/soft-reload',
};

export const DEMO_ROUTES = {
    JOIN: '/demo',
    BOARD: '/demo/play/board',
}

export const PUBLIC_ROUTES = {
    VERIFY_CONNECTION: '/verify-connection',
}

export const TERMS_ROUTES = {
    TERMS_OF_USE: '/terms-of-use',
    PRIVACY_POLICY: '/privacy-policy',
    COOKIES_POLICY: '/cookies-policy'
};
