import React from "react";

import "./Summary.scss";
import {useSelector} from "react-redux";
import i18nUtil from "../../../../../../../utils/i18nUtil";
import {useTranslation} from "react-i18next";

export default function Summary() {
    const {t} = useTranslation('pages/common/board/accounting', {keyPrefix: 'texts'});

    const showBoardForTeam = useSelector(state => state.session.showBoardForTeam);
    const accountingDetails = useSelector(state => state.teams[showBoardForTeam].accounting.totals);

    return (
        <>
            <h5 className="text-uppercase text-warning mb-2">{t('totals')}</h5>
            <div className="row accounting-summary mb-3">
                <div className="col-6 details">
                    <div>
                        <p>{t('available_for_market')}</p>
                        <p>{i18nUtil.formatMoney(accountingDetails.availableForMarket || 0)}</p>
                    </div>

                    <div>
                        <p>{t('revenue')}</p>
                        <p>{i18nUtil.formatMoney(accountingDetails.revenue || 0)}</p>
                    </div>
                    <div>
                        <p>{t('expenses')}</p>
                        <p>{i18nUtil.formatMoney(accountingDetails.totalCost || 0)}</p>
                    </div>
                    <div>
                        <p>{t('in_bank_with_sign')}</p>
                        <p>{i18nUtil.formatMoney(accountingDetails.balance || 0)}</p>
                    </div>
                </div>

                <div className="col-6 details">
                    <div>
                        <p>{t('capital')}</p>
                        <p className="text-warning">{i18nUtil.formatMoney(accountingDetails.capital || 0)}</p>
                    </div>
                    <div>
                        <p>{t('months')}</p>
                        <p>{accountingDetails.monthsAdvanced}</p>
                    </div>
                    <div>
                        <p>{t('acceleration')}</p>
                        <p>{i18nUtil.formatPercent(accountingDetails.acceleration || 0)}</p>
                    </div>
                    <div>
                        <p>{t('market_investment_rate')}</p>
                        <p>{i18nUtil.formatPercent(accountingDetails.marketInvestmentRate || 0)}</p>
                    </div>
                </div>
            </div>
        </>
    );
}