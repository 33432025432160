import React, {useEffect, useState} from "react";

import {useSelector} from "react-redux";
import OverlayUtil from "utils/OverlayUtil";
import TeamService from "services/player/TeamService";
import ToastUtil from "utils/ToastUtil";
import ModalForm from "../ModalForm";
import store from "redux/store";
import LoggerService from "services/common/LoggerService";
import {useTranslation} from "react-i18next";
import StartupCard from "components/cards/StartupCard";

import "./StartupModal.scss";
import useTranslationForGame from "../../../../../../../hooks/useTranslationForGame";
import DemoSessionService from "../../../../../../../services/common/DemoSessionService";
import DemoPlaceholder from "../DemoPlaceholder";
import {GAMES} from "../../../../../../../config/CONSTANTS";

export default function StartupsModal({onClose}) {
    const {t} = useTranslation('pages/common/board/conception_modal', {keyPrefix: 'components.startup_modal'});
    const tfg = useTranslationForGame(t);

    const isDemonstration = useSelector(state => state.game.isDemonstration);
    const isGameMaster = useSelector(state => state.session.isGameMaster);
    const game = useSelector(state => state.game.gameInfo.game);

    const showBoardForTeam = useSelector(state => state.session.showBoardForTeam);
    const startups = useSelector(state => state.cards.startups);

    const selectedStartups = useSelector(state => state.teams.startups);
    const teamSelectedStartup = useSelector(state => state.teams[showBoardForTeam].startup);

    const [startupsToDisplay, setStartupsToDisplay] = useState([]);

    useEffect(() => {
        const selected = Object.values(selectedStartups);
        let toDisplay = startups;

        if (isDemonstration && game === GAMES.SMGX) {
            // show only half of the startups
            toDisplay = startups.slice(0, Math.round(startups.length / 2));
        }

        setStartupsToDisplay(toDisplay.filter(st => !selected.includes(st)));
    }, [startups, selectedStartups]);

    const handleCardClick = (startup) => {
        if (isDemonstration) {
            DemoSessionService.setStartup(startup);
            ToastUtil.toastSuccess(t('toasts.updated_success.title'), tfg('toasts.updated_success.message'));
            onClose();

            return;
        }


        OverlayUtil.toggleLoadingOverlay(true, tfg('overlays.updating'));

        const params = {startup: startup.id};
        if (isGameMaster) params.team = store.getState().session.showBoardForTeam;

        TeamService.updateStartup(params).then(value => {
            if (value) {
                ToastUtil.toastSuccess(t('toasts.updated_success.title'), tfg('toasts.updated_success.message'));
                onClose();
            } else {
                ToastUtil.toastDanger(t('toasts.updated_error.title'), tfg('toasts.updated_error.message'));
            }
        }).catch(reason => {
            console.error('Could not update startup');
            console.error(reason);
            ToastUtil.toastDanger(t('toasts.updated_error.title'), tfg('toasts.updated_error.message'));

            LoggerService.error(reason, {
                action: 'update team startup - team board',
                params: params
            });
        }).finally(() => OverlayUtil.toggleLoadingOverlay(false));
    }

    return (
        <ModalForm showModal={true} onClose={onClose} title={tfg('texts.title')} modalClass="conception-startup-modal">
            {startupsToDisplay.map(st => {
                return (
                    <div key={st.id} className="d-inline-block text-left mb-3 float-right">
                        <StartupCard startup={st}
                                     selectable={true}
                                     onSelect={handleCardClick}
                                     previousSelected={teamSelectedStartup}/>
                    </div>
                )
            })}

            {isDemonstration && (
                <div className="d-inline-block text-left mb-3 float-right">
                    <DemoPlaceholder clasz={"startup-card-placeholder"}/>
                </div>
            )}
        </ModalForm>
    );
}