import React, {useEffect, useMemo} from "react";

import "./ObjectiveChallenge.scss";
import "components/cards/ObjectiveCard.scss";
import GameCardLayout from "components/cards/layouts/GameCardLayout";

import SimpleBar from "simplebar-react";
import ToastUtil from "../../../../../../utils/ToastUtil";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useSelector} from "react-redux";
import DemoSessionService from "../../../../../../services/common/DemoSessionService";
import useTranslationForGame from "../../../../../../hooks/useTranslationForGame";

const SHARE_LINK = `https://www.startupmundi.com`;

export default function DemoEnding({data}) {
    const {t, i18n} = useTranslation('pages/common/board/challenges', {keyPrefix: 'services.demo_challenges_service'})
    const tfg = useTranslationForGame(t);

    const hasNotifiedSessionEnded = useSelector(state => state.demo.state.hasNotifiedSessionEnded);

    const shareLink = useMemo(() => {
        return SHARE_LINK + (i18n.language === 'pt' ? '/pt-br/demo' : '/demo');
    }, [i18n.language]);

    const wppShareText = useMemo(() => {
        return encodeURIComponent(t('ending.wpp_share_text', {link: shareLink}));
    }, [i18n.language]);


    const handleRestartDemo = () => window.location.reload();
    const handleClick = () => document.getElementById('btn-sm-page').click();

    const handleCopyLink = (link) => {
        navigator.clipboard.writeText(link);
        ToastUtil.toastSuccess(t('toasts.link_copied.title'), t('toasts.link_copied.message'));
    };

    useEffect(() => {
        if (!hasNotifiedSessionEnded)
            DemoSessionService.notifyDemoSessionEnded();
    }, []);

    return (
        <div className="objective-challenge h-100 w-100 d-flex justify-content-center align-items-center flex-column">
            <GameCardLayout>
                <div className="objective-card box d-flex flex-column w-100 h-100 border-warning bg-dark ">
                    <div>
                        <div className="box-head bg-warning text-uppercase">
                            {t('ending.title')}
                        </div>
                    </div>

                    <div className="content-container h-100 w-100 d-flex justify-content-center align-items-center">
                        <SimpleBar className="content-scrollable mt-5 w-100">
                            <div className="value-content flex-fill w-100 text-white px-1 mb-3 text-center">
                                <h5 className="mx-2 mb-0">
                                    {t('ending.message')}
                                </h5>

                                <br/>

                                <div className="d-flex align-items-center justify-content-center flex-column"
                                     style={{gap: 10}}>
                                    <div>
                                        <button type="button" className="btn btn-secondary mr-2 py-0 px-2"
                                                onClick={() => handleCopyLink(shareLink)}>
                                            <FontAwesomeIcon icon={['fas', 'copy']}/>
                                        </button>

                                        {shareLink}
                                    </div>

                                    {t('ending.or')}

                                    <a className="btn btn-success text-white px-3" data-action="share/whatsapp/share"
                                       href={"https://wa.me/?text=" + wppShareText} target="_blank">

                                        <FontAwesomeIcon icon={['fab', 'whatsapp']} className="mr-2"
                                                         style={{fontSize: '140%'}}/>
                                        {t('ending.share_on_wpp')}
                                    </a>
                                </div>

                                <br/>
                                <br/>

                                <h5 className="mx-2">
                                    {t('ending.contact')}
                                </h5>
                            </div>
                        </SimpleBar>
                    </div>


                    <div className="box-footer bg-warning">
                        <button type="button" className="w-100 h-100 bg-transparent border-0 text-uppercase"
                                onClick={handleClick}>

                            {t('ending.action')}
                        </button>
                    </div>

                </div>
            </GameCardLayout>

            <div className="mt-4">
                <button className="btn btn-secondary" onClick={handleRestartDemo}>
                    {tfg('ending.play_again')}
                </button>
            </div>
        </div>
    );
}