import React from "react";
import "./GameCardLayout.scss";
import "./SmgxCardLayout.scss";

export default function GameCardLayout({children, className = ""}) {
    return (
        <div className={"game-card smgx-card " + className}>
            {children}
        </div>
    );
}