import React, {useMemo} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import i18nUtil from "utils/i18nUtil";

import "./TeamStepInPodium.scss";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import useTranslationForGame from "../../../../../../../../hooks/useTranslationForGame";
import GameUtil from "../../../../../../../../utils/GameUtil";

const POSITIONS = [
    'first-pos', 'second-pos', 'third-pos', 'fourth-pos'
]

export default function TeamStepInPodium({teamRanking}) {
    const {t} = useTranslation('pages/common/board/challenges', {keyPrefix: 'components.ranking.components.team_step_in_podium.texts'});
    const tfg = useTranslationForGame(t);

    const {team, ranking, acceleration, monthsAdvanced, revenue, show} = teamRanking;
    const startup = useSelector(state => state.teams[team]?.startup);

    const isEsg = useMemo(() => GameUtil.isCurrentGameEsg(), []);

    if (show) {
        return (
            <div
                className={"team-step-in-podium-player animate__animated animate__fadeIn d-flex flex-column align-items-center justify-content-end text-center position-relative " + POSITIONS[ranking - 1]}>

                <div className={"trophy-icon mb-2 animate__animated animate__bounceIn text-ranking-" + ranking}>
                    {ranking === 1 && <FontAwesomeIcon icon={['fas', 'trophy']} className="fa-3x"/>}
                    {(ranking === 2 || ranking === 3) && <FontAwesomeIcon icon={['fas', 'medal']} className="fa-2x"/>}
                    {ranking === 4 && <FontAwesomeIcon icon={['fas', 'award']} className="fa-2x"/>}
                </div>

                <h5 className={'font-weight-bold team-number'}>
                    {t('team', {team})}
                    <br/>
                    {startup?.title || ''}
                </h5>

                <div className={"podium-step  bg-team-" + team}>
                    <h5>{i18nUtil.ordinalSuffix(ranking)}</h5>
                </div>

                <div className="mt-2 totals">
                    <div className="animate__animated animate__faster animate__fadeInUp">
                        <b>{tfg('revenue')}</b>
                        <p>{isEsg ? i18nUtil.formatDecimal(revenue) : i18nUtil.formatMoney(revenue)}</p>
                    </div>
                    <div className="animate__animated animate__faster animate__fadeInUp">
                        <b>{tfg('acceleration')}</b>
                        <p>{i18nUtil.formatPercent(acceleration)}</p>
                    </div>
                    <div className="animate__animated animate__faster animate__fadeInUp">
                        <b>{t('months_advanced')}</b>
                        <p>{monthsAdvanced}</p>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div
                className={"team-step-in-podium-player d-flex flex-column justify-content-end text-center " + POSITIONS[ranking - 1]}>

                <h1><FontAwesomeIcon icon={['fas', 'question-circle']}/></h1>

                <div className={"podium-step podium-step-placeholder d-flex align-items-center "}>
                    <h5>{i18nUtil.ordinalSuffix(ranking)}</h5>
                </div>

                <div className="mt-2 totals"/>
            </div>
        )
    }
}