import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";

export default function TeamInfo({number, missingDefinitions}) {
    const {t} = useTranslation('pages/game_master/control_panel/step_status',
        {keyPrefix: 'components.status_conception.components.status_info_per_team.components.team_info.texts'});

    return (<div className="d-flex align-items-center mb-1">
        {missingDefinitions.length > 0 && (<>
            <h6 className="text-danger mb-0">
                <FontAwesomeIcon icon={['fas', 'exclamation-circle']} className="mr-2"/>
                {t('team', {team: number})}
            </h6>

            <p className="mb-0 ml-2">
                {missingDefinitions.join(' | ')}
            </p>
        </>)}


        {missingDefinitions.length === 0 && (<>
            <h6 className="text-success mb-0">
                <FontAwesomeIcon icon={['fas', 'check-circle']} className="mr-2"/>
                {t('all_set', {team: number})}
            </h6>
        </>)}

    </div>);
}