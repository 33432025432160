import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";

import "./UserList.scss";
import "components/cards/layouts/SmgxCardLayout.scss";

import gmIcon from "assets/img/board/ic_gamemaster.svg";
import SimpleBar from "simplebar-react";
import {useTranslation} from "react-i18next";

export default function UserList() {
    const {t} = useTranslation(['pages/player/lobby'], {keyPrefix: 'components.user_list'});

    const player = useSelector(state => state.session.user);
    const players = useSelector(state => state.game.players);
    const gameMaster = useSelector(state => state.game.gameInfo.gameMaster);

    const [playersToDisplay, setPlayersToDisplay] = useState([]);

    useEffect(() => {
        setPlayersToDisplay([...players].sort((a, b) => {
            if (a?.nickname && b?.nickname) return a.nickname.localeCompare(b.nickname)
            return 0;
        }));
    }, [players])

    return (
        <div className="smgx-card w-100 user-list">
            <div
                className="box d-inline-block border-warning w-100 h-100">
                <div className="box-head bg-warning text-uppercase">{t('texts.box_title')}</div>

                <div className="box-content mr-0">
                    <div className="d-flex align-items-center pt-1 pb-2 pl-2">
                        <img alt="Game master icon" src={gmIcon} className="gm-avatar mr-3"/>
                        <div>
                            <h3 className="text-warning">{t("texts.game_master")}</h3>
                            <h3 className="text-white">{gameMaster || '-'}</h3>
                        </div>
                    </div>

                    <SimpleBar style={{maxHeight: 470, height: 470}} className="simplebar pr-2">
                        <table className="table table-striped table-borderless table-players">
                            <thead>
                            <tr>
                                <th className="fit-content text-center">#</th>
                                <th className="fit-content text-center">{t('tables.users.header.team')}</th>
                                <th>{t('tables.users.header.player')}</th>
                                <th className="fit-content"/>
                            </tr>
                            </thead>

                            <tbody className="text-white">
                            {playersToDisplay.length ? (
                                playersToDisplay.map((p, index) =>
                                    <tr key={p.id}>
                                        <td className="text-center">{index + 1}</td>
                                        <td className="text-center">
                                                <span
                                                    className={`badge badge-team-${p.team}`}>{p.team || '?'}</span>
                                        </td>
                                        <td>
                                            <span className={p.id === player.id ? 'current-player' : ''}>
                                                {p.nickname}
                                            </span>

                                            {p.localPlayers && p.localPlayers.length > 0 && (
                                                <p className="small text-info m-0 local-players">
                                                    {p.localPlayers.map(lp => lp.nickname).join(', ')}
                                                </p>
                                            )}
                                        </td>
                                        <td className="text-center player-status">
                                                <span
                                                    className={p.isConnected ? 'player-online' : 'player-offline'}/>
                                        </td>
                                    </tr>
                                )
                            ) : null}

                            {!playersToDisplay.length ? (
                                <tr>
                                    <td colSpan={4} className="text-muted small text-center">
                                        {t('tables.users.empty_message')}
                                    </td>
                                </tr>
                            ) : null}
                            </tbody>
                        </table>
                    </SimpleBar>
                </div>
            </div>
        </div>
    );
}