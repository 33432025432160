import React, {useMemo} from "react";

import "./TeamCard.scss";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import SwalModalUtil from "utils/SwalModalUtil";
import {PLAYER_ROUTES} from "navigation/ROUTES";
import TeamService from "services/player/TeamService";
import OverlayUtil from "utils/OverlayUtil";
import ToastUtil from "utils/ToastUtil";
import {GAME_STEPS} from "config/CONSTANTS";
import LoggerService from "services/common/LoggerService";
import {useTranslation} from "react-i18next";

export default function TeamCard({number}) {
    const navigate = useNavigate();
    const {t} = useTranslation(['pages/player/lobby'], {keyPrefix: 'components.team_card'});

    const currentStep = useSelector(state => state.game.currentStep);
    const currentTeam = useSelector(state => state.session.team);
    const canJoinTeams = useSelector(state => state.game.canJoinTeam);
    const startups = useSelector(state => state.teams.startups);
    const teamCount = useSelector(state => state.game.teamCount);

    const handleJoinTeam = async (teamNumber) => {
        OverlayUtil.toggleLoadingOverlay(true, t('overlays.join_team', {team: teamNumber}));

        TeamService.joinTeam(teamNumber).then(result => {
            if (result) handleShowBoard();
            else ToastUtil.toastDanger(t('toasts.join_team_error.title'), t('toasts.join_team_error.message', {team: teamNumber}));
        }).catch(reason => {
            console.error('Could not join team');
            console.error(reason);
            ToastUtil.toastDanger(t('toasts.join_team_error.title'), t('toasts.join_team_error.message', {team: teamNumber}));

            LoggerService.error(reason, {action: 'join team - lobby', team: number});
        }).finally(() => {
            OverlayUtil.toggleLoadingOverlay(false);
        });
    }

    const handleShowBoard = async () => {
        navigate(PLAYER_ROUTES.BOARD);
    }

    const handleLeaveTeam = async () => {
        SwalModalUtil.confirmModal(null, t('confirms.leave_team')).then(async result => {
            if (result) {
                OverlayUtil.toggleLoadingOverlay(true, t('overlays.leave_team'));

                TeamService.leaveTeam().then(result => {
                    if (!result)
                        ToastUtil.toastDanger(t('toasts.leave_team_error.title'), t('toasts.leave_team_error.message'));
                }).catch(reason => {
                    console.error('Could not leave team');
                    console.error(reason);
                    ToastUtil.toastDanger(t('toasts.leave_team_error.title'), t('toasts.leave_team_error.message'));
                    LoggerService.error(reason, {action: 'leave team - lobby'});
                }).finally(() => {
                    OverlayUtil.toggleLoadingOverlay(false);
                });
            }
        });
    };

    const containerClass = useMemo(() => {
        return (currentStep === GAME_STEPS.AWAITING || (currentTeam && currentTeam !== number)
            || teamCount < number ? 'not-joinable ' : '')
            + 'team-card-' + number;
    }, [currentStep, currentTeam, number, teamCount]);

    return (
        <div className={"team-card-container mx-2 my-1 text-dark " + containerClass}>
            <div className="h-100 w-100 d-flex align-items-center justify-content-center flex-column pb-2">
                <div className="flex-fill pt-4 w-75">
                    <h2 className="font-weight-bold text-center">{t('texts.team_title', {team: number})}</h2>

                    {teamCount >= number && startups[number] && (
                        <div className="mt-4 font-weight-bold startup-info w-100 py-2 rounded text-center">
                            <p className="mb-0">{t('texts.startup', {startup: startups[number].title})}</p>
                            <p className="mb-0">{t('texts.area', {area: startups[number].sector})}</p>
                        </div>)}
                </div>

                <div className="pb-4 w-100">
                    {teamCount >= number && currentStep > GAME_STEPS.AWAITING && (
                        <>
                            {!currentTeam && canJoinTeams && (
                                <div className="text-center">
                                    <button type="button" className="btn btn-primary text-warning btn-lg px-4 "
                                            onClick={() => handleJoinTeam(number)}>
                                        {t('buttons.join')}
                                    </button>
                                </div>)}

                            {currentTeam === number && (
                                <div className="w-100 d-flex justify-content-around px-4">
                                    <button type="button" className="btn btn-primary text-warning btn-lg px-3 "
                                            onClick={handleShowBoard}>
                                        {t('buttons.board')}
                                    </button>

                                    {canJoinTeams && (
                                        <button type="button"
                                                className="btn btn-secondary border-0 btn-lg px-3 ml-2 "
                                                onClick={handleLeaveTeam}>{t('buttons.leave')}</button>)}
                                </div>)}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}