import {GAME_STEPS} from "config/CONSTANTS";
import i18nUtil from "utils/i18nUtil";
import {i18n} from 'i18n';

const t = i18n.getFixedT(null, 'pages/game_master/control_panel/game_control', 'components.advance_game_buttons.toasts.advance.messages');

const GoToStepConfirmMessageUtil = {
    getMessage(step) {
        const stepName = i18nUtil.stepName(step);

        switch (step) {
            // phase 1
            case GAME_STEPS.ONBOARDING:
                return t('onboarding');
            case GAME_STEPS.CONCEPTION:
                return i18nUtil.translateForGame(t,'conception');
            case GAME_STEPS.MVP:
                return t('default_with_question', {step: stepName});

            // phase 2
            case GAME_STEPS.BETA:
                return t('default_with_previous_pitch', {step: stepName, pitch: '1'});
            case GAME_STEPS.GTM:
            case GAME_STEPS.GROWTH_MODEL:
                return t('default_with_question', {step: stepName});

            // phase 3
            case GAME_STEPS.SALES_MACHINE:
                return t('default_with_previous_pitch', {step: stepName, pitch: '2'});
            case GAME_STEPS.CUSTOMER_SUCCESS:
            case GAME_STEPS.PRODUCT_SCALABILITY:
                return t('default_with_question', {step: stepName});

            // pitches
            case GAME_STEPS.PITCH_1:
            case GAME_STEPS.PITCH_2:
            case GAME_STEPS.PITCH_3:
                return t('pitch', {step: stepName});

            // end game
            case GAME_STEPS.END_GAME:
                return t('end_game')

            default:
                return '';
        }
    }
};

export default GoToStepConfirmMessageUtil;