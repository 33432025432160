import React from "react";

import "./InfoEvent.scss";

export default function InfoEvent({data}) {
    return (
        <div className="info-event mx-4 h-100 text-white d-flex justify-content-center align-items-center">
            <div className="bg-dark px-2 py-3 text-center rounded shadow">
                <h4 className="mb-0 text-uppercase">{data}</h4>
            </div>
        </div>
    );
}