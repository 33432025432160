import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    state: {
        hasShownQuestionInfoCard: false,
        hasFinishedConception: false,
        showAvailableForMarketChallenge: false,
        showAccountingChallenge: false,
        showStepChangeTransition: false,
        hasShownPitchInfoFor: null,
    },
};

export const TournamentSlice = createSlice({
    name: 'tournament',
    initialState,
    reducers: {
        updateTournamentState(state, {payload}) {
            state.state = {...state.state, ...payload};
        }
    }
});

export const {
    updateTournamentState
} = TournamentSlice.actions;


export default TournamentSlice.reducer;