import React, {useEffect, useMemo, useRef, useState} from "react";

import {useSelector} from "react-redux";
import AccountingService from "services/common/AccountingService";

import {SUPPORTED_LANGUAGES} from "i18n";
import LoggerService from "services/common/LoggerService";
import "./AnimatedStepChange.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import store from "../../../redux/store";

import smgxBoardPt from "assets/img/board/journey_map/smgx/pt.svg";
import smgxBoardEn from "assets/img/board/journey_map/smgx/en.svg";
import smgxBoardEs from "assets/img/board/journey_map/smgx/es.svg";

import esgBoardPt from "assets/img/board/journey_map/esg/pt.svg";
import esgBoardEn from "assets/img/board/journey_map/esg/en.svg";
import esgBoardEs from "assets/img/board/journey_map/esg/es.svg";


import GameUtil from "../../../utils/GameUtil";
import {Portal} from "react-portal";
import i18n from "i18next";
import i18nUtil from "../../../utils/i18nUtil";
import TournamentService from "../../../services/common/TournamentService";

const T1Icon = '<g><defs><style>.clst-t1-1{fill:#e86559;}.clst-t1-2{fill:#020100;opacity:0.19;}</style></defs><g><circle class="clst-t1-1" cx="#{cx}" cy="#{cy}" r="28.15"/><circle class="clst-t1-2" cx="#{cx}" cy="#{cy}" r="19.91"/><circle class="clst-t1-1" cx="#{cx}" cy="#{cy}" r="10.97"/></g></g>';
const T2Icon = '<g><defs><style>.clst-t2-1{fill:#f4a347;}.clst-t2-2{fill:#020100;opacity:0.19;}</style></defs><g><circle class="clst-t2-1" cx="#{cx}" cy="#{cy}" r="28.15"/><circle class="clst-t2-2" cx="#{cx}" cy="#{cy}" r="19.91"/><circle class="clst-t2-1" cx="#{cx}" cy="#{cy}" r="10.97"/></g></g>';
const T3Icon = '<g><defs><style>.clst-t3-1{fill:#80b7d6;}.clst-t3-2{fill:#020100;opacity:0.19;}</style></defs><g><circle class="clst-t3-1" cx="#{cx}" cy="#{cy}" r="28.15"/><circle class="clst-t3-2" cx="#{cx}" cy="#{cy}" r="19.91"/><circle class="clst-t3-1" cx="#{cx}" cy="#{cy}" r="10.97"/></g></g>';
const T4Icon = '<g><defs><style>.clst-t4-1{fill:#4ea772;}.clst-t4-2{fill:#020100;opacity:0.19;}</style></defs><g><circle class="clst-t4-1" cx="#{cx}" cy="#{cy}" r="28.15"/><circle class="clst-t4-2" cx="#{cx}" cy="#{cy}" r="19.91"/><circle class="clst-t4-1" cx="#{cx}" cy="#{cy}" r="10.97"/></g></g>';

const TEAM_PINS = [T1Icon, T2Icon, T3Icon, T4Icon];

const MAX_MONTHS_ESG = 24;
const MAX_MONTHS_SMGX = 48;

const BEFORE_TEXT_TIMER = 2500;  // in ms
const TEXT_TIMER = 3000;        // in ms

export default function AnimatedStepChange({onClose}) {
    const board = useRef();

    const currentStep = useSelector(state => state.game.currentStep);
    const teamCount = useSelector(state => state.game.teamCount);

    const [hasLoaded, setHasLoaded] = useState(false);
    const [timer, setTimer] = useState(undefined);
    const [textClass, setTextClass] = useState(false);
    const [containerClass, setContainerClass] = useState('animate__animated animate__fadeIn animate__faster')

    const isEsg = useMemo(() => GameUtil.isCurrentGameEsg(), []);

    const handleBoardLoaded = () => {
        setHasLoaded(true);
        board.current.style.opacity = 1;

        setTimer(setTimeout(handleShowText, BEFORE_TEXT_TIMER));
    }

    const handleShowText = () => {
        setTextClass('animate__animated animate__fadeIn');
        setTimer(setTimeout(handleHideText, TEXT_TIMER));
    };

    const handleHideText = () => {
        setContainerClass('animate__animated animate__fadeOut');

        setTimer(setTimeout(() => {
            TournamentService.touchTournamentState({showStepChangeTransition: false});
        }, 1000));
    }

    const boardToShow = useMemo(() => {
        if (store.getState().game.customization?.journey_map)
            return store.getState().game.customization.journey_map;

        switch (i18n.language) {
            case SUPPORTED_LANGUAGES.PORTUGUESE:
                return isEsg ? esgBoardPt : smgxBoardPt;
            case SUPPORTED_LANGUAGES.SPANISH:
                return isEsg ? esgBoardEs : smgxBoardEs;
            default:
                return isEsg ? esgBoardEn : smgxBoardEn;
        }
    }, [isEsg]);

    // touch effect that highlights the current step
    useEffect(() => {
        if (!hasLoaded) return;

        try {
            const domEl = board.current.contentDocument;

            const steps = domEl.getElementsByClassName('st6');
            for (let i = 0; i < steps.length; i++) {
                steps[i].style.display = 'none';
            }

            let stepToDisplay = currentStep - 1;
            if (stepToDisplay < 10) stepToDisplay = '0' + stepToDisplay.toString();

            const stepElement = domEl.getElementById('step-' + stepToDisplay);
            if (stepElement) stepElement.style.display = '';
        } catch (e) {
            LoggerService.error(e, 'Error updating journey map');
        }
    }, [hasLoaded, currentStep]);


    // touch teams' pins
    useEffect(() => {
        if (!hasLoaded) return;

        try {
            const domEl = board.current.contentDocument;
            const ranking = AccountingService.calculateMonthsAdvanced();
            const maxMonths = isEsg ? MAX_MONTHS_ESG : MAX_MONTHS_SMGX;

            for (let i = 1; i < teamCount + 1; i++) {
                const existingPinEl = domEl.getElementById('mark-t-' + (i));
                if (existingPinEl) existingPinEl.remove();

                let newPinPosition = ranking[i];

                if (newPinPosition > maxMonths)
                    newPinPosition = maxMonths;
                else if (newPinPosition < 10)
                    newPinPosition = '0' + newPinPosition.toString();


                let newPinPositionEl = domEl.getElementById(`pm-mo-${newPinPosition}-${i}`);
                newPinPositionEl = newPinPositionEl ?? domEl.getElementById(`pm-mo-00-${i}`);

                if (newPinPositionEl) {
                    let newPin = TEAM_PINS[i - 1]
                        .replaceAll('#{cx}', newPinPositionEl.getAttribute('cx'))
                        .replaceAll('#{cy}', newPinPositionEl.getAttribute('cy'));

                    const newEl = document.createElementNS("http://www.w3.org/2000/svg", "g");
                    newEl.innerHTML = newPin;
                    newEl.id = 'mark-t-' + i;

                    newPinPositionEl.parentNode.append(newEl);
                }
            }
        } catch (e) {
            LoggerService.error(e, 'Error updating journey map');
        }
    }, [hasLoaded]);


    useEffect(() => {
        return () => clearTimeout(timer);
    }, []);


    return (<Portal>
        <div id="animated-step-change"
             className={"show-question-modal d-flex justify-content-lg-center pt-xxl-5 pt-md-3 pt-0 px-lg-4 px-2 show " + (isEsg ? ' esg ' : ' smgx ') + containerClass}>

            <div className="container-custom h-100 w-100">
                <div className="d-flex w-100 justify-content-center content-container">
                    <div className="board-wrapper position-relative justify-content-center align-items-center">
                        {!hasLoaded && (
                            <FontAwesomeIcon icon={['fas', 'circle-notch']}
                                             className="position-absolute m-auto top bottom left right fa-6x text-muted fa-spin"/>)}

                        {boardToShow && (<object style={{opacity: 0}} height="100%" width="100%"
                                                 className="animate__animated animate__fadeIn animate__faster rounded shadow"
                                                 onLoad={handleBoardLoaded}
                                                 id="journey-board"
                                                 type="image/svg+xml"
                                                 data={boardToShow}
                                                 ref={board}/>)}


                        {textClass && (
                            <div
                                className="position-absolute text-center m-auto top bottom left right d-flex justify-content-center align-items-center">
                                <div className={"step-info flex-fill p-5 shadow " + textClass}>
                                    <h2 className="text-white m-0 text-uppercase font-weight-bold">
                                        Etapa {currentStep - 1} - {i18nUtil.stepName(currentStep)}
                                    </h2>
                                </div>
                            </div>
                        )}
                    </div>


                </div>
            </div>
        </div>
    </Portal>)
}