import React, {useEffect} from "react";
import {Trans, useTranslation} from "react-i18next";
import {i18nService} from "../../../i18n";
import InfoPageLayout from "./InfoPageLayout/InfoPageLayout";


export default function SessionNotFoundInfo() {
    const {t} = useTranslation('pages/common/session_status', {keyPrefix: 'components.session_not_found.texts'})

    useEffect(() => {
        i18nService.setLanguage();
    }, []);

    return (
        <InfoPageLayout withFarewell={false}>
            <h3 className='mb-3 text-danger font-weight-bold'>{t('title')}</h3>
            <Trans t={t} i18nKey={'message'} className="mb-4" parent={'h4'}/>
        </InfoPageLayout>
    );
}