import React from "react";

import "./CardPlaceholder.scss";
import GameCardLayout from "components/cards/layouts/GameCardLayout";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function CardPlaceholder({onClick, color}) {
    return (
        <GameCardLayout>
            <div className={"conception-modal-card-placeholder box text-left border-" + color} onClick={onClick}>
                <div className={"w-100 h-100  bg position-absolute top bottom left right bg-" + color}/>

                <div className="value-content d-flex justify-content-center align-items-center h-100 text-muted">
                    <FontAwesomeIcon icon={['fas', 'question-circle']} className="fa-4x"/>
                </div>
            </div>
        </GameCardLayout>
    );
}