import React, {useEffect, useMemo, useState} from "react";
import {Button, Modal} from "react-bootstrap";
import {useSelector} from "react-redux";
import ToastUtil from "utils/ToastUtil";
import SwalModalUtil from "utils/SwalModalUtil";
import {useTranslation} from "react-i18next";

export default function GameLinksModal({onClose}) {
    const {t} = useTranslation('pages/game_master/control_panel/header', {keyPrefix: 'components.game_links_modal'})
    const [showGmLink, setShowGmLink] = useState(false);
    const gameInfo = useSelector(state => state.game.gameInfo);

    const checkConnectionLink = useMemo(() => {
      return window.location.origin + '/verify-connection';
    }, []);

    useEffect(() => {
        setShowGmLink(false);
    }, []);

    const handleCopyLink = (link, isControlPanelLink = false) => {
        if (isControlPanelLink) {
            SwalModalUtil.infoModal(t('confirms.copy_gm_link_warn.title'), t('confirms.copy_gm_link_warn.message'), t('confirms.copy_gm_link_warn.confirm'))
                .then(result => doCopy(link));
        } else {
            doCopy(link);
        }
    };

    const doCopy = (link) => {
        // eslint-disable-next-line no-unused-expressions
        navigator.clipboard.writeText(link);
        ToastUtil.toastSuccess(t('toasts.link_copied.title'), t('toasts.link_copied.message'));
    }


    return (
        <Modal show={true} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>{t('texts.modal_title')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="form-group">
                    {gameInfo.callUrl && (
                        <button type="button" className="btn btn-primary mr-2 py-0 px-2 float-right"
                                onClick={() => handleCopyLink(gameInfo.callUrl)}>
                            {t('buttons.copy')}
                        </button>
                    )}
                    <label>{t('texts.call_link')}</label>
                    {gameInfo.callUrl && (
                        <a href={gameInfo.callUrl} rel="noreferrer" target="_blank"
                           className="d-block text-break">{gameInfo.callUrl}</a>
                    )}
                    {!gameInfo.callUrl && (<p className="m-0">-</p>)}
                </div>

                <hr/>

                <div className="form-group">
                    {gameInfo.gameUrl && (
                        <button type="button" className="btn btn-primary mr-2 py-0 px-2 float-right"
                                onClick={() => handleCopyLink(gameInfo.gameUrl)}>
                            {t('buttons.copy')}
                        </button>
                    )}
                    <label>{t('texts.game_link')}</label>

                    {gameInfo.gameUrl && (
                        <a href={gameInfo.gameUrl} rel="noreferrer" target="_blank"
                           className="d-block text-break">{gameInfo.gameUrl}</a>
                    )}
                    {!gameInfo.gameUrl && (<p className="m-0">-</p>)}
                </div>

                <hr/>

                <div className="form-group">
                    {gameInfo.gameMasterUrl && (
                        <>
                            <button type="button" className="btn btn-primary mr-2 py-0 px-2 float-right"
                                    onClick={() => handleCopyLink(gameInfo.gameMasterUrl, true)}>
                                {t('buttons.copy')}
                            </button>

                            <button type="button" className="btn btn-warning text-white mr-2 py-0 px-2 float-right"
                                    onClick={() => setShowGmLink(!showGmLink)}>
                                {showGmLink ? t('buttons.hide') : t('buttons.show')}
                            </button>
                        </>
                    )}

                    <label>{t('texts.game_master_link')}</label>

                    {showGmLink && gameInfo.gameMasterUrl && (
                        <a href={gameInfo.gameMasterUrl} rel="noreferrer" target="_blank"
                           className="d-block text-break">{gameInfo.gameMasterUrl}</a>
                    )}
                    {showGmLink && !gameInfo.gameMasterUrl && (<p className="m-0">-</p>)}

                    {!showGmLink && (
                        <p className="m-0">**********</p>
                    )}
                </div>

                <hr/>

                <div className="form-group">
                    <button type="button" className="btn btn-primary mr-2 py-0 px-2 float-right"
                            onClick={() => handleCopyLink(checkConnectionLink)}>
                        {t('buttons.copy')}
                    </button>

                    <label>{t('texts.verify_connection_link')}</label>

                    <a href={checkConnectionLink} rel="noreferrer" target="_blank"
                       className="d-block text-break">{checkConnectionLink}</a>

                    <p className="small text-muted mb-0 mt-2">
                        {t('texts.verify_connection_info')}
                    </p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>{t('buttons.close')}</Button>
            </Modal.Footer>
        </Modal>
    );
}