import React, {useMemo, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import BugReportModal from "../../BugReportModal/BugReportModal";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import AdjustVolumeModal from "../../AdjustVolumeModal/AdjustVolumeModal";

export default function Footer() {
    const {t} = useTranslation('common');
    const now = (new Date()).getUTCFullYear();

    const volumeConfig = useSelector(state => state.session.volumeConfig);

    const [showBugReportModal, setShowBugReportModal] = useState(false);
    const handleShowBugReportModal = () => setShowBugReportModal(true);
    const handleCloseBugReportModal = () => setShowBugReportModal(false);

    const [showAdjustVolumeModal, setShowAdjustVolumeModal] = useState(false);
    const handleShowAdjustVolumeModal = () => setShowAdjustVolumeModal(true);
    const handleCloseAdjustVolumeModal = () => setShowAdjustVolumeModal(false);

    const volumeButtonStyle = useMemo(() => {
        const hasVolume = !volumeConfig.isMuted && volumeConfig.volume > 0;

        return {
            class: hasVolume ? 'text-white-50' : 'btn-outline-danger',
            valueToDisplay: (!volumeConfig.isMuted ? Math.floor(volumeConfig.volume * 100) : 0),
            icon: hasVolume ? 'volume-up' : 'volume-mute'
        }
    }, [volumeConfig]);

    return (
        <>
            <footer id="app-footer"
                    className="position-relative bottom right text-center text-white-50  pt-3 mt-3 pb-2">
                <p className="mx-3 mb-0 p-0 small">
                    @ {now}

                    <a href="https://www.startupmundi.com.br/" className="text-white-50 text-decoration-underline ml-1"
                       target="_blank" rel="noreferrer">
                        Mundi Game Experiences</a>.

                    <br/>
                    {t('rights_reserved')}
                </p>

                <button className="btn btn-link small text-white-50 p-0 text-decoration-none btn-report-bug"
                        style={{fontSize: '80%'}}
                        onClick={handleShowBugReportModal}>
                    <FontAwesomeIcon icon={['fas', 'bug']}/> {t('btn_report_bug')}
                </button>


                <button className={"btn btn-adjust-volume small py-0 px-1  ml-2 " + volumeButtonStyle.class}
                        style={{fontSize: '80%'}}
                        onClick={handleShowAdjustVolumeModal}>

                    <FontAwesomeIcon icon={['fas', volumeButtonStyle.icon]} className="mr-1"/>
                    {t('adjust_volume', {volume: volumeButtonStyle.valueToDisplay})}
                </button>
            </footer>

            {showBugReportModal && (<BugReportModal onClose={handleCloseBugReportModal}/>)}
            {showAdjustVolumeModal && (<AdjustVolumeModal onClose={handleCloseAdjustVolumeModal}/>)}
        </>
    );
}