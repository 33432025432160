import Utils from "utils/Utils";
import store from "redux/store";
import {
    addPlayers,
    removePlayers, setAllowCertificateRequest,
    setCanHireCards,
    setCanJoinTeam,
    setCurrentStep,
    setCustomization,
    setGameInfo,
    setIsNationalSession,
    setIsOwnSession,
    setIsPaused,
    setIsPocket,
    setIsTemporary,
    setIsTournament,
    setMaxPlayerCount,
    setPlayerAccessControl,
    setPreSelectedStartups,
    setTeamCount,
    setTournamentInfo,
    setUsePreSelectedStartups,
    updatePlayer,
    updateRanking
} from "redux/slices/gameSlice";

import SessionReduxService from "./SessionReduxService";
import TeamReduxService from "./TeamReduxService";
import {GAME_STEPS} from "config/CONSTANTS";
import {addMessage, setHasDisplayedGoToBoardMessage} from "redux/slices/messagesSlice";
import AuthService from "../player/AuthService";
import {COMMON_ROUTES} from "../../navigation/ROUTES";
import {setPitchBonus} from "../../redux/slices/teamsSlice";
import {i18n, i18nService, SUPPORTED_LANGUAGES} from "../../i18n";
import {setLang} from "../../redux/slices/sessionSlice";
import {setTheme} from "../../redux/slices/themeSlice";
import LoggerService from "../common/LoggerService";

async function getT() {
    return i18n.loadNamespaces('services/player/game_redux_service')
        .then(() => i18n.getFixedT(null, 'services/player/game_redux_service', 'texts'));
}


const GameReduxService = {
    addPlayer(player) {
        store.dispatch(addPlayers(player));
        return true;
    },

    updatePlayer(player) {
        const players = store.getState().game.players;
        const user = store.getState().session.user;

        for (let i = 0; i < players.length; i++) {
            if (players[i].id === player.id) {
                store.dispatch(updatePlayer(player));

                if (players[i].id === user.id) {
                    SessionReduxService.updateUser(player);
                    if (player.team !== undefined) SessionReduxService.updateTeam(player.team);
                }

                return true;
            }
        }

        return false;
    },

    updateNpsCount(playerId, npsCount) {
        const players = store.getState().game.players;
        const user = store.getState().session.user;

        for (let i = 0; i < players.length; i++) {
            if (players[i].id === playerId) {
                const newPlayerData = {...players[i], npsCount};

                store.dispatch(updatePlayer(newPlayerData));

                if (players[i].id === user.id) {
                    SessionReduxService.updateUser(newPlayerData);
                }

                return true;
            }
        }
    },

    updatePlayerTeam(playerId, team) {
        const players = store.getState().game.players;
        const user = store.getState().session.user;

        for (let i = 0; i < players.length; i++) {
            if (players[i].id === playerId) {
                const newPlayerData = {...players[i], team};

                store.dispatch(updatePlayer(newPlayerData));

                if (players[i].id === user.id) {
                    SessionReduxService.updateUser(newPlayerData);
                }

                return true;
            }
        }
    },

    async removePlayer(player) {
        const user = store.getState().session.user;

        if (player.id === user.id) {
            await AuthService.leave();
            window.location.href = COMMON_ROUTES.PLAYER_REMOVED;
        } else {
            store.dispatch(removePlayers(player));
        }
    },

    updateStep(step) {
        store.dispatch(setCurrentStep(step));
        return true;
    },

    updateAllData(data) {
        TeamReduxService.toggleIgnoreToasts(true);

        // updateLang
        if (data?.gameInfo && data?.gameInfo?.language) {
            let appLang;
            let isNationalSession = false;

            switch (data.gameInfo.language) {
                case "pt":
                case "pt-BR":
                    appLang = SUPPORTED_LANGUAGES.PORTUGUESE;
                    isNationalSession = true;
                    break;
                case "es":
                    appLang = SUPPORTED_LANGUAGES.SPANISH;
                    break;
                default:
                    appLang = SUPPORTED_LANGUAGES.ENGLISH;
                    break;
            }

            store.dispatch(setLang(appLang));
            if (i18n.language !== appLang) i18nService.setLanguage(appLang);
        }


        // add message do billboard
        const currentStep = store.getState().game.currentStep;
        if ((data.step && currentStep !== data.step) || currentStep === GAME_STEPS.AWAITING) {
            setTimeout(async () => {
                let message = false;
                const t = await getT();

                switch (data.step) {
                    case GAME_STEPS.AWAITING:
                        if (data?.gameInfo?.startAt) {
                            store.dispatch(addMessage(t('game_scheduled', {
                                start: Utils.utcDateTimeToLocalDateStringWithoutSeconds(data.gameInfo.startAt)
                            })));
                        } else {
                            store.dispatch(addMessage(t('not_started')));
                        }
                        break;
                    case GAME_STEPS.ONBOARDING:
                        message = t('begins_now');
                        break;
                    case GAME_STEPS.END_GAME:
                        message = t('finished');
                        break;
                    case null:
                    case undefined:
                        break;
                    default:
                        if (!store.getState().messages.hasDisplayedGoToBoardMessage) {
                            message = t('join_board');
                            store.dispatch(setHasDisplayedGoToBoardMessage(true));
                        }
                        break;
                }

                if (message) {
                    store.dispatch(addMessage(message));
                }
            });
        }

        // update data
        if (Utils.isset(data.gameInfo)) store.dispatch(setGameInfo(data.gameInfo));
        if (Utils.isset(data.canJoinTeam)) store.dispatch(setCanJoinTeam(data.canJoinTeam));
        if (Utils.isset(data.canHireCards)) store.dispatch(setCanHireCards(data.canHireCards));
        if (data.teamCount) store.dispatch(setTeamCount(data.teamCount));
        if (Utils.isset(data.step)) store.dispatch(setCurrentStep(data.step));
        if (Utils.isset(data.teamCount)) store.dispatch(setTeamCount(data.teamCount));
        if (Utils.isset(data.isPaused)) store.dispatch(setIsPaused(data.isPaused));
        if (Utils.isset(data.canJoinTeam)) store.dispatch(setCanJoinTeam(data.canJoinTeam));
        if (Utils.isset(data.playerAccessControl)) store.dispatch(setPlayerAccessControl(data.playerAccessControl));
        if (Utils.isset(data.allowCertificateRequest)) store.dispatch(setAllowCertificateRequest(data.allowCertificateRequest));
        if (Utils.isset(data.isPocket)) store.dispatch(setIsPocket(data.isPocket));
        if (Utils.isset(data.isOwnSession)) store.dispatch(setIsOwnSession(data.isOwnSession));
        if (Utils.isset(data.isNationalSession)) store.dispatch(setIsNationalSession(data.isNationalSession));
        if (Utils.isset(data.isTemporary)) store.dispatch(setIsTemporary(data.isTemporary));
        if (Utils.isset(data.isTournament)) store.dispatch(setIsTournament(data.isTournament));
        if (Utils.isset(data.maxPlayerCount)) store.dispatch(setMaxPlayerCount(data.maxPlayerCount));

        if (Utils.isset(data.pitchBonus)) {
            data.pitchBonus.forEach(data => store.dispatch(setPitchBonus(data)));
        }

        if (Utils.isset(data.ranking)) {
            Object.entries(data.ranking).forEach(([team, data]) => {
                store.dispatch(updateRanking({team, data}));
            });
        }

        if (Utils.isset(data.usePreSelectedStartups)) {
            store.dispatch(setUsePreSelectedStartups(data.usePreSelectedStartups));

            if (data.usePreSelectedStartups === true) {
                store.dispatch(setPreSelectedStartups(data.preSelectedStartups));
                for (let i = 1; i < 5; i++) {
                    TeamReduxService.updateStartup(i, data.preSelectedStartups[i]);

                    const founders = data.preSelectedStartups[i].defaultFounders;
                    const founderIcons = data.preSelectedFoundersIcons[i];

                    for (let j = 0; j < 2; j++) {
                        if (founders[j]) {
                            TeamReduxService.updateFounder(i, j, founders[j]);
                            TeamReduxService.updateFounderIcon(i, j, founderIcons[j]);
                        }
                    }
                }
            } else {
                store.dispatch(setPreSelectedStartups(false));
                for (let i = 1; i < 5; i++) {
                    TeamReduxService.updateStartup(i, null);

                    for (let j = 0; j < 2; j++) {
                        TeamReduxService.updateFounder(i, j, null);
                        TeamReduxService.updateFounderIcon(i, j, null);
                    }
                }
            }
        }

        if (data.tournamentInfo)
            store.dispatch(setTournamentInfo(data.tournamentInfo));

        TeamReduxService.toggleIgnoreToasts(false);
    },

    updateGameCustomization(customization) {
        store.dispatch(setCustomization(customization));
    },

    async updateTheme(themeToUse) {
        return fetch(themeToUse, {cache: "no-store"})
            .then(response => response.json())
            .then(themeJson => store.dispatch(setTheme(themeJson)))
            .catch(err => LoggerService.error(err, {action: '[GameReduxService] updateTheme', themeToUse}));
    }
};

export default GameReduxService;