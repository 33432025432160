import React from "react";
import PhaseStatusTD from "./components/PhaseStatusTD";
import {GAME_STEPS, GAMES} from "config/CONSTANTS";
import PitchBonusButtons from "./components/PitchBonusButtons";
import QuestionsButton from "./components/QuestionsButton";
import UnpredictabilitiesButton from "./components/UnpredictabilitiesButton";
import {Trans, useTranslation} from "react-i18next";
import i18nUtil from "../../../../../../utils/i18nUtil";
import useTranslationForGame from "../../../../../../hooks/useTranslationForGame";
import {useSelector} from "react-redux";

export default function Phase2({
                                   showQuestionsCallback,
                                   drawUnpredictabilitiesCallback,
                                   showUnpredictabilitiesCallback
                               }) {

    const {t} = useTranslation(['pages/game_master/control_panel/game_control'], {keyPrefix: "components.phases"});
    const tfg = useTranslationForGame(t);

    const isEsg = useSelector(state => state.game.gameInfo.game === GAMES.ESG);

    return (<>
        {!isEsg && (
            <p className="small text-muted">{tfg('texts.info_pocket')}</p>
        )}

        <table className="table-switches table table-borderless table-striped">
            <tbody>
            <tr>
                <PhaseStatusTD step={GAME_STEPS.BETA}/>
                <td>{i18nUtil.stepName(GAME_STEPS.BETA)}</td>
                <td>
                    <Trans t={t} i18nKey={tfg('texts.release_questions_phase_2', {}, true)}
                           components={{step: i18nUtil.stepName(GAME_STEPS.BETA)}}/>
                </td>
                <td>
                    <QuestionsButton step={GAME_STEPS.BETA}
                                     onClick={() => showQuestionsCallback(GAME_STEPS.BETA)}/>

                    <UnpredictabilitiesButton step={GAME_STEPS.BETA}
                                              onShow={showUnpredictabilitiesCallback}
                                              onDraw={drawUnpredictabilitiesCallback}/>
                </td>
            </tr>

            <tr>
                <PhaseStatusTD step={GAME_STEPS.GTM}/>
                <td>{i18nUtil.stepName(GAME_STEPS.GTM)}</td>
                <td>
                    <Trans t={t} i18nKey={tfg('texts.release_questions_phase_2', {}, true)}
                           components={{step: i18nUtil.stepName(GAME_STEPS.GTM)}}/>
                </td>
                <td>
                    <QuestionsButton step={GAME_STEPS.GTM} onClick={showQuestionsCallback}/>
                    <UnpredictabilitiesButton step={GAME_STEPS.GTM}
                                              onShow={showUnpredictabilitiesCallback}
                                              onDraw={drawUnpredictabilitiesCallback}/>
                </td>
            </tr>

            <tr>
                <PhaseStatusTD step={GAME_STEPS.GROWTH_MODEL}/>
                <td>{i18nUtil.stepName(GAME_STEPS.GROWTH_MODEL)}</td>
                <td>
                    <Trans t={t} i18nKey={tfg('texts.release_questions_phase_2', {}, true)}
                           components={{step: i18nUtil.stepName(GAME_STEPS.GROWTH_MODEL)}}/>
                </td>
                <td>
                    <QuestionsButton step={GAME_STEPS.GROWTH_MODEL} onClick={showQuestionsCallback}/>
                    <UnpredictabilitiesButton step={GAME_STEPS.GROWTH_MODEL}
                                              onShow={showUnpredictabilitiesCallback}
                                              onDraw={drawUnpredictabilitiesCallback}/>
                </td>
            </tr>

            <tr>
                <PhaseStatusTD step={GAME_STEPS.PITCH_2}/>
                <td>{i18nUtil.stepName(GAME_STEPS.PITCH_2)}</td>
                <td>
                    <Trans t={t} i18nKey={'texts.release_pitch'}
                           components={{step: i18nUtil.stepName(GAME_STEPS.PITCH_2)}}/>
                </td>
                <td>
                    <PitchBonusButtons step={GAME_STEPS.PITCH_2}/>
                </td>
            </tr>

            </tbody>
        </table>
    </>);
}