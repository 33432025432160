import "./ReconnectingOverlay.scss";
import FadeIn from "assets/plugins/react-fade-in/FadeIn";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {useSelector} from "react-redux";

export default function ReconnectingOverlay() {
    const isReconnecting = useSelector(state => state.session.isReconnecting);

    if (!isReconnecting) return null;

    return (
        <div id="reconnecting-overlay" className="pb-5">
            <FadeIn childClassName="d-flex justify-content-center">
                <FontAwesomeIcon icon={['fas', 'circle-notch']} className="mb-4 fa-3x fa-spin"/>
                <h2>Connection lost</h2>
                <h3>Reconnecting to the game server...</h3>
            </FadeIn>
        </div>
    )
}