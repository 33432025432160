import React from "react";
import StepTitle from "../components/StepTitle";

export default function EsgStep1() {

    return (<div>
        <StepTitle>
            Nós escolhemos você para uma missão importante
        </StepTitle>

        <h5>
            Conduzir uma instituição rumo à práticas ambientais, sociais e de governança sustentáveis
        </h5>


        <h6 className="small mt-4" style={{color: "lightgray"}}>
            Use as setas abaixo para navegar pelo tutorial
        </h6>
    </div>)
}