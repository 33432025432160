import Swal from 'sweetalert2/dist/sweetalert2.js'
import AudioService from "../services/common/AudioService";
import {SOUND_EFFECTS} from "../config/AUDIO_FILES";
import store from "../redux/store";


const DEFAULT_OPTIONS = {
    soundEffect: true
}

function hasTeam() {
    return !!store.getState().session.showBoardForTeam;
}


const ToastUtil = {
    toastSuccess(title, message = null, options = DEFAULT_OPTIONS) {
        ToastUtil.toast('success', title, message);

        if (options.soundEffect && hasTeam())
            AudioService.playUiSound(SOUND_EFFECTS.TOASTS.SUCCESS);
    },

    toastInfo(title, message = null) {
        ToastUtil.toast('info', title, message);
    },

    toastWarning(title, message = null, options = DEFAULT_OPTIONS) {
        ToastUtil.toast('warning', title, message);

        if (options.soundEffect && hasTeam())
            AudioService.playUiSound(SOUND_EFFECTS.TOASTS.ERROR);
    },

    toastDanger(title, message = null, options = DEFAULT_OPTIONS) {
        ToastUtil.toast('error', title, message);

        if (options.soundEffect && hasTeam())
            AudioService.playUiSound(SOUND_EFFECTS.TOASTS.ERROR);
    },

    toast(type, title, message = null) {
        const toast = Swal.mixin({
            toast: true,
            position: 'top-start',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            willOpen: (toast) => {
                toast.addEventListener('click', Swal.close);
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
            showClass: {
                popup: 'animate__animated animate__fadeInLeft animate__faster'
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOutLeft animate__faster'
            }
        })

        toast.fire({
            icon: type,
            title: title,
            text: message,
        })
    }

};

export default ToastUtil;