import React, {useMemo} from "react";
import {Trans, useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

export default function StatusEndGame() {
    const {t} = useTranslation('pages/game_master/control_panel/step_status', {keyPrefix: 'components.status_end_game.texts'});

    const openUntil = useSelector(state => state.game.gameInfo.openUntil);
    const closingDate = useMemo(() => {
        if (!openUntil) return false;
        return (new Date(openUntil).toLocaleString().slice(0, -3));
    }, [openUntil]);

    return (
        <div className="alert alert-success">
            <Trans t={t} i18nKey={'info'} values={{date: closingDate}} components={{b: <b/>}}/>
        </div>
    );
}