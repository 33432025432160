import React from "react";

export default function AdvanceButtons({isFirstStep, isLastStep, onBack}) {
    return (<div className="d-flex justify-content-center align-items-center mt-4">
        <button type="submit" className="btn btn-success">
            {isLastStep ? 'Iniciar jogo agora!' :  'Continuar'}
        </button>

        <button type="button" className="btn btn-sm btn-outline-secondary border-0 ml-2"
                onClick={onBack} disabled={isFirstStep}>
            Voltar
        </button>
    </div>);
}