/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "sa-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "smgxApi",
            "endpoint": "https://uym9lrvzrc.execute-api.sa-east-1.amazonaws.com/prod",
            "region": "sa-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "sa-east-1:edbd1ebc-d50f-4ef6-a595-aae446076cd3",
    "aws_cognito_region": "sa-east-1",
    "aws_user_pools_id": "sa-east-1_51K35vjTF",
    "aws_user_pools_web_client_id": "7u5ri22efmv7pnml2u8g051utn",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "sa-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "SmgxGameSessions-prod",
            "region": "sa-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "amplify-smgx-prod-182415-deployment",
    "aws_user_files_s3_bucket_region": "sa-east-1",
    "aws_cognito_login_mechanisms": [
        "PREFERRED_USERNAME"
    ]
};


export default awsmobile;
