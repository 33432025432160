import React, {useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import Utils from "utils/Utils";

import "./ServiceStoreModal.scss";
import logo from "assets/img/logos/svg/logo_only.svg";
import iconBalance from "assets/img/board/ic_investment_accounting_box.svg";
import {useSelector} from "react-redux";
import i18nUtil from "utils/i18nUtil";
import CardUtil from "utils/CardUtil";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import ServiceCard from "../../../../../../components/cards/ServiceCard";
import CardService from "../../../../../../services/common/CardService";
import ToastUtil from "../../../../../../utils/ToastUtil";
import DemoSessionService from "../../../../../../services/common/DemoSessionService";
import DemoPlaceholder from "../../ConceptionModal/components/DemoPlaceholder";

export default function ServiceStoreModal({onClose}) {
    const {t} = useTranslation('pages/common/board/services', {keyPrefix: 'components.service_store_modal'});

    const showBoardForTeam = useSelector(state => state.session.showBoardForTeam);

    const services = useSelector(state => state.cards.services);
    const availableForMarket = useSelector(state => state.teams[showBoardForTeam].accounting?.totals?.availableForMarket);
    const hiredSkills = useSelector(state => state.teams[showBoardForTeam].skills);
    const hiredServices = useSelector(state => state.teams[showBoardForTeam].services);
    const founders = useSelector(state => state.teams[showBoardForTeam].founders);
    const canHireCards = useSelector(state => state.game.canHireCards);
    const isFinished = useSelector(state => state.game.isFinished);
    const isDemonstration = useSelector(state => state.game.isDemonstration);

    const [skillsToCheckRequirements, setSkillsToCheckRequirements] = useState([]);

    const [servicesToDisplay, setServicesToDisplay] = useState({});
    const [serviceAreasToDisplay, setServiceAreasToDisplay] = useState([]);

    const [hiringInProgressFor, setHiringInProgressFor] = useState({});
    const [servicesRequirementsInfo, setServicesRequirementsInfo] = useState({});

    const [filterName, setFilterName] = useState('');
    const [sortBy, setSortBy] = useState('price');
    const [sortOrder, setSortOrder] = useState('asc');

    const handleClearFilter = () => {
        setFilterName('');
    }

    const handleCheckIfMeetsRequirement = (card) => {
        if (!card.hasRequirements()) return true;
        return CardUtil.checkIfHasRequirementsForService(skillsToCheckRequirements, hiredServices, card);
    };

    const handleSetHiringInProgressFor = (card, isInProgress = false) => {
        const inProgressFor = {...hiringInProgressFor};
        if (isInProgress) inProgressFor[card.id] = true;
        else delete inProgressFor[card.id];

        setHiringInProgressFor(inProgressFor);
    };

    const handleHire = async (service) => {
        if (isDemonstration) {
            DemoSessionService.hireService({service});

            return;
        }

        handleSetHiringInProgressFor(service, true);

        const hired = await CardService.hireService(service.id);
        if (hired) {
            ToastUtil.toastSuccess(
                t('toasts.hire_success.title'),
                t('toasts.hire_success.message', {service: service.title}),
                {soundEffect: false}
            );
        } else {
            ToastUtil.toastDanger(t('toasts.hire_error.title'), t('toasts.hire_error.message'));
        }

        handleSetHiringInProgressFor(service, false);
    };

    const handleOpenSkillMarket = () => {
        onClose();
        document.getElementById('btn-open-skill-market').click();
    }

    useEffect(() => {
        const hiredServicesIds = CardUtil.hiredCardsToArrayOfGameCards(hiredServices).map(card => card.id);
        let servicesToDisplay = [];
        let serviceAreas = [];
        const res = {};


        if (isDemonstration) {
            servicesToDisplay = services.slice(0, Math.round(services.length / 2)).filter(s => !hiredServicesIds.includes(s.id));
        } else {
            servicesToDisplay = isFinished ? [...services] : [...services.filter(s => !hiredServicesIds.includes(s.id))];
        }


        // filter
        if (Utils.isset(filterName)) {
            const filter = filterName.toLowerCase();
            servicesToDisplay = servicesToDisplay.filter(s => {
                return s.title.toLowerCase().includes(filter) || s.area.toLowerCase().includes(filter)
            });
        }

        // sort
        let sortFn = false;
        if (sortBy === 'name') {
            sortFn = (a, b) => a.title.localeCompare(b.title);
        } else if (sortBy === 'power') {
            sortFn = (a, b) => CardUtil.getPower(a) - CardUtil.getPower(b);
        }

        if (sortFn) {
            servicesToDisplay = servicesToDisplay.sort(sortFn);
        }

        if (sortOrder === 'desc') {
            servicesToDisplay = servicesToDisplay.reverse();
        }

        // get areas and check for requirements
        servicesToDisplay.forEach(service => {
            if (!Utils.isset(res[service.area])) {
                res[service.area] = [];
                serviceAreas.push(service.area);
            }

            res[service.area].push(service);
        });

        serviceAreas = serviceAreas.sort((a, b) => a.localeCompare(b));

        setServiceAreasToDisplay(serviceAreas);
        setServicesToDisplay(res);
    }, [filterName, sortBy, sortOrder, services, hiredServices, isFinished]);

    useEffect(() => {
        const requirementsInfo = {};

        Object.values(servicesToDisplay).forEach(servicesPerArea => {
            servicesPerArea.forEach(service => {
                if (service.hasRequirements()) requirementsInfo[service.id] = handleCheckIfMeetsRequirement(service);
            })
        });

        setServicesRequirementsInfo(requirementsInfo);
    }, [servicesToDisplay, skillsToCheckRequirements]);

    useEffect(() => {
        const allSkills = CardUtil.hiredCardsToArrayOfGameCards(hiredSkills);
        if (founders[0]) allSkills.push(founders[0]);
        if (founders[1]) allSkills.push(founders[1]);

        setSkillsToCheckRequirements(allSkills);
    }, [hiredSkills, founders]);

    useEffect(() => {
        if (isDemonstration)
            DemoSessionService.setVisitedMarket();
    }, []);

    return (
        <Modal show={true} size={'xl'} onHide={onClose} className="service-store-modal" scrollable={true}>
            <Modal.Header closeButton className="bg-dark text-white">
                <div className="modal-header-content w-100 d-flex align-items-center">
                    <img alt="Mundi Game Experiences logo" src={logo} className="mr-4"/>

                    <div>
                        <Modal.Title>{t('texts.title')}</Modal.Title>
                        <p className="mb-0 small">{t('texts.click_info')}</p>
                        <p className="mb-1 small">{t('texts.hire_info')}</p>
                        <button onClick={handleOpenSkillMarket} className="btn btn-warning px-2 py-0 rounded">
                            {t('buttons.open_skill_market')}
                        </button>
                    </div>

                    <div className="available-for-market flex-fill d-flex justify-content-end">
                        <div className="px-3 py-2">
                            <p className="mb-1">{t('texts.available_for_market')}</p>
                            <div className="d-flex justify-content-between align-items-center">
                                <img alt="Balance logo" src={iconBalance} className="mr-2"/>
                                <h5 className="mb-0 text-warning">{i18nUtil.formatMoney(availableForMarket)}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Header>

            <Modal.Body>
                <div
                    className="w-100 p-2 text-white border-primary bg-primary rounded d-flex justify-content-between mb-4">
                    <div className="d-flex form-inline">
                        <div className="form-group mr-2">
                            <label className="small mr-1">{t('inputs.find.label')}</label>
                            <input type="text" className="form-control-sm" value={filterName}
                                   onChange={event => setFilterName(event.target.value)}/>
                        </div>

                        <button type="button" className="btn btn-link p-0 text-light small"
                                onClick={handleClearFilter}>
                            {t('buttons.clear')}
                        </button>
                    </div>

                    <div className="form-inline">
                        <div className="form-group mr-2">
                            <label className="small mr-1">{t('inputs.sort_by.label')}</label>

                            <select className="form-control-sm" value={sortBy}
                                    onChange={event => setSortBy(event.target.value)}>
                                <option value="price">{t('inputs.sort_by.options.price')}</option>
                                <option value="name">{t('inputs.sort_by.options.name')}</option>
                                <option value="power">{t('inputs.sort_by.options.power')}</option>
                            </select>
                        </div>

                        <div className="form-group mr-2">
                            <label className="small mr-1">{t('inputs.sort_order.label')}</label>

                            <select className="form-control-sm" value={sortOrder}
                                    onChange={event => setSortOrder(event.target.value)}>
                                <option value="asc">{t('inputs.sort_order.options.asc')}</option>
                                <option value="desc">{t('inputs.sort_order.options.desc')}</option>
                            </select>
                        </div>
                    </div>
                </div>

                {(!canHireCards || isFinished) && (
                    <div className="d-flex justify-content-center">
                        <div className="py-2 px-5 text-center w-auto rounded d-flex align-items-center">
                            <FontAwesomeIcon icon={['fas', 'exclamation-circle']} className="fa-2x mr-3"/>
                            <h5 className="mb-0 text-uppercase d-inline">
                                {!canHireCards && t('texts.market_closed')}
                                {isFinished && t('texts.game_finished')}
                            </h5>
                        </div>
                    </div>
                )}

                {serviceAreasToDisplay.map(area => {
                    return (
                        <div key={area} className="mt-4">
                            <h4 className="ml-3 mb-3">{i18nUtil.area(area)}</h4>
                            <div className="d-flex justify-content-center align-items-stretch flex-wrap">
                                {servicesToDisplay[area].map(service => {
                                    return (<ServiceCard key={service.id}
                                                         service={service}
                                                         isLoading={hiringInProgressFor[service.id] || false}
                                                         hasCreditToHire={availableForMarket >= service.price}
                                                         hirable={!isFinished && canHireCards}
                                                         meetRequirements={servicesRequirementsInfo[service.id] ?? true}
                                                         onHire={handleHire}/>
                                    )
                                })}

                                {isDemonstration && (
                                    <DemoPlaceholder clasz={"demo-card"}/>
                                )}
                            </div>

                            <hr/>
                        </div>
                    )
                })}

                {Object.entries(servicesToDisplay).length === 0 && filterName.length === 0 && (
                    <div className="d-flex justify-content-center w-100">{t('texts.all_hired')}</div>
                )}

                {Object.entries(servicesToDisplay).length === 0 && filterName.length > 0 && (
                    <div className="d-flex justify-content-center w-100">{t('texts.no_results')}</div>
                )}
            </Modal.Body>
        </Modal>
    );
}