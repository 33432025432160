import React, {useEffect, useMemo, useState} from "react";
import {Button, Modal} from "react-bootstrap";
import Utils from "utils/Utils";
import ToastUtil from "utils/ToastUtil";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import GameUtil from "../../../../../../utils/GameUtil";
import useTranslationForGame from "../../../../../../hooks/useTranslationForGame";

export default function DefineStartupsModal({onClose, onSubmit}) {
    const {t} = useTranslation('pages/game_master/control_panel/game_config', {keyPrefix: 'components.define_startups_modal'});
    const tfg = useTranslationForGame(t);

    const startups = useSelector(state => state.cards.startups);
    const stateSelectedStartups = useSelector(state => state.game.preSelectedStartups);

    const [startupOptions, updateStartupOptions] = useState({1: [], 2: [], 3: [], 4: []});
    const [selectedStartups, setSelectedStartups] = useState({1: '', 2: '', 3: '', 4: ''});

    const isEsg = useMemo(() => GameUtil.isCurrentGameEsg(), []);

    const handleSubmit = async () => {
        for (let i = 1; i < 5; i++) {
            if (!Utils.isset(selectedStartups[i])) {
                ToastUtil.toastDanger(
                    tfg('toasts.missing_startup.title'),
                    tfg('toasts.missing_startup.message')
                );

                return;
            }
        }

        onSubmit(selectedStartups);
    };

    const handleSetSelectedStartup = (team, startup) => {
        const res = {...selectedStartups};
        res[team] = startups.filter(st => st.id === startup)[0];
        setSelectedStartups(res);
    };

    const getStartupsForTeam = (team) => {
        let alreadySelected = ({...selectedStartups});
        delete alreadySelected[team];
        alreadySelected = Object.values(alreadySelected);
        return startups.filter(st => !alreadySelected.includes(st));
    };

    const filterStartupOptions = () => {
        updateStartupOptions({
            1: getStartupsForTeam(1),
            2: getStartupsForTeam(2),
            3: getStartupsForTeam(3),
            4: getStartupsForTeam(4),
        });
    };

    useEffect(() => {
        filterStartupOptions();
    }, [selectedStartups, startups]);

    useEffect(() => {
        if (stateSelectedStartups !== false) {
            setSelectedStartups(stateSelectedStartups);
        }

        filterStartupOptions();
    }, [stateSelectedStartups]);

    return (
        <Modal show={true} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>{tfg('texts.modal_title')}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="row">
                    {[1, 2, 3, 4].map(team => {
                        return (
                            <div className="form-group col-12 col-md-6" key={team}>
                                <label>{t('inputs.teams.label', {team})}</label>

                                <select className="form-control" value={selectedStartups[team]?.id} required={true}
                                        onChange={(event) => handleSetSelectedStartup(team, event.target.value)}>
                                    <option key={''} value={''}>{t('inputs.teams.empty_option')}</option>

                                    {startupOptions[team].map(st => {
                                        return (
                                            <option key={st.id} value={st.id}>
                                                {st.title} - {st.sector} {!isEsg && '- ' + st.businessModel}
                                            </option>
                                        )
                                    })}
                                </select>
                            </div>
                        )
                    })}
                </div>

                <p className="text-muted small text-center mb-0 mt-2">
                    {t('texts.info')}
                </p>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleSubmit}>{tfg('buttons.set')}</Button>
                <Button variant="secondary" onClick={onClose}>{t('buttons.close')}</Button>
            </Modal.Footer>
        </Modal>
    );
}