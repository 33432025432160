import store from "redux/store";
import {
    setPitches,
    setQuestions,
    setServices,
    setSkills,
    setUnpredictabilites,
    setValuePropositions,
    updateStartups
} from "redux/slices/cardsSlice";

const CardsReduxService = {
    updateAllCards(cards) {
        if (cards.startups) this.updateStartups(cards.startups);
        if (cards.pitches) this.updatePitches(cards.pitches);
        if (cards.skills) this.updateSkills(cards.skills);
        if (cards.services) this.updateServices(cards.services);
        if (cards.questions) this.updateQuestions(cards.questions);
        if (cards.unpredictabilities) this.updateUnpredictabilities(cards.unpredictabilities);
        if (cards.valuePropositions) this.updateValuePropositions(cards.valuePropositions);
    },

    updateStartups(startups) {
        store.dispatch(updateStartups(startups.sort(((a, b) => a.title.localeCompare(b.title)))));
    },

    updatePitches(pitches) {
        store.dispatch(setPitches(pitches));
    },

    updateSkills(skills) {
        store.dispatch(setSkills(skills.sort(((a, b) => a.price - b.price))));
    },

    updateServices(services) {
        store.dispatch(setServices(services.sort(((a, b) => a.price - b.price))));
    },

    updateQuestions(questions) {
        store.dispatch(setQuestions(questions));
    },


    updateUnpredictabilities(unpredictabilities) {
        store.dispatch(setUnpredictabilites(unpredictabilities));
    },

    updateValuePropositions(valuePropositions) {
        store.dispatch(setValuePropositions(valuePropositions.sort(((a, b) => a.title.localeCompare(b.title)))));
    }
};

export default CardsReduxService;