import React, {useState} from "react";
import {Button, Modal} from "react-bootstrap";
import ButtonSpinner from "components/ButtonSpinner";
import ToastUtil from "utils/ToastUtil";
import OverlayUtil from "utils/OverlayUtil";
import LoggerService from "services/common/LoggerService";
import SwalModalUtil from "utils/SwalModalUtil";
import {APP_VERSION} from "../../../config/CONSTANTS";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

export default function BugReportModal({onClose}) {
    const {t} = useTranslation(['pages/common/bug_report_modal', 'common']);

    const gameVersion = useSelector(state => state.game.gameInfo.version);
    const gamePackInfo = useSelector(state => state.game.gameInfo.gamePack);

    const [email, setEmail] = useState('');
    const [action, setAction] = useState('');
    const [description, setDescription] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();

        OverlayUtil.toggleLoadingOverlay(true, t('overlays.sending'));

        const params = {
            action, email, description, gameVersion,
            gamePackId: gamePackInfo?.id || 'none',
            gamePackVersion: gamePackInfo?.packVersion || 'none',
            gamePackContentVersion: gamePackInfo?.contentVersion || 'none',
            appVersion: APP_VERSION,
            page: window.location.href
        };

        LoggerService.userReportedError('User reported error', params).then(response => {
            if (response === true) {
                SwalModalUtil.successModal(t('toasts.report_sent.title'), t('toasts.report_sent.message'), t('common:modals.buttons.close'));
                onClose();
            } else {
                ToastUtil.toastDanger(t('toasts.report_send_error.title'), t('toasts.report_send_error.message'));
            }
        }).catch(reason => {
            console.error(reason);
            ToastUtil.toastDanger(t('toasts.report_send_error.title'), t('toasts.report_send_error.message'));
        }).finally(() => {
            OverlayUtil.toggleLoadingOverlay(false);
        });
    }

    return (<Modal show={true} onHide={onClose}>
        <Modal.Header closeButton>
            <Modal.Title>{t('texts.modal_title')}</Modal.Title>
        </Modal.Header>

        <form onSubmit={handleSubmit}>
            <Modal.Body>
                <div className="form-group">
                    <label>{t('inputs.email.label')}</label>
                    <input type="email" className="form-control" value={email}
                           onChange={e => setEmail(e.target.value)}/>
                    <span className="form-text text-muted small">{t('inputs.email.tip')}</span>
                </div>

                <div className="form-group">
                    <label>{t('inputs.action_performed.label')} <span className="text-danger">*</span></label>
                    <input type="text" className="form-control" value={action} required={true}
                           onChange={e => setAction(e.target.value)}
                           placeholder={t('inputs.action_performed.placeholder')}/>
                    <span className="form-text text-muted small">{t('inputs.action_performed.tip')}</span>
                </div>

                <div className="form-group">
                    <label>{t('inputs.problem_description.label')} <span className="text-danger">*</span></label>
                    <textarea required={true} className="form-control" minLength={1} maxLength={999} value={description}
                              onChange={e => setDescription(e.target.value)}/>
                    <span className="form-text text-muted small">{t('inputs.problem_description.tip')}</span>
                </div>

                <div className="small text-muted">{t('texts.app_version', {version: APP_VERSION})}</div>
                <div className="small text-muted">{t('texts.game_version', {version: gameVersion || 'v1.0.0'})}</div>

                {gamePackInfo.id && (<div className="small text-muted">
                    {t('texts.game_pack_info', {
                        id: gamePackInfo?.id || 'default',
                        packVersion: gamePackInfo?.packVersion || 'v1.0.0',
                        contentVersion: gamePackInfo?.contentVersion || 'v1.0.0'
                    })}
                </div>)}
            </Modal.Body>
            <Modal.Footer>
                <ButtonSpinner type="submit">{t('common:modals.buttons.submit')}</ButtonSpinner>

                <Button variant="secondary" onClick={onClose}>{t('common:modals.buttons.close')}</Button>
            </Modal.Footer>
        </form>
    </Modal>)
}