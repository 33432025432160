import React, {useEffect, useMemo, useState} from "react";

export default function ChatMessage({message, origin, from, onShow, onRender, delay = 0}) {
    const [show, setShow] = useState(false);
    const [timer, setTimer] = useState(undefined);


    const containerClass = useMemo(() => {
        let clasz = 'animate__animated ';

        if (origin === 'center')
            clasz += 'animate__fadeIn mx-auto text-muted small text-monospace';
        else if (origin === 'right')
            clasz += 'animate__slideInRight bg-success text-white shadow ml-auto'
        else
            clasz += 'animate__fadeInLeft shadow text-white bg-dark'

        return clasz;
    }, [origin]);

    useEffect(() => {
        if (delay) {
            setTimer(setTimeout(() => {
                setShow(true);

                if (onShow)
                    onShow();
            }, delay));
        } else {
            setShow(true);

            if (onShow)
                onShow();
        }

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        if (show && onRender)
            onRender();
    }, [show, onRender])

    if (!show)
        return null;

    return (<div className={"chat-message pb-2 pt-1 px-3 mb-2 rounded " + containerClass}>
        {origin === 'left' && (
            <p className="text-info" style={{fontSize: '75%', marginBottom: "-3px"}}>
                {from}
            </p>
        )}

        <p className="m-0 text-break message-text" dangerouslySetInnerHTML={{__html: message}}/>
    </div>)
}