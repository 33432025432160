import {useMemo} from "react";
import CardUtil from "../utils/CardUtil";
import {useSelector} from "react-redux";
import {GAME_PHASES} from "../config/CONSTANTS";

import "./CardAccelerationBar.scss";
import GameUtil from "../utils/GameUtil";

/**
 * Adapted from https://stackoverflow.com/a/61843342
 * Credits to: Elysian Storm
 */

export default function CardAccelerationBar({card}) {
    const currentPhase = useSelector(state => state.game.currentPhase);

    const bgStyle = useMemo(() => {
        const maxAcc = CardUtil.getMaxAcceleration(card.__type);
        const cardAcc = !GameUtil.isCurrentGameEsg() && currentPhase >= GAME_PHASES.PHASE_3
            ? card.accelerationPhase3
            : card.accelerationPhase2;

        const accelerationPercent = 100 - Math.round(cardAcc * 100 / maxAcc);


        return {
            clipPath: `inset(0 ${accelerationPercent}% 0 0)`
        }
    }, [card.__type, currentPhase]);


    return (<div className="card-power-progress-bar">
        <div className="prog-bar">
            <div className="background" style={bgStyle}></div>
        </div>
    </div>);
}