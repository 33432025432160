import ApiService from "./api/ApiService";
import {API_ENDPOINTS} from "../../config/API_CONSTANTS";
import store from "../../redux/store";


const CardService = {
    hireSkill(cardId, icon, gender, team = store.getState().session.showBoardForTeam, step = store.getState().game.currentStep) {
        return ApiService.post(API_ENDPOINTS.TEAMS.HIRE_SKILL, {data:{cardId, step, icon, gender}, team}, true)
            .then(response => response.status === true)
            .catch(() => false);
    },

    hireService(cardId, team = store.getState().session.showBoardForTeam, step = store.getState().game.currentStep) {
        return ApiService.post(API_ENDPOINTS.TEAMS.HIRE_SERVICE, {data:{cardId, step}, team}, true)
            .then(response => response.status === true)
            .catch(() => false);
    },

    refundSkill(hiringId, team = store.getState().session.showBoardForTeam) {
        return ApiService.post(API_ENDPOINTS.TEAMS.HIRE_REFUND, {type: 'SKILL', hiringId, team}, true)
            .then(response => response.status === true)
            .catch(() => false);
    },

    refundService(hiringId, team = store.getState().session.showBoardForTeam) {
        return ApiService.post(API_ENDPOINTS.TEAMS.HIRE_REFUND, {type: 'SERVICE', hiringId, team}, true)
            .then(response => response.status === true)
            .catch(() => false);
    }
};

export default CardService;