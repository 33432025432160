import React, {memo, useMemo, useState} from "react";

import "./FounderCard.scss";
import SimpleBar from "simplebar-react";
import GameCardLayout from "components/cards/layouts/GameCardLayout";
import i18nUtil from "utils/i18nUtil";
import {Trans, useTranslation} from "react-i18next";
import SkillCardModel from "../../models/SkillCardModel";

const FounderCard = ({founder, icon, onSelect, selectable = false, previousSelected}) => {
    const {t} = useTranslation('components/cards', {keyPrefix: 'founder_card'});

    const [showConfirm, setShowConfirm] = useState(false);
    const handleShowConfirm = () => {
        if (selectable) setShowConfirm(true);
    };
    const handleHideConfirm = () => setShowConfirm(false);

    const handleClick = () => {
        if (selectable && onSelect) onSelect(founder, SkillCardModel.getNumberForIcon(icon));
    };

    const cardClass = useMemo(() => {
        return (selectable ? 'selectable' : '')
            + (showConfirm ? ' show-confirm' : '');
    }, [selectable, showConfirm]);

    return (<GameCardLayout>
        <div className={"box border-warning bg-white founder-card d-flex flex-column " + cardClass}>

            <div className="box-skill-head bg-primary d-flex align-items-center w-100">
                <img src={icon} className="skill-face position-absolute" alt="Founder avatar"/>
                <div>
                    {founder.title}
                </div>
            </div>

            <div className="box-content skill-content position-relative flex-fill"
                 onClick={handleShowConfirm} onMouseLeave={handleHideConfirm}>
                <SimpleBar className="content-scrollable px-2 text-left">
                    <div className="skill-area text-dark text-break text-center mb-1">
                        {i18nUtil.area(founder.area)}
                    </div>

                    <p className={"m-0"}>{founder.description}</p>
                </SimpleBar>

                <div
                    className={"confirm-overlay h-100 position-absolute top bottom left right d-flex flex-column mb-4 justify-content-center align-items-center text-center"}>
                        <span className="small pb-1 text-primary">
                            <Trans t={t} i18nKey={'texts.change'} components={{1: <b/>}}
                                   values={{from: previousSelected?.title || t('texts.none'), to: founder.title}}/>
                        </span>

                    <button type="submit" className="btn btn-primary" onClick={handleClick}>
                        {t('buttons.select')}
                    </button>
                </div>
            </div>
        </div>
    </GameCardLayout>);
}

export default memo(FounderCard);