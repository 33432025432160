import {GAME_STEPS, GAMES} from "./CONSTANTS";

const DEMO_SESSION_CONFIG = {
    TEAM: 1,
    INITIAL_STEP: GAME_STEPS.ONBOARDING,
    QUESTION_STEP: GAME_STEPS.BETA,
    PITCH_STEP: 2,  // use the pitch number (1, 2 or 3)
    UNPREDICTABILITY_BONUS: 100000,

    WEBHOOK_ENDPOINT: 'https://hooks.zapier.com/hooks/catch/3272121/3k9x988/',
    NOTIFY_ADVANCED_WEBHOOK: 'https://hooks.zapier.com/hooks/catch/3272121/301qq6y/',

    VIDEOS: {
        'en': {
            [GAMES.ESG]: [
                'https://youtu.be/Wvx1p3up52k',
                'https://youtu.be/TAw4QYW69YE',
                'https://youtu.be/wyBwIKFwHVs',
                'https://youtu.be/676f6b2Pf3Q'
            ],

            [GAMES.SMGX]: [
                'https://youtu.be/FH_VrDoRUlw',
                'https://youtu.be/o_1kWPabCRE',
                'https://youtu.be/fc3jondNOz4',
                'https://youtu.be/r5gSE8fTohM'
            ]
        },
        'pt': {
            [GAMES.ESG]: [
                'https://youtu.be/Nft6JHcVhl8',
                'https://youtu.be/Rg18W0ZxX1I',
                'https://youtu.be/I26apBpERzE',
                'https://youtu.be/2DX5V6B3S_w'
            ],

            [GAMES.SMGX]: [
                'https://youtu.be/XhwHi6A7pUk',
                'https://youtu.be/rnCBrybXrFQ',
                'https://youtu.be/XGIlEI7fcgs',
                'https://youtu.be/O3M7S7nsafQ'
            ]
        },
    },


    VIDEO_PLAYER: {
        // related to the & width of the player
        // WIDTH = WINDOW_WIDTH / SCALE_FACTOR
        // a bigger number means a smaller player
        SCALE_FACTOR: 4,

        MAX_WIDTH: 1000,
        MIN_WIDTH: 400,

        INITIAL_SIZE: {
            width: 604.44,
            height: 340
        },

        INITIAL_POSITION: {
            x: +Infinity,
            y: +Infinity
        }
    }
}

export default DEMO_SESSION_CONFIG;