import React, {useMemo} from "react";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {GAME_STEPS} from "../../../../../../../config/CONSTANTS";

export default function QuestionsButton({step, onClick}) {
    const {t} = useTranslation('pages/game_master/control_panel/game_control', {keyPrefix: "components.question_buttons"});
    const currentGameStep = useSelector(state => state.game.currentStep);
    const teamCount = useSelector(state => state.game.teamCount);
    const isPocket = useSelector(state => state.game.isPocket);

    const team1Questions = useSelector(state => state.teams[1].questions[step]);
    const team2Questions = useSelector(state => state.teams[2].questions[step]);
    const team3Questions = useSelector(state => state.teams[3].questions[step]);
    const team4Questions = useSelector(state => state.teams[4].questions[step]);

    const allRevealed = useMemo(() => {
        if (currentGameStep < step)
            return false;

        const all = [team1Questions, team2Questions, team3Questions, team4Questions];

        for (let i = 0; i < teamCount; i++) {
            if (all[i]?.showResult !== true)
                return false;
        }

        return true;
    }, [team1Questions, team2Questions, team3Questions, team4Questions, teamCount]);

    const disabled = useMemo(() => {
        return step > currentGameStep || (isPocket && step > GAME_STEPS.PITCH_2);
    }, [currentGameStep, isPocket, step])

    const handleClick = () => onClick(step);

    return (
        <div>
            <button type="button" className="btn btn-primary  btn-sm w-100" onClick={handleClick} disabled={disabled}>
                {t('buttons.open_questions')}
            </button>
            <br/>

            {!disabled && currentGameStep !== GAME_STEPS.END_GAME && currentGameStep >= step && allRevealed && (
                <p className="mb-0 mt-1 text-success w-100 text-center small">{t('texts.all_done')}</p>)}
            {!disabled && currentGameStep !== GAME_STEPS.END_GAME && currentGameStep >= step && !allRevealed && (
                <p className="m-0 mt-1 text-danger w-100 text-center small">{t('texts.awaiting_action')}</p>)}
        </div>
    )
}