import {WS_TOPICS} from "config/WS_CONSTANTS";
import IotTopicSubscriber from "./IotTopicSubscriber";
import {IS_PRODUCTION_ENV} from "config/CONSTANTS";
import EventsUtil from "../utils/EventsUtil";
import {GAME_EVENTS} from "../config/EVENTS";

let subscription = null;

function processEvent(wsMessage) {
    if (!IS_PRODUCTION_ENV)
        console.debug('Message received', wsMessage);

    EventsUtil.publish(GAME_EVENTS.VERIFY_CONNECTION, wsMessage?.status === true);
}

const VerifyConnectionSubscriber = {
    async subscribe(testUuid, onMessage) {
        if (!IS_PRODUCTION_ENV)
            console.debug('Subscribing to verify connection topic');

        const gameSessionTopic = WS_TOPICS.GAME_TOPIC
            .replace('$gameSessionId', testUuid);


        subscription = await IotTopicSubscriber.subscribe(
            gameSessionTopic,
            data => processEvent(data)
        );

        if (subscription === false) {
            subscription = null;
            return false;
        }

        return true;
    },

    async unsubscribe() {
        if (subscription) {
            await subscription.unsubscribe();
            subscription = null;
        }

        return true;
    },
};

export default VerifyConnectionSubscriber;