import React, {memo} from "react";

import i18nUtil from "utils/i18nUtil";
import GameEventCardLayout from "./GameEventCardLayout/GameEventCardLayout";
import icon from "assets/img/board/ic_investment_journey_box.svg";
import {Trans, useTranslation} from "react-i18next";

const PitchCapitalCard = ({data}) => {
    const {t} = useTranslation('pages/common/board/game_events', {keyPrefix: 'components.pitch_capital_card'});
    const {step, bonus} = data;

    return (
        <GameEventCardLayout title={i18nUtil.stepName(step)} color={'success'} leftContent={(
            <div>

                <p><Trans t={t} i18nKey={'texts.title'} components={{1: <b/>}}/></p>
                <h5 className="mt-2 font-weight-bold">{i18nUtil.formatMoney(bonus || 0)}</h5>
            </div>
        )} rightContent={(
            <img alt="Investment icon" src={icon} width={90}/>
        )}/>
    );
}

export default memo(PitchCapitalCard);