const eventQueue = [];

//const ticks = {users: 0, game: 0, teams: {1: 0, 2: 0, 3: 0, 4: 0}};
//const missingTicks = {users: [], game: [], teams: {1: [], 2: [], 3: [], 4: []}};

let processEvents = false;


const EventProcessor = {
    queue(wsMessage, callback) {
        eventQueue.push([wsMessage, callback]);
        this.process();
    },

    async process() {
        // TODO: use ticks to manage missing data
        if (processEvents && eventQueue.length) {
            // uncomment the line bellow to use tick attr inside wsMessage
            // const [wsMessage, callback] = eventQueue.shift();
            const [, callback] = eventQueue.shift();
            await callback();
            this.process();
        }
    },

    pause() {
        if (processEvents) {
            console.debug('Pausing event processing');
            processEvents = false;
        }
    },

    clearQueue(){
      eventQueue.length = 0;
    },

    resume() {
        if (!processEvents) {
            console.debug('Resuming event processing');
            processEvents = true;
            this.process();
        }
    },
};

export default EventProcessor;
