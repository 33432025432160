import React, {useCallback, useMemo, useState} from "react";

import {useTranslation} from "react-i18next";
import CardUtil from "../../../../../../../utils/CardUtil";
import {GAME_CARD_TYPE} from "../../../../../../../config/CONSTANTS";
import SkillItem from "../../../Skills/components/SkillItem";
import ServiceItem from "../../../Services/components/ServiceItem";
import useTranslationForGame from "../../../../../../../hooks/useTranslationForGame";
import i18nUtil from "../../../../../../../utils/i18nUtil";

function AreaDetails({area, hiredCards}) {
    const {t} = useTranslation('pages/common/board/accounting');
    const tfg = useTranslationForGame(t);

    const onClickMock = useCallback(() => {
    }, []);

    const acceleration = useMemo(() => hiredCards.reduce((acc, x) => acc + x.card.accelerationPhase2, 0), [hiredCards]);

    return (<div className="mb-3">
        <h5 className="text-warning mb-0">{i18nUtil.area(area)}</h5>
        <h6 className="mb-1 text-white font-weight-light small">
            {t('texts.hires')}: {hiredCards.length} | {tfg('texts.acceleration')}: +{acceleration}%
        </h6>

        <div className="d-flex flex-wrap hired-cards-container text-white">
            {hiredCards.map(hiring => {
                return hiring.card.__type === GAME_CARD_TYPE.SKILL
                    ? (<SkillItem key={'has' + hiring.id} hiredSkill={hiring} onClick={onClickMock}
                                  showAccelerationInfo={false} viewType={2}/>)

                    : (<ServiceItem key={'hase' + hiring.id} hiredService={hiring} onClick={onClickMock}
                                    showAccelerationInfo={false} viewType={2}/>)
            })}
        </div>

        <hr/>
    </div>)
}

export default function HiringDetails({hiredCards, color}) {
    const {t} = useTranslation('pages/common/board/accounting');
    const tfg = useTranslationForGame(t);

    const [totalCards, setTotalCards] = useState(0);
    const [totalAcceleration, setTotalAcceleration] = useState(0);

    const hiringsPerArea = useMemo(() => {
        const cardsPerArea = {};
        const hiredCardsAsArray = CardUtil.hiredCardsToArray(hiredCards);
        let acceleration = 0;


        hiredCardsAsArray.forEach(hiringDetails => {
            acceleration += hiringDetails.card.accelerationPhase2;

            if (!cardsPerArea[hiringDetails.card.area])
                cardsPerArea[hiringDetails.card.area] = [hiringDetails];
            else
                cardsPerArea[hiringDetails.card.area].push(hiringDetails);
        });

        setTotalCards(hiredCardsAsArray.length);
        setTotalAcceleration(acceleration);

        return Object.entries(cardsPerArea)
            .sort((a, b) => a[0].localeCompare(b[0]));
    }, [hiredCards]);

    const hasSomethingToDisplay = useMemo(() => Object.keys(hiringsPerArea).length > 0, [hiringsPerArea]);


    return (
        <div className="p-2">
            {hasSomethingToDisplay && (<>
                <h5 className={"text-uppercase text-" + color}>
                    {t('texts.hires_summary')}
                </h5>
                <h6 className={"mb-1 text-white font-weight-light small text-" + color}>
                    {t('texts.total_hires')}: {totalCards} | {tfg('texts.acceleration')}: +{totalAcceleration}%
                </h6>

                <hr/>

                <div>
                    {hiringsPerArea.map(([area, cards]) => <AreaDetails key={area} area={area} hiredCards={cards}/>)}
                </div>
            </>)}

            {!hasSomethingToDisplay && (
                <p className="text-center text-white mt-3">{t('texts.empty_message')}</p>
            )}
        </div>);
};