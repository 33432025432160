import {AWSIoTProvider, PubSub} from "@aws-amplify/pubsub";
import store from "../redux/store";
import Utils from "../utils/Utils";
import Amplify from "@aws-amplify/core";
import {default as awsExports} from "aws-exports";
import {WS_ENDPOINT} from "../config/WS_CONSTANTS";
import SessionReduxService from "../services/redux/SessionReduxService";
import LoggerService from "../services/common/LoggerService";
import {IS_PRODUCTION_ENV} from "../config/CONSTANTS";

let addedPluggable = false;

function touchPluggable() {
    if (!addedPluggable) {
        const userId = store.getState().session?.user?.id;
        const gameSessionId = store.getState().game?.gameInfo?.id;
        const options = {
            aws_pubsub_region: awsExports.aws_project_region,
            aws_pubsub_endpoint: WS_ENDPOINT,
        };

        if (Utils.isset(userId) && Utils.isset(gameSessionId)) {
            options.clientId = userId + '//' + gameSessionId + '//' + (IS_PRODUCTION_ENV ? 'prod' : 'dev');
        }

        Amplify.addPluggable(new AWSIoTProvider(options));

        addedPluggable = true;
    }

    return true;
}

function handleError(error = false) {
    if (error) {
        console.error(error);
        LoggerService.error(error, {action: 'iotTopicSubscriberError'});
    }

    SessionReduxService.updateHasConnectionError(true);
}

const IotTopicSubscriber = {
    async subscribe(topic, onMessage) {
        if (!touchPluggable())
            return false;

        console.debug('Subscribing to topic ' + topic);

        return PubSub.subscribe(topic).subscribe({
            next: data => onMessage(data.value),
            error: error => {
                handleError(error.value);
                console.error(error);
            },
            complete: () => {
            },
        });
    },

    handleError() {

    }
};

export default IotTopicSubscriber;