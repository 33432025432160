import React, {useEffect, useState} from 'react';
import {LATEST_POLICIES_VERSION} from "../config/CONSTANTS";

import "./PolicesInfo.scss";
import {TERMS_ROUTES} from "../navigation/ROUTES";
import {Trans, useTranslation} from "react-i18next";

export default function PolicesInfo() {
    const {t} = useTranslation('common');

    const [show, setShow] = useState(false);

    const accept = () => {
        localStorage.setItem('acceptedPoliciesVersion', LATEST_POLICIES_VERSION);
        setShow(false);
    }

    useEffect(() => {
        const acceptedPoliciesVersion = localStorage.getItem('acceptedPoliciesVersion');
        setShow(!acceptedPoliciesVersion || acceptedPoliciesVersion !== LATEST_POLICIES_VERSION);
    }, []);

    if (!show) return null;

    return (
        <div id="policies-container"
             className="position-absolute px-5 justify-content-center d-flex w-100 animate__animated animate__fadeIn animate__faster animate__delay-1s">

            <div id="policies-info"
                 className="bg-white d-flex flex-column flex-md-row justify-content-between align-items-center px-3 py-3 border text-muted">
                <div className="text-center text-md-left">
                    <Trans t={t} i18nKey={'policies_info'}
                           components={{
                               1: <a className="ml-1 mr-1" target="_blank" href={TERMS_ROUTES.PRIVACY_POLICY}/>,
                               2: <a className="ml-1" target="_blank" href={TERMS_ROUTES.COOKIES_POLICY}/>
                           }}/>
                </div>

                <div className="ml-md-2 mt-2 mt-md-0">
                    <button onClick={accept} className="btn btn-sm btn-outline-primary px-2 py-1">
                        {t('close_and_continue')}
                    </button>
                </div>
            </div>
        </div>
    );
}


