import React from "react";
import StepTitle from "../components/StepTitle";

export default function EsgStep3() {
    return (<div className="step-3">
        <StepTitle>
            Para chegar lá você terá alguns desafios pela frente
        </StepTitle>

        <div className="text-left h5">
            <ul>
                <li>
                    Mostrar que está sintonizado com conceitos e práticas ESG e assim ter recursos para contratar
                    profissionais e serviços para implementar ações de transformação de impacto
                </li>

                <li>
                    Engajar os stakeholders com seus objetivos e também monitorar o andamento das ações implementadas
                </li>

                <li>
                    Descobrir a importância de comunicar os resultados alcançados e ter talentos e serviços para esse
                    fim
                </li>
            </ul>
        </div>
    </div>)
}