import React, {useEffect, useMemo} from "react";

import logo from "assets/img/logos/svg/default_2.svg"
import "./ErrorPageLayout.scss";
import {useTranslation} from "react-i18next";
import {TERMS_ROUTES} from "navigation/ROUTES";
import {useSelector} from "react-redux";
import GameEventSubscriber from "subscribers/GameEventSubscriber";
import TeamEventSubscriber from "subscribers/TeamEventSubscriber";
import AuthService from "services/player/AuthService";
import {default as GmAuthService} from "services/gameMaster/AuthService";
import TournamentEventSubscriber from "../../../../subscribers/TournamentEventSubscriber";

export default function ErrorPageLayout({children, alwaysShowReloadBtn = false}) {
    const {t} = useTranslation('common');
    const t2 = useTranslation('pages/common/errors', {keyPrefix: 'layout.buttons'}).t;

    const gameUrl = useSelector(state => state.game.gameInfo.gameUrl);

    const now = useMemo(() => (new Date()).getUTCFullYear(), []);
    const isAuthenticated = useMemo(() => {
        return AuthService.isAuthenticated() || GmAuthService.isAuthenticated();
    }, []);

    const handleLeave = () => {
        AuthService.leave();
        GmAuthService.leave();
        // eslint-disable-next-line no-restricted-globals
        location.href = gameUrl;
    };

    const handleReload = () => {
        // eslint-disable-next-line no-restricted-globals
        location.reload();
    };

    useEffect(() => {
        GameEventSubscriber.unsubscribe();
        TournamentEventSubscriber.unsubscribe();
        TeamEventSubscriber.unsubscribe();
    }, []);

    return (
        <div className="min-vw-100 min-vh-100 d-flex flex-column bg-dark text-white error-page">
            <div
                className="flex-fill d-flex justify-content-center align-items-center flex-column mb-4 pb-5 text-center">
                <img alt="Logo" className="mb-4 pb-4" src={logo}/>

                {children}

                <div className="d-flex flex-column flex-sm-row">
                    {(gameUrl || alwaysShowReloadBtn) && (
                        <button className="btn btn-info text-white my-3" onClick={handleReload}>{t2('refresh')}</button>
                    )}

                    {gameUrl && isAuthenticated && (
                        <button className="btn btn-link text-white" onClick={handleLeave}>
                            {t2('leave')}
                        </button>
                    )}
                </div>


                <p className="mx-3 mt-4 mb-1 text-white">
                    @ {now}

                    <a href="https://www.startupmundi.com.br/" className="text-white text-decoration-underline ml-1"
                       target="_blank" rel="noreferrer">
                        Mundi Game Experiences</a>. {t('rights_reserved')}
                </p>

                <p className="mb-0 small d-inline">
                    <a href={TERMS_ROUTES.TERMS_OF_USE} target="_blank"
                       className="text-white mr-1">{t('common:terms_of_use')}</a>
                    <a href={TERMS_ROUTES.COOKIES_POLICY} target="_blank"
                       className="text-white mr-1">{t('common:cookies_policy')}</a>
                    <a href={TERMS_ROUTES.PRIVACY_POLICY} target="_blank"
                       className="text-white">{t('common:privacy_policy')}</a>
                </p>
            </div>
        </div>
    );
}