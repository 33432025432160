export const SOUND_EFFECTS = {
    DEMO: '/audio/demo.mp3',
    BUTTON_PRESSED: '/audio/effects/button_pressed.mp3',

    QUESTIONS: {
        ANSWER_UPDATED: '/audio/questions/answer_updated.mp3',
        DRUM_ROLL: '/audio/questions/drum_roll.mp3',
        RIGHT_ANSWER: [
            "/audio/questions/right_answer_1.mp3", "/audio/questions/right_answer_2.mp3", "/audio/questions/right_answer_3.mp3",
            "/audio/questions/right_answer_4.mp3", "/audio/questions/right_answer_5.mp3", "/audio/questions/right_answer_6.mp3",
            "/audio/questions/right_answer_7.mp3", "/audio/questions/right_answer_8.mp3", "/audio/questions/right_answer_9.mp3"
        ],
        WRONG_ANSWER: [
            "/audio/questions/wrong_answer_1.mp3", "/audio/questions/wrong_answer_2.mp3", "/audio/questions/wrong_answer_3.mp3",
            "/audio/questions/wrong_answer_4.mp3", "/audio/questions/wrong_answer_5.mp3", "/audio/questions/wrong_answer_6.mp3",
            "/audio/questions/wrong_answer_7.mp3", "/audio/questions/wrong_answer_8.mp3"
        ],
    },

    UNPREDICTABILITY: {
        SUSPENSE_REVEAL: '/audio/unpredictabilities/reveal.mp3',
        SUSPENSE_REVEAL_OUTCOME: '/audio/unpredictabilities/reveal_outcome.mp3',
        POSITIVE_OUTCOME: '/audio/unpredictabilities/positive_outcome.mp3',
        NEGATIVE_OUTCOME: "/audio/unpredictabilities/negative_outcome.mp3",
        NEUTRAL_OUTCOME: '/audio/unpredictabilities/neutral_outcome.mp3'
    },

    RANKING: {
        REVEAL: '/audio/podium/reveal.mp3',
        1: '/audio/podium/first_place.mp3',
        2: '/audio/podium/second_place.mp3',
        3: '/audio/podium/third_place.mp3',
        4: '/audio/podium/fourth_place.mp3',
    },

    CARDS: {
        HIRED: '/audio/effects/card_hired.mp3',
    },

    ACCOUNTING: {
        INVESTMENT_RECEIVED: '/audio/effects/investment_received.mp3',
        ACCOUNTING_SIGNED: '/audio/effects/signing.mp3',
    },

    TOASTS: {
        SUCCESS: '/audio/toasts/success.mp3',
        ERROR: '/audio/toasts/error.mp3',
        INFO: ''
    }
}