import Utils from "./Utils";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import {i18n} from "../i18n";

i18n.loadNamespaces('utils/swall_modal_utils');
const t = i18n.getFixedT(null, 'utils/swall_modal_utils');

const SwalModalUtil = {
    confirmModal(title, message, confirmButtonText, cancelButtonText) {
        title = Utils.isset(title) ? title : t('confirm.title');
        message = Utils.isset(message) ? message : t('confirm.message');
        confirmButtonText = Utils.isset(confirmButtonText) ? confirmButtonText : t('confirm.button');
        cancelButtonText = cancelButtonText ?? t('confirm.close');

        return Swal.fire({
            title: title,
            text: message,
            showCancelButton: true,
            confirmButtonText: confirmButtonText,
            cancelButtonText: cancelButtonText,
            showClass: {
                popup: 'animate__animated animate__fadeInDown animate__faster',
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOutUp animate__faster',
            }
        }).then((result) => {
            return result.value;
        });
    },

    successModal(title, message, confirmButtonText, successCallback) {
        title = Utils.isset(title) ? title : t('success.title');
        confirmButtonText = Utils.isset(confirmButtonText) ? confirmButtonText : t('success.message');
        successCallback = Utils.isFunction(successCallback) ? successCallback : function () {
        };

        Swal.fire({
            title: title,
            text: message,
            //  icon: 'success',
            showCancelButton: false,
            confirmButtonText: confirmButtonText,
            showClass: {
                popup: 'animate__animated animate__fadeInDown animate__faster',
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOutUp animate__faster',
            }
        }).then((result) => {
            if (result.value) {
                successCallback();
            }
        });
    },

    errorModal(title, message, confirmButtonText) {
        title = Utils.isset(title) ? title : t('error.title');
        message = Utils.isset(message) ? message : t('error.message');
        confirmButtonText = Utils.isset(confirmButtonText) ? confirmButtonText : t('error.button');

        Swal.fire({
            title: title,
            text: message,
            // icon: 'error',
            showCancelButton: false,
            confirmButtonText: confirmButtonText,
            showClass: {
                popup: 'animate__animated animate__fadeInDown animate__faster',
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOutUp animate__faster',
            }
        }).then((result) => {
            return result.value;
        });
    },

    infoModal(title, message, confirmButtonText, successCallback) {
        confirmButtonText = Utils.isset(confirmButtonText) ? confirmButtonText : t('info.button');
        successCallback = Utils.isFunction(successCallback) ? successCallback : function () {
        };

        return Swal.fire({
            title: title,
            text: message,
            // icon: 'info',
            showCancelButton: false,
            confirmButtonText: confirmButtonText,
            showClass: {
                popup: 'animate__animated animate__fadeInDown animate__faster',
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOutUp animate__faster',
            }
        }).then((result) => {
            if (result.value) {
                successCallback();
            }
        });
    },


    swal() {
        return Swal;
    }
}

export default SwalModalUtil;

