import {createSlice} from '@reduxjs/toolkit'
import {ACCELERATION_BONUSES} from "../../config/CONSTANTS";
import CardUtil from "../../utils/CardUtil";
import {SKILL_GENDER_GROUP, SKILL_ICONS_GROUP, SKILL_ICONS_METADATA} from "../../config/SKILL_ICONS";
import ACCOUNTING_COMMON_CONFIG from "../../config/ACCOUNTING_COMMON_CONFIG";
import ACCOUNTING_ESG_CONFIG from "../../config/ACCOUNTING_ESG_CONFIG";
import Utils from "../../utils/Utils";
import GameUtil from "../../utils/GameUtil";
import ACCOUNTING_SMGX_CONFIG from "../../config/ACCOUNTING_SMGX_CONFIG";

const INITIAL_STATE = {
    startup: null,
    valuePropositions: {},
    founders: {},
    foundersIcons: {},
    skills: [],
    services: [],
    questions: {},
    unpredictabilities: {},

    accounting: {},
    pitchBonus: {},
    signedAccounting: {},
    hiredAccountingService: false,
    pitchData: {},

    accelerationBonuses: {},
    accelerationBonusesDetails: {},
    accelerationBonusesCards: {},
    skillsGroups: {
        race: {},
        gender: {}
    },
};


const initialState = {
    1: structuredClone(INITIAL_STATE),
    2: structuredClone(INITIAL_STATE),
    3: structuredClone(INITIAL_STATE),
    4: structuredClone(INITIAL_STATE),

    startups: {
        1: null,
        2: null,
        3: null,
        4: null
    },

    pitchBonus: {}
};


// --------------- FACADES ---------------
function touchBonusServices(isRefund, state, team, hiringDetails) {

    if (GameUtil.isCurrentGameSmgx()) {
        // accounting card bonus
        if (hiringDetails.card.id === ACCOUNTING_SMGX_CONFIG.ACCOUNTING_CARD_ID)
            state[team].hiredAccountingService = isRefund ? false : hiringDetails;
    }
}

function touchHirableCardBonus(isRefund, state, team, hiringDetails) {
    if (!hiringDetails.card.relatedBonus)
        return;

    if (isRefund) {
        recalculateRelatedBonus(state, team, hiringDetails);
    } else {
        touchRelatedBonus(state, team, hiringDetails)
    }
}


// --------------- CALCULATIONS ---------------
function touchDiversityBonus(isRefund, state, team, hiringDetails) {
    // this may be an older sessions that does not have this kind of information stored
    // (i.e. a session that does not store the icon number when hiring a skill)
    if (!hiringDetails.iconNumber || !SKILL_ICONS_METADATA[hiringDetails.iconNumber] || !hiringDetails.gender)
        return;

    const teamGroupData = state[team].skillsGroups;
    const iconMetadata = SKILL_ICONS_METADATA[hiringDetails.iconNumber];
    const race = iconMetadata.race;
    const gender = hiringDetails.gender;

    // update counts
    state[team].skillsGroups.race[race] = sumOrDecrement(teamGroupData.race[race], isRefund);
    state[team].skillsGroups.gender[gender] = sumOrDecrement(teamGroupData.gender[gender], isRefund);


    // -------------------------- calculate race bonus --------------------------
    const totalCards = teamGroupData.race[SKILL_ICONS_GROUP.MAJORITY] + teamGroupData.race[SKILL_ICONS_GROUP.MINORITY];
    const raceMinorityPercent = Utils.percent(totalCards, teamGroupData.race[SKILL_ICONS_GROUP.MINORITY]) || 0;

    state[team].accelerationBonuses[ACCELERATION_BONUSES.RACE_DIVERSITY] = calculateBonusValue(
        ACCOUNTING_COMMON_CONFIG.DIVERSITY_RACE_BONUS,
        raceMinorityPercent
    );


    // -------------------------- calculate gender bonus --------------------------
    const maleCount = teamGroupData.gender[SKILL_GENDER_GROUP.MALE] || 0;
    const femaleCount = teamGroupData.gender[SKILL_GENDER_GROUP.FEMALE] || 0;
    const otherCount = teamGroupData.gender[SKILL_GENDER_GROUP.OTHER] || 0;

    const femalePercentage = femaleCount * 100 / (maleCount + femaleCount);
    const otherBonusConfig = ACCOUNTING_COMMON_CONFIG.DIVERSITY_GENDER_BONUS[SKILL_GENDER_GROUP.OTHER];
    const femaleBonusConfig = ACCOUNTING_COMMON_CONFIG.DIVERSITY_GENDER_BONUS[SKILL_GENDER_GROUP.FEMALE];

    // for each X otherCount, give Y bonus
    let totalGenderBonus = Math.floor(otherCount / otherBonusConfig.AMOUNT) * otherBonusConfig.BONUS;

    if (femalePercentage >= femaleBonusConfig.MIN && femalePercentage <= femaleBonusConfig.MAX)
        totalGenderBonus += femaleBonusConfig.BONUS;

    state[team].accelerationBonuses[ACCELERATION_BONUSES.GENDER_DIVERSITY] = totalGenderBonus;
}

function touchRelatedBonus(state, team, hiringDetails) {
    const hiredCardRelatedGoals = hiringDetails.card.relatedBonus;


    if (!hiredCardRelatedGoals?.length)
        return;

    if (state[team].accelerationBonusesCards[hiringDetails.id])
        return;


    const bonusChanged = [];

    for (let i = 0; i < hiredCardRelatedGoals.length; i++) {
        const relatedBonus = hiredCardRelatedGoals[i];
        const relatedBonusConfig = ACCOUNTING_ESG_CONFIG.ACCELERATION_BONUS_VALUES[relatedBonus];

        if (!relatedBonusConfig)
            continue;


        // check if already has max bonus
        const existingCardsRelatedToBonus = state[team].accelerationBonusesDetails[relatedBonus]?.length ?? 0;
        const cardsToGetMaxBonus = parseInt(Object.keys(relatedBonusConfig).at(-1));

        if (existingCardsRelatedToBonus === cardsToGetMaxBonus)
            continue;


        bonusChanged.push(relatedBonus);

        state[team].accelerationBonusesCards[hiringDetails.id] = relatedBonus;
        state[team].accelerationBonusesDetails[relatedBonus] = addBonusCardToArrayOfDetails(
            state[team].accelerationBonusesDetails[relatedBonus],
            hiringDetails
        );

        break;
    }

    new Set(bonusChanged).forEach(bonus => {
        state[team].accelerationBonuses[bonus] = calculateBonusValue(
            ACCOUNTING_ESG_CONFIG.ACCELERATION_BONUS_VALUES[bonus],
            state[team].accelerationBonusesDetails[bonus]
        );
    });
}

function recalculateRelatedBonus(state, team, hiringDetails) {
    if (!state[team].accelerationBonusesCards[hiringDetails.id])
        return;

    // reset everything related bo bonuses
    ['accelerationBonuses', 'accelerationBonusesDetails', 'accelerationBonusesCards'].forEach(bonus => {
        state[team][bonus] = {};
    });


    // recalculate
    const hiredSkills = CardUtil.hiredCardsToArray(state[team].skills);
    const hiredServices = CardUtil.hiredCardsToArray(state[team].services);
    const allHiredCard = [...hiredSkills, ...hiredServices].sort((a, b) => a.at - b.at);

    for (let i = 0; i < allHiredCard.length; i++) {
        touchRelatedBonus(state, team, allHiredCard[i]);
    }
}


// --------------- UTILS ---------------
function sumOrDecrement(value, isRefund) {
    return isRefund
        ? (value || 1) - 1
        : (value || 0) + 1;
}

function addBonusCardToArrayOfDetails(currentBonusDetails, hiringDetails) {
    if (!currentBonusDetails)
        currentBonusDetails = [];

    currentBonusDetails.push(hiringDetails);

    return currentBonusDetails;
}

function calculateBonusValue(bonusValuesAsObj, bonusDetails) {
    const bonusValues = Object.entries(bonusValuesAsObj);
    const teamValue = Array.isArray(bonusDetails) ? bonusDetails.length : bonusDetails;

    // teamValue is less than the first bonus (i.e. teamValue = 4; bonus = 5%)
    if (bonusValues[0][0] > teamValue)
        return false;

    for (let i = bonusValues.length - 1; i >= 0; i--) {
        const bonus = bonusValues[i];

        if (teamValue >= parseInt(bonus[0]))
            return bonus[1];
    }

    return false;
}


export const TeamsSlice = createSlice({
    name: 'session',
    initialState,
    reducers: {
        updateStartup(state, {payload}) {
            const {team, startup} = payload;
            state[team].startup = startup;
            state.startups[team] = startup;
        },

        updateFounder(state, {payload}) {
            const {team, number, founder} = payload;
            state[team].founders[number] = founder;
        },

        updateFounderIcon(state, {payload}) {
            const {team, number, icon} = payload;
            state[team].foundersIcons[number] = icon;
        },

        addSkill(state, {payload}) {
            const {team, step, data} = payload;
            if (!state[team].skills[step]) state[team].skills[step] = [];
            state[team].skills[step].push(data);

            state[team].skills[step] = state[team].skills[step].sort((a, b) => a.hiredAt - b.hiredAt);
            touchDiversityBonus(false, state, team, data);
            touchHirableCardBonus(false, state, team, data);
        },

        removeSkill(state, {payload}) {
            const {team, step, hiringId} = payload;

            if (!state[team].skills[step])
                return;

            const relatedCard = state[team].skills[step].find(card => card.id === hiringId);

            if (!relatedCard)
                return;

            touchDiversityBonus(true, state, team, relatedCard);
            touchHirableCardBonus(true, state, team, relatedCard);

            state[team].skills[step] = state[team].skills[step].filter(card => card.id !== hiringId);
        },

        addService(state, {payload}) {
            const {team, step, data} = payload;
            if (!state[team].services[step]) state[team].services[step] = [];
            state[team].services[step].push(data);

            state[team].services[step] = state[team].services[step].sort((a, b) => a.hiredAt - b.hiredAt);
            touchBonusServices(false, state, team, data);
            touchHirableCardBonus(false, state, team, data);
        },

        removeService(state, {payload}) {
            const {team, step, hiringId} = payload;

            if (!state[team].services[step])
                return;

            const relatedCard = state[team].services[step].find(card => card.id === hiringId);

            if (!relatedCard)
                return;

            touchBonusServices(true, state, team, relatedCard);
            touchHirableCardBonus(true, state, team, relatedCard);

            state[team].services[step] = state[team].services[step].filter(card => card.id !== hiringId);
        },

        setSkills(state, {payload}) {
            const {team, data} = payload;
            state[team].skills = data;

            const hiredCards = CardUtil.hiredCardsToArray(data);

            for (let i = 0; i < hiredCards.length; i++) {
                touchDiversityBonus(false, state, team, hiredCards[i]);
                touchHirableCardBonus(false, state, team, hiredCards[i]);
            }
        },

        setServices(state, {payload}) {
            const {team, data} = payload;
            state[team].services = data;

            const hiredCards = CardUtil.hiredCardsToArray(data);

            for (let i = 0; i < hiredCards.length; i++) {
                touchBonusServices(false, state, team, hiredCards[i]);
                touchHirableCardBonus(false, state, team, hiredCards[i]);
            }
        },

        setQuestions(state, {payload}) {
            const {team, questions} = payload;
            state[team].questions = questions;
        },

        setQuestionForStep(state, {payload}) {
            const {team, step, question} = payload;
            state[team].questions[step] = question;
        },

        updateQuestion(state, {payload}) {
            const {team, step, data} = payload;
            if (!state[team].questions[step]) return;
            state[team].questions[step] = {...state[team].questions[step], ...data};
        },

        updateValueProposition(state, {payload}) {
            const {team, number, valueProposition} = payload;
            state[team].valuePropositions[number] = valueProposition;
        },

        setUnpredictabilities(state, {payload}) {
            const {team, data} = payload;
            state[team].unpredictabilities = data;
        },

        setUnpredictabilityForStep(state, {payload}) {
            const {team, step, data} = payload;
            state[team].unpredictabilities[step] = data;
        },

        updateUnpredictability(state, {payload}) {
            const {team, step, data} = payload;
            if (!state[team].unpredictabilities[step]) return;
            state[team].unpredictabilities[step] = {...state[team].unpredictabilities[step], ...data};
        },

        setAccounting(state, {payload}) {
            const {team, accounting} = payload;
            state[team].accounting = accounting;
        },

        setPitchBonus(state, {payload}) {
            const {step, team, amount} = payload;
            state[team].pitchBonus[step] = amount;
            state.pitchBonus[step] = {team, amount};
        },

        setSignedAccounting(state, {payload}) {
            const {step, team, data} = payload;
            state[team].signedAccounting[step] = data;
        },

        setPitchData(state, {payload}) {
            const {pitch, team, data} = payload;
            state[team].pitchData[pitch] = data;
        },

        reset(state, {payload}) {
            state = {
                1: structuredClone(INITIAL_STATE),
                2: structuredClone(INITIAL_STATE),
                3: structuredClone(INITIAL_STATE),
                4: structuredClone(INITIAL_STATE),
            }
        }
    }
});

export const {
    updateStartup,
    updateFounder,
    updateFounderIcon,
    addSkill,
    removeSkill,
    addService,
    removeService,
    setSkills,
    setServices,
    setQuestions,
    setQuestionForStep,
    updateQuestion,
    updateValueProposition,
    setUnpredictabilities,
    setUnpredictabilityForStep,
    updateUnpredictability,
    setAccounting,
    setPitchBonus,
    setSignedAccounting,
    setPitchData,
    reset,
} = TeamsSlice.actions;
export default TeamsSlice.reducer;