import React, {useMemo, useState} from "react";
import {Portal} from "react-portal";

import "./TutorialModal.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import EsgStep1 from "./stepsEsg/EsgStep1";
import EsgStep2 from "./stepsEsg/EsgStep2";
import EsgStep3 from "./stepsEsg/EsgStep3";
import EsgStep4 from "./stepsEsg/EsgStep4";
import EsgStep5 from "./stepsEsg/EsgStep5";
import GameUtil from "../../../utils/GameUtil";
import SmgxStep1 from "./stepsSmgx/SmgxStep1";
import SmgxStep2 from "./stepsSmgx/SmgxStep2";
import SmgxStep3 from "./stepsSmgx/SmgxStep3";
import SmgxStep4 from "./stepsSmgx/SmgxStep4";
import SmgxStep5 from "./stepsSmgx/SmgxStep5";


export default function TutorialModal({onClose}) {
    const [step, setStep] = useState(0);

    const stepsToShow = useMemo(() => {
        return GameUtil.isCurrentGameEsg()
            ? [<EsgStep1/>, <EsgStep2/>, <EsgStep3/>, <EsgStep4/>, <EsgStep5 onClose={onClose}/>]
            : [<SmgxStep1/>, <SmgxStep2/>, <SmgxStep3/>, <SmgxStep4/>, <SmgxStep5 onClose={onClose}/>]
    }, [onClose]);

    const handleNextStep = () => {
        if (step === stepsToShow.length - 1) {
            // onClose();
        } else {
            setStep(step + 1);
        }
    };

    const handlePreviousStep = () => {
        if (step > 0)
            setStep(step - 1);
    }

    return (<Portal>
        <div id="first-access-modal"
             className="animate__animated animate__fadeIn px-4 py-4 d-flex justify-content-center">
            <div className="text-white text-center d-flex flex-column" style={{maxWidth: 1000, maxHeight: 1000}}>
                <div className="flex-fill d-flex align-items-center pb-5 mb-5">
                    <div className="animate__animated animate__fadeIn">
                        <div key={step} className="animate__animated animate__fadeInDown animate__faster content">
                            {stepsToShow[step]}
                        </div>
                    </div>
                </div>

                <div className="mt-4 animate__animated animate__fadeIn">
                    <div className="d-flex justify-content-center mb-1 text-light">
                        <button type="button" onClick={handlePreviousStep} disabled={step === 0}
                                className={"btn btn-sm btn-outline-light border-0 px-2 py-0 mr-1 "}>
                            <FontAwesomeIcon icon={['fas', 'angle-left']} className="fa-2x"/>
                        </button>

                        <h5 className="mb-0 mx-4 font-weight-bold">
                            {step + 1}
                            <span className="small">/</span>
                            {stepsToShow.length}
                        </h5>

                        <button type="button" onClick={handleNextStep} disabled={step === stepsToShow.length - 1}
                                className={"btn btn-sm btn-outline-light border-0 px-2 py-0 mr-1 "}>
                            <FontAwesomeIcon icon={['fas', 'angle-right']} className="fa-2x"/>
                        </button>
                    </div>

                    <button className="btn btn-outline-secondary border-0 mt-1" onClick={onClose}>
                        Pular tutorial
                    </button>
                </div>
            </div>
        </div>
    </Portal>);
}

