// credits to: https://stackoverflow.com/a/53837442

import {useState} from "react";

/**
 * Hook to force update a component
 *
 * @return {(function(): void)|*}
 */
export default function useForceUpdate() {
    const [, setValue] = useState(0);
    return () => setValue(value => value + 1);
}