import React, {useMemo} from "react";

import "./TotalsSummary.scss";
import {useSelector} from "react-redux";
import i18nUtil from "../../../../../../../utils/i18nUtil";
import {useTranslation} from "react-i18next";
import ACCOUNTING_ESG_CONFIG from "../../../../../../../config/ACCOUNTING_ESG_CONFIG";
import {GAME_STEPS} from "../../../../../../../config/CONSTANTS";
import useTranslationForGame from "../../../../../../../hooks/useTranslationForGame";

export default function TotalsSummary() {
    const {t} = useTranslation('pages/common/board/accounting', {keyPrefix: 'texts'});
    const tfg = useTranslationForGame(t);

    const showBoardForTeam = useSelector(state => state.session.showBoardForTeam);
    const accountingDetails = useSelector(state => state.teams[showBoardForTeam].accounting.totals);
    const pitch1Bonus = ACCOUNTING_ESG_CONFIG.GAME_PITCH_INVESTMENT[GAME_STEPS.PITCH_1][1];

    const balance = useMemo(() => {
        const spent = (accountingDetails.servicesAndSkillsInvestment || 0) + (accountingDetails.extraCosts || 0);
        return pitch1Bonus - spent;
    }, [accountingDetails, pitch1Bonus]);

    const impactElementClass = useMemo(() => {
        return accountingDetails.acceleration < 0 ? 'text-danger' : 'text-success';
    }, [accountingDetails]);

    return (
        <>
            <h5 className="text-uppercase text-warning mb-2">{t('totals')}</h5>
            <div className="row accounting-summary mb-3">
                <div className="col-6 details">
                    <div>
                        <p>{t('budget')}</p>
                        <p>{i18nUtil.formatMoney(pitch1Bonus)}</p>
                    </div>

                    <div>
                        <p>{t('spent_in_market')}</p>
                        <p>{i18nUtil.formatMoney(accountingDetails.servicesAndSkillsInvestment || 0)}</p>
                    </div>
                    <div>
                        <p>{t('extra_costs')}</p>
                        <p>{i18nUtil.formatMoney(accountingDetails.extraCosts || 0)}</p>
                    </div>
                    <div>
                        <p>{t('balance')}</p>
                        <p>{i18nUtil.formatMoney(balance)}</p>
                    </div>
                </div>

                <div className="col-6 details">
                    <div>
                        <p>{t('available_for_market')}</p>
                        <p>{i18nUtil.formatMoney(accountingDetails.availableForMarket || 0)}</p>
                    </div>
                    <div>
                        <p>{t('months')}</p>
                        <p>{accountingDetails.monthsAdvanced}</p>
                    </div>
                    <div>
                        <p>{tfg('revenue')}</p>
                        <p>{i18nUtil.formatDecimal(accountingDetails.revenue || 0)}</p>
                    </div>
                    <div>
                        <p>{tfg('acceleration')}</p>
                        <p className={"font-weight-bold " + impactElementClass}>
                            {i18nUtil.formatPercent(accountingDetails.acceleration || 0)}
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}