import store from "../../redux/store";
import Utils from "../../utils/Utils";
import LoggerService from "./LoggerService";
import {SOUND_EFFECTS} from "../../config/AUDIO_FILES";

let _playingCount = 0;

function isMuted() {
    return store.getState().session.volumeConfig.isMuted;
}

const AudioService = {
    /**
     * Creates an HTMLAudioElement synchronously.
     *
     * If {audioToPlay} is an array, a random sound will be selected from it.
     *
     * @param audioToPlay {string|array}
     * @return {HTMLAudioElement}
     */
    createAudio(audioToPlay) {
        try {
            audioToPlay = Array.isArray(audioToPlay) ? Utils.randomFromArray(audioToPlay) : audioToPlay;

            const audio = new Audio(audioToPlay);
            audio.volume = store.getState().session.volumeConfig.volume;
            audio.addEventListener('ended', () => _playingCount--);
            audio.addEventListener('play', () => _playingCount++);

            return audio;
        } catch (e) {
            LoggerService.error(e, {action: '[AudioService] createAudio()', params: audioToPlay});
            return new Audio('');
        }
    },

    /**
     * Creates an HTMLAudioElement asynchronously.
     * The promise will only resolve when there's no other audio playing.
     *
     * This function will call AudioService.createAudio() when there's no audio playing.
     * See AudioService.createAudio() for more information about the returned data.
     *
     * @param audioToPlay {string|array}
     * @return {Promise<HTMLAudioElement>} |
     */
    async queue(audioToPlay) {
        return new Promise(resolve => {
            try {
                const checkIfCanPlayFn = () => {
                    if (_playingCount > 0) {
                        setTimeout(() => checkIfCanPlayFn(audioToPlay), 200);
                    } else {
                        resolve(AudioService.createAudio(audioToPlay));
                    }
                }

                checkIfCanPlayFn();
            } catch (e) {
                LoggerService.error(e, {action: '[AudioService] queue()', params: audioToPlay});
                resolve(new Audio(''));
            }
        });
    },

    /**
     * Asynchronously plays an audio.
     *
     * @param audioToPlay {string} the url to the file to play
     * @param afterDelay {number} milliseconds to wait after an audio has finished playing to resolve the promise (use to add a delay between sounds)
     * @return {Promise}
     */
    async play(audioToPlay, afterDelay = 0) {
        return new Promise(resolve => {
            if (isMuted())
                return resolve();

            const audio = AudioService.createAudio(audioToPlay);
            audio.onended = () => setTimeout(resolve, afterDelay);
            audio.play()
        });
    },

    /**
     * Asynchronously plays an audio.
     *
     * @param audioToPlay {string} the url to the file to play
     * @return {void}
     */
    async playUiSound(audioToPlay) {
        if (isMuted())
            return;

        setTimeout(() => {
            const audio = AudioService.createAudio(audioToPlay);
            audio.volume = store.getState().session.volumeConfig.volume / 2;
            audio.play();
        }, 0);
    },

    playButtonClicked() {
        AudioService.playUiSound(SOUND_EFFECTS.BUTTON_PRESSED);
    }
};

export default AudioService;